.mn-toolbar {
  background-color: var(--global-colors-ui-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--global-spacing-lg);
  font-family: var(--global-font-regular);
}

.mn-toolbar > [class$="-col"] {
  display: flex;
  align-items: center;
}

.mn-toolbar > div > div {
  font-size: var(--global-font-size-xxl);
  color: var(--global-colors-ink-dark);
  margin-right: var(--global-spacing-lg);
}

.mn-toolbar .mn-toolbar__nav-index {
  font-size: var(--global-font-size-sm);
  margin-right: var(--global-spacing-md);
}

.mn-toolbar .mn-toolbar__right-col .mn-toolbar__nav-loader {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid var(--global-colors-ink-light);
  border-top-color: transparent;
  animation: spinner 0.6s linear infinite;
  margin-right: 15px;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.mn-toolbar .mn-toolbar__nav-loader-container {
  padding-right: 24px;
}

.button-send-msg {
  margin-left: 10px;
  cursor: var(--global-cursor-not-allowed) !important;
}

.mn-toolbar .mn-toolbar__left-col .button-send-msg #sendMsg,
.button-send-msg .button,
.button-send-msg span {
  cursor: var(--global-cursor-not-allowed) !important;
}

.name-plate-pointer {
  content: "\2022";
  display: inline-block;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 4px;
  height: 8px;
  width: 8px;
  background-color: #cdd1d6;
  border-radius: 50px;
}
