.map-container {
  width: 1440px;
  margin: 0 auto;
}

.map {
  width: 688px;
  height: 448px;
  float: left;
  margin-bottom: 16px;
}

.drop-area {
  width: 100%;
  height: 675px; /* 778 */
}

.drop-area .mn-mw-drop-area {
  width: 100%;
  height: 100%;
}
