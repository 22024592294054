.mn-top-bar {
  padding: 0 var(--global-spacing-lg);
  font-size: var(--global-font-size-sm);
  font-family: var(--global-font-medium);
  margin: 0;
  border-bottom: 1px solid var(--global-colors-sky-dark);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  background-color: var(--global-colors-sky-lighter);
}

.mn-top-bar__title .svg {
  line-height: 12px;
}

.mn-top-bar__navigation {
  display: flex;
  align-items: center;
}

.mn-top-bar__navigation > *:first-child {
  margin-right: 0px;
}

.mn-top-bar__navigation > *:nth-child(2) {
  margin-right: 17px;
}

.mn-top-bar__navigation > *:nth-child(3) {
  margin-right: 8px;
}

.mn-top-bar__navigation > *:nth-child(4) {
  margin-right: 13px;
}

.mn-top-bar__title ul,
.mn-top-bar__navigation nav ul {
  padding-left: 0;
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
}

.mn-top-bar__title ul li:not(.mn-top-bar__title__separator),
.mn-top-bar__navigation nav ul li {
  margin-bottom: 0;
}

.mn-top-bar__navigation nav ul li {
  padding: 0 16px;
  position: relative;
  overflow: hidden;
  cursor: var(--global-cursor-pointer);
}

.mn-top-bar__navigation nav ul li span:not(.ripple) {
  padding: 21px 0;
  display: block;
  border-bottom: 2px solid transparent;
  position: relative;
  height: 60px;
}

.mn-top-bar__navigation
  nav
  ul
  li.mn-top-bar__menu-item--active
  span:not(.ripple) {
  border-bottom: 2px solid var(--global-colors-ink-dark);
}

.mn-top-bar__title__separator {
  padding: 0 8px;
  height: 12px;
}

.mn-top-bar__splitter {
  float: left;
  height: 24px;
  width: 1px;
  background-color: var(--global-colors-sky-sky);
}

.mn-top-bar .user-notification,
.mn-top-bar .user-help {
  position: relative;
  cursor: var(--global-cursor-pointer);
  background-color: transparent;
  width: 32px;
  height: 32px;
  padding: 6px 9px;
}

.mn-top-bar .user-notification:hover,
.mn-top-bar .user-help:hover {
  background-color: var(--global-colors-sky-light);
  border-radius: 4px;
}

.mn-top-bar .user-profile {
  position: relative;
  cursor: var(--global-cursor-pointer);
  width: 32px;
  padding: 2px;
  height: 32px;
}

.mn-top-bar .user-profile .mn-menu-balloon,
.mn-top-bar .user-notification .notification-menu,
.mn-top-bar .user-help .mn-menu-balloon {
  position: absolute;
  right: 0;
  top: 41px;
}

.mn-top-bar .user-help .mn-menu-balloon {
  margin-bottom: var(--global-spacing-md);
}

.mn-top-bar .user-help .mn-menu-balloon header + div {
  border-top: 1px solid var(--global-colors-sky-dark);
  padding: var(--global-spacing-sm) 16px 0;
}

.mn-top-bar .user-help .mn-menu-balloon header + div p {
  font-size: var(--global-font-size-xs);
  color: var(--global-colors-ink-light);
  margin: 0;
}

.mn-top-bar .user-help .mn-menu-balloon header + div ul li {
  padding: var(--global-spacing-xs) 0;
}

.mn-top-bar .user-help .mn-menu-balloon header + div ul li:first-child {
  margin-top: var(--global-spacing-xs);
}

.mn-top-bar .user-help .mn-menu-balloon header + div ul li:hover {
  background-color: transparent;
}

.mn-top-bar .user-help .mn-menu-balloon header + div ul li a {
  color: var(--global-colors-ink-ink);
}

.mn-top-bar .user-help .mn-menu-balloon header + div ul li a .svg {
  color: var(--global-colors-ink-light);
  margin-right: var(--global-spacing-xs);
}

.counter {
  float: right;
}

/* Ripple */
.ripple {
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: 600ms ease 1 forwards ripple;
}

.mn-top-bar__navigation nav ul li > .ripple,
.mn-top-bar__navigation nav ul li:hover {
  background-color: #edeef0;
}

.mn-top-bar-company-name-text-container {
  height: 60px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mn-top-bar-company-name-text {
  color: #031832;
  font-size: var(--global-font-size-xs);
  font-family: var(--global-font-medium);
}

@keyframes ripple {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(10);
    opacity: 0.375;
  }
  100% {
    transform: scale(35);
    opacity: 0;
  }
}
/* End Ripple */
