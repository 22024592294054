.mn-driver-summary {
  display: flex;
  justify-content: space-between;
  background-color: var(--global-colors-ui-white);
  padding: var(--global-spacing-xs) var(--global-spacing-lg);
  font-family: var(--global-font-regular);
  height: 96px;
  overflow: hidden;
  width: 100%;
  border-bottom: 1px solid rgb(0 0 0 / 8%);
}

.mn-driver-summary--show {
  height: auto;
}

.mn-driver-summary__left-col {
  width: 85%;
}

.mn-driver-summary__left-col,
.mn-driver-summary__right-col,
.mn-driver-summary__details {
  display: flex;
  align-items: flex-start;
}

.mn-driver-summary__details {
  flex-wrap: wrap;
  width: 80%;
}

.mn-driver-summary__details > * {
  width: 33%;
  line-height: 20px;
  padding: var(--global-spacing-sm) var(--global-font-size-md);
  font-size: var(--global-font-size-xs);
}

.mn-driver-summary__details-item-container > * > .svg {
  margin-right: var(--global-font-size-md);
  color: var(--global-colors-ink-light);
}

.mn-driver-summary__icon-status {
  position: relative;
  z-index: 2;
  margin: var(--global-spacing-lg) var(--global-spacing-lg)
    var(--global-spacing-lg) 0;
}

.mn-driver-summary__icon {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 150px;
  overflow: hidden;
  background-color: var(--global-colors-sky-lighter);
}
.mn-driver-summary__icon > svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mn-driver-summary__icon-status .signal-status {
  position: absolute;
  bottom: 1px;
  right: 1px;
}
