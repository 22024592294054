.public-route-planning-plan-selector-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  width: 100%;
  border-bottom: 1px solid var(--global-colors-sky-sky);
}

.public-route-planning-plan-selector-right-section {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.public-route-planning-plan-selector-right-section-status {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
  margin-right: 20px;
}

.public-route-planning-switch-element {
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 15px;
  height: 100%;
  cursor: pointer;
  font-family: var(--global-font-regular);
  font-size: 14px;
  margin-left: 20px;
}

.public-route-planning-switch-element-selected {
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 15px;
  height: 100%;
  cursor: pointer;
  border-bottom: 2px solid black;
  font-family: var(--global-font-regular);
  font-size: 14px;
  margin-left: 30px;
}

.public-route-planning-calendar-icon-background {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: var(--global-colors-sky-sky);
  width: 32px;
  height: 32px;
  border-radius: 4px;
}

.public-route-planning-plan-summary-container {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 10px;
  gap: 15px;
  border-bottom: 1px solid var(--global-colors-sky-sky);
}

.public-route-planning-plan-summary-title {
  font-size: 14px;
  font-weight: 500;
  font-family: var(--global-font-regular);
}

.public-route-planning-plan-summary-elements-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.public-route-planning-plan-summary-elements {
  display: flex;
  flex-direction: row;
  gap: 60px;
  justify-content: flex-start;
  align-items: center;
  margin-left: 20px;
}

.public-route-planning-plan-summary-buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  margin-left: 20px;
}

.public-route-planning-plan-summary-element {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 65px;
}

.public-route-planning-plan-summary-element-formatted {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.public-route-planning-plan-summary-element-formatted-textone {
  font-size: 10px;
  font-family: var(--global-font-regular);
  color: var(--global-colors-ink-light);
  white-space: nowrap;
}

.public-route-planning-plan-summary-element-formatted-texttwo {
  font-size: 14px;
  font-family: var(--global-font-regular);
}

.public-route-planning-export .dropdown-list {
  background-color: var(--global-colors-ui-white);
  position: absolute;
  right: 0px;
  width: max-content;
}

.public-route-planning-subtopbar {
  height: 82px;
  width: 100%;
  margin: 0;
  background-color: var(--global-colors-ui-white);
  border-bottom: 1px solid var(--global-colors-sky-sky);
  padding: 25px 24px;
  align-items: center;
}

.public-route-planning-subsection-name {
  max-height: 28px;
  max-width: 200px;
  color: #031832;
  font-family: var(--global-font-regular);
  font-size: 24px;
  letter-spacing: 0;
  line-height: 28px;
  margin: 0;
  float: left;
}
.public-route-planning-export .button:first-child {
  min-width: 100px;
}
