.mn-menu-search-action {
  min-height: 238px;
  width: 326px;
  border-radius: 4px 4px 4px 4px;
  border-bottom: none;
  border: 1px solid var(--global-colors-sky-sky);
  background-color: var(--global-colors-ui-white);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
  position: relative;
  font-family: var(--global-font-regular);
  z-index: 99;
  margin-right: 4px;
}

.mn-menu-search-report {
  width: 326px;
  border-radius: 4px 4px 4px 4px;
  border-bottom: none;
  border: 1px solid var(--global-colors-sky-sky);
  background-color: var(--global-colors-ui-white);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
  position: absolute;
  font-family: var(--global-font-regular);
  z-index: 99;
  margin-right: 4px;
}

.mn-menu-search-report .slider-container {
  margin-bottom: 0px;
}

.mn-menu-search-action .search-field input {
  min-height: 32px;
  max-height: 32px;
}

.find-button {
  font-size: 14px;
  font-family: var(--global-font-light);
  float: right;
  margin-top: 13px;
  margin-bottom: 20px;
}

.mn-menu-search-report .find-button {
  margin-top: 21px;
  min-height: 32px;
  max-height: 32px;
}

.find-button .button--is-loading {
  width: 53px;
  background: var(--global-colors-ui-primary);
}

.vehicles-found {
  max-height: 260px;
  width: 326px;
  margin-top: -10px;
  margin-left: -1px;
  background-color: var(--global-colors-sky-lighter);
  border: 1px solid var(--global-colors-sky-sky);
  border-top: none;
  border-bottom: none;
  padding-top: 12px;
  overflow: auto;
}

.vehicles-found .mn-vehicle-box__lower-row .svg {
  margin-top: 1px;
}

.result-label {
  height: 16px;
  min-width: 90px;
  color: var(--global-colors-ink-dark);
  font-family: var(--global-font-semibold);
  font-size: 12px;
  margin-left: 16px;
  margin-bottom: 8px;
}

.reset-button-card {
  background-color: var(--global-colors-sky-lighter);
  height: 66px;
  width: 325px;
  border-radius: 0 0 4px 4px;
  border: 1px solid var(--global-colors-sky-sky);
  border-top: none;
  border-left: none;
  padding-right: 16px;
  padding-top: 16px;
}

.reset-button-card .reset-button {
  height: 32px;
  width: 62px;
  font-size: 14px;
  font-family: var(--global-font-semibold);
  float: right;
}

.vehicles-found .mn-vehicle-box {
  max-width: 100%;
  cursor: var(--global-cursor-pointer);
}

.vehicles-found .vehiclebox-container:hover {
  background-color: var(--global-colors-ui-sky-sky--hover);
}

.vehicles-found .mn-vehicle-box--active,
.vehicles-found .mn-vehicle-box .ripple {
  background-color: transparent;
  border-right: 0;
}
