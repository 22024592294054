.zoom-control,
.street-view {
  display: inline-flex;
  flex-direction: column;
  border-radius: 4px;
  overflow: hidden;
  user-select: none;
  min-width: 32px;
  min-height: 32px;
  box-sizing: border-box;
  box-shadow: rgb(0 0 0 / 30%) 1px 2px 4px -1px;
  height: fit-content;
}

.map-actions {
  display: inline-flex;
  flex-direction: column;
  border-radius: 4px;
  overflow: hidden;
  user-select: none;
  min-width: 32px;
  min-height: 32px;
  box-sizing: border-box;
  /* box-shadow: rgb(0 0 0 / 30%) 1px 2px 4px -1px; */
  height: fit-content;
}

.zoom-control button,
.street-view button,
.map-actions button {
  border: 0;
  font-family: var(--global-font-regular);
  padding: 9px;
  background-color: var(--global-colors-ink-ink);
  margin: 0;
  transition: all 0.1s ease;
  outline: none;
}

.zoom-control .zoom-control__plus-disabled,
.zoom-control .zoom-control__minus-disabled {
  pointer-events: none;
  background-color: var(--global-colors-ui-sky-sky--focus);
}

.street-view button {
  padding: 7px 11px;
}

.zoom-control button:not(:enabled):active,
.street-view button:not(:enabled):active {
  background-color: var(--global-colors-ink-ink);
}

.zoom-control button:active,
.street-view button:active,
.map-actions button:active {
  background-color: var(--global-colors-ink-light);
}

.zoom-control button:not(:enabled) svg,
.street-view button:not(:enabled) svg {
  opacity: 0.5;
}
