.search-field {
  font-family: var(--global-font-regular);
  position: relative;
}

.search-field .svg {
  position: absolute;
  top: 50%;
  right: 14px;
  transform: translateY(-50%);
}

.search-field input:disabled + .svg {
  color: var(--global-colors-sky-dark);
  opacity: 0.5;
  cursor: var(--global-cursor-not-allowed);
}

.search-field input[value=""] {
  border: 1px solid var(--global-colors-sky-dark);
}

.search-field input {
  height: 42px;
  width: 100%;
  border: 1px solid var(--global-colors-ink-light);
  border-radius: 4px;
  padding: 13px 34px 13px 16px;
  outline: 0;
  background-color: transparent;
  transition: all 0.3s ease;
  font-size: var(--global-font-size-sm);
  font-family: inherit;
}

.search-field input:focus {
  border-color: var(--global-colors-ui-primary);
  box-shadow: 0 1px 3px 0 rgba(0, 170, 255, 0.26);
}

.search-field input:focus:not(:placeholder-shown) {
  background-color: var(--global-colors-ui-white);
}

.search-field input:disabled {
  background-color: var(--global-colors-sky-dark);
  opacity: 0.5;
  border: 1px solid var(--global-colors-ui-sky-sky--hover);
  cursor: var(--global-cursor-not-allowed);
}

.search-field input.input--small,
.search-field input#searchAddress {
  max-height: 32px;
}

.search-field + p:empty {
  display: none;
}

::placeholder {
  color: var(--global-colors-ink-light);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--global-colors-ink-light);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--global-colors-ink-light);
}

.pac-container {
  border-radius: 4px;
  margin-top: 4px;
  top: auto !important;
  left: 0px !important;
}

.pac-icon {
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 4px;
  background: url("./utils/PlacesIcon.svg") no-repeat center !important;
}

.pac-item {
  border: 0px;
  min-height: 37px;
  padding-top: 4px;
}

.pac-item:hover {
  background-color: var(--global-colors-icon-disabled);
  cursor: var(--global-cursor-pointer);
}
