.map-marker {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 150px;
  height: 36px;
  width: 36px;
  border: 2px solid var(--global-colors-ui-white);
  background-color: var(--global-colors-ui-primary);
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1),
    0 3px 2px -1px rgba(0, 0, 0, 0.2);
  color: var(--global-colors-ui-white);
  font-size: 1.6rem;
  font-family: var(--global-font-regular);
  text-align: center;
}

.map-marker-pin,
.map-marker-toggle {
  position: relative;
  display: inline-block;
  line-height: 9px;
  color: var(--global-colors-ui-primary);
}

.map-marker-pin .status-led,
.map-marker-toggle .label-led {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  border-radius: 150px;
  height: 13px;
  width: 13px;
  border: 2px solid #ffffff;
  box-shadow: 0 3px 2px -1px rgba(0, 0, 0, 0.2);
}

.map-marker-toggle .label-led {
  background-color: var(--global-colors-ui-white);
  font-family: var(--global-font-semi-bold);
  text-align: center;
}
.map-marker-pin .status-led,
.map-marker-event-toggle .label-led {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  border-radius: 150px;
  height: 13px;
  width: 13px;
  border: 2px solid #ffffff;
  box-shadow: 0 3px 2px -1px rgba(0, 0, 0, 0.2);
}

.map-marker-event-toggle .label-led {
  background-color: var(--global-colors-ui-white);
  font-family: var(--global-font-semi-bold);
  text-align: center;
}

.map-marker-pin,
.map-marker-event-toggle {
  position: relative;
  display: inline-block;
  line-height: 9px;
  color: var(--global-colors-ui-primary);
}

.map-marker-pin .status-led {
  background-color: var(--global-colors-feedback-success);
}
