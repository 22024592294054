.dropdown-content {
  border: 1px solid var(--global-colors-sky-dark);
  border-radius: 4px;
  position: absolute;
  margin-top: 4px;
  width: 270px;
  padding-top: 16px;
  background-color: var(--global-colors-ui-white);
  cursor: var(--global-cursor-pointer);
  outline: none;
  color: var(--global-colors-ink-dark);
  font-family: var(--global-font-regular);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
  z-index: 100;
}

.dropdown-content .dropdown-search {
  padding-right: 16px;
  padding-left: 16px;
  position: relative;
}

.dropdown-content .dropdown-search input {
  height: 32px;
  width: 100%;
  padding: 16px 34px 16px 16px;
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-sm);
  border: 1px solid var(--global-colors-sky-dark);
  border-radius: 4px;
  outline: none;
}

.dropdown-content .dropdown-search .svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
}

.dropdown-content .dropdown-item .color {
  width: 14px;
  height: 14px;
  border-radius: 150px;
  margin-right: var(--global-spacing-md);
  display: inline-block;
}

.dropdown-content .dropdown-item-wrapper {
  margin-top: 11px;
  overflow-y: auto;
  max-height: 243px;
  min-height: 68px;
}

.dropdown-content .dropdown-item {
  width: 100%;
  padding: 11px 16px;
  align-items: center;
  display: flex;
  color: var(--global-colors-ink-dark);
}

.dropdown-content .dropdown-item:hover,
.dropdown-item.active {
  background: var(--global-colors-sky-lighter) !important;
}

.dropdown-item.active,
.dropdown-item:active {
  color: var(--global-colors-ink-dark) !important;
}

.dropdown-content .dropdown-item .sub-arrow {
  display: block;
  position: inherit;
  right: 16px;
  height: 18px;
  transition: all 0.2s ease-in-out;
}

.dropdown-content .dropdown-item .sub-arrow.open {
  transform: rotate(180deg);
}

.dropdown-content .dropdown-item .drop-checkbox {
  width: 100%;
  align-items: center;
  display: flex;
}

.option-content {
  background: var(--global-colors-sky-lighter);
  border: 1px solid var(--global-colors-sky-sky);
  border-top: none;
  border-bottom: none;
  padding-top: 1px;
  padding-bottom: 1px;
  min-height: 40px;
  display: list-item;
}

.option-content-items .checkbox:first-child {
  margin-top: 7px;
}

.option-content-items .checkbox {
  margin-left: 24px;
  margin-bottom: 14px;
}

.option-content .mn-vehicle-box:hover,
.option-content .mn-vehicle-box--active,
.option-content .mn-vehicle-box .ripple {
  background-color: transparent;
  border-right: 0;
}

.option-content .mn-user-box__iconText {
  display: none;
}

.option-content .mn-user-box--regular {
  cursor: var(--global-cursor-default);
}

.empty-options {
  position: absolute;
}

.childs-loader-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 243px;
  min-height: 68px;
}

.childs-loader {
  animation: spinner 0.6s linear infinite;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
