.mc-alert {
  height: 48px;
  color: var(--global-colors-ui-white);
  padding: var(--global-spacing-md);
  border-radius: 4px;
  box-shadow: var(--global-elevation-01);
  font-family: var(--global-font-regular);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--global-font-size-sm);
}

.mc-alert__message {
  margin-right: auto;
  margin-left: var(--global-spacing-sm);
}

.mc-alert__close {
  background-color: transparent;
  border: 0;
  color: currentColor;
}

.mc-alert__icon {
  line-height: 4px;
}

.mc-alert--error {
  background-color: var(--global-colors-feedback-danger);
}

.mc-alert--success {
  background-color: var(--global-colors-feedback-success);
}

.mc-alert--info {
  background-color: var(--global-colors-ui-secondary);
}

.mc-alert--warning {
  background-color: var(--global-colors-feedback-warning);
  color: var(--global-colors-ink-ink);
}
