.admin-notification-forms-manage-text {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: fit-content;
  margin-top: 20px;
}

.admin-notification-forms-event-selection {
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  margin-top: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: inherit;
  min-width: 200px;
  max-height: 280px;
  overflow: scroll;
}

.admin-notification-divider {
  border: 1px solid #e4e6e9;
}

.admin-notification-forms-event {
  font-size: 14px;
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
}

.admin-notification-forms-event-selected {
  font-size: 14px;
  background-color: #e4e6e9;
  border-radius: 4px;
  padding: 10px;
}

.admin-notification-forms-text-manager-components {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 30px;
}

.admin-notification-forms-text-manager-component {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  gap: 80px;
}

.admin-notification-forms-text-manager-description {
  font-size: 14px;
  margin-bottom: 40px;
}

.admin-notification-forms-text-manager-text {
  color: var(--global-colors-ink-light);
  font-size: var(--gloval-font-size-xs);
  font-family: var(--global-font-semi-bold);
}

.admin-notification-tag-variable-container {
  margin-right: 60px;
}

.metadata-tag {
  background-color: #d2d5da5b;
  font-size: 14px;
  text-transform: uppercase;
  width: fit-content;
  height: fit-content;
  border-radius: 4px;
  font-weight: 500;
  padding: 3px;
  padding-left: 6px;
  padding-right: 6px;
  margin-left: 2px;
  margin-right: 2px;
}
