.balloon-tooltip {
	display: inline-block;
	text-align: center;
	line-height: 1.4;
  opacity: 0.8;
  border-radius: 4px;
  background-color: #02285a;
  color: var(--global-colors-ui-white);
  font-size: var(--global-font-size-xs);
  font-family: var(--global-font-regular);
  text-transform: uppercase;
	position: relative;
	max-width: 250px;
}

.balloon-tooltip--regular {
  padding: 4px 8px;
  min-width: 48px;
  min-height: 24px;
}

.balloon-tooltip--small {
  padding: 1px 6px;
  min-width: 42px;
  min-height: 17px;
	font-size: var(--global-font-size-xxs);
}

.balloon-tooltip--bottom::after {
	content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #02285a transparent transparent transparent;
}

.balloon-tooltip--top::before {
	content: " ";
  position: absolute;
  bottom: 100%; /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 4px;
  border-style: solid;
  border-color: transparent transparent #02285a transparent;
}