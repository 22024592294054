.mn-message-divider {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 11px;
  font-family: var(--global-font-semi-bold);
  text-transform: uppercase;
  font-size: var(--global-font-size-xs);
  border-bottom: 1px solid var(--global-colors-sky-sky);
  position: relative;
}

.mn-message-divider.mn-message-divider--has-data {
  border-bottom: 0;
  padding: 8px;
  margin-bottom: 31px;
}

.mn-message-divider.mn-message-divider--has-data span {
  display: block;
  padding: var(--global-spacing-xxs) var(--global-spacing-sm);
  border-radius: 150px;
  position: relative;
  background-color: var(--global-colors-sky-lighter);
  font-family: var(--global-font-semi-bold);
  font-size: var(--global-font-size-xs);
  color: var(--global-colors-ink-light);
}

.mn-message-divider.mn-message-divider--has-data::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
  background-color: var(--global-colors-sky-sky);
}
