.archive-file-filterbar-row {
  display: flex;
  align-items: center;
  font-weight: var(--global-font-semi-bold);
  width: 100%;
  padding: 24px 27px;
}

.archive-file-filterbar-row .datepicker-dropdown-btn {
  margin: 0;
}

.archive-file-filter-btn-generate #button {
  width: 85px;
  margin-left: 16px;
}
