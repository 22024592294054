.datepicker-container {
  float: left;
  width: 100%;
}

.single-datepicker {
  position: relative;
}

.datepicker-menu {
  margin-top: 46px;
  z-index: 99;
  position: absolute;
  overflow: visible;
  display: flex; /*added*/
  flex-direction: column; /*added*/
}

.datepicker-icon {
  position: absolute;
  top: 35%;
  right: 0px;
}

.datepicker-container
  .text-field.text-field--has-icon
  .datepicker-icon-small
  .svg {
  top: -5px;
}
