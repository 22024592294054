.vehicle-filterbar-row-splitter {
  display: flex;
  align-items: center;
  font-weight: var(--global-font-semi-bold);
  width: 100%;
  border-bottom: 1px solid var(--global-colors-sky-dark);
}

.vehicle-filterbar-dropdown-columns .dropdown {
  width: 207px;
}

.vehicle-filterbar-search,
.vehicle-dropdown-groups,
.vehicle-dropdown-statuses,
.vehicle-dropdown-customize {
  margin-top: 24px;
  margin-bottom: 24px;
}

.vehicle-filterbar-search {
  margin-left: 16px;
}

.vehicle-dropdown-groups,
.vehicle-dropdown-statuses,
.vehicle-dropdown-customize {
  margin-left: 12px;
}
