.timeDropdown-field {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 32px;
  padding: 8px 16px;
  width: 98px;
  border: 1px solid var(--global-colors-sky-dark);
  border-radius: 4px;
  user-select: none;
}

.timeDropdown-field.active {
  border: 1px solid var(--global-colors-ui-primary--focus);
  cursor: var(--global-cursor-default);
  color: var(--global-colors-ui-black);
}

.timeDropdown-field.selected {
  border: 1px solid var(--global-colors-ink-light);
  cursor: var(--global-cursor-default);
  color: var(--global-colors-ui-black);
}

.timeDropdown-span {
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-sm);
  color: var(--global-colors-ink-light);
  width: 42px;
}

.timeDropdown-span input {
  border: none;
  outline: transparent;
  width: 55px;
  font-family: var(--global-font-regular);
  font-size: 13px;
  line-height: 16px;
}

.timeDropdown-icon {
  display: flex;
  margin-left: 15px;
  margin-top: 1px;
  align-items: center;
  transition: all 0.2s ease-in-out;
}

.timeDropdown-icon.open {
  transform: translateY(-10%) rotate(180deg);
}

.timeDropdown-container {
  width: 236px;
  height: 122px;
  border: 1px solid var(--global-colors-sky-sky);
  border-radius: 4px;
  margin-top: 4px;
  position: absolute;
  background-color: var(--global-colors-ui-white);
  z-index: 9999;
}

.timeDropdown-list {
  width: 100%;
  padding: 12px;
}

.timeDropdown-list .slider-radius-label {
  padding-top: 0px;
  height: 22px;
}

.timeDropdown-list .slider-container {
  margin-bottom: 6px;
  width: 99%;
}

.timeDropdown-list .value-label:first-child {
  margin-right: 6px;
}

.timeDropdown-field,
.timeDropdown-field * {
  cursor: var(--global-cursor-pointer);
}

.timeDropdown-button {
  font-size: 14px;
  font-family: var(--global-font-light);
  margin-right: 16px;
  float: right;
}

.timeDropdown-button .button:hover {
  background: var(--global-colors-ui-primary);
}
