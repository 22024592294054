.mn-reports .mn-reports__reports-list {
  border-radius: 4px;
  /* overflow: hidden; */
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
}

.mn-report__schedule {
  background-color: var(--global-colors-sky-light);
  height: 64px;
  padding: 8px 30px;
  display: flex;
  align-items: center;
}

.mn-report__schedule-expand {
  height: 24px;
  width: 5.8px;
  border-left: 1px solid var(--global-colors-ink-lighter);
  border-bottom: 1px solid var(--global-colors-ink-lighter);
}

.mn-report__schedule-text {
  margin-left: 8px;
  display: grid;
}

.mn-report__schedule-title {
  margin-left: 12px;
  color: var(--global-colors-ink-light);
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-xs);
}

.mn-report__schedule-sub-title {
  color: var(--global-colors-ink-dark);
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-sm);
}

.mn-report__schedule-sub-title-bold {
  color: var(--global-colors-ink-dark);
  font-family: var(--global-font-medium);
  font-size: var(--global-font-size-sm);
}

.mn-report__schedule-dropdown {
  height: 32px;
  margin-left: auto;
}

.mn-reports > div:first-child {
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-md);
  color: var(--global-colors-ink-dark);
  height: 21px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  margin-top: 16px;
}

.mn-reports .mn-reports__title-icon {
  margin-right: var(--global-spacing-xs);
}

.mn-report {
  display: flex;
  align-items: center;
  background-color: var(--global-colors-ui-white);
  padding: var(--global-spacing-md) var(--global-spacing-lg);
  cursor: var(--global-cursor-pointer);
}

.mn-report-not-found {
  cursor: unset !important;
}

.mn-report > *:first-child {
  flex-grow: 1;
}

.mn-report .mn-data-label {
  padding: 0;
}

.mn-report .mn-data-label__text .mn-data-label__text-label,
.mn-report .mn-data-label__text .mn-data-label__text-data {
  font-size: var(--global-font-size-sm);
}

.mn-report .mn-data-label__text .mn-data-label__text-label {
  display: inline-block;
  color: var(--global-colors-ink-dark);
  font-family: var(--global-font-medium);
}

.mn-report .mn-data-label__text .mn-data-label__text-data {
  color: var(--global-colors-ink-light);
}

.mn-report .mn-tag {
  margin-left: var(--global-font-size-sm);
}

.mn-report:not(:last-child) {
  border-bottom: 1px solid var(--global-colors-sky-sky);
}

.mn-report .mn-report__chevron {
  cursor: var(--global-cursor-pointer);
}
