.reset-password-support .mn-modal .mn-modal__dialog {
  max-width: 1011px;
  max-height: 642px;
}

.reset-password-support .mn-modal__left-column {
  height: 642px;
  width: 600px;
}

.reset-password-support .mn-modal__right-column {
  height: 642px;
  width: 411px;
}

.reset-password-support-container {
  height: 642px;
  width: 600px;
}

.mn-modal__left-column
  .reset-password-support-container
  .mn-modal__dialog-body {
  padding: 48px 48px 48px;
  height: 460px;
  width: 607px;
}

.reset-password-support .mn-modal__dialog-body .central-forms {
  height: 60px;
  width: 510px;
  float: left;
  margin-bottom: 108px;
}

.reset-password-support
  .mn-modal__right-column
  .right-help-column
  .mn-modal__dialog-body {
  padding: 48px 32px;
}

.reset-password-support .mn-label-text__label {
  margin-bottom: 0;
}

.reset-password-support .mn-label-text__text {
  color: var(--global-colors-ink-ink);
  font-size: var(--global-font-size-xs);
  font-family: var(--global-font-medium);
}

.reset-password-support .mn-modal__dialog-body .mn-label-text__text {
  margin-left: 8px;
}

.reset-password-support
  .mn-modal__dialog
  .mn-modal__dialog-header
  .mn-modal__dialog-close {
  right: 11px;
}

.reset-password-support .mn-modal__dialog-footer {  
  width: 607px;
}
