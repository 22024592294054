.gm-style .maptype-control .maptype-control-map,
.gm-style .maptype-control .maptype-control-satellite {
  background: white;
}

.gm-style .maptype-control .maptype-control-map .svg span,
.gm-style .maptype-control .maptype-control-satellite .svg span {
  color: var(--global-colors-ink-ink) !important;
}

.gm-style .maptype-control {
  margin: 0px 16px 16px 16px;
  box-sizing: border-box;
  cursor: var(--global-cursor-pointer);
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  min-width: 32px;
  min-height: 32px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: rgb(0 0 0 / 30%) 1px 2px 4px -1px;
}

.gm-style .maptype-control button {
  width: 32px;
  display: flex;
  justify-content: center;
  border: 0;
  height: 32px;
  font-family: var(--global-font-regular);
  padding: 9px;
  background-color: var(--global-colors-ink-ink);
  margin: 0;
  transition: all 0.1s ease;
  outline: none;
  cursor: var(--global-cursor-pointer);
}

.gm-style .maptype-control button:first-child {
  border-radius: 4px 4px 0 0;
}

.gm-style .maptype-control button:last-child {
  border-radius: 0 0 4px 4px;
}

.gm-style iframe + div {
  border: none !important;
}
