.signal-indicator-bar {
  display: inline-block;
  height: 100%;
}

.signal-indicator-circle {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  border-style: solid;
  -webkit-border-radius: 0 100% 0 0;
  border-radius: 0 100% 0 0;
}
