.checkbox {
  align-items: center;
  z-index: 0;
  position: relative;
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-sm);
  display: flex;
}

/* Input */
.checkbox > input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  z-index: -1;
  position: absolute;
  left: -10px;
  width: 40px;
  height: 40px;
  display: block;
  margin: 0;
  border-radius: 50%;
  background-color: var(--global-colors-sky-dark);
  box-shadow: none;
  outline: none;
  pointer-events: none;
  opacity: 0;
  transform: scale(1);
  cursor: var(--global-cursor-pointer);
  transition: opacity 0.3s, transform 0.2s;
}

/* Span */
.checkbox > span {
  display: inline-flex;
  width: 100%;
  height: 18px;
  cursor: var(--global-cursor-pointer);
  align-items: center;
}

/* P */
.checkbox > p {
  display: inline-block;
  width: 100%;
  font-size: 12px;
  line-height: 16px;
  color: var(--global-colors-ink-light);
}

/* Box */
.checkbox > span::before {
  content: "";
  display: inline-block;
  box-sizing: border-box;
  margin: auto 11px auto 1px;
  border: solid 2px; /* Safari */
  border-color: rgba(0, 0, 0, 0.6);
  border-radius: 2px;
  min-width: 18px;
  height: 18px;
  vertical-align: top;
  transition: border-color 0.2s, background-color 0.2s;
}

/* Checkmark */
.checkbox > span::after {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: 5px;
  width: 10px;
  height: 5px;
  border: solid transparent;
  border-width: 0 0 2px 2px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.checkbox > span .checkbox-label {
  display: flex;
  text-wrap: balance;
}

/* Checked, Indeterminate */
.checkbox > input:checked,
.checkbox > input:indeterminate {
  background-color: var(--global-colors-ui-primary);
}

.checkbox > input:checked + span::before,
.checkbox > input:indeterminate + span::before {
  border-color: var(--global-colors-ui-primary);
  background-color: var(--global-colors-ui-primary);
}

.checkbox > input:checked + span::after,
.checkbox > input:indeterminate + span::after {
  border-color: var(--global-colors-ui-white);
}

.checkbox > input:indeterminate + span::after {
  border-left: none;
  transform: translate(4px, 3px);
}

/* Hover */
.checkbox:hover > input {
  opacity: 0.2;
}

.checkbox:hover > input:checked {
  opacity: 0.1;
}

/* Focus */
/* .checkbox > input:focus {
  opacity: 0.12;
}

.checkbox:hover > input:focus {
  opacity: 0.16;
}  */
/* Active */
.checkbox > input:active {
  opacity: 1;
  transform: scale(0);
  transition: transform 0s, opacity 0s;
}

.checkbox > input:active + span::before {
  border-color: var(--global-colors-ui-primary);
}

.checkbox > input:checked:active + span::before {
  border-color: transparent;
  background-color: rgb(0, 0, 0, 0.6);
}

/* Disabled */
.checkbox > input:disabled {
  opacity: 0;
}

.checkbox > input:disabled + span {
  color: var(--global-colors-sky-dark);
  cursor: var(--global-cursor-not-allowed);
}

.checkbox > input:disabled + span::before {
  border-color: var(--global-colors-sky-dark);
}

.checkbox > input:checked:disabled + span::before,
.checkbox > input:indeterminate:disabled + span::before {
  border-color: transparent;
  background-color: var(--global-colors-sky-dark);
}
