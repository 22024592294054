.mn-lh-track-summary {
  width: 301px;
}

.mn-lh-track-summary-short {
  width: 301px;
  border-top: 1px solid var(--global-colors-sky-dark);
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-radius: 0px;
}

.mn-lh-track-summary .mn-card__header > div {
  font-size: var(--global-font-size-md);
  font-family: var(--global-font-regular);
}

.mn-lh-track-summary-short .mn-card__header > div {
  font-size: var(--global-font-size-md);
  font-family: var(--global-font-regular);
}

.mn-lh-track-summary .mn-lh-track-summary__details > div:not(:last-child) {
  margin-bottom: var(--global-spacing-md);
}

.mn-lh-track-summary
  .mn-lh-track-summary__details
  .mn-lh-track-summary__button
  .button {
  width: 100%;
  margin-top: var(--global-spacing-md);
  margin-top: 14px;
}

.mn-lh-track-summary
  .mn-lh-track-summary__details
  .mn-lh-track-summary__button
  .label {
  width: 100%;
  height: 33px;
  justify-content: center;
}

.mn-lh-track-summary
  .mn-lh-track-summary__details
  .mn-data-label.mn-data-label--has-graph
  .mn-data-label__text
  .mn-data-label__text-data {
  align-items: center;
}

.mn-lh-track-summary
  .mn-lh-track-summary__details
  .mn-data-label.mn-data-label--has-graph
  .mn-data-label__text
  .mn-data-label__text-data
  progress {
  margin-right: 14px;
  width: 91px;
  height: 8px;
}

.mn-lh-track-summary
  .mn-lh-track-summary__details
  .fuel-consumption
  .mn-data-label__text-data
  > span {
  margin-top: 2px;
}
