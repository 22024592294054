.mn-lh-summary-details {
  padding: 0;
  width: 308px;
}

.mn-lh-summary-details .mn-card__header {
  height: 34px;
  font-size: var(--global-font-size-sm);
  padding: var(--global-spacing-xs) 0 var(--global-spacing-xs)
    var(--global-spacing-md);
  border-bottom: 1px solid var(--global-colors-sky-sky);
  margin-bottom: 0;
}

.mn-lh-summary-details .mn-card__header > div {
  margin-top: 8px;
  margin-bottom: 8px;
}

.mn-lh-summary-details .mn-card__body {
  margin-top: 0;
}

.mn-lh-summary-details .mn-lh-summary-details__details {
  padding: var(--global-spacing-xs) var(--global-spacing-md) 0;
}

.mn-lh-summary-details__details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.mn-lh-summary-details__details > div {
  flex-basis: 47%;
  margin-bottom: 16px;
}

.mn-lh-summary-details__details .mn-data-label__text-data > span {
  margin-bottom: 4px;
}

.mn-lh-summary-details__details > div:not(:last-child) {
  margin-bottom: var(--global-spacing-md);
}

.mn-lh-summary-details__details > .fuel-consumption {
  flex-basis: 100%;
}

.description-summary-details {
  height: 18px;
  text-wrap: balance;
  color: #031832;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  padding: 10px 0px 0px 13px;
  margin-bottom: 10px;
}
