.map-button {
  padding: 9px;
  border-radius: 4px;
  background-color: var(--global-colors-ui-white);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
}
