.cg-map-functionalities {
  width: 100%;
  height: 100%;
  position: "relative";
  z-index: 0;
  overflow: hidden;
}
.cg-map-functionalities .address-search-field {
  left: 800px;
}

.cg-map-functionalities .address-search-field input {
  width: 369px;
  margin-top: 47px;
  left: -60px;
  background-color: var(--global-colors-ui-white);
}

.cg-map-functionalities .address-search-field .svg {
  top: 75%;
}
