.schedule-working {
  font-family: var(--global-font-medium);
  max-width: 653px;
  height: 76px;
  padding: 17px 32px;
  user-select: none;
  cursor: var(--global-cursor-pointer);
}

.schedule-working:hover {
  background-color: var(--global-colors-sky-lighter);
}

.schedule-working-simple {
  font-family: var(--global-font-medium);
  max-width: 653px;
  height: 76px;
  padding: 17px 32px;
  user-select: none;
  cursor: var(--global-cursor-pointer);
}

.schedule-working-circle span {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  border: 1px solid var(--global-colors-sky-sky);
  background-color: var(--global-colors-ui-white);
  float: left;
  display: flex;
  justify-content: center;
}

.schedule-working-icon {
  margin: 0;
  float: right;
  display: flex;
  align-items: center;
  cursor: var(--global-cursor-pointer);
}

.schedule-working-button {
  background: transparent;
  padding: 0;
  border: 0;
  width: 100%;
  display: flex;
  align-items: center;
}

.schedule-working-label {
  margin-left: 26px;
  margin-right: auto;
}

.schedule-working-pretitle {
  color: var(--global-colors-ink-light);
  font-size: var(--global-font-size-xs);
  font-family: var(--global-font-regular);
  margin-bottom: 6px;
  float: left;
}

.schedule-working-title {
  color: var(--global-colors-ink-dark);
  font-size: var(--global-font-size-md);
  font-family: var(--global-font-regular);
  float: left;
  text-overflow: ellipsis;
  text-align: left;
  overflow: hidden;
  max-width: 400px;
}

.schedule-working-reset {
  height: 16px;
  width: 34px;
  font-size: var(--global-font-size-sm);
  font-family: var(--global-font-regular);
  margin-right: 16px;
  float: left;
  text-decoration: none;
  -webkit-text-decoration-color: var(--global-colors-ui-secondary);
  text-decoration-color: var(--global-colors-ui-secondary);
  color: var(--global-colors-ui-secondary);
}

.schedule-working-save-button {
  margin-right: 16px;
  height: 32px;
  width: 57px;
  float: left;
}

.schedule-working-body-expanded {
  max-width: 653px;
  height: 216px;
  padding: 0 32px 32px 100px;
}

.schedule-working-body {
  max-width: 653px;
  height: 110px;
  padding: 0 32px 32px 100px;
}

.schedule-working-labels {
  color: var(--global-colors-ink-light);
  font-size: var(--global-font-size-xs);
  font-family: var(--global-font-semi-bold);
  float: left;
  height: 36px;
  width: 170px;
  margin-bottom: 14px;
  align-items: center;
  display: flex;
}

.schedule-working-value {
  color: var(--global-colors-ink-dark);
  font-size: var(--global-font-size-sm);
  height: 36px;
  align-items: center;
  display: flex;
  margin-bottom: 14px;
  margin-right: 60px;
}

.schedule-working-metric {
  margin-left: 12px;
}

.schedule-working-left-column {
  display: flex;
  height: 36px;
  float: left;
}

.schedule-working-right-column {
  display: flex;
  height: 36px;
  float: right;
}

.schedule-working-right-column .schedule-working-labels {
  width: auto;
  margin-right: 10px;
}

.schedule-working-value form {
  width: 80px;
}

/* Chrome, Safari, Edge, Opera */
.schedule-working-value input::-webkit-outer-spin-button,
.schedule-working-value input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.schedule-working-value input[type="number"] {
  -moz-appearance: textfield;
}
