.btn-group {
  display: inline-flex;
  align-items: center;
}

.btn-group > button {
  margin-left: 0;
}

/* .btn-group > button:focus:not(:focus-visible) {
  background-color: var(--global-colors-sky-sky);
} */

.btn-group > button:not(:last-child):not(:first-child) {
  border-radius: 0;
}

.btn-group > button:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > button:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group > button .svg {
  margin-right: 7px;
}