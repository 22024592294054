.mn-mw-filter-bar {
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-sm);
  color: var(--global-colors-ink-dark);
}

.mn-mw-filter-bar__header {
  background-color: var(--global-colors-ui-white);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
  padding: var(--global-spacing-md) var(--global-spacing-lg);
  display: flex;
  height: 64px;
  justify-content: space-between;
  align-items: center;
}

.mn-mw-filter-bar__header > div {
  font-size: inherit;
  color: inherit;
}

.mn-mw-filter-bar__section {
  display: flex;
  box-shadow: inset 0 1px 0 0 var(--global-colors-sky-sky);
  background-color: var(--global-colors-ui-white);
  padding: var(--global-spacing-md);
}

.mn-mw-filter-bar__section > * {
  width: 100%;
}

.mn-mw-filter-bar__section > *:not(:last-child) {
  margin-right: var(--global-spacing-md);
}

.mn-mw-filter-bar .svg {
  transition: all 0.2s ease;
}

.mn-mw-filter-bar__header > div:first-child {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--global-spacing-md);
}

.mn-mw-filter-bar .dropdown .options {
  bottom: 100%;
  top: auto;
  margin-bottom: 6px;
  margin-top: 0;
}
