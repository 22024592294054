.installer-container .mn-modal .mn-modal__dialog {
  max-width: 600px;
  max-height: 942px;
}

.installer-container .mn-modal .mn-modal__dialog .installer .mn-modal__dialog-body {
  padding: 48px 48px 0px 45px;
  height: 739px;
  width: 600px;
}

.installer .mn-label-text__label {
  margin-bottom: 0;
}

.installer .mn-label-text__text {
  color: var(--global-colors-ink-ink);
}

.installer .mn-modal__dialog-body .left-forms {
  width: 240px;
  float: left;
}

.installer .mn-modal__dialog-body .right-forms {
  width: 240px;
  float: left;
  margin-left: 24px;
  margin-bottom: 7px;
}

.installer .mn-modal__dialog-body .mn-label-text__text {
  margin-left: 8px;
}

.installer .mn-modal__dialog-body .central-forms {
  height: 100px;
  width: 504px;
  float: left;
  margin-bottom: 27px;
}

.installer .selection-control {
  float: left;
  height: 18px;
  margin-bottom: 11px;
}

.installer .selection-control .checkbox>span {
  height: 18px;
  font-size: 14px;
  line-height: 18px;
}

.installer .selection-control .checkbox>span::before {
  margin: 0px 11px 0px 1px;
}

.installer .company-rectangle {
  float: left;
  height: 106px;
  width: 504px;
  border-radius: 4px;
  background-color: var(--global-colors-sky-light);
}

.installer .company-text {
  height: 82px;
  margin-top: 12px;
  margin-left: 12px;
  margin-bottom: 12px;
  margin-right: 20px;
  text-align: left;
  width: 472px;
  color: var(--global-colors-status-stopped);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  overflow-y: scroll;
  padding: 0;
}

.installer .company-text p {
  margin-top: 0;
  margin-bottom: 0;
}

.installer .captcha {
  position: absolute;
  height: 77px;
  width: 504px;
  margin-top: 16px;
}
