.dropdragfile {
  text-align: center;
}

.dropdragfile.mn-mw-drop-area--not-active {
  color: #687484;
}

.dropdragfile.mn-mw-drop-area--active {
  background-color: rgba(3, 24, 50, 0.48) !important;
  color: var(--global-colors-ui-white);
}

.dropdragfile div {
  padding-top: 70px;
  float: unset;
}

.dropdragfile div.svg span {
  display: block;
}

.dropdragfile p:nth-child(2) {
  font-size: 16px;
  margin-top: 34px;
}

.dropdragfile p:last-child {
  font-size: 11px;
  margin-top: -6px;
}
