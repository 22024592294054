.log-filterbar-dropdown-groups > .dropdown {
  width: fit-content !important;
}

.log-filterbar-row {
  display: flex;
  align-items: center;
  font-weight: var(--global-font-semi-bold);
  width: 100%;
  border-bottom: 1px solid var(--global-colors-sky-dark);
  padding: 24px;
}

.log-filterbar-row > div:not(:last-child) {
  margin-right: 16px;
}

.log-filterbar-date-picker {
  height: 32px;
}

.log-filterbar-dropdown-columns .dropdown {
  width: 207px;
}

.log-filterbar-btn {
  width: auto;
  margin-left: auto;
}

.log-filterbar-btn .dropdown-list {
  background-color: var(--global-colors-ui-white);
  position: absolute;
  right: 0px;
  width: max-content;
}

.log-filterbar-btn .dropdown-btn .dropdown-list .dropdown-elements .text {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
}

.log-filterbar-btn-generate #button {
  width: 85px;
}
