.verify-code-container .mn-modal .mn-modal__dialog {
  max-width: 600px;
  max-height: 753px;
}

.verify-code-container
  .mn-modal
  .mn-modal__dialog
  .verify-code
  .mn-modal__dialog-body {
  padding: 48px 48px 0px 45px;
  height: 543px;
  width: 600px;
}

.verify-code .mn-label-text__label {
  margin-bottom: 0;
}

.verify-code .mn-label-text__text {
  color: var(--global-colors-ink-light);
  font-size: var(--global-font-size-xs);
  font-family: var(--global-font-medium);
  margin-bottom: 6px;
  height: 14px;
}

.verify-code .mn-modal__dialog-body .central-forms {
  max-height: 60px;
  width: 320px;
  float: left;
  margin-left: 105px;
  margin-bottom: 99px;
}

.verify-code .mn-modal__dialog-body .resend-button {
  height: 32px;
  width: 295px;
  text-align: center;
  margin-bottom: 43px;
  margin-top: 23px;
}

.verify-code .mn-modal__dialog-body .central-forms-error {
  max-height: 60px;
  width: 320px;
  float: left;
  margin-left: 105px;
  margin-bottom: 99px;
}

.verify-code .mn-modal__dialog-body .central-forms-error .mn-label-text__text {
  color: var(--global-colors-feedback-danger);
  margin-bottom: 6px;
  height: 14px;
}

.verify-code .mn-modal__dialog-body .central-forms-error .single-form input {
  border-color: var(--global-colors-feedback-danger);
}

.verify-code .mn-modal__dialog-body .forms-container {
  justify-content: space-between;
  height: 48px;
  width: 320px;
}

.verify-code .mn-modal__dialog-body .single-form {
  height: 48px;
  width: 40px;
  display: inline-flex;
  justify-content: space-between;
}

.verify-code .mn-modal__dialog-body .single-form input {
  padding: 14px;
  width: 40px;
  height: 48px;
}

.verify-code .mn-modal__dialog-body .forms-loader {
  width: 14px;
  float: right;
  margin-top: 13px;
  height: 17px;
  color: var(--global-colors-ui-primary);
  -webkit-animation: spin 2s linear infinite;
  -moz-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.verify-code .mn-modal__dialog-body .single-form:not(:first-child) {
  width: 40px;
  display: inline-flex;
  justify-content: space-between;
  margin-left: 11px;
}

.verify-code .mn-modal__dialog-body .new-password-container {
  width: 100%;
  display: inline-block;
}

.verify-code
  .mn-modal__dialog-body
  .new-password-container
  .password-not-equals
  input {
  border-color: var(--global-colors-feedback-danger);
}

.verify-code
  .mn-modal__dialog-body
  .new-password-container
  .password-not-equals
  .text-field
  p {
  margin: 0;
}

.verify-code
  .mn-modal__dialog-body
  .new-password-container
  .password-not-equals
  .password-not-equals-label {
  color: var(--global-colors-feedback-danger);
  font-size: 10px;
  display: flex;
  align-items: center;
  font-family: var(--global-font-light);
  margin-left: 8px;
}

.verify-code .mn-modal__dialog-body .new-password-container-disabled {
  width: 100%;
  display: inline-block;
  pointer-events: none;
  opacity: 0.4;
}

.verify-code .mn-modal__dialog-body .new-password-title {
  color: var(--global-colors-ink-light);
  font-size: var(--global-font-size-sm);
  margin-bottom: 26px;
}

.verify-code .mn-modal__dialog-body .new-password-title .mn-label-text__text {
  font-family: var(--global-font-medium);
}

.verify-code .mn-modal__dialog-body .constraint-container {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 15px;
  line-height: 18px;
}

.verify-code .mn-modal__dialog-body .constraint-title {
  color: var(--global-colors-ink-light);
  font-size: var(--global-font-size-xs);
  font-family: var(--global-font-medium);
  margin-bottom: 6px;
  margin-left: 15px;
}

.verify-code .mn-modal__dialog-body .hide-constraint-check {
  display: none;
}

.verify-code .mn-modal__dialog-body .constraint-check {
  float: left;
  margin-right: 5px;
  margin-top: 4px;
}

.verify-code .mn-modal__dialog-body .disabled-constraint {
  font-size: var(--global-font-size-xs);
  color: var(--global-colors-ink-light);
  margin-left: 15px;
}

.verify-code .mn-modal__dialog-body .default-constraint {
  font-size: var(--global-font-size-xs);
  color: var(--global-colors-ui-primary);
  margin-left: 15px;
}

.verify-code .mn-modal__dialog-body .success-constraint {
  font-size: var(--global-font-size-xs);
  color: var(--global-colors-feedback-success);
}

.verify-code .mn-modal__dialog-body .error-constraint {
  font-size: var(--global-font-size-xs);
  color: var(--global-colors-feedback-danger);
}

.verify-code .mn-modal__dialog-body .new-password-conflict-label {
  color: var(--global-colors-feedback-danger);
  font-size: 10px;
  display: flex;
  align-items: center;
  font-family: var(--global-font-light);
  margin-left: 8px;
}
