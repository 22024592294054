.help .mn-modal__dialog {
  max-width: 1012px;
  max-height: 850px;
}

.mn-modal__left-column {
  max-width: 600px;
  max-height: 850px;
  height: 850px;
}

.mn-modal__right-column {
  width: auto;
  max-height: 850px;
  border-radius: 0 4px 4px 0;
}

/* Right Column */

.right-help-column .mn-modal__dialog-body {
  margin: 0 50px;
}

.right-help-column .mn-modal__dialog-body .button {
  width: 248px;
  height: 42px;
}

.help .mn-modal__dialog .mn-modal__dialog-header .mn-modal__dialog-close {
  right: 22px;
  padding-left: 10px;
  top: 18px;
}

.help
  .mn-modal__dialog
  .mn-modal__columns
  .mn-modal__right-column
  .mn-modal__dialog-body {
  padding: 48px 28px;
}

/* Left Column */

.help .mn-modal .mn-modal__dialog .left-help-column .mn-modal__dialog-body {
  padding: 48px 48px 0px 45px;
  height: 662px;
  width: 600px;
}

.left-help-column .mn-label-text__label {
  margin-bottom: 0;
}

.left-help-column .mn-label-text__text {
  color: var(--global-colors-ink-ink);
}

.left-help-column .mn-modal__dialog-body .left-forms {
  width: 240px;
  float: left;
}

.left-help-column .mn-modal__dialog-body .right-forms {
  width: 240px;
  float: left;
  margin-left: 24px;
  margin-bottom: 7px;
}

.left-help-column .mn-modal__dialog-body .mn-label-text__text {
  margin-left: 8px;
  font-family: var(--global-font-medium);
}

.left-help-column .mn-modal__dialog-body .central-forms {
  height: 100px;
  width: 504px;
  float: left;
  margin-bottom: 27px;
}

.left-help-column .selection-control {
  float: left;
  height: 18px;
  margin-bottom: 11px;
}

.help .left-help-column .selection-control .checkbox > span {
  height: 18px;
  font-size: 14px;
  line-height: 18px;
}

.help .left-help-column .selection-control .checkbox > span::before {
  margin: 0px 11px 0px 1px;
}

.help .left-help-column .company-rectangle {
  float: left;
  height: 106px;
  width: 504px;
  border-radius: 4px;
  background-color: var(--global-colors-sky-light);
}

.help .left-help-column .company-text {
  height: 82px;
  margin-top: 12px;
  margin-left: 12px;
  margin-bottom: 12px;
  margin-right: 20px;
  text-align: left;
  width: 472px;
  color: var(--global-colors-status-stopped);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  overflow-y: scroll;
  padding: 0;
}

.help .left-help-column .company-text p {
  margin-top: 0;
  margin-bottom: 0;
}

.help .captcha {
  position: absolute;
  height: 77px;
  width: 100%;
  margin-top: 16px;
}
