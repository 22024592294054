.mn-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 993;
  width: 100vw;
  height: 100vh;
  background-color: var(--global-colors-overlay-background);
  opacity: 0.8;
}

.mn-modal {
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  outline: 0;
}

@media (max-height: 960px) {
  /* For 13" display */
  .mn-modal {
    bottom: unset;
  }
}

.mn-modal .mn-modal__dialog {
  border-radius: 4px;
  max-width: 600px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: var(--global-colors-ui-white);
  outline: 0;
  margin: 1.75rem auto;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
  font-family: var(--global-font-regular);
}

.mn-modal__dialog-title {
  font-family: var(--global-font-medium);
}

.mn-modal__dialog-text {
  margin: 0;
  white-space: break-spaces;
}

.mn-modal__dialog-tag {
  display: inline;
  justify-content: space-between;
}

.mn-modal__dialog-tag .mn-tag {
  margin-left: 4px;
}

.mn-modal .mn-modal__dialog--center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
}

.mn-modal .mn-modal__dialog .mn-modal__dialog-header {
  padding: 24px 32px 0;
  position: relative;
}

.mn-modal .mn-modal__dialog .mn-modal__dialog-header .mn-modal__dialog-close {
  position: absolute;
  top: 22px;
  right: 22px;
  cursor: var(--global-cursor-pointer);
  z-index: 1;
}

.mn-modal
  .mn-modal__dialog
  .mn-modal__dialog-header
  .mn-modal__dialog-title
  > div {
  font-size: var(--global-font-size-xl);
  font-family: var(--global-font-medium);
  line-height: 18px;
  margin: 0 0 4px;
}

.mn-modal
  .mn-modal__dialog
  .mn-modal__dialog-header
  .mn-modal__dialog-title
  > div
  + div {
  margin-top: var(--global-spacing-xs);
  margin-bottom: 0;
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-sm);
  color: var(--global-colors-ink-light);
}

.mn-modal .mn-modal__dialog .mn-modal__dialog-body {
  padding: 32px 32px 48px;
  font-size: var(--global-font-size-sm);
  line-height: 18px;
  text-align: left;
}

.mn-modal
  .mn-modal__dialog
  .mn-modal__dialog-body[data-scrollable="true"]
  .mn-modal__dialog-body--scrollable {
  height: 200px;
  overflow-y: scroll;
}

.mn-modal .mn-modal__dialog .mn-modal__dialog-footer .dropdown-btn {
  margin-left: 14px;
}

.mn-modal
  .mn-modal__dialog
  .mn-modal__dialog-footer
  .dropdown-btn
  .dropdown-list {
  background-color: var(--global-colors-ui-white);
  position: absolute;
  right: 0;
  width: max-content;
}

.mn-modal .mn-modal__dialog .mn-modal__dialog-footer,
.mn-modal .mn-modal__dialog .mn-modal__dialog-footer-back {
  padding: 46px 24px 24px;
  display: flex;
  justify-content: flex-end;
}

.mn-modal
  .mn-modal__dialog
  .mn-modal__dialog-footer-back
  > .button--secondary:first-child {
  margin-right: auto;
}

.mn-modal .mn-modal__dialog > .mn-modal__dialog-header:first-of-type {
  padding: 0;
}

.mn-modal__columns {
  display: grid;
  grid-template-columns: 1fr 40%;
}

.mn-modal__right-column {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background: linear-gradient(
    180deg,
    #007adb 0%,
    /* Not in design system color palette. */ var(--global-colors-ui-primary)
      30%
  );
  color: var(--global-colors-ui-white);
}

.mn-modal
  .mn-modal__dialog
  .mn-modal__right-column
  .mn-modal__dialog-header
  .mn-modal__dialog-title
  > div
  + div {
  color: var(--global-colors-ink-lighter);
}

.image-import {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

.image-import > div:first-child {
  min-width: 274px;
}
