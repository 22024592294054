.card-flex-vehicle {
  width: 48%;
  margin-bottom: 20px;
}

.card-flex-vehicle .mn-card {
  max-width: none;
  margin: 16px auto 0px auto;
}

.withVehicleDetails {
  height: calc(
    (
      100vh - 100px -
        (var(--header-menu-height) + var(--page-container-filters-height))
    )
  );
}
.withVehicleDetailsOpen {
  height: calc(
    (
      100vh - 190px -
        (var(--header-menu-height) + var(--page-container-filters-height))
    )
  );
}

.mn-card__header a {
  cursor: var(--global-cursor-pointer);
}

.last-tracks-notFound,
.quick-actions-notFound {
  padding-left: 12px;
  padding-top: 10px;
  padding-bottom: 22px;
  font-family: var(--global-font-regular);
  color: var(--global-colors-ink-light);
  size: 14px;
}

.internal-battery {
  display: contents;
  white-space: nowrap;
  position: relative;
}

.hooked-trailer {
  display: contents;
  white-space: nowrap;
  position: relative;
}

.sensor-dropdown .dropdown--normal {
  margin-top: 24px;
  margin-bottom: 24px;
  width: 200px !important;
  display: flex;
  gap: 30px;
}

.sensor-dropdown {
  display: flex;
  gap: 30px;
}

.sensor-dropdown-container {
  display: flex;
}

.section-divider {
  border: none;
  border-top: 2px solid #6b6767;
  margin: 2rem 0;
}

.sensor_analysis_title {
  font-size: 14px;
  font-family: var(--global-font-medium);
}

.sensor-analysis {
  display: flex;
  gap: 1rem;
  margin: 1rem 0;
}

.sensor-card {
  flex: 1;
  background-color: #f5f5f5;
  border-radius: 6px;
  padding: 1rem;
}

.sensor-card-title {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 14px;
  display: flex;
}

.sensor-rows {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.sensor-row {
  display: flex;
  align-items: center;
  gap: 10px;
}

.sensor-row label {
  width: 80px;
  font-weight: 500;
}

.sensor-value {
  font-weight: 600;
}

.container-cards {
  display: flex;
  width: 100%;
  gap: 80px;
}

.container-card-temperature {
  width: 45%;
}

.container-card-humidity {
  width: 45%;
}

.sensor-data {
  display: flex;
  gap: 30%;
}

.sensor-data-text-value {
  font-size: 20px;
  font-weight: 500;
}

.sensor-data-text {
  padding-left: 10px;
  color: #636161;
  font-size: 12px;
  font-weight: 500;
}
