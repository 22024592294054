.mn-track-bar-collapse {
  height: 32px;
  display: flex;
  flex-direction: row-reverse;
}

.mn-track-bar-collapse-first {
  background-color: var(--global-colors-ui-white);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  width: 112px;
  height: 32px;
  box-shadow: 3px -3px 7px -4px rgb(0 0 0 / 8%);
}

.mn-track-bar-coll-title {
  width: 110px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: flex;
  align-items: center;
  cursor: var(--global-cursor-pointer);
}

.mn-track-bar-coll-title button {
  width: 100%;
}

.mn-track-bar-coll-title button .label {
  font-family: var(--global-font-medium);
  font-size: var(--global-font-size-xs);
  line-height: 16px;
  width: 100%;
}

.mn-track-bar-coll-title button .label .svg {
  margin-right: 13px;
}

.mn-track-bar-coll-title .svg {
  transform: rotate(-90deg);
}

.mn-track-bar-coll {
  width: 100%;
  border-bottom-right-radius: 4px;
}

.mn-track-bar {
  border-radius: 4px;
  border-top-right-radius: 0;
  background-color: var(--global-colors-ui-white);
  padding: var(--global-spacing-md) var(--global-spacing-lg);
  font-family: var(--global-font-regular);
  padding-right: 17px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 8%);
}

.mn-track-bar__upper,
.mn-track-bar__upper-collapsed {
  display: flex;
  padding-left: var(--global-spacing-xs);
  justify-content: space-between;
}

.mn-track-bar__upper-collapsed > div:nth-child(1),
.mn-track-bar__upper-collapsed > div:nth-child(2) {
  margin-right: var(--global-spacing-xl);
}

.mn-track-bar__upper
  > div:first-child
  .mn-data-label
  .mn-data-label__text
  .mn-data-label__text-data
  > span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  max-width: 20vw;
  min-width: 185px;
}

.mn-track-bar__upper
  > :not(div:first-child)
  .mn-data-label
  .mn-data-label__text
  .mn-data-label__text-data
  > span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  max-width: 30vw;
  min-width: 85px;
}

.mn-track-bar .mn-track-bar__upper-collapsed > div:nth-child(3) {
  margin-left: auto;
}

.mn-track-bar .mn-track-bar__upper-collapsed > div:nth-child(4) {
  margin-left: var(--global-spacing-lg);
}

.mn-track-bar .mn-track-bar__upper-collapsed > div:nth-child(5) {
  margin-left: var(--global-spacing-lg);
  margin-right: var(--global-spacing-xxxl);
}

.mn-track-bar__upper > div > :first-child {
  margin-bottom: var(--global-spacing-sm);
}

.mn-track-bar__bar-holder {
  position: relative;
}

.mn-track-bar__bar {
  width: 100%;
  border-radius: 3px;
  height: 6px;
  background-color: var(--global-colors-sky-dark);
  overflow: hidden;
}

.mn-track-bar__bar > div {
  background-color: var(--global-colors-ui-secondary);
  height: 100%;
  border-radius: 3px 0 0 3px;
}

.mn-track-bar__segment {
  position: absolute;
  height: 6px;
  margin-left: 0px;
  margin-right: 0px;
}

.mn-track-bar__handle {
  width: 20px;
  height: 20px;
  position: absolute;
  background-color: var(--global-colors-ui-white);
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.12), 0 1px 10px 0 rgba(0, 0, 0, 0.06),
    0 2px 4px -1px rgba(0, 0, 0, 0.1);
  border-radius: 150px;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  cursor: var(--global-cursor-pointer);
  border: 0;
  z-index: 10;
}

.mn-track-bar__handle span,
.mn-track-bar__handle span::before,
.mn-track-bar__handle span::after {
  content: "";
  position: absolute;
  left: 5px;
  display: block;
  text-indent: -9999px;
  background-color: #d8d8d8;
  height: 0.1rem;
  width: 10px;
}

.mn-track-bar__handle span {
  top: 1rem;
}

.mn-track-bar__handle span::before {
  top: -0.2rem;
  left: 0;
}

.mn-track-bar__handle span::after {
  top: 0.2rem;
  left: 0;
}

.mn-track-bar-point,
.mn-track-bar-point-icon-start,
.mn-track-bar-point-icon-end {
  position: absolute;
  line-height: 22px;
}

.mn-track-bar-point-icon-start,
.mn-track-bar-point-icon-end {
  top: -5px;
}

.mn-track-bar-time {
  font-size: var(--global-font-size-xs);
  background-color: var(--global-colors-ui-white);
}

.mn-track-bar__points {
  display: flex;
  position: relative;
  /* margin is needed to adjust width to real available slider width
     total margin size (right + left) should be equal to slider thumb size */
  margin-left: 10px;
  margin-right: 10px;
}

.mn-track-bar__points-time {
  display: flex;
  position: relative;
  height: 52px;
  /* margin is needed to adjust width to real available slider width
     total margin size (right + left) should be equal to slider thumb size */
  margin-left: 10px;
  margin-right: 10px;
}

.mn-track-bar__points-time > div {
  position: absolute;
  top: 10px;
}

.mn-track-bar__points .svg {
  margin-bottom: 12px;
}

.mn-track-bar__points .mn-track-bar-point-icon-start::after,
.mn-track-bar__points .mn-track-bar-point-icon-end::after {
  content: "";
  width: 1.5px;
  height: 12px;
  background-color: var(--global-colors-ui-primary);
  display: block;
  top: 19px;
  position: relative;
  left: 8px;
}

.mn-track-bar__points-time > div::before {
  content: "";
  width: 1px;
  height: 29px;
  background-color: var(--global-colors-sky-dark);
  display: block;
  position: relative;
  left: 50%;
}

.mn-track-bar__lower {
  display: flex;
  height: 87px;
  align-items: center;
  margin-top: var(--global-spacing-lg);
}

.mn-track-bar__lower #trackBar_slider input[type="range"] {
  width: 100%;
}

.mn-track-bar__slider {
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

#trackBar_slider {
  margin-top: 26px;
}

#trackBar_slider input {
  position: absolute;
}

#trackBar_slider input#input_up {
  background-color: transparent;
  background-image: none;
  z-index: 1;
}

#trackBar_slider input:focus-visible {
  outline: 0px;
}

.arrow-disabled {
  pointer-events: none;
  margin-bottom: var(--global-spacing-xs);
  margin-bottom: 18px;
}

.arrow-disabled .svg span {
  color: var(--global-colors-icon-disabled) !important;
}

.arrow {
  cursor: var(--global-cursor-pointer);
  margin-bottom: 18px;
}

.mn-track-bar-collapsed {
  border-radius: 4px;
  height: 52px;
  background-color: var(--global-colors-ui-white);
  padding: 8px 16px;
}

.mn-track-bar-collapsed #icon {
  margin-right: 0px;
  margin-left: auto;
  align-items: center;
  display: flex;
  cursor: var(--global-cursor-pointer);
}

.mn-track-bar-collapsed #icon .svg {
  transform: rotate(90deg);
}

.mn-track-bar-collapsed .mn-track-bar__upper-collapsed > div {
  margin-left: auto;
  margin-right: auto;
}

.mn-track-bar__upper-break {
  display: flex;
  padding-left: var(--global-spacing-xs);
}

.mn-track-bar-collapsed .mn-track-bar__upper-break > div {
  margin-right: var(--global-spacing-lg);
  margin-left: var(--global-spacing-lg);
}

.mn-track-bar-collapsed #location .mn-data-label__text-data {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100px;
}

.slider input[type="range"]::-webkit-slider-thumb {
  margin-left: var(--margin-left);
}

.mn-track-bar-collapsed #driver .mn-data-label__text-data {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100px;
}

.mn-track-bar-collapsed #odometer .mn-data-label__text-data {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100px;
}

.mn-track-bar-collapsed #currentTime .mn-data-label__text-data {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100px;
}

.mn-track-bar-collapsed #currentSpeed .mn-data-label__text-data {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100px;
}

.mn-track-bar-collapsed #fuelLevelLiters .mn-data-label__text-data {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100px;
}
