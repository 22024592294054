.notification-filterbar-row {
  display: flex;
  align-items: center;
  font-weight: var(--global-font-semi-bold);
  width: 100%;
  border-bottom: 1px solid var(--global-colors-sky-dark);
  padding: 24px 16px;
}

.notification-filterbar-row > div:not(:last-child) {
  margin-right: 12px;
}

.notification-date-picker {
  height: 32px;
}

.notification-filterbar-button {
  width: auto;
  margin-left: auto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
}
