.mn-event-detail {
  display: flex;
  background-color: var(--global-colors-ui-white);
  align-items: center;
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-sm);
  height: 64px;
  border-radius: 5px;
  cursor: var(--global-cursor-pointer);
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 8%);
}

.mn-event-detail__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

.mn-event-detail-circle_speed_limit {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  background: transparent;
  border: 3px solid var(--global-colors-palette-dark-yellow);
  color: var(--global-colors-palette-dark-yellow);
  font-family: var(--global-font-bold);
  font-size: var(--global-font-size-md);
  display: flex;
  padding: 4px;
  align-items: center;
}

.mn-event-detail-circle_speed_limit_high {
  font-size: var(--global-font-size-xs);
}

.mn-event-detail__event {
  font-size: var(--global-font-size-sm);
}

.detailBalloon {
  display: none;
  position: absolute;
  bottom: 51px;
}

.carousel-root.presentation-mode:hover + .detailBalloon {
  display: block;
}

/* Carousel css */

.carousel.carousel-slider {
  width: 252px !important;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel .slider-wrapper {
  margin: 0px 4px;
  width: initial;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
  display: inline-block;
}

.carousel.carousel-slider .control-arrow {
  background-color: var(--global-colors-ui-white);
  padding-left: 2px;
  padding-right: 2px;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  opacity: 1;
  display: block;
  display: none;
}

.carousel .control-disabled.control-arrow {
  pointer-events: none;
}

.carousel.carousel-slider .control-arrow:hover,
.arrow-carousel {
  background-color: var(--global-colors-ui-white);
  width: 22px;
  height: 63px;
  position: relative;
  padding: 4px;
  display: flex;
  align-items: center;
  cursor: var(--global-cursor-pointer);
}

.arrow-left {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.arrow-right {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
  background-color: var(--global-colors-palette-light-grey);
}

.carousel .control-dots {
  padding: inherit;
  width: auto;
  margin: 6px 0;
}

.carousel .control-dots .dot {
  background-color: var(--global-colors-sky-dark);
  box-shadow: none;
  opacity: 1;
  width: 5px;
  height: 5px;
  margin: 0 2px;
}
