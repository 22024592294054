.signal-status {
  border: 1px solid #ccc;
  display: inline-block;
  border-radius: 150px;
  height: 12px;
  width: 12px;
}

.signal-status--small {
  height: 8px;
  width: 8px;
}

.signal-status--moving {
  background-color: var(--global-colors-feedback-success);
  border-color: var(--global-colors-feedback-success);
}

.signal-status--online {
  background-color: var(--global-colors-feedback-success);
  border-color: var(--global-colors-feedback-success);
}

.signal-status--parking {
  background-color: var(--global-colors-feedback-danger);
  border-color: var(--global-colors-feedback-danger);
}

.signal-status--offline {
  background-color: var(--global-colors-ink-light);
  border-color: var(--global-colors-ink-light);
}

.signal-status--unknown {
  background-color: var(--global-colors-ink-lighter);
  border-color: var(--global-colors-ink-lighter);
}

.signal-status--stop {
  background-color: var(--global-colors-ui-secondary);
  border-color: var(--global-colors-ui-secondary);
}

.signal-status .screen-reader {
  display: none;
}
