.mn-label-text {
  /* font-family: var(--global-font-medium); */
  display: inline-block;
}

.mn-label-text:not(:last-child) {
  margin-right: var(--global-spacing-xs);
}

.mn-label-text__label {
  font-size: var(--global-font-size-xs);
  font-family: var(--global-font-semi-bold);
  margin-bottom: var(--global-spacing-xs);
}

.mn-label-text__text {
  font-size: var(--global-font-size-xs);
  font-family: var(--global-font-regular);
  color: var(--global-colors-ink-light);
}
