/*#region table or pagination style*/
.admin-drivers-table {
  margin-bottom: 70px;
}

.admin-drivers-table table tbody tr td .driver-validation-expire {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.admin-drivers-table
  table
  tbody
  tr
  .dropdown-btn
  > .dropdown__button--ghost
  > .dropdown-only-icon {
  margin-left: 0px;
}

.admin-drivers-table
  table
  tbody
  tr:nth-last-child(-n + 2):not(:first-child)
  .dropdown-btn.dropdown--ghost
  .dropdown-list-only-icon:not(.list-two):not(.list-one) {
  top: -129px;
}

.admin-drivers-table
  table
  tbody
  tr:last-child:not(:only-child)
  .dropdown-btn
  .dropdown-list-only-icon
  .list-one {
  top: -46px;
}

.admin-drivers-table
  table
  tbody
  tr:last-child:not(:only-child)
  .dropdown-btn
  .dropdown-list-only-icon
  .list-two {
  top: -88px;
}

.admin-drivers-pagination {
  position: fixed;
  bottom: 0;
  z-index: 3;
  /* for Chrome, Edge, Opera */
  min-width: -webkit-fill-available !important;
  max-width: -webkit-fill-available !important;
  /* for Mozilla */
  min-width: -moz-available !important;
  max-width: -moz-available !important;
}
/*#endregion table or pagination style*/
