.edit-deadline-container
  .mn-modal
  .mn-modal__dialog
  .edit-deadline
  .mn-modal__dialog-body {
  padding: 32px 2px 0px 0px;
  width: 600px;
  min-height: 303px;
}

.edit-deadline-container
  .mn-modal
  .mn-modal__dialog
  .set-edit-deadline
  .mn-modal__dialog-body {
  margin-top: 32px;
  padding: 0;
  min-height: 424px;
  max-height: 400px;
  overflow-y: scroll;
  width: 600px;
}

.edit-deadline .mn-label-text__label {
  margin-bottom: 0;
}

.edit-deadline .mn-label-text__text {
  color: var(--global-colors-ink-ink);
}

.edit-deadline .mn-modal__dialog-body .mn-label-text__text {
  margin-left: 8px;
  font-family: var(--global-font-medium);
  font-size: var(--global-font-size-xs);
}

.edit-deadline .mn-modal__dialog-body .central-forms {
  width: 504px;
  display: flex;
  margin-left: 32px;
  margin-top: 24px;
  margin-bottom: 27px;
}

.edit-deadline .mn-modal__dialog-body .central-forms .dropdown {
  margin-left: 16px;
}

.edit-deadline .mn-modal__dialog-body .central-forms form {
  width: 200px;
}

.edit-deadline .mn-modal__dialog-body .checkboxes-list {
  overflow-y: scroll;
  height: 216px;
  max-height: 216px;
  display: flex;
  flex-wrap: wrap;
  padding-left: 32px;
}

.edit-deadline .mn-modal__dialog-body .checkboxes-list div {
  width: 220px;
  height: 36px;
  display: flex;
  align-items: center;
}

.edit-deadline .mn-modal__dialog-body .checkboxes-list div:nth-child(even) {
  margin-left: 46px;
}

.edit-deadline .mn-modal__dialog-body .checkboxes-list label p {
  margin-left: 4px !important;
  width: auto;
  color: var(--global-colors-ink-light);
  font-size: var(--global-font-size-sm);
}

.edit-deadline .mn-modal__dialog-body .checkboxes-list span {
  width: auto;
}

.split-line-edit-deadline {
  height: 1px;
  width: auto;
  background-color: var(--global-colors-sky-sky);
}

.edit-deadline-container .mn-modal__dialog .mn-modal__dialog-footer-back {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
}
