.mn-vehicle-utilization .mn-vehicle-utilization__inner {
  display: flex;
}

.mn-vehicle-utilization__inner .mn-vehicle-utilization__graph {
  width: 33%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: var(--global-spacing-xl);
}

.mn-vehicle-utilization__inner .mn-vehicle-utilization__graph svg {
  max-width: 110px;
  display: block;
}

.mn-vehicle-utilization__inner .mn-vehicle-utilization__graph svg path {
  stroke-linecap: round;
  stroke-width: 1.6;
  animation: progress 1s ease-out forwards;
}

.mn-vehicle-utilization__inner .mn-vehicle-utilization__graph svg text {
  fill: var(--global-colors-ink-dark);
  font-size: 0.8rem;
  font-family: inherit;
  text-anchor: middle;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.mn-vehicle-utilization__data {
  display: flex;
  flex-wrap: wrap;
}

.mn-vehicle-utilization__data > * {
  width: 50%;
}

.mn-vehicle-utilization__data > :nth-last-child(-n + 2) {
  border-top: 1px solid var(--global-colors-sky-sky);
}

.mn-vehicle-utilization__data-item {
  padding: var(--global-spacing-md) var(--global-spacing-lg);
}

.mn-vehicle-utilization__data-item > div:first-child {
  font-family: var(--global-font-semi-bold);
  font-size: var(--global-font-size-xs);
  margin: 0 0 var(--global-spacing-xs);
}
.mn-vehicle-utilization__data-item p {
  color: var(--global-colors-ink-light);
  font-size: var(--global-font-size-sm);
  margin: 0;
}
