.cluster-event-marker {
  position: absolute;
  display: inline-block;
  width: 35px;
  height: 35px;
  align-items: center;
  line-height: 34px;
  border: 3px solid var(--global-colors-ui-white);
  border-radius: 50%;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  padding: 0px;
  z-index: 1;
}
/* 
.cluster-event-marker-inner-simple::before,
.cluster-event-marker-inner::before {
  width: 56px;
  height: 56px;
  border: 11px solid rgba(0, 82, 189, 0.1);
  content: "";
  border-radius: 50%;
  display: block;
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

.cluster-event-marker-inner-simple,
.cluster-event-marker-inner {
  z-index: 1;
  width: 30px;
  height: 30px;
  position: absolute;
  font-size: var(--global-font-size-xs);
  font-family: var(--global-font-semi-bold);
  font-weight: normal;
  justify-content: center;
  display: flex;
  border-radius: 50%;
  box-shadow: inset 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
}

.cluster-event-marker-inner-simple > div,
.cluster-event-marker-inner > div {
  z-index: 1;
}

.cluster-event-marker-inner::after {
  content: "";
  display: block;
  position: absolute;
  width: 22px;
  height: 22px;
  background: var(--global-colors-ui-white);
  border-radius: 50%;
  top: 15px;
  left: 15px;
  transform: translate(-50%, -50%);
}

.cluster-event-marker-triangle {
  border-top: 6px solid var(--global-colors-ui-white);
  border-left: 4px dashed transparent;
  border-right: 4px dashed transparent;
  border-bottom: 0;
  position: absolute;
  z-index: 1;
  display: block;
  top: calc(100% + 1px);
  left: calc(100% - 20px);
  overflow: hidden;
  width: 0;
  height: 0;
  filter: drop-shadow(0px 2px 1px rgb(0 0 0 / 0.2));
}

/*#region cluster large size*/
.cluster-event-marker-large {
  position: absolute;
  width: 40px;
  height: 40px;
  left: -4px;
  display: flex;
  align-items: center;
  line-height: 18px;
  border: 3px solid var(--global-colors-ui-white);
  border-radius: 50%;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  padding: 0px;
  z-index: 1;
}

.cluster-event-marker-large:hover {
  cursor: var(--global-cursor-pointer);
  color: transparent;
}

.cluster-event-marker-inner-large {
  z-index: 1;
  position: absolute;
  width: 35px;
  height: 36px;
  font-size: var(--global-font-size-xs);
  font-family: var(--global-font-semi-bold);
  font-weight: normal;
  justify-content: center;
  display: flex;
  border-radius: 50%;
  box-shadow: inset 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
}

.cluster-event-marker-inner-large > div {
  z-index: 9;
}

.cluster-event-marker-inner-large::after {
  content: "";
  display: block;
  position: absolute;
  width: 26px;
  height: 26px;
  background: white;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cluster-event-marker-triangle-large {
  border-top: 6px solid var(--global-colors-ui-white);
  border-left: 4px dashed transparent;
  border-right: 4px dashed transparent;
  border-bottom: 0;
  position: absolute;
  z-index: 1;
  display: block;
  top: calc(100% + 1px);
  left: calc(100% - 22px);
  overflow: hidden;
  width: 0;
  height: 0;
  filter: drop-shadow(0px 2px 1px rgb(0 0 0 / 0.2));
}
/*#endregion cluster large size*/

.map-marker-pin .status-led,
.cluster-event-marker .label-led {
  box-sizing: border-box;
  position: absolute;
  line-height: 9px;
  z-index: 99;
  top: -2px;
  right: -2px;
  display: inline-block;
  border-radius: 150px;
  height: 13px;
  width: 13px;
  border: 2px solid #ffffff;
  box-shadow: 0 3px 2px -1px rgba(0, 0, 0, 0.2);
  background-color: var(--global-colors-ui-white);
  font-family: var(--global-font-semi-bold);
  text-align: center;
}

.cluster-event-marker .label-led {
  background-color: var(--global-colors-ui-white);
  font-family: var(--global-font-semi-bold);
  text-align: center;
}

.map-marker-pin,
.cluster-event-marker {
  position: relative;
  display: inline-block;
  line-height: 9px;
  color: var(--global-colors-ui-primary);
}

.event-circle {
  width: 62px;
  height: 62px;
  border: 11px solid rgba(0, 82, 189, 0.1);
  content: "";
  border-radius: 50%;
  display: block;
  position: absolute;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
