.input-container {
  display: flex;
  flex-direction: column;
  font-family: var(--global-font-regular);
}

.text-field {
  font-family: var(--global-font-regular);
  max-width: auto;
}

.text-field p {
  height: 13px;
  font-family: var(--global-font-light);
  font-size: var(--global-font-size-xxs);
  color: var(--global-colors-feedback-danger);
  margin-top: 4px;
  margin-left: 8px;
  line-height: 10px;
  margin-bottom: 0px;
}

.text-field.text-field--has-icon {
  position: relative;
}

.text-field.text-field--has-icon input {
  width: 100%;
  margin: 0;
  padding-right: 42px;
}

.text-field.text-field--has-icon .svg {
  position: absolute;
  top: 0;
  display: block;
  line-height: 42px;
  text-align: center;
  right: 16px;
  cursor: var(--global-cursor-pointer);
}

/* #region for check indicator as icon*/
.text-field.text-field--has-icon .check {
  position: absolute;
  top: 33%;
  text-align: center;
  right: 16px;
  cursor: var(--global-cursor-pointer);
  justify-content: center;
  display: flex;
  z-index: 1;
  pointer-events: all;
}

.text-field.text-field--has-icon .check .svg {
  position: relative;
  cursor: var(--global-cursor-pointer);
  right: 0;
  line-height: normal;
}
/* #endregion */

.text-field input:disabled + .svg {
  color: var(--global-colors-sky-dark);
  opacity: 0.5;
  cursor: var(--global-cursor-not-allowed);
}

.text-field input[value=""],
.text-field textarea:placeholder-shown {
  border: 1px solid var(--global-colors-sky-dark);
}

.text-field input,
.text-field textarea {
  height: 42px;
  width: 100%;
  max-width: auto;
  border: 1px solid var(--global-colors-ink-light);
  border-radius: 4px;
  padding: 13px 16px;
  outline: 0;
  background-color: transparent;
  transition: all 0.3s ease;
  font-size: var(--global-font-size-sm);
  font-family: inherit;
  color: var(--global-colors-ink-dark);
}

.text-field input:-webkit-autofill,
.text-field input:-webkit-autofill:hover,
.text-field input:-webkit-autofill:focus,
.text-field input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.text-field textarea {
  height: 81px;
  max-width: auto;
  resize: none;
}

.text-field input:focus,
.text-field textarea:focus {
  border-color: var(--global-colors-ui-primary);
  box-shadow: 0 1px 3px 0 rgba(0, 170, 255, 0.26);
}

.text-field input:focus:not(:placeholder-shown),
.text-field textarea:focus:not(:placeholder-shown) {
  background-color: var(--global-colors-ui-white);
}

.text-field input.input--has-error,
.text-field textarea.input--has-error {
  border-color: var(--global-colors-feedback-danger);
}

.text-field input:disabled,
.text-field textarea:disabled {
  background-color: var(--global-colors-sky-dark);
  opacity: 0.5;
  color: var(--global-colors-ink-light);
  border: 1px solid var(--global-colors-ui-sky-sky--hover);
  cursor: var(--global-cursor-not-allowed);
}

.text-field input.input--small {
  height: 32px;
}

.text-field input.input--small + .svg {
  line-height: 32px !important;
}

.text-field textarea.input--small {
  height: 70px;
}

.text-field label:empty {
  display: none;
}

.text-field label {
  padding-left: 8px;
  padding-bottom: 4px;
  display: block;
  font-size: var(--global-font-size-xs);
  font-family: var(--global-font-regular);
}

.text-field input[type="time"] {
  width: 100px;
  padding: 9px 14px;
}

.text-field input[type="time"]::-webkit-calendar-picker-indicator {
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGl2ZWxsb18xIiBkYXRhLW5hbWU9IkxpdmVsbG8gMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMTQgMTQiPjxkZWZzPjxzdHlsZT4uY2xzLTF7ZmlsbDojNjg3NDg1O308L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik03LDBIN0E3LDcsMCwwLDAsNywxNEg3QTcsNywwLDAsMCw3LDBaTTcsMTIuNThIN0E1LjU4LDUuNTgsMCwwLDEsNywxLjQySDdBNS41OCw1LjU4LDAsMCwxLDcsMTIuNThaIi8+PHJlY3QgY2xhc3M9ImNscy0xIiB4PSI2LjIyIiB5PSIzLjA3IiB3aWR0aD0iMS40OSIgaGVpZ2h0PSI0LjUyIiByeD0iMC43NSIvPjxyZWN0IGNsYXNzPSJjbHMtMSIgeD0iNy4xNSIgeT0iNS43OSIgd2lkdGg9IjEuNDkiIGhlaWdodD0iMy42IiByeD0iMC42NyIgdHJhbnNmb3JtPSJtYXRyaXgoLTAuNTMsIDAuODUsIC0wLjg1LCAtMC41MywgMTguNSwgNC44OCkiLz48L3N2Zz4=)
    no-repeat center center;
  background-size: 14px;
  cursor: var(--global-cursor-pointer);
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.text-field + p:empty {
  display: none;
}

::placeholder {
  color: var(--global-colors-ink-light);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--global-colors-ink-light);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--global-colors-ink-light);
}
