.hs-header {
  display: inline-flex;
  padding-top: 2px;
  padding-bottom: 1.6rem;
  width: 100%;
  border-bottom: var(--global-colors-sky-sky-outline);
  justify-content: space-between;
}

.hs-header .mn-user-box--regular {
  padding: 0rem;
}

.hs-header .mn-user-box__iconText {
  display: none;
}

.hs-driver {
  padding-left: 1.4rem;
  padding-top: 0.3rem;
  width: 100%;
}

.hs-driver #driver {
  height: 1.8rem;
  color: var(--global-colors-ink-dark);
  font-size: 1.2rem;
  letter-spacing: 0;
  line-height: 1.8rem;
}

.hs-driver #fleet {
  height: 1.4rem;
  color: var(--global-colors-ink-light);
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 1.4rem;
}

.hs-slot {
  padding-top: 0.3rem;
}

.hs-slot .dropdown {
  width: 9.1rem;
}

.hs-table-driver-inner {
  display: inline-flex;
  width: 100%;
}

.hs-table-driver-summary {
  width: 50%;
  padding-top: 7.2rem;
  padding-left: 1.5rem;
}

.hs-table-driver-summary div {
  width: fit-content;
}

.hs-table-driver-graphs {
  width: 50%;
}

.hs-table-driver-graphs div {
  display: inline-flex;
}

.hs-table-driver-cycle {
  padding-bottom: 1.6rem;
}

.hs-table-driver-tomorrow {
  padding-top: 1.6rem;
  border-top: var(--global-colors-sky-sky-outline);
}

.hs-table-driver-last {
  color: var(--global-colors-ink-light);
  margin-top: 77px;
  font-size: 1rem;  
}

.hs-table-driver-last-no-driver {
  color: var(--global-colors-ink-light);
  margin-top: 53px;
  font-size: 1rem;
}

.hs-table-driver-last-no-truck {
  color: var(--global-colors-ink-light);
  margin-top: 59px;
  font-size: 1rem;
  margin-left: 16px;
}

.hs-table-driver-graph-driving {
  margin-left: 2.7rem;
  margin-right: 1.5rem;
}

.hs-table-driver-graph-available {
  width: 100%;
  justify-content: center;
}

.hs-driver-detail-lower-row-second-type {
  display: flex;
  width: 100%;
  margin-top: 43px;
  margin-left: 53px;
}

.hs-chart__label {
  font-size: 10px;
  font-family: var(--global-font-medium);
  color: var(--global-colors-ink-dark);
  display: block;
}

.hs-chart {
  align-items: center;
  display: inline-grid;
}

.hs-driver-detail-lower-row-second-type .hs-chart:not(:last-child) {
  margin-right: 72px;
}

.hs-chart__data {
  display: block;
  font-size: 24px;
  text-align: center;
  font-family: var(--global-font-regular);
  color: var(--global-colors-ink-dark);
}

.hs-driver-green-row {
  height: 50px;
  width: 6px;
  border-radius: 3.5px;
  background-color: var(--global-colors-status-online);
  margin-right: 12px;
}

.hs-driver-orange-row {
  height: 50px;
  width: 6px;
  border-radius: 3.5px;
  background-color: var(--global-colors-radialbar-break);
  margin-right: 12px;
}

.hs-driver-sky-blue-row {
  height: 50px;
  width: 6px;
  border-radius: 3.5px;
  background-color: var(--global-colors-ui-secondary);
  margin-right: 12px;
}
