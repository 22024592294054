.admin-notification-subtopbar {
  height: 82px;
  width: 100%;
  background-color: var(--global-colors-ui-white);
  border-bottom: 1px solid #d2d5da;
  padding: 16px;
  display: flex;
  align-items: center;
}

.admin-notification-subsection-name {
  color: var(--global-colors-ink-dark);
  font-family: var(--global-font-regular);
  font-size: 24px;
}

.admin-notification-buttons {
  margin-left: auto;
}

.admin-notification-page-container {
  position: relative;
  top: 0;
  background-color: var(--global-colors-sky-lighter);
  width: auto;
  height: 100%;
  overflow-y: scroll;
}

.admin-notification-forms-container {
  width: auto;
  min-height: 42px;
  margin-top: 24px;
  margin-left: 36px;
  margin-right: 77px;
  vertical-align: middle;
}

.admin-notification-info-form {
  margin: 16px 16px;
  box-sizing: border-box;
  padding-bottom: 60px;
  width: auto;
  background-color: var(--global-colors-ui-white);
  border: 1px solid #e4e6e9;
  border-radius: 4px;
}

.admin-notification-info-form-period {
  margin: 16px 16px;
  box-sizing: border-box;
  padding-bottom: 60px;
  width: auto;
  background-color: var(--global-colors-ui-white);
  border: 1px solid #e4e6e9;
  border-radius: 4px;
}

.admin-notification-left-form-item {
  width: 100%;
  float: left;
}

.admin-notification-left-form-item > .dropdown {
  width: 100%;
}

.admin-notification-forms-title {
  font-family: var(--global-font-medium);
  font-size: var(--global-font-size-sm);
  width: 153px;
  height: 18px;
  margin-top: 36px;
  margin-left: 36px;
}
.admin-notification-forms-title-entities {
  font-family: var(--global-font-medium);
  font-size: var(--global-font-size-sm);
  width: 230px;
  height: 18px;
  margin-top: 36px;
  margin-left: 36px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.admin-notification-left-form-label {
  font-family: var(--global-font-semi-bold);
  font-size: var(--global-font-size-xs);
  color: var(--global-colors-ink-light);
  width: auto;
  height: 42px;
  float: left;
  display: flex;
  align-items: center;
}

.admin-notification-forms {
  width: calc(100% - (85px + 111px));
  float: right;
}

.admin-notification-forms-dropdown {
  width: calc(100% - (85px + 111px));
  float: right;
  display: flex;
  gap: 180px;
}

.admin-notification-left-form-item .textfield-icon {
  right: auto;
  margin-left: 8px;
  margin-bottom: 8px;
}

.admin-notification-left-form-item .datepicker-dropdown-btn {
  width: 100% !important;
  margin: 0;
  height: 42px;
}

.text-field-tag-container {
  display: flex;
  width: 100%;
  gap: 20px;
}

.text-field-tag-input {
  width: 97%;
}

.text-field-tag-button {
  width: 3%;
}

.text-field-tag-button .button {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border: 1px solid var(--global-colors-sky-dark);
}

.start-end-date-button .button:hover {
  background-color: var(--global-colors-ui-primary--hover);
  border: 1px solid var(--global-colors-sky-dark);
}

.start-end-date-button {
  width: 3%;
}

.start-end-date-button .button {
  width: 100%;
  height: 100%;
  background-color: var(--global-colors-ui-primary);
  border: 1px solid var(--global-colors-sky-dark);
}

.row-start-end-time-container {
  width: 96%;
  display: flex;
}

.row-start-time-container {
  display: flex;
  gap: 70px;
  width: 50%;
}

.row-start-time-text {
  position: relative;
  font-family: var(--global-font-semi-bold);
  font-size: var(--global-font-size-xs);
  color: var(--global-colors-ink-light);
  height: 42px;
  float: left;
  display: flex;
  align-items: center;
  width: 18%;
}

.row-start-time-datepicker {
  width: 100%;
}

.row-start-time-datepicker .text-field input[type="time"] {
  width: 90%;
  height: 100%;
}

.row-end-time-container {
  display: flex;
  gap: 70px;
  width: 50%;
  justify-content: end;
}

.row-end-time-text {
  position: relative;
  font-family: var(--global-font-semi-bold);
  font-size: var(--global-font-size-xs);
  color: var(--global-colors-ink-light);
  height: 42px;
  float: left;
  display: flex;
  align-items: center;
  width: 16%;
}

.row-end-time-datepicker {
  width: 100%;
  text-align: end;
}

.row-end-time-datepicker .text-field input[type="time"] {
  width: 90%;
  height: 100%;
}
