.widget-top-bar {
  background-color: var(--global-colors-sky-lighter);
  height: 60px;
  align-items: center;
  display: flex;
  padding: 14px 24px;
}

.widget-title {
  height: 36px;
  width: 90%;
  color: var(--global-colors-ink-dark);
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: left;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.widget-title-button {
  width: 100%;
  justify-content: end;
  display: flex;
}

.widget-searchfield {
  padding: 16px 24px;
}

.widget-searchfield input {
  width: -webkit-fill-available;
}

.widget {
  display: flex;
  font-size: var(--global-font-size-xs);
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid var(--global-colors-sky-sky);
  border-radius: 4px;
  background-color: var(--global-colors-ui-white);
}

.widget-inverse-button {
  height: 35px;
  width: 35px;
  background-color: var(--global-colors-ink-lighter);
}

.widget-body {
  width: 100%;
  display: block;
  height: calc((100vh - 350px));
  max-height: calc((100vh - 350px));
  overflow-y: auto;
}

.widget-no-child-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35%;
}

.widget-no-child-icon {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background-color: var(--global-colors-sky-light);
  display: flex;
  align-items: center;
  justify-content: center;
}

.widget-no-child-label {
  width: 150px;
  color: var(--global-colors-ink-light);
  font-size: var(--global-font-size-sm);
  text-align: center;
  margin-top: 8px;
}
