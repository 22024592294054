.driver-lh-filter-row {
  display: flex;
  align-items: center;
  font-weight: var(--global-font-semi-bold);
  width: 100%;
}

.driver-lh-search,
.driver-lh-date-picker,
.driver-lh-tracks-stops {
  margin-top: 24px;
  margin-bottom: 24px;
}

.driver-lh-search {
  width: 219px;
  margin-left: 16px;
}

.driver-lh-search .lh-search-results {
  box-sizing: border-box;
  height: auto;
  width: 219px;
  border: 1px solid var(--global-colors-sky-sky);
  border-radius: 4px;
  margin-top: 4px;
  position: absolute;
  background-color: var(--global-colors-ui-white);
  z-index: 999;
}

.driver-lh-search .lh-search-results .lh-driverbox {
  height: 72px;
  font-weight: var(--global-font-semi-bold);
  font-size: 14px;
}

.driver-lh-search .lh-search-results .lh-driverbox-selected {
  background-color: var(--global-colors-ui-primary);
}

.driver-lh-search
  .lh-search-results
  .lh-driverbox-selected
  .mn-user-box__upper-row--regular,
.driver-lh-search
  .lh-search-results
  .lh-driverbox-selected
  .mn-user-box__lower-row {
  color: var(--global-colors-ui-white);
}

.driver-lh-search .lh-search-results .lh-driverbox:hover {
  background: var(--global-colors-sky-lighter);
}

.driver-lh-date-picker {
  margin-left: 12px;
  height: 32px;
}

.driver-lh-tracks-stops {
  margin-left: 12px;
}
