.textfield-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: var(--global-cursor-pointer);
  z-index: 9;
}

.textfield-container {
  min-height: 42px;
  display: flex;
  width: 100%;
  border: 1px solid var(--global-colors-sky-dark);
  border-radius: 4px;
  padding: 8px 14px 2px 14px;
  transition: all 0.3s ease;
  font-size: var(--global-font-size-sm);
}

.textfield-inner {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  float: left;
}

.textfield-tags {
  display: flex;
  align-items: center;
  margin-right: 6px;
  height: 24px;
  margin-bottom: 6px;
  cursor: var(--global-cursor-pointer);
}

.textfield-input {
  flex: 1;
  height: 24px;
  width: 100%;
  min-width: 10%;
  display: flex;
  border: none;
  margin-bottom: 6px;
  outline: transparent;
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-sm);
  background-color: transparent;
}

.textfield-icon {
  display: flex;
  align-items: center;
  transition: all 0.2s ease-in-out;
}

.textfield-icon.open {
  transform: rotate(180deg);
}

.textfield-menu-container {
  width: 100%;
  height: auto;
  border: 1px solid var(--global-colors-sky-sky);
  border-radius: 4px;
  z-index: 9999999;
  overflow-x: hidden;
  background: var(--global-colors-ui-white);
  position: absolute;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
}

.textfield-menu-container:empty {
  border: none;
}

.textfield-menu-container .textfield-list {
  cursor: var(--global-cursor-pointer);
  align-items: center;
}

.textfield-menu-container .textfield-list .mn-user-box--large {
  width: 100%;
}

.textfield-list-container {
  overflow-y: auto;
  max-height: 288px;
}

.add-all-button .button--ghost {
  width: 100%;
}

.add-all-button .button--ghost > * {
  font-size: var(--global-font-size-xs);
  font-family: var(--global-font-semi-bold);
}

.textfield-container:has(.mn-tag) {
  border: 1px solid var(--global-colors-ink-light);
}

.textfield-list-item {
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-xs);
  padding: var(--global-spacing-xs);
  position: relative;
  overflow: hidden;
  align-items: center;
}

.textfield-list-item:hover {
  background-color: var(--global-colors-ui-secondary-light);
  color: var(--global-colors-ink-dark);
}

.textfield-icon-disabled {
  color: var(--global-colors-ink-light);
  opacity: 0.5;
  cursor: var(--global-cursor-not-allowed);
}

.textfield-input:disabled {
  background-color: var(--global-colors-sky-dark);
  opacity: 0.5;
  cursor: var(--global-cursor-not-allowed);
}

.textfield-wrapper-disabled .textfield-container {
  border-radius: 4px;
  background-color: var(--global-colors-sky-dark);
  opacity: 0.5;
  border: 1px solid var(--global-colors-ui-sky-sky--hover);
  cursor: var(--global-cursor-not-allowed);
}

.textfield-wrapper-disabled .textfield-tags {
  pointer-events: none;
}
