.help-feedback .mn-modal__dialog {
  width: 600px;
}

.feedback .mn-modal__dialog {
  width: 600px;
}

.help-feedback .mn-modal .mn-modal__dialog .mn-modal__dialog-body {
  padding: 32px 32px 0px 32px;
}