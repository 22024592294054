.geofence-report-tabs .mn-tabs__list {
  padding-left: 24px;
  margin-bottom: -5px;
}

.geofence-report-tabs .mn-tabs__content {
  padding: 0px;
}

.geofence-report-container {
  height: 100%;
  max-height: calc(100% - (242px));
}

.geofence-report-table-container {
  overflow-y: auto;
  height: 100%;
}

.geofence-report-table-container .mn-table tbody tr td:last-child {
  justify-content: flex-end;
  display: flex;
  float: none;
}

/* data title style */
.show-child [data-title] {
  position: relative;
  cursor: var(--global-cursor-pointer);
}

.show-child [data-title]:hover::before {
  content: attr(data-title);
  position: absolute;
  height: 16px;
  padding: 6px;
  text-transform: uppercase;
  border-radius: 4px;
  background: var(--global-colors-ink-dark);
  color: var(--global-colors-ui-white);
  display: flex;
  align-items: center;
  font-size: 10px;
  font-family: var(--global-font-semi-bold);
  white-space: nowrap;
  left: 96px;
  top: 11px;
}

.details-geofenceReport-wrapper {
  padding: 16px 16px 16px 32px;
  font-size: var(--global-font-size-sm);
  line-height: 18px;
  text-align: left;
  height: 100px;
  border-radius: 4px;
  border-bottom: var(--global-colors-sky-sky-outline);
}

.details-geofenceReport-wrapper .details-report-title {
  margin-bottom: 16px;
  font-family: var(--global-font-medium);
  font-size: var(--global-font-size-sm);
}

.details-geofenceReport-wrapper .details-report-container {
  height: 34px;
  align-items: center;
  width: 100%;
  display: flex;
}

.details-geofenceReport-wrapper .details-report-icons {
  display: flex;
  padding-left: 8px;
  float: left;
}

.details-geofenceReport-wrapper .details-report-item {
  margin-right: 20px;
  max-width: 16%;
  min-width: 120px;
  width: 10vw;
}

.details-geofenceReport-wrapper .details-report-item:last-child {
  margin: 0;
}

.details-geofenceReport-wrapper .details-report-button {
  display: flex;
  height: 32px;
  float: right;
  margin-left: auto;
}

.details-geofenceReport-wrapper .details-report-button button {
  min-width: 146px;
}

.details-geofenceReport-wrapper .details-report-loader {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid var(--global-colors-ink-light);
  border-top-color: transparent;
  animation: spinner 0.6s linear infinite;
  margin-right: 15px;
  margin-left: 70px;
  margin-top: -17px;
}
