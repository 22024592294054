.gpsd-report-filter-row {
  display: flex;
  align-items: center;
  font-weight: var(--global-font-semi-bold);
  width: 100%;
  height: 80px;
  padding: 24px;
}

.gpsd-report-filter-row > div:not(:last-child) {
  margin-right: 16px;
}

.gpsd-report-filter-datepicker {
  height: 32px;
}

.gps-report-filter-btn-generate #button {
  width: 85px;
}
.gpsd-report-filter-vehicles-dropdown .vehicles-dropdown-btn {
  width: 180px !important;
}

.gps-report-filter-btn {
  width: auto;
  display: inline-flex;
  margin-left: auto;
  gap: 16px;
}

.gpsd-report-filter-row .dropdown-list {
  background-color: var(--global-colors-ui-white);
  position: absolute;
  right: 0px;
  width: max-content;
}

.gps-report-filter-bt .dropdown-elements {
  font-family: var(--global-font-regular);
  font-size: 14px;
  text-align: left;
  color: var(--global-colors-ink-dark);
}
