.zoom-button-track {
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 8%);
  position: relative;
}

.zoom-button-track .zoom-button-disabled {
  pointer-events: none;
}

.zoom-button-disabled .svg span {
  color: var(--global-colors-icon-disabled) !important;
}

.zoom-button .svg,
.zoom-button-disabled .svg {
  height: 33px;
  padding: 9px;
}

.zoom-button-track .zoom-button {
  cursor: var(--global-cursor-pointer);
}

.zoom-number {
  font-size: var(--global-font-size-xxs);
  color: var(--global-colors-ink-light);
  width: 96px;
  text-align: end;
}

.zoom-button .iconAddBalloon .balloon-tooltip,
.zoom-button .iconMinusBalloon .balloon-tooltip,
.zoom-button .iconRefreshBalloon .balloon-tooltip {
  width: max-content;
}

.zoom-button .iconRefreshBalloon .balloon-tooltip--bottom::after {
  left: 72%;
}

.iconAddBalloon {
  bottom: 32px;
  right: 46px;
}

.iconMinusBalloon {
  bottom: 32px;
  right: 9px;
}

.iconRefreshBalloon {
  bottom: 32px;
  right: -16px;
}

.iconAddBalloon,
.iconMinusBalloon,
.iconRefreshBalloon {
  display: none;
  position: absolute;
}

.zoom-button:hover .iconAddBalloon,
.zoom-button:hover .iconMinusBalloon,
.zoom-button:hover .iconRefreshBalloon {
  display: block;
  background-color: var(--global-colors-sky-light);
}

.zoom-button:hover {
  background-color: var(--global-colors-sky-light);
}
