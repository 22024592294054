.vehicle-lh-filter-row {
  display: flex;
  align-items: center;
  font-weight: var(--global-font-semi-bold);
  width: 100%;
  position: relative;
}

.vehicle-lh-search,
.vehicle-lh-date-picker,
.vehicle-lh-tracks-stops {
  margin-top: 24px;
  margin-bottom: 24px;
}

.vehicle-lh-search {
  margin-left: 16px;
  width: 220px;
}

.vehicle-lh-search .lh-search-results {
  box-sizing: border-box;
  height: auto;
  border: 1px solid var(--global-colors-sky-sky);
  border-radius: 4px;
  margin-top: 4px;
  position: absolute;
  background-color: var(--global-colors-ui-white);
  z-index: 999;
  max-height: 300px;
  width: 220px;
  overflow-y: auto;
}

.vehicle-lh-search .lh-search-results .lh-fleet-label {
  font-weight: var(--global-font-semi-bold);
  color: var(--global-colors-ink-light);
  font-size: 12px;
  padding-left: 16px;
  padding-top: 12px;
  padding-bottom: 4px;
}

.vehicle-lh-search .lh-search-results .lh-vehicle-alias {
  height: 38px;
  font-weight: var(--global-font-semi-bold);
  font-size: 14px;
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 11px;
}

.vehicle-lh-search .lh-search-results .lh-vehicle-alias-selected {
  background-color: var(--global-colors-ui-primary);
  color: var(--global-colors-ui-white);
  height: 38px;
  font-weight: var(--global-font-semi-bold);
  font-size: 14px;
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 11px;
}

.vehicle-lh-search .lh-search-results .lh-vehicle-alias:hover {
  background: var(--global-colors-sky-lighter);
}

.vehicle-lh-date-picker {
  margin-left: 12px;
  height: 32px;
}

.vehicle-lh-tracks-stops {
  margin-left: 12px;
}

.vehicle-lh-speed-btn {
  right: 16px;
  position: absolute;
  top: 30%;
}

.vehicle-lh-speed-btn .dropdown-btn .dropdown-list {
  position: absolute;
  right: 0px;
  width: max-content;
  z-index: 9999999;
}

.dropdown-events-filter-row {
  margin-left: 12px;
}

.dropdown-events-filter-vehicles-dropdown .dropdown-content {
  border: 1px solid var(--global-colors-sky-dark);
  border-radius: 4px;
  position: absolute;
  margin-top: 4px;
  width: 221px;
  padding-top: 0px;
  background-color: var(--global-colors-ui-white);
  cursor: var(--global-cursor-pointer);
  outline: none;
  color: var(--global-colors-ink-dark);
  font-family: var(--global-font-regular);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
  z-index: 100;
}

.dropdown-events-filter-row .events-dropdown-btn.active {
  width: 220px !important;
}

.dropdown-events-filter-row .events-dropdown-btn {
  width: 120px !important;
}
