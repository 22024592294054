.center-location {
  cursor: var(--global-cursor-pointer);
}

.customMarker {
  position: absolute;
  cursor: var(--global-cursor-pointer);
  display: flex;
  justify-content: center;
  width: 100px;
  height: 100px;
  /* -width/2 */
  margin-left: -50px;
  /* -height + arrow */
  margin-top: -110px;
  border-radius: 10px;
  padding: 0px;
}

.customMarker > div {
  margin-top: 72px;
  text-align: center;
  display: flow-root;
}

.customMarker:after {
  content: "";
  position: absolute;
  bottom: -10px;
  z-index: 1;
  left: 40px;
  display: block;
  width: 0;
}

.customMarker > div > .labelId {
  font-size: 10px;
  background-color: var(--global-colors-ui-white);
  font-family: var(--global-font-semi-bold);
  padding: 2px 4px;
  border-radius: 2px;
  cursor: var(--global-cursor-pointer);
  box-shadow: 0 3px 2px -1px rgba(0, 0, 0, 0.2);
  max-width: 14ch;
  margin: 0 auto;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Limita il numero di righe */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 2ch;
}

.customMarker > div > .labelId:hover {
  max-height: none;
  white-space: unset;
  display: block;
}

.custom-cluster-arrow {
  position: absolute;
  cursor: var(--global-cursor-pointer);
  display: flex;
  justify-content: center;
  width: 100px;
  height: 100px;
  /* -width/2 */
  margin-left: -48px;
  /* -height + arrow */
  margin-top: -83px;
  border-radius: 10px;
  padding: 0px;
  z-index: 999;
}

.custom-cluster-arrow > div {
  margin-top: 70px;
  z-index: 999;
  text-align: center;
  display: flow-root;
}

.custom-cluster-arrow:after {
  content: "";
  position: absolute;
  bottom: -10px;
  z-index: 999;
  left: 40px;
  display: block;
  width: 0;
}

.custom-cluster-petals {
  position: absolute;
  cursor: var(--global-cursor-pointer);
  display: flex;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  margin-top: -20px;
  border-radius: 10px;
  padding: 0px;
  z-index: 100;
}

.custom-cluster-petals > div {
  text-align: center;
  position: absolute;
  display: flow-root;
  z-index: 100;
}

.custom-cluster-single-event {
  position: absolute;
  cursor: var(--global-cursor-pointer);
  display: flex;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  margin-top: -20px;
  border-radius: 10px;
  padding: 0px;
  z-index: 1;
}

.custom-cluster-single-event > div {
  text-align: center;
  position: absolute;
  display: flow-root;
  z-index: 999;
}

.marker-public-stop {
  position: absolute;
  cursor: var(--global-cursor-pointer);
  display: flex;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  margin-top: -20px;
  border-radius: 10px;
  padding: 0px;
  z-index: 1;
  margin-top: -15px;
}

.marker-public-stop > div {
  text-align: center;
  position: absolute;
  display: flow-root;
  z-index: 999;
}

.marker-public-stop-start-end {
  position: absolute;
  cursor: var(--global-cursor-pointer);
  display: flex;
  justify-content: center;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: -20px;
  border-radius: 10px;
  padding: 0px;
  z-index: 999;
  margin-top: -20px;
  margin-left: -50px;
}

.marker-public-stop-start-end > div {
  text-align: center;
  position: absolute;
  display: flow-root;
  z-index: 999;
}

.gm-ui-hover-effect {
  display: block !important;
}

.alert-lock-view {
  font-family: var(--global-font-medium);
  font-size: 12px;
  color: var(--global-colors-ui-primary);
  position: absolute;
  cursor: var(--global-cursor-pointer);
  height: 32px;
  right: 64px;
  bottom: 70px;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  background-color: var(--global-colors-ui-white);
  display: flex;
  align-items: center;
  padding: 14px;
}

.alert-lock-view label {
  margin-top: 3px;
  margin-left: 8px;
  cursor: var(--global-cursor-pointer);
}

.map-functionalities .gm-style .maptype-control {
  position: absolute !important;
  right: 0px !important;
  bottom: 134px !important;
}

.fleetPolling {
  right: 48px !important;
  bottom: 6px !important;
}

.next-button .button {
  background-color: transparent;
  top: 5px;
  left: 5px;
  width: 8px;
  height: 8px;
  margin-right: 10px;
}

.next-button .button:hover {
  background-color: transparent;
}

.prev-button .button {
  background-color: transparent;
  top: 5px;
  left: 5px;
  width: 8px;
  height: 8px;
}

.prev-button .button:hover {
  background-color: transparent;
}

.action-control {
  display: flex;
  flex-direction: column;
}

.tag {
  width: max-content;
  max-width: 14ch;
  height: 20px;
  max-height: max-content;
  margin-bottom: 5px;
  color: var(--global-colors-ui-white);
  font-family: var(--global-font-medium);
  border-radius: 4px;
  z-index: 1000;

  font-size: 2.25ch; /*16px*/
  padding: 12px;
  cursor: var(--global-cursor-pointer);
  box-shadow: 0 3px 2px -1px rgba(0, 0, 0, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 54%;
  left: 50%;
  transform: translate(-50%, -54%);
  /* line-height: 2ch; */
}

.hide {
  display: none;
}

.next {
  background-color: var(--global-colors-ui-secondary) !important;
}

.onTime {
  background-color: var(--global-colors-feedback-success);
}

.late {
  background-color: var(--global-colors-feedback-danger);
}

.notAvailable {
  background-color: rgb(134, 134, 134);
}

.statusCircle {
  border: solid 3px white;
  border-radius: 50px;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 80%;
  left: 66%;
  transform: translate(-80%, -66%);
  z-index: 1000;
}
