.addressBook-modal .mn-modal .mn-modal__dialog {
  height: auto;
  max-width: 950px;
  border-radius: 4px;
  overflow: hidden;
}

.mn-modal .mn-modal__dialog .addressBook-modal-body .mn-modal__dialog-body {
  padding: 36px 0px 0px 0px;
  height: 482px;
  width: 874px;
  /* border-bottom: 1px solid gray; */
  /* box-shadow: 0 2px 10px 0 rgba(0,s 0, 0, 0.08); */
}

/* Left Column */
.left-addressBook-column {
  width: 220px;
  height: 100%;
  float: left;
  padding: 0px 12px 0px 12px;
  border-right: var(--global-colors-sky-sky-outline);
}

.section-button-list .button .label {
  width: 196px;
}

.section-button-list button + button {
  margin: 0px;
}

.split-line-addressBook-vertical {
  height: 738px;
  width: 1px;
  background-color: var(--global-colors-sky-sky);
  position: absolute;
  left: 220px;
}

/* Right Column */
.right-addressBook-column {
  float: right;
  width: 653px;
  /* height: 730px; */
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.split-line-addressBook-row {
  height: 1px;
  width: auto;
  background-color: var(--global-colors-sky-sky);
}

.inner-section-header {
  height: 118px;
}

.inner-section-body {
  height: auto;
}

.contacts-list-container {
  height: 40px;
}

.addressBook-title {
  padding: 12px 32px;
  height: 42px;
  color: var(--global-colors-ink-dark);
  font-size: 14px;
  float: left;
}

.addressBook-modify {
  user-select: none;
  margin-right: 32px;
  display: flex;
  align-items: center;
  height: 42px;
  color: var(--global-colors-ui-primary);
  float: right;
}

.addressBook-modify:hover {
  cursor: var(--global-cursor-pointer);
}

.addressBook {
  padding: 28px 32px;
  height: 76px;
  width: 653px;
  color: var(--global-colors-ink-dark);
  font-size: 16px;
}

.addressBook-switch {
  float: right;
}

.addressBook-modal .mn-modal .mn-modal__dialog .mn-modal__dialog-footer,
.mn-modal .mn-modal__dialog .mn-modal__dialog-footer-back {
  padding: 48px 24px 24px;
  display: flex;
  justify-content: flex-end;
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 8%);
}

/* Applica l'animazione all'oggetto desiderato */
.addressBook-page-content {
  position: absolute;
  right: 12px;
  left: 230px;
  /* max-height: max-content; */
  /* max-width: fit-content; */
}

.addressBook-search-field .search-field input.input--small {
  max-height: 42px;
}

.contact-list-table {
  margin-bottom: 70px;
  max-height: 405px;
  overflow-y: scroll;
}

.contact-list-table
  table
  tbody
  tr
  .dropdown-btn
  > .dropdown__button--ghost
  > .dropdown-only-icon {
  margin-left: 0px;
}

.contact-list-table
  table
  tbody
  tr:nth-last-child(-n + 2):not(:first-child)
  .dropdown-btn.dropdown--ghost
  .dropdown-list-only-icon:not(.list-two):not(.list-one) {
  top: -129px;
}

.contact-list-table
  table
  tbody
  tr:last-child:not(:only-child)
  .dropdown-btn
  .dropdown-list-only-icon
  .list-one {
  top: -46px;
}

.contact-list-table
  table
  tbody
  tr:last-child:not(:only-child)
  .dropdown-btn
  .dropdown-list-only-icon
  .list-two {
  top: -88px;
}

.contact-list-table table tbody tr td .contact-list-validation-check {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contact-list-table
  table
  tbody
  tr
  td
  .contact-list-validation-check
  .check--failed {
  cursor: var(--global-cursor-pointer);
}

.contact-list-table table tbody tr td .contact-list-validation-text {
  width: 100%;
  margin-left: 16px;
}

.contact-list-withoutTable {
  background-color: var(--global-colors-sky-lighter);
  width: 100%;
  height: 100%;
}

.contact-list-withoutTable-item {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.contact-list-withoutTable-addRule {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}

.add-contact-container {
  height: 384px;
  width: 653px;
  margin-right: 0 auto;
}

.add-contact-title-bold {
  font-weight: bold;
  margin-left: 32px;
  margin-bottom: 36px;
}

.add-contact-title-centered {
  margin-left: 32px;
  margin-bottom: 36px;
}

.add-contact-title {
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 38px;
  font-size: var(--global-font-size-sm);
  color: var(--global-colors-ink-dark);
  font-weight: normal;
}

.add-contact-left-subtitle {
  color: var(--global-colors-ink-light);
  font-size: var(--global-font-size-xs);
  margin-right: 44px;
  width: 88px;
}

.add-contact-row-form {
  margin-left: 35px;
  margin-right: 0px;
  margin-bottom: 15px;
  height: 59px;
  width: 525px;
  display: flex;
  align-items: center;
}

.add-contact-row-form-dropdown-prefix .dropdown {
  width: 97px !important;
  margin-right: 28px;
}

.add-contact-telephone {
  height: 42px;
  width: 270px;
}

.add-contact-row-form .text-field {
  height: 42px;
  width: 394px;
  float: right;
}

.add-contact-text-fields-username {
  width: 75%;
}

.add-contact-text-fields-username .dropdown {
  width: 0px;
}

.add-contact-text-fields-username .dropdown .options.open {
  padding: 0px;
  height: 116px;
}
