.public-route-plan-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.public-route-plan-row {
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.public-route-plan-row-shift {
  display: flex;
  flex-direction: row;
  height: fit-content;
  width: 100%;
}

.public-route-plan-row-shift-driver {
  min-width: 130px;
  border-bottom: 1px solid #e4e6e9;
  padding-left: 24px;
  padding-top: 8px;
}

.public-route-plan-element {
  height: fit-content;
  width: 100%;
}

.public-route-plan-header-element {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 24px;
  padding-right: 24px;
  min-width: 120px;
  height: 60px;
  align-items: center;
  border-bottom: 1px solid #e4e6e9;
  border-right: 1px solid #e4e6e9;
  align-self: center;
  justify-content: center;
  background-color: #e4e6e985;
}

.public-route-plan-header-element-text {
  font-size: larger;
  font-weight: 700;
  color: #687484da;
}

.public-route-plan-row-shift-driver-textone {
  font-size: 14px;
  font-family: var(--global-font-semi-bold);
}

.public-route-plan-row-shift-driver-texttwo {
  font-size: 14px;
  font-family: var(--global-font-regular);
  color: var(--global-colors-ink-light);
}
