.mn-single-notification {
  display: flex;
  padding: var(--global-spacing-xs) var(--global-spacing-sm);
  background-color: var(--global-colors-ui-white);
  font-family: var(--global-font-regular);
  width: 100%;
  border-bottom: 1px solid var(--global-colors-sky-sky);
  cursor: var(--global-cursor-default);
}

.mn-single-notification ul {
  border-top: 0px;
}

.mn-single-notification__icon {
  margin-right: var(--global-spacing-sm);
}

.mn-single-notification__icon,
.mn-single-notification__icon .icon-handler {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mn-single-notification__icon .icon-handler {
  background-color: var(--global-colors-sky-light);
  height: 30px;
  width: 30px;
  border-radius: 150px;
}

.mn-single-notification__message {
  width: 100%;
}

.mn-single-notification__message span {
  font-size: var(--global-font-size-xs);
  height: 38px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.mn-single-notification__message .date {
  font-size: var(--global-font-size-xxs);
  color: var(--global-colors-ink-light);
  height: 14px;
}
