.cointainer-avatar > div {
  display: flex;
}

.cointainer-avatar svg:not(:last-child) {
  margin-right: 15px;
}

.cointainer-avatar div:not(:first-child) {
  margin-top: 8px;
}

.cointainer-avatar div svg {
  width: 50px;
  cursor: var(--global-cursor-pointer);
}
