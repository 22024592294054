.analogic-report-graph {
}

.analogic-report-graph
  .apexcharts-legend
  .apexcharts-legend-series:first-child {
  margin-right: 20px !important;
}

.analogic-report-graph .apexcharts-legend .apexcharts-legend-series {
  display: flex !important;
  align-items: center !important;
}

.analogic-report-graph
  .apexcharts-legend
  .apexcharts-legend-series
  .apexcharts-legend-text {
  margin-left: 4px !important;
}

.analogic-report-graph .apexcharts-reset-icon,
.analogic-report-graph .apexcharts-zoomin-icon,
.analogic-report-graph .apexcharts-zoomout-icon {
  transform: none;
  margin-left: 6px;
  margin-right: 0px;
  width: auto;
  height: auto;
}

.analogic-report-graph .apexcharts-menu,
.analogic-report-graph .apexcharts-zoom-icon {
  display: none;
}

.analogic-report-graph .analogic-report-graph-loader {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  border: 2px solid var(--global-colors-ink-light);
  border-top-color: transparent;
  animation: spinner 0.6s linear infinite;
  margin-left: auto;
  margin-right: 32px;
}
