.ticket-container .mn-modal .mn-modal__dialog {
  width: 660px;
  max-height: 942px;
}

.mn-modal .mn-modal__dialog .ticket .mn-modal__dialog-body {
  padding: 45px 30px 0px 32px;
  height: 556px;
  width: 660px;
}

.ticket .mn-label-text__label {
  margin-bottom: 0;
}

.ticket .mn-label-text__text {
  color: var(--global-colors-ink-ink);
}

.ticket .mn-modal__dialog-body .left-forms {
  width: 280px;
  float: left;
}

.ticket .mn-modal__dialog-body .right-forms {
  width: 280px;
  float: left;
  margin-left: 36px;
  margin-bottom: 9px;
}

.ticket .mn-modal__dialog-body .mn-label-text__text {
  margin-left: 8px;
}

.ticket .mn-modal__dialog-body .central-forms {
  height: 282px;
  width: 596px;
  float: left;
  margin-bottom: 16px;
}

.ticket .text-field textarea {
  height: 264px;
}

.ticket .text-field {
  margin-top: 4px;
}
