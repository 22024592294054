.mn-mw-vehicle-map {
  position: relative;
  border-radius: 4px;
  border: 3px solid var(--global-colors-ui-white);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  background-size: cover;
  background-position: center center;
  width: 100%;
  min-width: 400px;
  height: 250px;
}

.mn-mw-vehicle-map .status-badge {
  position: absolute;
  top: 16px;
  left: 16px;
}

.mn-mw-vehicle-map .location-information {
  position: absolute;
  bottom: 16px;
  left: 16px;
}

.mn-mw-vehicle-map .button {
  position: absolute;
  top: 16px;
  right: 16px;
}

.mn-mw-vehicle-map .map-actions {
  position: absolute;
  top: 64px;
  right: 16px;
}
