.messages-filterbar-row {
  display: flex;
  align-items: center;
  font-weight: var(--global-font-semi-bold);
  width: 100%;
  height: 80px;
  border-bottom: 1px solid var(--global-colors-sky-dark);
}

.messages-filterbar-search {
  margin-left: 16px;
  width: 280px;
}

.messages-filterbar-statuses {
  margin-top: 24px;
  margin-bottom: 24px;
  margin-right: 16px;
}

.messages-filterbar-select {
  margin-top: 11px;
  margin-bottom: 11px;
  margin-right: 16px;
}

.messages-filterbar-button {
  width: auto;
  margin-left: auto;
  margin-right: 16px;
}

.messages-filterbar-button
  .dropdown-btn
  .dropdown-list
  .dropdown-elements
  .text {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
}
