.maintenance-filterbar-row {
  display: flex;
  align-items: center;
  font-weight: var(--global-font-semi-bold);
  width: 100%;
  border-bottom: 1px solid var(--global-colors-sky-dark);
}

.maintenance-filterbar-dropdown-columns .dropdown {
  width: 207px;
}

.maintenance-filterbar-search {
  margin-left: 16px;
}

.maintenance-filterbar-search,
.maintenance-filterbar-statuses {
  margin-top: 24px;
  margin-bottom: 24px;
  margin-right: 16px;
}

.maintenance-filterbar-select {
  margin-top: 11px;
  margin-bottom: 11px;
  margin-right: 16px;
}

.maintenance-filterbar-button {
  width: auto;
  margin-left: auto;
  margin-right: 16px;
  margin-top: 24px;
  margin-bottom: 24px;
}

.maintenance-filterbar-button .dropdown-btn {
  margin-top: 0;
}

.maintenance-filterbar-row .dropdown-list {
  background-color: var(--global-colors-ui-white);
  position: absolute;
  right: 0px;
  width: max-content;
}

.maintenance-filterbar-button
  .dropdown-btn
  .dropdown-list
  .dropdown-elements
  .text {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
}
