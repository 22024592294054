.mn-menu-balloon {
  width: 240px;
  border: 1px solid var(--global-colors-sky-sky);
  background-color: var(--global-colors-ui-white);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  position: relative;
  font-family: var(--global-font-regular);
  z-index: 99;
}

.mn-menu-balloon-inner {
  overflow: hidden;
  position: relative;
}

.mn-menu-balloon-inner ul:first-of-type {
  border-top: 1px solid var(--global-colors-sky-sky);
}

.user-notification .mn-menu-balloon header {
  padding-bottom: 4px;
}

.user-notification .mn-menu-balloon-inner ul:first-of-type {
  border-top: 0px;
}

.user-help .mn-menu-balloon-inner ul:first-of-type {
  border-top: 0px;
}

.mn-menu-balloon-inner > div {
  font-family: var(--global-font-regular);
}

.mn-menu-balloon-inner p {
  cursor: var(--global-cursor-default);
  font-family: var(--global-font-regular);
}

.mn-menu-balloon-inner a {
  cursor: var(--global-cursor-pointer);
}

.mn-menu-balloon::before,
.mn-menu-balloon::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid var(--global-colors-sky-sky);
  display: block;
  position: absolute;
  top: -5.3px;
  right: 10px;
}

.mn-menu-balloon::after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid var(--global-colors-ui-white);
  top: -4.2px;
  right: 10.9px;
}

.mn-menu-balloon header {
  padding: var(--global-spacing-md);
  font-size: var(--global-font-size-xs);
}

.mn-menu-balloon header > div {
  font-family: var(--global-font-semi-bold);
  font-size: var(--global-font-size-xs);
  margin-bottom: 4px;
}

.mn-menu-balloon header > * {
  margin: 0;
}

.mn-menu-balloon header .link {
  text-decoration: none;
  text-decoration-color: var(--global-colors-ui-secondary);
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-sm);
}

.mn-menu-balloon header .link:hover {
  text-decoration-color: var(--global-colors-ui-primary);
  color: var(--global-colors-ui-primary);
}

.mn-menu-balloon ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.mn-menu-balloon ul > li {
  padding: 11px var(--global-spacing-md);
  font-size: var(--global-font-size-sm);
  margin-bottom: 0;
  cursor: var(--global-cursor-pointer);
  display: flex;
  align-items: center;
}

.mn-menu-balloon ul > li > .svg {
  margin-right: var(--global-spacing-md);
}

.mn-menu-balloon ul > li:hover {
  background-color: #edeef0;
}

.mn-menu-balloon .help-header {
  padding-bottom: 7px;
}

.mn-menu-balloon .help-header > div:first-child {
  font-family: var(--global-font-medium);
  font-size: var(--global-font-size-sm);
  margin-bottom: 24px;
  cursor: var(--global-cursor-default);
}

.mn-menu-balloon .help-header + div ul li:last-child {
  margin-bottom: 7px;
}

.mn-menu-balloon .help-header > div:last-child {
  font-family: var(--global-font-regular);
  margin-top: 24px;
  font-size: var(--global-font-size-sm);
}

.mn-menu-balloon .help-header > div:last-child:hover {
  text-decoration-color: var(--global-colors-ui-primary);
  color: var(--global-colors-ui-primary);
}
