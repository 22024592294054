.edit-category-container .mn-modal .mn-modal__dialog {
  max-width: 600px;
  height: 524px;
  /* max-height: 530px; */
}

.edit-category-container .mn-modal .mn-modal__dialog .mn-modal__dialog-body {
  padding: 31px 32px 36px 32px;
  height: 343px;
  width: 600px;
}

.edit-category .mn-label-text__text {
  color: var(--global-colors-ink-light);
  font-size: var(--global-font-size-xs);
  font-family: var(--global-font-semi-bold);
}

.edit-category .mn-modal__dialog-body .left-forms {
  width: 95px;
  margin-top: 16px;
  float: left;
}

.edit-category .mn-modal__dialog-body .right-forms {
  width: 262px;
  margin-left: 111px;
  margin-top: 16px;
  float: left;
}

/* Custom Footer */
.edit-category .edit-category-custom-footer .mn-modal__dialog-footer {
  display: flow-root !important;
}

.edit-category .edit-category-custom-footer .button--primary {
  float: right;
}

.edit-category .edit-category-custom-footer .button--secondary {
  float: left;
}
