.schedulation-manage-modal-card {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e4e6e9;
  padding-bottom: 20px;
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 20px;
  position: relative;
}

.schedulation-manage-modal-card-body {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px;
  justify-content: flex-start;
}

.schedulation-manage-modal-card-body-text-one {
  font-size: 12px;
  color: #687484;
}

.schedulation-manage-modal-card-body-text-two {
  font-size: 14px;
  color: #031832;
}

.schedulation-manage-modal-card-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
}
