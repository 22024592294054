.notification-table {
  margin-bottom: 70px;
}

.notification-table
  table
  tbody
  tr:nth-last-child(-n + 2):not(:first-child)
  .dropdown-btn.dropdown--ghost
  .dropdown-list-only-icon:not(.list-two):not(.list-one),
.notification-table
  table
  tbody
  tr:nth-last-child(-n + 3):not(:first-child)
  .dropdown-btn.dropdown--outline
  .dropdown-list-only-icon:not(.list-two):not(.list-one) {
  top: -129px;
}

.notification-table
  table
  tbody
  tr:last-child:not(:only-child)
  .dropdown-btn
  .dropdown-list-only-icon
  .list-one {
  top: -46px;
}

.notification-table
  table
  tbody
  tr:last-child:not(:only-child)
  .dropdown-btn
  .dropdown-list-only-icon
  .list-two {
  top: -88px;
}

.notification-table table tbody tr .dropdown-btn.dropdown--outline {
  margin-right: 8px;
}

.notification-pagination {
  position: fixed;
  bottom: 0;
  z-index: 3;
  /* for Chrome, Edge, Opera */
  min-width: -webkit-fill-available !important;
  max-width: -webkit-fill-available !important;
  /* for Mozilla */
  min-width: -moz-available !important;
  max-width: -moz-available !important;
}

.notification-unread {
  font-family: var(--global-font-semi-bold);
  font-size: var(--global-font-size-sm);
}

.notification-read {
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-sm);
}

/* .iconExternalLink {
  cursor: var(--global-cursor-pointer);
  float: right;
  margin-left: 30px;
} */

.mn-table .button-mark-read .button--small > * {
  font-size: var(--global-font-size-sm);
  padding: 1px 12px;
}

.mn-table tbody tr td:last-child .iconCheck-mark-read .svg {
  cursor: var(--global-cursor-pointer);
  float: right;
  padding: 9px 9px 9px 0px;
}

.mn-forms {
  width: calc(100% - (85px + 110px));
  height: 42px;
  float: right;
  margin-left: 75px;
}

.mn-left-form {
  width: 49%;
  height: 42px;
  float: left;
}

.mn-right-form {
  width: 49%;
  height: 42px;
  float: right;
  margin-left: 16px;
}

.mn-left-form-notifications-Modal {
  width: 33.33%;
  padding-right: 15px;
  height: 42px;
  float: left;
}

.mn-right-form-notifications-Modal {
  width: 33.34%;
  height: 42px;
  float: right;
}
