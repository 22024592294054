html,
body,
#root {
  height: 100%;
}

.mn-sidebar {
  display: flex;
  flex-direction: column;
  background-color: var(--global-colors-ui-white);
  border-right: 1px solid var(--global-colors-sky-sky);
  width: 48px;
  font-family: var(--global-font-medium);
  font-size: var(--global-font-size-xs);
  height: 100%;
}

.mn-sidebar__content,
.mn-vehicle-box .mn-vehicle-box--active {
  width: 302px;
  margin: 0;
  overflow-y: auto;
}

.mn-sidebar.mn-sidebar--expanded {
  width: 302px;
  height: 100%;
}

.mn-sidebar .mn-sidebar__collapse {
  margin-top: auto;
  margin-left: 8px;
  margin-right: 8px;
}

.mn-sidebar .mn-sidebar__collapse::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  border-top: 1px solid var(--global-colors-sky-sky);
  margin-bottom: 4px;
}

.mn-sidebar .mn-sidebar__collapse > div {
  padding: 9px;
  cursor: var(--global-cursor-pointer);
  line-height: 12px;
  border-radius: 4px;
  height: 30px;
  margin-bottom: 14px;
}

.mn-sidebar .mn-sidebar__collapse > div:hover {
  background-color: var(--global-colors-sky-light);
}

.mn-sidebar .mn-sidebar__collapse > div .svg + span {
  display: inline-block;
  margin-left: 14px;
  font-size: 12px;
}

.mn-sidebar .mn-sidebar__collapse > div .svg {
  color: #031832;
}

.mn-sidebar:not(.mn-sidebar--expanded) > div .svg {
  margin-bottom: 21px;
}

.mn-sidebar:not(.mn-sidebar--expanded) > div .svg + span {
  display: none;
}
