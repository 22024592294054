.cg-savebar-row {
  display: flex;
  align-items: center;
  font-weight: var(--global-font-semi-bold);
  width: 100%;
}

.cg-savebar-row-splitter {
  display: flex;
  align-items: center;
  font-weight: var(--global-font-semi-bold);
  width: 100%;
  height: 80px;
  border-bottom: 1px solid var(--global-colors-sky-dark);
}

.cg-savebar-search {
  margin-left: 16px;
  height: 32px;
  width: 200px;
}

.cg-savebar-search input {
  height: 32px;
}

.cg-savebar-dropdown-columns .dropdown {
  min-width: 180px;
}

.cg-savebar-search,
.cg-savebar-dropdown-groups,
.cg-savebar-dropdown-statuses,
.cg-savebar-dropdown-orderby,
.cg-savebar-dropdown-columns {
  margin-top: 24px;
  margin-bottom: 24px;
}

.cg-savebar-dropdown-groups,
.cg-savebar-dropdown-statuses,
.cg-savebar-dropdown-orderby,
.cg-savebar-dropdown-columns {
  margin-left: 12px;
}

.cg-savebar-btn {
  width: auto;
  margin-left: auto;
  margin-right: 24px;
}

.cg-savebar-subtopbar {
  height: 80px;
  width: 100%;
  margin: 0;
  background-color: var(--global-colors-ui-white);
  border-bottom: 1px solid #d2d5da;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cg-savebar-subsection-name {
  color: var(--global-colors-ink-dark);
  font-family: var(--global-font-regular);
  font-size: 24px;
}
