.mn-mw-drop-area {
  width: 400px;
  height: 264px;
  border-radius: 4px;
  background-color: var(--global-colors-sky-sky);
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.12), 0 1px 10px 0 rgba(0, 0, 0, 0.06),
    0 2px 4px -1px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-md);
  color: var(--global-colors-ink-light);
  position: relative;
}

.mn-mw-drop-area--active {
  background-color: rgba(3, 24, 50, 0.48);
  color: var(--global-colors-ui-white);
}

.mn-mw-drop-area > .mn-mw-vehicle-map {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.mn-mw-drop-area__message .svg {
  margin-left: 7px;
}

.mn-mw-drop-area__message p {
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-xm);
}

.mn-mw-drop-area__message--replace {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mn-mw-drop-area__message--replace > * {
  position: relative;
}

.mn-mw-drop-area__message--replace::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(3, 24, 50, 0.48);
  /* z-index: 9; */
}

/*
const style = {
  height: "12rem",
  width: "12rem",
  marginRight: "1.5rem",
  marginBottom: "1.5rem",
  color: "white",
  padding: "1rem",
  textAlign: "center",
  fontSize: "1rem",
  lineHeight: "normal",
  float: "left",
};
*/
