.schedule-insurance {
  font-family: var(--global-font-medium);
  max-width: 653px;
  height: 76px;
  padding: 17px 32px;
  user-select: none;
  cursor: var(--global-cursor-pointer);
}

.schedule-insurance:hover {
  background-color: var(--global-colors-sky-lighter);
}

.schedule-insurance-simple {
  font-family: var(--global-font-medium);
  max-width: 653px;
  height: 76px;
  padding: 17px 32px;
  user-select: none;
  cursor: var(--global-cursor-pointer);
}

.schedule-insurance-circle span {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  border: 1px solid var(--global-colors-sky-sky);
  background-color: var(--global-colors-ui-white);
  float: left;
  display: flex;
  justify-content: center;
}

.schedule-insurance-icon {
  margin: 0;
  float: right;
  display: flex;
  align-items: center;
  cursor: var(--global-cursor-pointer);
}

.schedule-insurance-button {
  background: transparent;
  padding: 0;
  border: 0;
  width: 100%;
  display: flex;
  align-items: center;
}

.schedule-insurance-label {
  margin-left: 26px;
  margin-right: auto;
}

.schedule-insurance-pretitle {
  color: var(--global-colors-ink-light);
  font-size: var(--global-font-size-xs);
  font-family: var(--global-font-regular);
  margin-bottom: 6px;
  float: left;
}

.schedule-insurance-title {
  color: var(--global-colors-ink-dark);
  font-size: var(--global-font-size-md);
  font-family: var(--global-font-regular);
  float: left;
  text-overflow: ellipsis;
  text-align: left;
  overflow: hidden;
  max-width: 400px;
}

.schedule-insurance-reset {
  height: 16px;
  width: 34px;
  font-size: var(--global-font-size-sm);
  font-family: var(--global-font-regular);
  margin-right: 16px;
  float: left;
  text-decoration: none;
  -webkit-text-decoration-color: var(--global-colors-ui-secondary);
  text-decoration-color: var(--global-colors-ui-secondary);
  color: var(--global-colors-ui-secondary);
}

.schedule-insurance-save-button {
  margin-right: 16px;
  height: 32px;
  width: 57px;
  float: left;
}

.schedule-insurance-body {
  height: 64px;
  max-width: 653px;
  padding: 0 32px 32px 100px;
}

.schedule-insurance-selection {
  color: var(--global-colors-ink-light);
  font-size: var(--global-font-size-xs);
  font-family: var(--global-font-semi-bold);
  float: left;
  height: 36px;
  align-items: center;
  display: flex;
}

.schedule-insurance-dropdown {
  float: right;
  width: 96px;
  margin-right: 220px;
}

.schedule-insurance-dropdown .text-field.text-field--has-icon input {
  width: 160px;
}
