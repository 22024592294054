.rr-filterbar-row {
  display: flex;
  align-items: center;
  font-weight: var(--global-font-semi-bold);
  width: 100%;
  padding: 24px;
  border-bottom: 1px solid var(--global-colors-sky-dark);
}

.rr-dropdown-groups,
.rr-date-picker,
.rr-dropdown-customize {
  margin-left: 12px;
}

.rr-filterbar-btn-generate {
  margin-left: 12px;
  margin-right: 12px;
}

.rr-filterbar-btn-generate #button {
  width: 85px;
}

.rr-date-picker {
  margin-left: 12px;
  height: 32px;
}

.rr-filterbar-btn {
  width: auto;
  margin: 24px 16px 24px auto;
  display: flex;
}

.rr-filterbar-btn-export {
  width: auto;
  margin-left: auto;
}

.rr-filterbar-btn-export .dropdown-btn.dropdown--secondary {
  margin-top: 0;
}

.rr-filterbar-row .dropdown-list {
  background-color: var(--global-colors-ui-white);
  position: absolute;
  right: 0px;
  width: max-content;
}

.rr-filterbar-search .rr-search-results {
  box-sizing: border-box;
  height: auto;
  width: 234px;
  border: 1px solid var(--global-colors-sky-sky);
  border-radius: 4px;
  margin-top: 4px;
  position: absolute;
  background-color: var(--global-colors-ui-white);
  z-index: 999;
  max-height: 300px;
  overflow-y: auto;
  cursor: var(--global-cursor-pointer);
}

.rr-filterbar-search .rr-search-results .rr-fleet-label {
  font-weight: var(--global-font-semi-bold);
  color: var(--global-colors-ink-light);
  font-size: 12px;
  padding-left: 16px;
  padding-top: 12px;
  padding-bottom: 4px;
}

.rr-search-results .rr-driverbox {
  height: 72px;
  font-weight: var(--global-font-semi-bold);
  font-size: 14px;
}

.rr-filterbar-search
  .rr-search-results
  .rr-driverbox-selected
  .mn-user-box__upper-row--regular,
.rr-filterbar-search
  .rr-search-results
  .rr-driverbox-selected
  .mn-user-box__lower-row {
  color: var(--global-colors-ui-white);
}

.rr-filterbar-search .rr-search-results .rr-driverbox-selected {
  background-color: var(--global-colors-ui-primary);
  color: var(--global-colors-ui-white);
  height: 38px;
  font-weight: var(--global-font-semi-bold);
  font-size: 14px;
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 11px;
}

.rr-filterbar-search .rr-search-results .rr-alias-selected {
  background-color: var(--global-colors-ui-primary);
  color: var(--global-colors-ui-white);
  height: 38px;
  display: flex;
  font-weight: var(--global-font-semi-bold);
  font-size: 14px;
  padding: 8px 16px 11px 16px;
}

.rr-filterbar-search .rr-search-results .rr-alias {
  height: 38px;
  display: flex;
  font-weight: var(--global-font-semi-bold);
  font-size: 14px;
  padding: 8px 16px 11px 16px;
}

.rr-filterbar-search .rr-search-results .rr-alias:hover {
  background: var(--global-colors-sky-lighter);
}

.rr-filterbar-search .rr-search-results .rr-dot {
  display: flex;
  justify-content: center;
  color: var(--global-colors-ink-lighter);
  width: 24px;
  font-size: 14px;
}

.rr-filterbar-search .rr-search-results .rr-dot::after {
  content: "\2022";
}

.rr-filterbar-search .rr-search-results .rr-driverbox:hover {
  background: var(--global-colors-sky-lighter);
}
