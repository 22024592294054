.page-counter-loader {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid var(--global-colors-ink-light);
  border-top-color: transparent;
  animation: spinner 0.6s linear infinite;
  float: right;
  margin-top: 22px;
  margin-right: -9px;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.page-count {
  height: 60px;
  font-family: var(--global-font-regular);
  background-color: var(--global-colors-ui-white);
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 8%);
  overflow: hidden;
}

.number-selected {
  padding-left: 24px;
  padding-top: 22px;
  padding-bottom: 22px;
  font-size: var(--global-font-size-sm);
  color: var(--global-colors-ink-dark);
  display: inline-block;
}

.page-running {
  padding: 22px 30px;
  font-size: var(--global-font-size-xs);
  color: var(--global-colors-ink-light);
  display: block;
  float: right;
}

.page-list {
  padding-right: 24px;
  padding-top: 14px;
  padding-bottom: 14px;
  display: flex;
  align-items: center;
  float: right;
}

.button-opac {
  opacity: 20%;
}

.number {
  font-size: var(--global-font-size-sm);
  font-family: var(--global-colors-ink-ink);
  color: var(--global-colors-ink-ink);
  line-height: 16px;
}

.button-number {
  margin-left: 3px;
  margin-right: 3px;
}
