.mn-break-row {
  display: flex;
  align-items: center;
  width: auto;
  padding: var(--global-spacing-md) var(--global-spacing-lg);
  font-size: var(--global-font-size-xs);
  font-family: var(--global-font-regular);
  border-right: 4px solid transparent;
}

.mn-break-row .mn-break-row__icon {
  margin-right: var(--global-spacing-xs);
  max-height: 26px;
}

.mn-break-row .mn-break-row__location > div:first-child:not(:only-child) {
  margin-bottom: var(--global-spacing-xxs);
}

.mn-break-row .mn-break-row__location > div > span {
  display: inline-block;
}
.mn-break-row .mn-break-row__location > div > span:first-child {
  margin-right: var(--global-spacing-xs);
}

.mn-break-row__location {
  width: 100%;
  display: flex;
}

.mn-break-row.mn-break-row--location {
  align-items: flex-start;
}

.mn-break-row:hover {
  cursor: var(--global-cursor-pointer);
  background-color: var(--global-colors-sky-lighter);
}

.mn-break-row--focused {
  background-color: #f1f2f4;
  border-right: 4px solid var(--global-colors-ui-primary);
}

.mn-break-row__pending {
  justify-content: space-between;
  display: flex;
  width: 100%;
}

.mn-break-row__content {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.mn-break-row__content__stop-info {
  justify-content: end;
  display: flex;
}

.mn-break-row__content-time {
  margin-left: 5px;
}
