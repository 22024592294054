.mn-menu-geofence-action {
  width: 210px;
  border-radius: 4px;
  border-bottom: none;
  border: 1px solid var(--global-colors-sky-sky);
  background-color: var(--global-colors-ui-white);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
  position: relative;
  font-family: var(--global-font-regular);
  z-index: 99;
  margin-top: 28px;
  margin-right: 4px;
  top: 4px;
}

.mn-menu-geofence-title {
  color: var(--global-colors-ink-dark);
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-xs);
  font-weight: 600;
  margin-left: 16px;
  margin-top: 12px;
  height: 18px;
}

.mn-menu-geofence-search {
  padding: var(--global-spacing-xxs) var(--global-spacing-md) 6px
    var(--global-spacing-md);
  position: relative;
}

.mn-menu-geofence-search input {
  height: 32px;
  width: 100%;
  padding: var(--global-spacing-xxs) var(--global-spacing-md);
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-sm);
  border: 1px solid var(--global-colors-sky-dark);
  border-radius: 4px;
  outline: 0;
}

.mn-menu-geofence-search input:focus {
  border: 1px solid var(--global-colors-ui-primary--focus);
}

.mn-menu-geofence-search .svg {
  position: absolute;
  top: 50%;
  line-height: 0;
  transform: translateY(-50%);
  right: 28px;
}

.mn-menu-geofence-options {
  min-height: 123px;
  max-height: 152px;
  overflow-y: scroll;
  padding: 0;
  margin-bottom: 10px;
}

.mn-menu-geofence-option {
  box-sizing: border-box;
  color: var(--global-colors-ink-dark);
  padding: 11px var(--global-spacing-md);
  font-size: var(--global-font-size-sm);
  display: flex;
  align-items: center;
  height: 40px;
}

.mn-menu-geofence-option .checkbox {
  margin-left: auto;
  width: 18px;
}

.mn-menu-geofence-option .color {
  width: 14px;
  height: 14px;
  border-radius: 150px;
  display: inline-block;
  margin-right: var(--global-spacing-md);
}

/* Split-line Style */
.mn-menu-geofence-split-line {
  height: 1px;
  width: 100%;
  background-color: var(--global-colors-sky-sky);
}

/* Button Style */
.mn-menu-geofence-button button {
  width: 100%;
}

.mn-menu-geofence-button .button .label {
  width: 100%;
  padding: 0;
  margin-left: 16px;
}

.mn-menu-geofence-button .button .label .text {
  font-size: var(--global-font-size-sm);
}
