.status-filter-badge-container {
  display: flex;
  flex-direction: column;
  direction: rtl;
}

.status-filter-badge {
  display: inline-flex;
  align-items: center;
  justify-content: end;
  width: 48px;
  height: 26px;
  gap: 8px;
  padding: 8px;
  margin-bottom: 8px;
  font-size: var(--global-font-size-xs);
  font-family: var(--global-font-regular);
  color: var(--global-colors-ink-dark);
  cursor: var(--global-cursor-pointer);
  transition: width 0.8s ease-out;
  overflow: hidden;
  background-color: var(--global-colors-ui-white);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.status-filter-badge .signal-status {
  min-width: 8px;
}

.status-filter-badge.expanded {
  width: 154px;
  padding: 8px;
}

.status-filter-badge.active {
  background-color: var(--global-colors-primary);
  color: var(--global-colors-ui-white);
  font-weight: bold;
}

.status-filter-badge .fleet-name {
  overflow: hidden;
  white-space: nowrap;
}
