.driver-lh-sidebar {
  height: 100%;
}

.driver-lh-sidebar .mn-sidebar .mn-sidebar__content {
  overflow-y: auto;
  overflow-x: hidden;
}

.driver-lh-trackSummary .mn-card {
  border-radius: none;
  border: none;
  box-shadow: none;
}

.driver-lh-static-map {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  height: 100%;
  width: 100%;
}

.driver-lh-splitter-line {
  border-bottom: 1px solid var(--global-colors-sky-sky);
}
