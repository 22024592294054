.status-badge {
  min-width: 36px;
  min-width: 65px;
  background-color: var(--global-colors-ui-white);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  font-family: var(--global-font-regular);
  color: var(--global-colors-ink-dark);
}

.status-badge--small {
  min-height: 26px;
  min-width: 57px;
}

.status-badge > span:last-of-type {
  margin-left: 8px;
  font-size: var(--global-font-size-md);
  display: inline-block;
}

.status-badge--small > span:last-of-type {
  font-size: var(--global-font-size-sm);
}
