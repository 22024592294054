/* Login */

.login-container {
  height: 100%;
}

.login-first-column {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}

.form-request-demo {
  margin-top: 165px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.copyright-macnil-logo {
  display: flex;
  margin: 140px auto 0;
  padding-bottom: 10px;
  width: 429px;
  align-items: center;
}

.login-form {
  margin-top: 48px;
}

.request-demo {
  margin-top: 88px;
}

/* Component styles */

.text-field.text-field--has-icon {
  max-width: 100%;
}

.text-field .text-field__inner input {
  max-width: 100%;
}

.password-field {
  margin-top: 16px;
}

.password-label {
  font-family: var(--global-font-regular);
  color: var(--global-colors-ink-light);
  font-size: 14px;
  height: 18px;
  float: left;
  margin-top: 12px;
}

.password-label:hover {
  color: var(--global-colors-ui-primary);
  text-decoration: underline;
  cursor: var(--global-cursor-pointer);
}

.login-button button {
  margin-top: 48px;
  margin-right: 0;
  width: 100%;
}

.mn-widget-help__item {
  height: 52px;
}

.login-second-column .mn-login-sidebar {
  width: 100%;
}

.copyright-label {
  height: 40px;
  float: left;
  width: 240px;
  font-family: var(--global-font-regular);
  color: var(--global-colors-ink-light);
  font-size: 12px;
  line-height: 20px;
}

.mini-logo-container {
  margin-left: auto;
}

.mini-logo-label {
  height: 14px;
  text-align: center;
  align-items: center;
  font-family: var(--global-font-regular);
  color: var(--global-colors-ui-primary--hover);
  font-size: 9px;
  font-weight: bold;
}

.mini-logo-img {
  display: flex;
  text-align: center;
  align-items: center;
  height: 23px;
  width: 85px;
}

.alert-container {
  height: 48px;
  margin-top: 32px;
  width: 429px;
  max-width: 429px;
}
