.cluster-marker {
  position: absolute;
  width: 35px;
  height: 35px;
  left: 32px;
  display: flex;
  align-items: center;
  line-height: 34px;
  border: 2px solid var(--global-colors-ui-white);
  border-radius: 50%;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  padding: 0px;
  z-index: 1;
}

.cluster-marker:hover {
  cursor: var(--global-cursor-pointer);
}

.cluster-marker-inner-simple::before,
.cluster-marker-inner::before {
  width: 56px;
  height: 56px;
  border: 11px solid rgba(0, 82, 189, 0.1);
  content: "";
  border-radius: 50%;
  display: block;
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cluster-marker-inner-simple,
.cluster-marker-inner {
  z-index: 1;
  width: 32px;
  height: 32px;
  font-size: var(--global-font-size-xs);
  font-family: var(--global-font-semi-bold);
  font-weight: normal;
  justify-content: center;
  display: flex;
  border-radius: 50%;
  box-shadow: inset 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
}

.cluster-marker-inner-simple > div,
.cluster-marker-inner > div {
  z-index: 9;
}

.cluster-marker-inner::after {
  content: "";
  display: block;
  position: absolute;
  width: 22px;
  height: 22px;
  background: var(--global-colors-ui-white);
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cluster-marker-triangle {
  border-top: 6px solid var(--global-colors-ui-white);
  border-left: 4px dashed transparent;
  border-right: 4px dashed transparent;
  border-bottom: 0;
  position: absolute;
  z-index: 1;
  display: block;
  top: calc(100% + 1px);
  left: calc(100% - 20px);
  overflow: hidden;
  width: 0;
  height: 0;
  filter: drop-shadow(0px 2px 1px rgb(0 0 0 / 0.2));
}

/*#region cluster large size*/
.cluster-marker-large {
  position: absolute;
  width: 42px;
  height: 42px;
  left: 32px;
  display: flex;
  align-items: center;
  line-height: 40px;
  border: 2px solid var(--global-colors-ui-white);
  border-radius: 50%;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  padding: 0px;
  z-index: 1;
}

.cluster-marker-large:hover {
  cursor: var(--global-cursor-pointer);
}

.cluster-marker-inner-large::before {
  width: 62px;
  height: 62px;
  border: 11px solid rgba(0, 82, 189, 0.1);
  content: "";
  border-radius: 50%;
  display: block;
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cluster-marker-inner-large {
  z-index: 1;
  width: 38px;
  height: 38px;
  font-size: var(--global-font-size-xs);
  font-family: var(--global-font-semi-bold);
  font-weight: normal;
  justify-content: center;
  display: flex;
  border-radius: 50%;
  box-shadow: inset 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
}

.cluster-marker-inner-large > div {
  z-index: 9;
}

.cluster-marker-inner-large::after {
  content: "";
  display: block;
  position: absolute;
  width: 28px;
  height: 28px;
  background: white;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cluster-marker-triangle-large {
  border-top: 6px solid var(--global-colors-ui-white);
  border-left: 4px dashed transparent;
  border-right: 4px dashed transparent;
  border-bottom: 0;
  position: absolute;
  z-index: 1;
  display: block;
  top: calc(100% + 1px);
  left: calc(100% - 22px);
  overflow: hidden;
  width: 0;
  height: 0;
  filter: drop-shadow(0px 2px 1px rgb(0 0 0 / 0.2));
}
/*#endregion cluster large size*/
