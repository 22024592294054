.mn-chat {
  background-color: var(--global-colors-ui-white);
  border-radius: 8px 8px 0 0;
  font-size: var(--global-font-size-md);
  overflow: hidden;
  height: 100%;
}

.mn-chat__body {
  height: 100%;
}

.mn-chat__top-bar {
  background-color: var(--global-colors-ui-white);
  padding: 12px 16px;
  box-shadow: inset 0 -1px 0 0 var(--global-colors-sky-sky);
  display: flex;
  align-items: center;
}

.mn-chat__top-bar > span {
  height: 42px;
  align-items: center;
  display: flex;
}

.mn-chat__dropdown {
  width: 100%;
  display: flex;
  align-items: center;
}

.mn-chat__dropdown .textfield-menu-container {
  width: calc(100% - (64px));
}

.mn-chat__top-bar-to {
  color: var(--global-colors-ink-light);
  margin-right: var(--global-spacing-ld);
}

.mn-chat_messages {
  bottom: 0px;
  padding: var(--global-spacing-xl);
}

.mn-chat__chat:has(.mn-chat-icon) {
  display: flex;
  text-align: center;
  justify-content: center;
}

.mn-chat-icon {
  margin: auto 0;
}

.mn-chat__chat {
  overflow-y: scroll;
  background-color: var(--global-colors-sky-lighter);
  position: relative;
}

.mn-chat--close {
  height: 40px;
}

.mn-chat__control > span:not(:last-child) {
  display: inline-block;
  margin-right: var(--global-spacing-md);
}

.mn-chat__sent-by {
  height: 36px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  font-size: var(--global-font-size-xs);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--global-spacing-xs) var(--global-spacing-md);
  color: var(--global-colors-ink-light);
}

.sent-by-badges > .mn-tag {
  margin-right: var(--global-spacing-xs);
}

.mn-chat__message {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--global-spacing-md);
}

.mn-chat__message:last-child {
  margin-bottom: 0;
}

.mn-chat__message--reverse {
  flex-direction: row-reverse;
}

.mn-chat__message .mn-thumb {
  margin-right: var(--global-spacing-md);
}

.mn-chat__message--reverse .mn-thumb {
  margin-right: 0;
  margin-left: var(--global-spacing-md);
}

.mn-chat__message-text {
  max-width: 80%;
}

.mn-chat__message .mn-chat__message-text > div {
  padding: var(--global-spacing-xs) var(--global-spacing-md);
  border-radius: 8px;
  font-size: var(--global-font-size-sm);
}

.mn-chat__message .mn-chat__message-text > div::before,
.mn-chat__message .mn-chat__message-text > div::after {
  content: "";
  display: table;
  clear: both;
}

.mn-chat__message--from-user .mn-chat__message-text > div {
  background-color: var(--global-colors-sky-sky);
}

.mn-chat__message--from-me .mn-chat__message-text > div {
  background-color: rgba(0, 82, 189, 0.1);
}

.mn-chat__message .mn-chat__message-text > small {
  display: flex;
  align-items: center;
  float: right;
  margin-top: var(--global-spacing-xxs);
  color: var(--global-colors-ink-light);
  font-size: var(--global-font-size-xxs);
}

.mn-chat__message .mn-chat__message-text > small > :first-child {
  margin-right: var(--global-spacing-xxs);
}

.mn-chat__textarea {
  line-height: 0;
  width: -webkit-fill-available;
  bottom: 0;
  border-top: 1px solid var(--global-colors-sky-sky);
  padding: var(--global-spacing-md);
  position: fixed;
}

.mn-chat__textarea #button_send {
  width: 90px;
}

.mn-chat__textarea textarea {
  padding: var(--global-spacing-sm) calc(1.7 * var(--global-spacing-xxxl))
    var(--global-spacing-md) var(--global-spacing-md);
  border-radius: 8px;
  border: 1px solid var(--global-colors-sky-sky);
  width: 100%;
  font-family: var(--global-font-regular);
  min-height: 84px;
  resize: none;
  font-size: var(--global-font-size-sm);
  color: var(--global-colors-ink-light);
  letter-spacing: 0;
  line-height: 18px;
  max-height: 150px;
}

.mn-chat__textarea Button {
  position: absolute;
  top: 36px;
  right: 32px;
}

.mn-chat__textarea textarea:focus {
  outline: 0;
  border: 1px solid var(--global-colors-ui-primary);
}

.messages-loader-container {
  background-color: var(--global-colors-sky-lighter);
  height: 46px;
  display: flex;
  align-items: center;
}

.retrive-messages-loader {
  width: 16px;
  height: 16px;
  background-color: var(--global-colors-sky-lighter);
  border-radius: 50%;
  border: 2px solid var(--global-colors-ui-primary);
  border-top-color: transparent;
  animation: spinner 0.6s linear infinite;
  margin: 0 auto;
}

.single-message-loader {
  width: 20px;
  height: 20px;
  animation: spinner 0.6s linear infinite;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
