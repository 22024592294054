.container-buttons-attached {
  display: flex;
  justify-content: space-between;
  border: 3px solid #e4e6e9;
  width: 100%;
  height: 60px;
  border-radius: 8px 8px 0px 0px;
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.1), 0 0px 0px rgba(0, 0, 0, 0.2);
  position: relative;
  background: white;
}

.buttons-attached {
  padding: 12px;
}

.photo-size {
  font-size: 14px;
  padding: 20px;
  color: #687484;
}

.container-photos-attached {
  background-color: #e4e6e9;
  width: 100%;
  height: 580px;
  position: relative;
  bottom: 6px;
  z-index: 0;
}

.photos-attached {
  width: 450px;
  height: 500px;
  position: relative;
  left: 48px;
  top: 40px;
}

.buttons-attached .button--small {
  font-size: var(--global-font-size-sm);
  padding: 0px;
}
