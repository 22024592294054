.mn-card {
  max-width: 600px;
  border-radius: 4px;
  background-color: var(--global-colors-ui-white);
  padding: 16px 24px;
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-sm);
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 8%);
  border: 1px solid rgb(210, 213, 218);
}

.mn-card .mn-card__header {
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mn-card .mn-card__header > div {
  font-size: 14px;
  font-family: var(--global-font-medium);
}

.mn-card .mn-card__header button .svg {
  margin-left: auto;
  margin-right: 0px;
  transform: rotate(-90deg);
}

.mn-card__dropdown {
  margin-top: 15px;
  margin-bottom: 24px;
}

.mn-card__dropdown .dropdown {
  width: 168px;
}

.mn-card__body {
  margin-top: 24px;
}

.mn-card__columns {
  float: right;
  display: flex;
}

.mn-card__service-column {
  width: 55px;
  font-family: var(--global-font-semi-bold);
  font-size: var(--global-font-size-xs);
  color: var(--global-colors-status-stopped);
}

.mn-card__vehicle-column {
  width: 55px;
  font-family: var(--global-font-semi-bold);
  font-size: var(--global-font-size-xs);
  color: var(--global-colors-status-stopped);
}
