.travel-detail-modal .mn-modal .mn-modal__dialog {
  height: auto;
  max-width: 1240px;
  border-radius: 4px;
}

.travel-detail-modal.travel-detail-modal
  .mn-modal
  .mn-modal__dialog
  .mn-modal__dialog-body {
  padding-bottom: 0px;
}

.travel-detail-modal .group-input {
  margin-top: 16px;
  margin-bottom: 16px;
  padding-bottom: 4px;
  font-family: var(--global-font-medium);
  font-size: var(--global-font-size-sm);
}

.travel-detail-icons {
  display: flex;
  padding-left: var(--global-spacing-md);
  padding-bottom: 16px;
}

.travel-detail-icons .mn-data-label {
  padding-right: 80px;
}

.travel-detail-modal .mn-table {
  border-top: var(--global-colors-sky-sky-outline);
  background-color: var(--global-colors-ui-white);
  text-align: left;
}

.travel-detail-modal .mn-table tbody tr:nth-child(odd) {
  background-color: var(--global-colors-sky-lighter);
}

.travel-detail-modal table {
  display: block;
}

.travel-detail-modal tbody {
  display: block;
  max-height: 400px;
  overflow: auto;
}

.travel-detail-modal .mn-table thead th {
  height: 60px;
  padding: 21px 24px 21px 24px;
}

.travel-detail-modal .mn-table tbody td {
  white-space: nowrap;
  height: 60px;
  padding: 12px 24px 12px 24px;
}

.travel-detail-modal thead {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.travel-detail-modal tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.travel-detail-modal .reportMarked {
  margin-bottom: 0;
  font-family: var(--global-font-semi-bold);
}

.travel-detail-modal .reportNormal {
  color: var(--global-colors-ink-light);
}

.travel-detail-modal p {
  margin-top: 0;
}

/* Columns table head resize */
.travel-detail-modal .mn-table thead th:nth-child(1) {
  width: 10%;
}

.travel-detail-modal .mn-table thead th:nth-child(2) {
  width: 15%;
}

.travel-detail-modal .mn-table thead th:nth-child(3) {
  width: 15%;
}

.travel-detail-modal .mn-table thead th:nth-child(4) {
  width: 10%;
  white-space: nowrap;
}

.travel-detail-modal .mn-table th:nth-child(5) {
  width: 15%;
  white-space: nowrap;
}

.travel-detail-modal .mn-table thead th:nth-child(6) {
  width: 10%;
  white-space: nowrap;
}

.travel-detail-modal .mn-table thead th:nth-child(7) {
  width: calc(100% - 75%);
}

.travel-detail-modal .mn-table thead th:nth-child(8) {
  width: 10%;
}

/* Columns table body resize */
.travel-detail-modal .mn-table tbody td:nth-child(1) {
  width: 10%;
}

.travel-detail-modal .mn-table tbody td:nth-child(2) {
  width: 15%;
}

.travel-detail-modal .mn-table tbody td:nth-child(3) {
  width: 15%;
}

.travel-detail-modal .mn-table tbody td:nth-child(4) {
  width: 10%;
}

.travel-detail-modal .mn-table tbody td:nth-child(5) {
  width: 15%;
}

.travel-detail-modal .mn-table tbody td:nth-child(6) {
  width: 10%;
}

.travel-detail-modal .mn-table tbody td:nth-child(7) {
  width: 10%;
}

.travel-detail-modal .mn-table tbody td:nth-child(8) {
  width: calc(100% - 85%);
  display: unset;
}

/* Icon customization */
.travel-detail-modal .mn-table thead th#id > span {
  justify-content: stretch;
}

.travel-detail-modal .mn-table .mn-data-label .svg span {
  color: var(--global-colors-ink-light);
}

.travel-detail-modal .mn-table thead th#id .ascending > span > span {
  margin-left: 12px;
  transform: rotate(180deg);
}

.travel-detail-modal .mn-table thead th#id > span > span {
  margin-left: 12px;
}

/* Footer */
.travel-detail-modal-buttons .mn-modal__dialog-footer {
  display: flow-root !important;
  box-shadow: 0px 2px 10px 0px rgb(0 0 0 / 8%);
}

.travel-detail-modal .button--secondary {
  float: left;
}

.travel-detail-modal .button--primary {
  float: right;
}
