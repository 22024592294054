.mn-widget-help {
  border: 1px solid var(--global-colors-sky-sky);
  border-radius: 8px;
  max-width: 500px;
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-md);
}

.mn-widget-help .mn-widget-help__list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.mn-widget-help .mn-widget-help__item {
  cursor: var(--global-cursor-pointer);
  padding: 18px 24px;
  display: flex;
  align-items: center;
  overflow: hidden;
  margin: 0;
  position: relative;
}

.mn-widget-help .mn-widget-help__item > span:not(.ripple) {
  position: relative;
  z-index: 2;
}

.mn-widget-help .mn-widget-help__item:hover {
  background-color: #edeef0;
}

.mn-widget-help .mn-widget-help__item:not(:last-child) {
  border-bottom: 1px solid var(--global-colors-sky-sky);
}

.mn-widget-help .mn-widget-help__item .mn-widget-help__item-icon {
  margin-right: var(--global-spacing-md);
}

.mn-widget-help .mn-widget-help__item .mn-widget-help__item-forward {
  margin-left: auto;
}

/* Ripple */
.mn-widget-help .ripple {
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  background-color: #e3e5e8;
  animation: 600ms ease 1 forwards ripple;
}

@keyframes ripple {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(10);
    opacity: 0.375;
  }
  100% {
    transform: scale(35);
    opacity: 0;
  }
}
