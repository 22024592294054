.new-category-container .mn-modal .mn-modal__dialog {
  max-width: 600px;
  height: 522px;
  /* max-height: 530px; */
}

.new-category-container .mn-modal .mn-modal__dialog .mn-modal__dialog-body {
  padding: 31px 32px 36px 32px;
  height: 343px;
  width: 600px;
}

.new-category .mn-label-text__text {
  color: var(--global-colors-ink-light);
  font-size: var(--global-font-size-xs);
  font-family: var(--global-font-semi-bold);
}

.new-category .mn-modal__dialog-body .left-forms {
  width: 95px;
  margin-top: 16px;
  float: left;
}

.new-category .mn-modal__dialog-body .right-forms {
  width: 262px;
  margin-left: 111px;
  margin-top: 16px;
  float: left;
}
