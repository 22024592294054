.fleet-filterbar-row {
  display: flex;
  align-items: center;
  font-weight: var(--global-font-semi-bold);
  width: 100%;
  border-bottom: 1px solid var(--global-colors-sky-dark);
}

.fleet-filterbar-search {
  margin-left: 16px;
}

.fleet-filterbar-search {
  margin-top: 24px;
  margin-bottom: 24px;
  margin-right: 16px;
}

.fleet-filterbar-addFleet {
  width: auto;
  margin-left: auto;
  margin-right: 16px;
  margin-top: 24px;
  margin-bottom: 24px;
}
