.speed-graph-dropdown {
  display: flex;
  justify-content: end;
  gap: 10px;
  align-items: center;
}

.speed-report-arrow-box {
  font-family: var(--global-font-semi-bold) !important;
  font-size: 10px;
  text-align: center;
  align-items: center;
  color: var(--global-colors-ui-white);
  display: flex;
  flex-direction: column;
  background-color: var(--global-colors-cyan-blue);
  border-color: var(--global-colors-cyan-blue);
  padding: 0 8px;
  opacity: 1;
  border-radius: 4px;
}

.speed-graph-filter-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.speed-graph-title {
  font-size: 16px;
}

.speed-graph-legend {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  font-family: var(--global-font-semi-bold);
  color: var(--global-colors-ink-light);
  font-size: 12px;
}

.speed-graph-legend-item {
  display: flex;
  align-items: center;
}

.speed-graph-legend-square {
  height: 12px;
  width: 12px;
  border-radius: 2px;
  display: inline-flex;
  margin-right: 6px;
}

.speed-graph-container {
  height: 100%;
  margin-top: 50px;
  left: -14px;
  position: relative;
}

.speed-graph-container .apexcharts-toolbar {
  margin-right: 14px;
  width: 108px;
  top: -20px !important;
}

.speed-graph-container .apexcharts-reset-icon,
.speed-graph-container .apexcharts-zoomin-icon,
.speed-graph-container .apexcharts-zoomout-icon {
  transform: none;
  margin-left: 6px;
  margin-right: 0px;
  width: auto;
  height: auto;
}

.speed-graph-container .apexcharts-menu,
.speed-graph-container .apexcharts-xcrosshairs,
.speed-graph-container .apexcharts-xaxistooltip-bottom,
.speed-graph-container .apexcharts-ycrosshairs,
.speed-graph-container .apexcharts-title-text,
.speed-graph-container .apexcharts-element-hidden,
.speed-graph-container .apexcharts-zoom-icon {
  display: none;
}

.speed-graph-dropdown .speed-graph-loader {
  height: 16px;
  border-radius: 50%;
  border: 2px solid var(--global-colors-ink-light);
  border-top-color: transparent;
  animation: spinner 0.6s linear infinite;
  margin-right: 8px;
  display: flex;
  position: relative;
  width: 16px;
  min-width: 16px;
}

.speed-graph-dropdown .mn-periodnav {
  margin-right: 0px;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
