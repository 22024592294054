.overviewContainers {
  overflow-y: auto;
  height: 100%;
  display: flex;
  padding: 24px 16px;
  background-color: #f7f7f8;
}

.overviewContainers .overviewContainer {
  min-width: calc(100% / 2 - 8px);
  height: fit-content;
}

.overviewContainers .overviewContainer:not(:last-child) {
  margin-right: 16px;
  height: fit-content;
}

.overviewContainers .overviewContainer .subContainerOverview:not(:last-child) {
  margin-bottom: 16px;
}

.overviewContainers .overviewContainer .subContainerOverview > div {
  max-width: 100%;
  min-width: 100%;
}

.overviewContainers
  .overviewContainer
  .subContainerOverview
  .mn-card.mn-vehicle-activity
  .mn-card__body {
  margin-top: unset;
}

.overviewContainers
  .overviewContainer
  .subContainerOverview
  .mn-card.mn-deadlines
  .mn-card__body
  .mn-single-item-container:last-child {
  border-bottom: unset;
}

.overviewContainers
  .overviewContainer
  .subContainerOverview
  .mn-card.mn-deadlines
  .mn-card__body
  .mn-single-item-container
  .mn-data-label {
  width: unset;
}

div.mn-data-label.viewRed + span * {
  color: #ff4f48 !important;
}

div.mn-data-label:not(.viewRed) + span * {
  color: var(--global-colors-ui-primary);
}

.mn-widget-help__item-forward > span {
  float: left;
  margin-right: 16px;
}
