.report {
  background-color: var(--global-colors-sky-lighter);
  height: inherit;
  overflow: scroll;
}

.search-reports {
  width: 517px;
  margin: auto;
  padding-top: 68px;
  padding-bottom: 56px;
}

.report-archive {
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(90deg, #c9c9c9, #ececec);
  border-radius: 6px;
  width: 100%;
  height: 110px;
  margin-top: 24px;
  padding: 20px;
  justify-content: space-between;
  cursor: pointer;
}

.report-archive-title-section {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.report-archive-title {
  line-height: 20px;
  color: #031832;
  font-size: 16px;
}

.report-archive-subtitle-section {
  display: flex;
  flex-direction: column;
}

.report-archive-subtitle {
  color: #031832;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

.report-archive-subtitle-two {
  color: #35465b;
  font-size: 14px;
  line-height: 18px;
}

.search-reports Form {
  background-color: var(--global-colors-ui-white);
}

.sections {
  display: flex;
  justify-content: center;
  margin-right: 8px;
}

.section {
  width: 696px;
}

.section:first-child {
  margin-right: 16px;
}

.section .mn-report {
  padding-top: 11px;
  padding-bottom: 11px;
}

.section .mn-report:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.section .mn-report:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
