.mn-menu {
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    180deg,
    #007adb 0%,
    /* Not in design system color palette. */ var(--global-colors-ui-primary)
      30%
  );
  width: 54px;
  height: 100%;
  padding: 12px 0;
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-xs);
  color: var(--global-colors-ui-white);
}

.mn-menu.mn-menu--admin {
  background: var(--global-colors-ui-white);
  color: var(--global-colors-ink-dark);
  border-right: 1px solid var(--global-colors-sky-sky);
}

.mn-menu.mn-menu--expanded {
  width: 192px;
  height: 100%;
}

.mn-menu .mn-menu__collapse,
.mn-menu .mn-menu__collapse-admin {
  padding: 0px 8px;
  margin-top: auto;
  display: block;
  height: 37px;
}

.mn-menu .mn-menu__collapse::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  border-top: 1px solid var(--global-colors-sky-sky);
  margin-bottom: 4px;
  opacity: 12%;
}

.mn-menu .mn-menu__collapse-admin::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  border-top: 1px solid var(--global-colors-sky-sky);
  margin-bottom: 4px;
}

.mn-menu .mn-menu__collapse > div,
.mn-menu .mn-menu__collapse-admin > div {
  border-radius: 4px;
  cursor: var(--global-cursor-pointer);
  height: 32px;
  line-height: 11px;
  padding: 10px;
}

.mn-menu .mn-menu__collapse > div .svg span,
.mn-menu .mn-menu__collapse-admin > div .svg span {
  color: var(--global-colors-ui-white);
}

.mn-menu.mn-menu.mn-menu--admin .mn-menu__collapse > div .svg span,
.mn-menu.mn-menu.mn-menu--admin .mn-menu__collapse-admin > div .svg span {
  color: var(--global-colors-ink-dark);
}

.mn-menu .mn-menu__collapse > div:hover,
.mn-menu .mn-menu__collapse-admin > div:hover {
  background-color: var(--global-colors-ui-primary--focus);
}

.mn-menu.mn-menu--admin .mn-menu__collapse > div:hover,
.mn-menu.mn-menu--admin .mn-menu__collapse-admin > div:hover {
  background-color: var(--global-colors-sky-sky);
}

.mn-menu .mn-menu__collapse > div .svg + span,
.mn-menu .mn-menu__collapse-admin > div .svg + span {
  display: inline-block;
  margin-left: 14px;
  font-size: 12px;
}

.mn-menu:not(.mn-menu--expanded) > div .svg + span {
  display: none;
}

.mn-menu__logo {
  width: 100%;
  margin-top: 30px;
  display: flex;
  height: 40px;
  justify-content: center;
}

.mn-menu:not(.mn-menu--expanded) .mn-menu__logo {
  margin-left: -2px;
  margin-top: 24px;
}

.mn-menu__logo-powered {
  height: 30px;
  margin-top: 18px;
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
}

.mn-menu__logo svg {
  cursor: var(--global-cursor-pointer);
}

.mn-menu .mn-menu__nav-list {
  flex-direction: column;
  display: flex;
  font-size: var(--global-font-size-xs);
  padding-left: 10px;
  margin: auto 0;
  list-style: none;
  overflow-y: scroll;
  height: calc((100%) - 238px);
  gap: 4px;
}

.mn-menu.mn-menu--expanded ul {
  flex-direction: column;
  display: flex;
  font-size: var(--global-font-size-xs);
  padding-left: 10px;
  margin: auto 0;
  list-style: none;
  overflow-y: scroll;
  height: calc((100%) - 280px);
  gap: 4px;
}

.mn-menu:not(.mn-menu--expanded) .mn-menu__nav-list__item div:last-child,
.mn-menu:not(.mn-menu--expanded)
  .mn-menu__nav-list__item--active
  div:last-child {
  display: none;
}

.mn-menu .mn-menu__nav-list__item {
  border-radius: 4px;
  min-height: 32px;
  height: 32px;
  align-items: center;
  display: flex;
  padding-left: 9px;
  padding-right: 9px;
}

.mn-menu .mn-menu__nav-list__item.isActiveCircle::after {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 2px solid var(--global-colors-ui-white);
  border-top-color: transparent;
  animation: spinner 0.6s linear infinite;
  margin-left: 140px;
}

.mn-menu .mn-menu__nav-list__item.isActiveCircleCollapsed::after {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 2px solid var(--global-colors-ui-white);
  border-top-color: transparent;
  animation: spinner 0.6s linear infinite;
}

.mn-menu .mn-menu__nav-list__item.isActiveCircleCollapsed .svg {
  display: none;
}

.mn-menu .mn-menu__nav-list__item.isActiveCircleAdmin::after {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 2px solid var(--global-colors-ink-light);
  border-top-color: transparent;
  animation: spinner 0.6s linear infinite;
  margin-left: 140px;
}

.mn-menu .mn-menu__nav-list__item.isActiveCircleAdminCollapsed::after {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 2px solid var(--global-colors-ink-light);
  border-top-color: transparent;
  animation: spinner 0.6s linear infinite;
}

.mn-menu .mn-menu__nav-list__item.isActiveCircleAdminCollapsed .svg {
  display: none;
}

.mn-menu__nav-list__item--active {
  background-color: #3d7bcc;
}

.mn-menu .mn-menu__nav-list__item:hover {
  background-color: var(--global-colors-ui-primary--focus);
  cursor: var(--global-cursor-pointer);
}

.mn-menu.mn-menu.mn-menu--admin .mn-menu__nav-list__item:hover {
  background-color: var(--global-colors-sky-light);
}

.mn-menu.mn-menu.mn-menu--admin
  .mn-menu__nav-list__item.mn-menu__nav-list__item--active {
  background-color: #e3e5e8;
}

.mn-menu .mn-menu__nav-list__item > .svg {
  margin-right: var(--global-spacing-sm);
}

.mn-menu.mn-menu.mn-menu--admin .mn-menu__nav-list__item > .svg span {
  color: var(--global-colors-ink-dark);
}

.mn-menu:not(.mn-menu--expanded) .mn-menu__nav-list__item > .svg + span {
  display: none;
}

.mn-menu .mn-empty-space {
  margin-bottom: 40px;
  display: block;
}

.mn-menu__partner-logo-container {
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 42px;
}

.mn-menu__partner-logo {
  width: 40px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mn-menu__theguardian-logo-container {
  width: 110px;
}
