.mn-thumb {
  border-radius: 150px;
  overflow: hidden;
  background-color: var(--global-colors-sky-sky);
}

.mn-thumb img {
  width: 100%;
}

.mn-thumb--has-svg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mn-thumb--large {
  width: 100px;
  height: 100px;
}

.mn-thumb--medium {
  width: 64px;
  height: 64px;
}

.mn-thumb--mediumsmall {
  width: 50px;
  height: 50px;
}

.mn-thumb--small {
  width: 38px;
  height: 38px;
}

.mn-thumb--smaller {
  width: 28px;
  height: 28px;
}
