.report-driver-filterbar-row {
  display: flex;
  align-items: center;
  font-weight: var(--global-font-semi-bold);
  width: 100%;
  border-bottom: 1px solid var(--global-colors-sky-dark);
}

.report-driver-filterbar-row .dropdown-list .dropdown-elements .button .label {
  font-family: var(--global-font-regular);
  font-size: 14px;
  text-align: left;
  color: var(--global-colors-ink-dark);
}

.report-driver-filterbar-search,
.report-driver-dropdown-groups,
.report-driver-date-picker,
.report-driver-dropdown-statuses,
.report-driver-dropdown-customize {
  margin-left: 12px;
}

.report-driver-filterbar-btn-generate {
  margin-left: 12px;
  margin-right: 12px;
}

.report-driver-filterbar-btn-generate #button {
  width: 85px;
}

.report-driver-filterbar-search {
  margin-left: 24px;
  width: 220px;
}

.report-driver-date-picker {
  margin-left: 12px;
  height: 32px;
}

.report-driver-filterbar-btn {
  width: auto;
  margin: 24px 16px 24px auto;
  display: flex;
}

.report-driver-filterbar-row .dropdown-list {
  background-color: var(--global-colors-ui-white);
  position: absolute;
  right: 0px;
  width: max-content;
}

.report-driver-filterbar-btn .dropdown-elements {
  font-family: var(--global-font-regular);
  font-size: 14px;
  text-align: left;
  color: var(--global-colors-ink-dark);
}

.report-driver-filterbar-search .report-driver-search-results {
  box-sizing: inherit;
  height: auto;
  border: 1px solid var(--global-colors-sky-sky);
  border-radius: 4px;
  margin-top: 4px;
  position: absolute;
  background-color: var(--global-colors-ui-white);
  z-index: 999;
  max-height: 300px;
  width: 220px;
  overflow-y: auto;
}

.report-driver-filterbar-search
  .report-driver-search-results
  .report-driver-driverbox
  .mn-user-box__lower-row {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 220px;
  padding-right: 80px;
}

.report-driver-filterbar-search
  .report-driver-search-results
  .report-driver-driverbox-selected
  .mn-user-box__lower-row {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 220px;
  padding-right: 80px;
}

.report-driver-filterbar-search
  .report-driver-search-results
  .report-driver-driverbox-selected {
  background-color: var(--global-colors-ui-primary);
}

.report-driver-filterbar-search
  .report-driver-search-results
  .report-driver-driverbox-selected
  .mn-user-box__upper-row--regular,
.report-driver-filterbar-search
  .report-driver-search-results
  .report-driver-driverbox-selected
  .mn-user-box__lower-row {
  color: var(--global-colors-ui-white);
}

.report-driver-filterbar-search .mn-user-box__upper-row--regular {
  width: auto;
}

.report-driver-filterbar-search
  .report-driver-search-results
  .report-driver-driverbox:hover {
  background: var(--global-colors-sky-lighter);
}
