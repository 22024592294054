.mn-back-to-summary {
  border-bottom: 1px solid var(--global-colors-sky-sky);
  padding: var(--global-spacing-md) var(--global-spacing-lg);
  display: flex;
  align-items: center;
  position: relative;
  font-family: var(--global-font-medium);
  font-size: var(--global-font-size-sm);
  color: var(--global-colors-ink-dark);
  cursor: var(--global-cursor-pointer);
  width: 301px;
}

.mn-back-to-summary__text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
