.mn-table thead {
  z-index: 1;
  background-color: var(--global-colors-ui-white);
}

thead {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  position: sticky;
  top: 0;
}

.mn-table {
  font-family: var(--global-font-semi-bold);
  background-color: var(--global-colors-ui-white);
  border-collapse: collapse;
  height: fit-content;
  width: 100%;
}

.mn-table thead th {
  padding: 21px var(--global-spacing-lg);
  font-size: var(--global-font-size-xs);
  color: var(--global-colors-ink-light);
  text-align: left;
  cursor: var(--global-cursor-pointer);
  user-select: none;
  font-weight: normal;
}

/* Rows colors */
.mn-table thead {
  background-color: var(--global-colors-ui-white);
}

.mn-table tbody tr:nth-child(odd) {
  background-color: var(--global-colors-sky-lighter);
}

.mn-table tbody tr:nth-child(even) {
  background-color: var(--global-colors-ui-white);
}
/* 
.mn-table tbody .child-show:nth-child(odd) {
  background-color: var(--global-colors-ui-white);
}

.mn-table tbody .child-show:nth-child(even) {
  background-color: var(--global-colors-sky-lighter);
} */
/* --- */

.mn-table thead th.ascending > span > span {
  transform: rotate(180deg);
}

.mn-table thead th > span {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mn-table tbody td {
  padding: 14px var(--global-spacing-lg);
  font-size: var(--global-font-size-sm);
  font-family: var(--global-font-regular);
  word-wrap: break-word;
  line-height: 16px;
}

.mn-table tbody tr td:last-child .svg {
  padding: 9px;
  cursor: var(--global-cursor-pointer);
  float: right;
}

.mn-table tbody tr td:last-child .dropdown-btn .svg {
  padding: 0px;
  cursor: var(--global-cursor-pointer);
  float: right;
}

.mn-table tbody tr td:last-child {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 14px var(--global-spacing-lg) 14px 9px;
  cursor: var(--global-cursor-pointer);
  float: right;
}

.mn-table tbody td:not(:last-child) .inout-row-data .svg,
.mn-table tbody td:not(:last-child) .stoptime-row-data .svg {
  margin-right: 12px;
}

.mn-table tbody tr .switch-show-tab {
  float: right;
  width: 100%;
  text-align: end;
  cursor: var(--global-cursor-pointer);
}

.mn-table tbody tr td:last-child .dropdown-btn {
  margin: 0;
}

.mn-table tbody tr:first-child {
  cursor: var(--global-cursor-default);
}

.mn-table .mn-label-text .mn-label-text__label,
.mn-table .mn-label-text .mn-label-text__text {
  font-size: var(--global-font-size-sm);
  margin-bottom: 0;
  height: 16px;
  white-space: nowrap;
  line-height: 16px;
}

td.show {
  height: 60px;
  font-family: var(--global-font-regular);
  background-color: var(--global-colors-ui-white);
  box-shadow: inset 0 -1px 0 0 var(--global-colors-sky-sky);
  padding: 8px 24px !important;
}

td.show-child {
  height: 60px;
}

.title-section-show-tab {
  width: 99%;
}

.title-section-show-tab .stoptime-row-data .svg {
  margin-right: 12px;
}

.title-section-show-tab .date-row-data {
  max-width: 120px;
  white-space: normal;
}

.title-show-tab {
  margin-left: 12px;
  color: var(--global-colors-ink-dark);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
}

.switch-show-tab {
  display: inline-block;
  text-align: right;
}

.arrow-down-show-tab {
  margin-top: 7px;
}

.arrow-down-show-tab .svg {
  transition: all 0.2s ease;
}

.arrow-up-show-tab .svg {
  transform: rotate(-180deg);
}

.mn-table tbody tr td:last-child .disabledExternalLink .svg {
  cursor: not-allowed;
}

.mn-table tbody tr td:last-child .disabledExternalLink .svg span {
  color: var(--global-colors-ink-lighter) !important;
}
