.apexcharts-bar-area[filter^="url(#SvgjsFilter"] {
  fill: url(#hoverGradient);
}

.mn-graph .graphLoader {
  position: absolute;
  top: 30%;
  left: 50%;
  display: none !important;
}

.mn-graph .graphLoader.isActiveCircleAdmin {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.mn-graph .graphLoader.isActiveCircleAdmin::before {
  content: "";
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 2px solid var(--global-colors-ink-light);
  border-top-color: transparent;
  animation: spinner 0.6s linear infinite;
}

.arrow-box {
  font-family: var(--global-font-semi-bold) !important;
  font-size: 10px;
  text-align: center;
  color: #ffffff;
  display: flex;
  background-color: #335278;
  border-color: #335278;
  height: 21px;
  width: auto;
  line-height: 2;
  padding: 0 8px;
  text-transform: uppercase;
  opacity: 1;
  border-radius: 4px;
  margin-left: -26px;
  margin-top: -22px;
}

.arrow-box::after {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  bottom: -3px;
  left: 50%;
  margin-left: -21px;
  border-top-color: #335278;
  border-top-style: solid;
  border-top-width: 6px;
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  border-top-color: #335278 !important;
}

.apexcharts-tooltip {
  overflow: visible !important;
  border-color: transparent !important;
  background-color: transparent !important;
}
