.schedulation-modal {
}

.schedulation-modal-card {
  border-bottom: 1px solid #e4e6e9;
  padding-bottom: 20px;
  padding-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
  position: relative;
}

.schedulation-modal-card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.schedulation-modal-card-header-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  margin-right: 10px;
}

.schedulation-modal-card-body {
}

.schedulation-modal-card-circle-icon {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 1px solid #e4e6e9;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
}

.schedulation-modal-card-text-sub {
  color: #35465b;
  font-size: 12px;
}

.schedulation-modal-radio {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.schedulation-modal-card-radio-area {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.schedulation-modal-card-monthly-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.schedulation-recursion-input {
  border: 1px solid var(--global-colors-sky-dark);
}

.schedulation-recursion-input:focus {
  border: 1px solid #007bff;
  outline: none;
}
