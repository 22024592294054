.component-petals {
  position: absolute;
  display: inline-block;
  width: 65px;
  height: 65px;
  align-items: center;
  line-height: 34px;
  border: 0px solid var(--global-colors-ui-white);
  border-radius: 50%;
  padding: 0px;
  z-index: 0;
  top: -15px;
  left: -15px;
}

.component-petals-large {
  position: absolute;
  display: inline-block;
  width: 75px;
  height: 75px;
  align-items: center;
  line-height: 34px;
  border: 0px solid var(--global-colors-ui-white);
  border-radius: 50%;
  padding: 0px;
  z-index: 0;
  top: -17px;
  left: -19px;
}
