.map-functionalities {
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
}

.openVehicleDetails {
  z-index: 2;
  position: absolute;
  top: 157px;
  margin-left: 16px;
  height: calc(100% - 157px);
}

.openVehicleDetails.widenDiv {
  top: calc(157px - var(--page-container-filters-height));
  height: calc(100% - 157px + var(--page-container-filters-height));
}

.fc-sidebar-collapse-container {
  background-color: var(--global-colors-ui-white);
  font-family: var(--global-font-medium);
  font-size: var(--global-font-size-xs);
  margin-top: auto;
  margin-left: 8px;
  margin-right: 8px;
}

.fc-sidebar-collapse-container::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  border-top: 1px solid var(--global-colors-sky-sky);
  margin-bottom: 4px;
}

.fc-sidebar-collapse-container .fc-sidebar-collapse {
  padding: 9px;
  cursor: var(--global-cursor-pointer);
  display: flex;
  border-radius: 4px;
  height: 30px;
  align-items: center;
  margin-bottom: 14px;
}

.fc-sidebar-collapse-container .fc-sidebar-collapse:hover {
  background-color: var(--global-colors-sky-light);
}

.fc-sidebar-collapse-container .fc-sidebar-collapse .svg + span {
  display: inline-block;
  margin-left: 14px;
  font-size: 12px;
}

.fc-sidebar-collapse-container .fc-sidebar-collapse .svg {
  color: #031832;
}

.fc-vehicle-modal-container {
  height: calc(
    100vh -
      (
        (var(--header-menu-height) + var(--page-container-filters-height)) +
          92px
      )
  );
  padding: 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.fc-vehicle-modal-container-tracking {
  height: calc(100vh - ((var(--header-menu-height)) + 92px));
  padding: 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.fc-vehicles-count {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: var(--global-font-medium);
  font-size: var(--global-font-size-xs);
  padding: var(--global-spacing-xs) var(--global-spacing-sm);
  border-bottom: 1px solid var(--global-colors-sky-sky);
  width: 100%;
}
