.speed-report-modal .mn-modal .mn-modal__dialog {
  min-width: 1200px;
  min-height: 300px;
  border-radius: 4px;
}

.speed-report-modal
  .mn-modal
  .mn-modal__dialog
  .speed-report-modal-body
  .mn-modal__dialog-header {
  padding-top: 24px;
  padding-bottom: 24px;
}

.speed-report-modal .speed-report-modal-title {
  font-family: var(--global-font-medium);
  margin-top: 6px;
}

.speed-report-modal
  .mn-modal
  .mn-modal__dialog
  .speed-report-modal-body
  .mn-modal__dialog-body {
  border-top: 1px solid var(--global-colors-sky-sky);
}

.speed-report-modal-modal
  .mn-modal
  .mn-modal__dialog
  .speed-report-modal-body
  .mn-modal__dialog-body
  .speed-report-modal-text {
  display: block;
  color: var(--global-colors-ink-light);
}

.speed-report-modal .group-input {
  margin-top: 16px;
  padding-bottom: 4px;
}

.speed-report-modal .mn-modal .mn-modal__dialog .speed-report-modal-footer {
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 8%);
}

.speed-report-modal .mn-modal .mn-modal__dialog .mn-modal__dialog-footer {
  padding: 34px 28px 28px;
}
