.map-button-view-arrow-active {
  padding: 9px;
  border-radius: 4px;
  background-color: var(--global-colors-ui-white);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.map-button-view-arrow-deactive {
  padding: 9px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  background-color: #35465b;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  cursor: pointer;
}

.map-button-view-arrow-disabled {
  padding: 9px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  background-color: var(--global-colors-ui-sky-sky--focus);
  pointer-events: none;
}
