.withFilterBar {
  height: calc(
    100vh - (var(--header-menu-height) + var(--page-container-filters-height))
  );
  overflow-y: auto;
}

.withoutFilterBar,
.row.withoutFilterBar .content-sidebar {
  height: calc(100vh - var(--header-menu-height));
}
