.date-picker {
  width: 270px;
  font-family: var(--global-font-regular);
  background-color: var(--global-colors-ui-white);
  border: 1px solid var(--global-colors-sky-dark);
  border-radius: 4px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
}

.date-picker .header {
  font-size: var(--global-font-size-lg);
  text-align: center;
  display: flex;
  padding: var(--global-spacing-lg);
  justify-content: space-between;
  user-select: none;
}

.date-picker .header .previous,
.date-picker .header .next {
  cursor: var(--global-cursor-pointer);
  margin-top: 3px;
}

.date-picker .header .previous-disabled,
.date-picker .header .next-disabled {
  cursor: var(--global-cursor-not-allowed);
  margin-top: 3px;
}

.date-picker .footer {
  display: flex;
  justify-content: space-between;
  padding: var(--global-spacing-md);
}

.date-picker .body {
  padding: 0 var(--global-spacing-md);
}

.date-picker .body > div:last-child {
  border-bottom: 1px solid var(--global-colors-sky-sky);
  padding-bottom: var(--global-spacing-md);
}

.date-picker__day {
  position: relative;
  width: calc(100% / 7);
  height: 34px;
  box-sizing: border-box;
  z-index: 1;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: var(--global-spacing-sm);
  cursor: var(--global-cursor-pointer);
}

.date-picker .week {
  width: calc(100% / 7);
  text-align: center;
  text-transform: uppercase;
  color: var(--global-colors-ink-light);
  font-size: var(--global-font-size-xxs);
}

.date-picker__day-names {
  padding: 0 0 var(--global-spacing-md);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid var(--global-colors-sky-sky);
  margin-bottom: var(--global-spacing-xs);
}

.date-picker .week div {
  width: 100%;
}

.date-picker .date-picker__day.selected,
.start-date,
.single-date,
.end-date {
  background-color: var(--global-colors-ui-primary);
  color: var(--global-colors-ui-white);
}

.second-start-date,
.second-end-date {
  background-color: var(--global-colors-ink-light);
  color: var(--global-colors-ui-white);
}

.date-picker .date-picker__day.today:not(.selected) {
  border: 1px solid var(--global-colors-ui-primary);
}

.date-picker .date-picker__day.not-selectable {
  color: var(--global-colors-sky-dark);
  cursor: var(--global-cursor-not-allowed);
}

.single-date {
  border-radius: 4px;
}

.start-date,
.second-start-date {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.end-date,
.second-end-date {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.between-dates {
  background-color: var(--global-colors-ui-secondary-light);
  border-radius: 0;
}

.second-between-dates {
  background-color: #ebedef;
  border-radius: 0;
}

.is-double .date-picker__inner > div:first-child .header .next,
.is-double .date-picker__inner > div:last-child .header .previous {
  visibility: hidden;
}

.is-double .date-picker__inner {
  display: flex;
  justify-content: space-between;
}

.is-double .date-picker__inner > div {
  width: calc(100% / 2);
}

.has-date-presets {
  width: 478px;
  display: flex;
}

.has-week-presets {
  width: 500px;
  display: flex;
}

.is-double.has-date-presets {
  width: 748px;
}

.has-date-presets .left {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.has-date-presets .left .footer {
  margin-top: auto;
}

.has-week-presets .preset-title,
.has-date-presets .preset-title {
  border-top-right-radius: 4px;
  padding: 16px 16px 0 16px;
  border-left: 1px solid var(--global-colors-sky-sky);
  font-size: var(--global-font-size-xs);
  font-family: var(--global-font-bold);
  background-color: var(--global-colors-sky-lighter);
}

.has-date-presets .preset-list {
  width: 200px;
  height: 368px;
  padding: var(--global-spacing-md) 0;
  background-color: var(--global-colors-sky-lighter);
  border-left: 1px solid var(--global-colors-sky-sky);
  max-height: 435px;
  overflow: auto;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.has-week-presets .preset-list {
  width: 234px;
  height: 368px;
  background-color: var(--global-colors-sky-lighter);
  border-left: 1px solid var(--global-colors-sky-sky);
  max-height: 435px;
  overflow: auto;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.has-date-presets .preset-list > div {
  padding-left: var(--global-spacing-md);
  font-family: var(--global-font-semi-bold);
  font-size: var(--global-font-size-xs);
  margin: 0 0 var(--global-spacing-xs);
}

.has-date-presets .preset-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.has-date-presets .preset-list ul li {
  margin-bottom: 0;
  padding: var(--global-spacing-sm);
  font-size: var(--global-font-size-sm);
  cursor: var(--global-cursor-pointer);
  display: flex;
}

.has-date-presets .preset-list ul li .preset-check {
  width: 14px;
  display: block;
  margin-right: var(--global-spacing-md);
}

.has-date-presets .preset-list ul li .preset-status {
  margin-left: auto;
}

.has-date-presets .preset-list ul li.active-preset {
  background-color: var(--global-colors-ui-primary);
  color: var(--global-colors-ui-white);
  align-items: center;
}

.has-date-presets .preset-list ul li.active-preset > span {
  margin-right: 16px;
}

.has-single-date {
  width: 384px;
  display: flex;
}

.has-single-date .left {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.has-single-date .left .footer {
  margin-top: auto;
}

.has-single-date .preset-title {
  width: 114px;
  font-size: var(--global-font-size-xs);
  font-family: var(--global-font-bold);
  background-color: var(--global-colors-sky-lighter);
}
.has-single-date .preset-list {
  width: 114px;
  height: 400px;
  padding: 24px 0px 0px 0px;
  background-color: var(--global-colors-sky-lighter);
  border-left: 1px solid var(--global-colors-sky-sky);
}
.has-single-date .preset-list ul {
  height: 330px;
  list-style: none;
  overflow-y: scroll;
  margin-left: -20px;
}
.has-single-date .preset-list ul::-webkit-scrollbar-thumb {
  height: 45px;
}
.has-single-date .preset-list ul li {
  text-align: center;
  padding: 10px 0px 10px 0px;
  font-size: var(--global-font-size-sm);
  cursor: var(--global-cursor-pointer);
}
.has-single-date .preset-list ul li:hover,
.year-selected {
  background-color: var(--global-colors-ui-primary);
  color: var(--global-colors-ui-white);
}
.has-single-date .preset-list ul li > span {
  width: 14px;
  height: 14px;
  display: block;
  margin-right: var(--global-spacing-md);
}
.has-single-date .preset-list ul li.active-preset {
  background-color: var(--global-colors-ui-primary);
  color: var(--global-colors-ui-white);
}
.is-double {
  width: 540px;
}
.time {
  padding: var(--global-spacing-md) var(--global-spacing-md) 0;
  display: flex;
  justify-content: space-between;
}
.time > div:first-child {
  margin-right: var(--global-spacing-md);
}
.time label {
  font-size: var(--global-font-size-xs);
  font-family: var(--global-font-semi-bold);
  color: var(--global-colors-ink-light);
  margin-bottom: 4px;
}
.comparison {
  display: flex;
  justify-content: flex-end;
  padding: var(--global-spacing-md);
  flex-direction: column;
}
.comparison .switch {
  font-size: var(--global-font-size-sm);
  font-family: var(--global-font-regular);
  line-height: 1.7;
  width: fit-content;
  margin-left: auto;
}
.comparison .switch > span::before {
  background-color: var(--global-colors-sky-sky);
}
.comparison .firstDate,
.comparison .secondDate {
  display: flex;
}
.comparison .firstDate {
  margin-bottom: var(--global-spacing-sm);
}
.comparison .firstDate > *,
.comparison .secondDate > * {
  flex: 1;
}
.comparison .dates {
  display: flex;
  align-items: center;
}
.comparison .date-separator {
  font-size: var(--global-font-size-sm);
  margin: 0 var(--global-spacing-xs);
  color: var(--global-colors-ink-dark);
}
.comparison .comparison-controls {
  padding-top: var(--global-spacing-md);
}
.comparison .firstDate input,
.comparison .secondDate input {
  padding: var(--global-spacing-xs) var(--global-spacing-sm);
  border-radius: 4px;
  border: 1px solid var(--global-colors-sky-dark);
  color: var(--global-colors-ink-light);
  width: 140px;
}
.comparison .firstDateStartDate input,
.comparison .firstDateEndDate input,
.comparison .secondDateStartDate input,
.comparison .secondDateEndDate input {
  outline: none;
  cursor: default;
}
.comparison .dropdown,
.comparison .dropdown input {
  width: 180px;
}
.comparison .dropdown .options {
  overflow: hidden;
}
.comparison .datePreset {
  display: flex;
  align-items: center;
}
.comparison .firstDateIndicator::before,
.comparison .secondDateIndicator::before {
  content: "";
  width: 11px;
  height: 11px;
  border-radius: 150px;
  display: block;
  background-color: var(--global-colors-ui-primary);
  margin-right: var(--global-spacing-xs);
}
.comparison .firstDateIndicator,
.comparison .secondDateIndicator {
  display: flex;
  align-items: center;
  font-size: var(--global-font-size-sm);
}
.comparison .secondDateIndicator::before {
  background-color: var(--global-colors-ink-light);
}
.comparison .firstDateStartDate,
.comparison .firstDateEndDate,
.comparison .secondDateStartDate,
.comparison .secondDateEndDate {
  font-size: var(--global-font-size-sm);
} /*customize the list item hover color*/
.e-timepicker.e-popup.e-custom-style .e-list-parent.e-ul .e-list-item.e-hover, /* csslint allow: adjoining-classes */.e-timepicker.e-popup.e-custom-style .e-list-parent.e-ul .e-list-item.e-active.e-hover {
  /* csslint allow: adjoining-classes */
  background-color: blue;
  color: #fff;
} /*customize the active element text color*/
.e-timepicker.e-popup.e-custom-style .e-list-parent.e-ul .e-list-item.e-active {
  /* csslint allow: adjoining-classes */
  color: #333;
  background-color: #fff;
} /* Hover color for preset selection */
.date-picker__day:last-child.hovered-week {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  color: var(--global-colors-ui-white);
  background-color: var(--global-colors-ink-light);
}
.date-picker__day:first-child.hovered-week {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  color: var(--global-colors-ui-white);
  background-color: var(--global-colors-ink-light);
}
.date-picker__day.hovered-week {
  border-radius: 0;
  background-color: rgba(51, 51, 51, 0.1);
}

.date-picker__day.not-selectable.hovered-week {
  cursor: var(--global-cursor-pointer);
}
/* Hover color for preset selection */
.date-picker__day:last-child.hovered-week {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  color: var(--global-colors-ui-white);
  background-color: var(--global-colors-ink-light);
}

.date-picker__day:first-child.hovered-week {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  color: var(--global-colors-ui-white);
  background-color: var(--global-colors-ink-light);
}

.date-picker__day.hovered-week {
  border-radius: 0;
  background-color: rgba(51, 51, 51, 0.1);
}

.date-picker__day.not-selectable.hovered-week {
  cursor: var(--global-cursor-pointer);
}

.has-single-date .right {
  width: 114px;
  height: 400px;
  padding: 24px 0px 0px 0px;
  background-color: var(--global-colors-sky-lighter);
  border-left: 1px solid var(--global-colors-sky-sky);
}

.has-single-date .right h4 {
  padding-left: var(--global-spacing-md);
  font-size: var(--global-font-size-xs);
  font-family: var(--global-font-bold);
  margin: 0 0 var(--global-spacing-xs);
}

.has-single-date .right ul {
  height: 330px;
  list-style: none;
  overflow-y: scroll;
  margin-left: -20px;
}

.has-single-date .right ul::-webkit-scrollbar-thumb {
  height: 45px;
}

.has-single-date .right ul li {
  text-align: center;
  padding: 10px 0px 10px 0px;
  font-size: var(--global-font-size-sm);
  cursor: var(--global-cursor-pointer);
}

.has-single-date .right ul li:hover,
.year-selected {
  background-color: var(--global-colors-ui-primary);
  color: var(--global-colors-ui-white);
}

.has-single-date .right ul li > span {
  width: 14px;
  height: 14px;
  display: block;
  margin-right: var(--global-spacing-md);
}

.has-single-date .right ul li.active-preset {
  background-color: var(--global-colors-ui-primary);
  color: var(--global-colors-ui-white);
}
