.public-route-stop-component-wrapper {
  position: relative;
  width: 100%;
  border-left: 1px solid var(--global-colors-sky-sky);
  border-bottom: 1px solid var(--global-colors-sky-sky);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.public-route-stop-component-date {
  border: 2px solid var(--global-colors-sky-sky);
  border-radius: 4px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  padding-left: 8px;
  padding-right: 8px;
  width: 100%;
}

.public-route-stop-component-date-text {
  color: var(--global-colors-status-stopped);
  opacity: 0.9;
  font-size: 11px;
}

.public-route-stop-component-date-icon {
  position: absolute;
  top: 10px;
}

.public-route-stop-component-icon {
}
