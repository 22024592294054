.mn-menu-settings-action {
  height: auto;
  width: auto;
  border-radius: 4px;
  padding: 11px;
  border: 1px solid var(--global-colors-sky-sky);
  background-color: var(--global-colors-ui-white);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
  position: relative;
  font-family: var(--global-font-regular);
  margin-top: 98px;
  margin-right: 4px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.mn-menu-settings-options {
  display: flex;
  height: auto;
  flex-direction: column;
  justify-content: space-between;
}

.map-actions-container
  .mn-menu-settings-action
  .mn-menu-settings-options
  .checkbox {
  align-items: center;
  z-index: 0;
  position: relative;
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-sm);
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}

.map-actions-container
  .mn-menu-settings-action
  .mn-menu-settings-options
  .checkbox
  > span {
  display: inline-flex;
  width: 100%;
  cursor: var(--global-cursor-pointer);
  align-items: center;
}
