.row.page-content.withoutFilterBar
  .col
  > div
  > .vlh-open-details-inner
  > .track-details-up {
  top: 1px;
}
.row.page-content.withoutFilterBar
  .col
  > div
  > .vlh-open-details-inner
  > .stop-details-out {
  top: 60px;
}

.row.page-content.withFilterBar
  .col
  > div
  > .vlh-open-details-inner
  > .track-details-up {
  top: 0px;
}

.row.page-content.withFilterBar
  .col
  > div
  > .vlh-open-details-inner
  > .stop-details-out {
  top: 0px;
}

.vlh-open-details {
  width: 100%;
}

.vlh-open-details .vlh-open-details-inner {
  padding-left: 16px;
  width: 100%;
  height: 100%;
}

.vlh-open-details .vlh-open-details-inner .track-details-up {
  padding-top: 16px;
  position: absolute;
}
.vlh-open-details .vlh-open-details-inner .stop-details-out {
  padding-top: 16px;
  position: absolute;
}

.vlh-open-details .vlh-open-details-inner .track-details-down {
  bottom: 16px;
  position: absolute;
  width: -webkit-fill-available;
  margin-right: 16px;
}

.vlh-open-summary-details {
  top: 155px;
  width: fit-content;
}

.LocationHistoryMap .track-details-up {
  position: absolute;
}

.vlh-open-summary-details .track-details-down {
  bottom: 16px;
  position: fixed;
  width: -webkit-fill-available;
  margin-right: 16px;
}
