.card-flex-driver {
  width: 48%;
  margin-bottom: 20px;
}
.card-flex-driver .mn-card {
  max-width: none;
  margin: 16px auto 0px auto;
}
.withDriverDetails {
  height: calc(
    (
      100vh - 95px -
        (var(--header-menu-height) + var(--page-container-filters-height))
    )
  );
}
.withDriverDetailsOpen {
  height: calc(
    (
      100vh - 145px -
        (var(--header-menu-height) + var(--page-container-filters-height))
    )
  );
}

.last-tracks-notFound {
  padding-left: 12px;
  padding-top: 10px;
  padding-bottom: 22px;
  font-family: var(--global-font-regular);
  color: var(--global-colors-ink-light);
  size: 14px;
}
