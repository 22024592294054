.draggable-container {
  display: flex;
  flex-direction: column;
  max-height: 340px;
  height: 340px;
  padding: 10px;
  float: left;
}

.draggable-item {
  padding: 12px 18px;
  border-radius: 4px;
  height: 38px;
  width: 221px;
  margin: 4px auto;
  display: flex;
  align-items: center;
  cursor: var(--global-cursor-grab);
}

.draggable-item:hover {
  background-color: var(--global-colors-sky-lighter);
}

.draggable-item:active {
  background-color: var(--global-colors-ui-white);
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.12);
}

.draggable-item--dragging {
  border-radius: 4px;
  min-height: 38px;
  min-width: 221px;
  margin: 4px auto;
  border: 1px dashed var(--global-colors-sky-dark);
  cursor: var(--global-cursor-grabbing);
}

.draggable-item--dragging > * {
  display: none;
}

.draggable-item-label {
  margin-left: 18px;
  height: 16px;
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-sm);
}
