.geofence-filterbar-row {
  display: flex;
  align-items: center;
  font-weight: var(--global-font-semi-bold);
  width: 100%;
  border-bottom: 1px solid var(--global-colors-sky-dark);
}

.geofence-filterbar-dropdown-columns .dropdown {
  width: 207px;
}

.geofence-filterbar-search {
  margin-left: 16px;
}

.geofence-filterbar-search,
.geofence-filterbar-statuses {
  margin-top: 24px;
  margin-bottom: 24px;
  margin-right: 16px;
}
.geofence-filterbar-dropdown-categories {
  margin-top: 24px;
  margin-bottom: 24px;
  margin-right: 16px;
}

.geofence-filterbar-categories {
  width: auto;
  margin-left: auto;
  margin-right: 16px;
  margin-top: 24px;
  margin-bottom: 24px;
}

.geofence-filterbar-categories .dropdown-btn.dropdown--secondary {
  margin-top: 0;
  height: 32px;
}

.geofence-filterbar-categories .dropdown-list {
  background-color: var(--global-colors-ui-white);
  position: absolute;
  right: 0px;
  width: max-content;
}

.geofence-filterbar-categories
  .dropdown-btn
  .dropdown-list
  .dropdown-elements
  .text {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  color: var(--global-colors-ink-dark);
}

.geofence-filterbar-addGeofence {
  width: auto;
  margin-right: 16px;
  margin-top: 24px;
  margin-bottom: 24px;
}
