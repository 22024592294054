.admin-filterbar-row {
  display: flex;
  align-items: center;
  font-weight: var(--global-font-semi-bold);
  width: 100%;
}

.admin-filterbar-row-splitter {
  display: flex;
  align-items: center;
  font-weight: var(--global-font-semi-bold);
  width: 100%;
  border-bottom: 1px solid var(--global-colors-sky-dark);
}

.admin-filterbar-search {
  margin-left: 16px;
}

.admin-filterbar-dropdown-columns .dropdown {
  width: 207px;
}

.admin-filterbar-search,
.admin-filterbar-dropdown-groups,
.admin-filterbar-dropdown-statuses,
.admin-filterbar-dropdown-orderby,
.admin-filterbar-dropdown-columns {
  margin-top: 24px;
  margin-bottom: 24px;
}

.admin-filterbar-dropdown-groups,
.admin-filterbar-dropdown-statuses,
.admin-filterbar-dropdown-orderby,
.admin-filterbar-dropdown-columns {
  margin-left: 12px;
}

.admin-filterbar-btn {
  width: auto;
  margin-left: auto;
  margin-right: 24px;
}
