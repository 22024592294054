.mn-lh-stop {
  padding: 0;
  width: 322px;
}

.mn-lh-stop .mn-card__header {
  font-size: var(--global-font-size-sm);
  padding: var(--global-spacing-xs) 0 var(--global-spacing-xs)
    var(--global-spacing-md);
  border-bottom: 1px solid var(--global-colors-sky-sky);
  margin-bottom: 0;
  height: 34px;
}

.mn-lh-stop .mn-card__body {
  margin: 0;
}

.mn-lh-stop .mn-card__header > div {
  margin-bottom: 0;
}

.mn-lh-stop .mn-lh-stop__details {
  padding: var(--global-spacing-md);
}
.mn-lh-stop .mn-lh-stop__details > div:first-child {
  padding: 0 0 var(--global-spacing-md) 0;
}

.mn-lh-stop .mn-lh-stop__details > div:first-child .svg {
  margin-right: var(--global-spacing-sm);
}

.mn-lh-stop .mn-lh-stop__details img {
  border-radius: 4px;
  display: block;
  height: 142px;
  width: 290px;
  margin-bottom: var(--global-spacing-md);
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.mn-lh-stop .mn-lh-stop__details-data {
  display: flex;
  flex-wrap: wrap;
}

.mn-lh-stop .mn-lh-stop__details-data > div {
  width: 47%;
}

#StreetViewImg {
  cursor: var(--global-cursor-pointer);
}

.mn-lh-stop .mn-lh-stop__details-data > div:not(:last-child) {
  margin-bottom: var(--global-spacing-md);
}

.mn-lh-stop .mn-lh-stop__details-data > div:nth-child(even) {
  margin-left: var(--global-spacing-md);
}
