.mn-single-item-container {
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 12px;
}

.mn-single-item-container:not(:last-child) {
  border-bottom: 1px solid var(--global-colors-sky-sky);
}

.mn-single-item-container > *:not(:last-child) {
  display: flex;
  min-width: 76px;
  margin-right: 12px;
}

.mn-single-item-container > *:nth-child(1),
.mn-single-item-container > *:nth-child(2) {
  min-width: 106px;
}

.mn-single-item-container .mn-data-label__text span {
  font-size: 12px;
}

.mn-widget-help__item-forward {
  min-width: auto !important;
  cursor: var(--global-cursor-pointer);
  margin-left: auto;
}

.mn-single-item-container .mn-data-label__text .mn-data-label__text-data {
  margin-bottom: 0;
}
