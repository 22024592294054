.preview-attachment-modal .mn-modal .mn-modal__dialog {
  width: 600px;
  height: 705px;
  border-radius: 4px;
}

.preview-attachment-modal .mn-modal .mn-modal__dialog .mn-modal__dialog-body {
  padding: 32px 32px 0px;
  height: inherit;
}

.preview-attachment-file-wrapper {
  width: 100%;
  height: 100%;
}

.preview-attachment-file-container {
  display: flex;
  align-self: center;
  justify-self: center;
  height: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.preview-attachment-file-container #file {
  max-height: 101%;
  max-width: 100%;
  object-fit: contain;
}

.delete-attachment-modal .mn-modal .mn-modal__dialog {
  width: 600px;
  border-radius: 4px;
}
