.check {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  border-radius: 150px;
}

.check--success,
.check--active {
  background-color: var(--global-colors-feedback-success);
}

.check--failed {
  background-color: var(--global-colors-feedback-danger);
}

.check--nothing {
  background-color: transparent;
  border: 1px solid var(--global-colors-sky-dark);
}

.check--pending {
  background-color: var(--global-colors-ui-secondary);
}

.check--deactive {
  background-color: var(--global-colors-sky-dark);
}

.check--small {
  width: 14px;
  height: 14px;
}

.check--xsmall {
  width: 10px;
  height: 10px;
}

.check--medium {
  width: 16px;
  height: 16px;
}
