.mn-location-map {
  width: 100%;
  height: 192px;
}

.mn-location-map .location-information {
  position: absolute;
  bottom: var(--global-spacing-sm);
  left: var(--global-spacing-sm);
  margin-left: -70px;
}

.mn-location-map-empty {
  width: 100%;
  height: 192px;
  background-color: var(--global-colors-ui-white);
}

.mn-location-map-empty .location-information {
  display: none;
}
