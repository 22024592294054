.completed-maintenance-modal .mn-modal .mn-modal__dialog {
  width: 580px;
  border-radius: 4px;
}

.completed-maintenance-modal
  .mn-modal
  .mn-modal__dialog
  .mn-modal__dialog-body {
  padding: 32px 32px 10px;
}

.completed-maintenance-modal .completed-maintenance-odometer,
.completed-maintenance-modal .completed-maintenance-date,
.completed-maintenance-modal .completed-maintenance-amount,
.completed-maintenance-modal .completed-maintenance-upload {
  height: 49px;
}

.completed-maintenance-modal .completed-maintenance-upload #upload {
  cursor: var(--global-cursor-pointer);
  caret-color: transparent; /* remove blinking cursor */
}

.completed-maintenance-modal .completed-maintenance-note {
  height: 127px;
}

.completed-maintenance-modal .mn-modal__dialog-body .left-forms-first {
  width: 131px;
  float: left;
}

.completed-maintenance-modal .mn-modal__dialog-body .left-forms-second {
  width: 186px;
  float: left;
}

.completed-maintenance-modal
  .mn-modal__dialog-body
  .left-forms-first
  .mn-label-text__text,
.completed-maintenance-modal
  .mn-modal__dialog-body
  .left-forms-second
  .mn-label-text__text,
.completed-maintenance-modal
  .mn-modal__dialog-body
  .left-forms
  .mn-label-text__text {
  font-size: 12px;
}

.completed-maintenance-modal .mn-modal__dialog-body .right-forms {
  width: 200px;
  margin-right: 53px;
  float: right;
}

.completed-maintenance-modal
  .mn-modal__dialog-body
  .right-forms
  #kmTraveled::-webkit-outer-spin-button,
.completed-maintenance-modal
  .mn-modal__dialog-body
  .right-forms
  #kmTraveled::-webkit-inner-spin-button,
.completed-maintenance-modal
  .mn-modal__dialog-body
  .right-forms
  #amount::-webkit-outer-spin-button,
.completed-maintenance-modal
  .mn-modal__dialog-body
  .right-forms
  #amount::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.completed-maintenance-modal
  .mn-modal__dialog-body
  .right-forms
  input[type="number"] {
  -moz-appearance: textfield;
  -webkit-appearance: none;
  appearance: textfield;
}

.completed-maintenance-modal .completed-maintenance-odometer .right-forms {
  width: 200px;
  margin-right: 0px;
  float: left;
  margin-left: 133px;
}

.completed-maintenance-modal .completed-maintenance-odometer .editing {
  float: right;
  margin-left: -8px;
}

.completed-maintenance-modal .completed-maintenance-date .right-forms {
  width: 200px;
  margin-right: 53px;
  float: left;
  margin-left: 77px;
}

.completed-maintenance-modal
  .completed-maintenance-amount
  .right-forms-maintenance,
.completed-maintenance-modal
  .completed-maintenance-amount
  .right-forms-deadline {
  width: 200px;
  margin-right: 53px;
  float: left;
  margin-left: 131px;
}

.completed-maintenance-modal
  .completed-maintenance-amount
  .right-forms-maintenance
  input,
.completed-maintenance-modal
  .completed-maintenance-amount
  .right-forms-deadline
  input {
  height: 32px;
  width: 100%;
  max-width: auto;
  border: 1px solid var(--global-colors-ink-light);
  border-radius: 4px;
  padding: 13px 16px;
  outline: 0;
  background-color: transparent;
  transition: all 0.3s ease;
  font-size: var(--global-font-size-sm);
  font-family: inherit;
  color: var(--global-colors-ink-dark);
}

.completed-maintenance-modal
  .completed-maintenance-amount
  .right-forms-maintenance
  input[value=""],
.completed-maintenance-modal
  .completed-maintenance-amount
  .right-forms-deadline
  input[value=""] {
  border: 1px solid var(--global-colors-sky-dark);
}

.completed-maintenance-modal
  .completed-maintenance-amount
  .right-forms-maintenance
  .svg
  span {
  z-index: 9;
  position: absolute;
  left: 464px;
  top: 205px;
}

.completed-maintenance-modal
  .completed-maintenance-amount
  .right-forms-deadline
  .svg
  span {
  z-index: 9;
  position: absolute;
  left: 464px;
  top: 156px;
}

.completed-maintenance-modal
  .completed-maintenance-amount
  .right-forms-maintenance
  .text-field.text-field--has-icon
  .svg,
.completed-maintenance-modal
  .completed-maintenance-amount
  .right-forms-deadline
  .text-field.text-field--has-icon
  .svg {
  cursor: unset;
}

.completed-maintenance-modal
  .mn-modal__dialog-body
  .completed-maintenance-note
  .left-forms {
  margin-bottom: 3px;
  float: left;
}

.completed-maintenance-modal
  .mn-modal__dialog-body
  .completed-maintenance-note
  .right-forms {
  width: 515px;
  height: 98px;
  margin-right: 0px;
  float: right;
}

.completed-maintenance-modal
  .mn-modal__dialog-body
  .right-forms
  .text-field
  input {
  height: 32px;
}

.completed-maintenance-modal
  .mn-modal__dialog-body
  .completed-maintenance-note
  .right-forms
  .text-field
  textarea {
  height: 98px;
}

.completed-maintenance-modal .text-field.text-field--has-icon .svg {
  line-height: 33px;
}

.completed-maintenance-modal
  .mn-modal
  .mn-modal__dialog
  .mn-modal__dialog-footer {
  padding: 26px 24px 24px;
}

/* Tooltip text Region */
.completed-maintenance-modal
  .completed-maintenance-upload
  .right-forms
  .tooltiptext {
  visibility: hidden;
  background-color: #02285a;
  opacity: 0.8;
  font-family: var(--global-font-semi-bold);
  font-size: 10px;
  color: var(--global-colors-ui-white);
  text-align: center;
  align-items: center;
  border-radius: 4px;
  margin-top: 5px;
  left: 50%;
  margin-left: -23px;
  position: absolute;
  z-index: 1;
  padding: 2px 8px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.completed-maintenance-modal
  .completed-maintenance-upload
  .right-forms:hover
  .tooltiptext {
  visibility: visible;
}

.completed-maintenance-modal
  .completed-maintenance-upload
  .right-forms:hover
  .tooltiptext::after {
  content: " ";
  position: absolute;
  bottom: 100%; /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #02285a transparent;
}

/* View Edit Completed Maintenance Region */
.view-edit-completed-maintenance_view {
  pointer-events: none;
}

.view-edit-completed-maintenance_view input {
  border: 1px solid var(--global-colors-sky-dark) !important;
  color: var(--global-colors-ink-light) !important;
}

.view-edit-completed-maintenance_view textarea {
  border: 1px solid var(--global-colors-sky-dark);
  color: var(--global-colors-ink-light);
}
