.gr-filterbar-row {
  display: flex;
  margin-top: 24px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--global-colors-sky-sky);
}

.gr-filterbar-datepicker,
.gr-filterbar-btn-generate,
.gr-filterbar-vehicles-dropdown,
.gr-geolocator-drop,
.gr-eventtype-drop,
.gr-time-dropdown {
  margin-left: 16px;
}

.gr-filterbar-btn-generate #button {
  width: 85px;
}

.gr-filterbar-btn {
  width: auto;
  margin: 0 16px 0 auto;
  display: flex;
}

.gr-filterbar-row .dropdown-list {
  background-color: var(--global-colors-ui-white);
  position: absolute;
  right: 0px;
  width: max-content;
}

.gr-filterbar-btn-schedule {
  margin-right: 12px;
}

.gr-filterbar-btn .dropdown-elements {
  font-family: var(--global-font-regular);
  font-size: 14px;
  text-align: left;
  color: var(--global-colors-ink-dark);
}

.gr-eventtype-drop .dropdown {
  min-width: 159px;
}

.gr-geolocator-drop .geofence-dropdown .dropdown-content {
  width: 355px;
}

.gr-geolocator-drop .checkbox > span .checkbox-label {
  line-height: 16px;
}
