.mn-chart-time {
  position: relative;
}

.mn-chart-radial-time {
  position: relative;
}

.mn-chart-radial-time svg {
  display: block;
}

.mn-chart-radial-time svg path {
  stroke-linecap: round;
  stroke-width: 2.2;
  animation: progress 1s ease-out forwards;
}

.mn-chart-radial-time svg path#color-bg {
  stroke-linecap: round;
  stroke-width: 2.0;
  animation: progress 1s ease-out forwards;
}


.mn-chart-radial-time svg text {
  fill: var(--global-colors-ink-dark);
  text-anchor: middle;
  font-family: var(--global-font-regular);
  font-size: 0.6rem;
}

.mn-chart-radial-time svg text#title {
    font-size: 0.3rem;
}

.mn-chart-time-title {
  position: absolute;
  top: 28%;
  font-size: 10px;
  width: 106px;
  font-family: var(--global-font-medium);
  justify-content: center;
  display: inline-flex;
}

.mn-chart-time-val {
  position: absolute;
  top: 40%;
  font-size: 24px;
  width: 106px;
  font-family: var(--global-font-regular);
  justify-content: center;
  display: inline-flex;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

