.mn-tabs__list {
  padding: 0;
  display: flex;
  margin: 0;
}

.mn-tabs__list-item {
  padding: var(--global-spacing-sm) var(--global-spacing-md) 0
    var(--global-spacing-md);
  font-family: var(--global-font-medium);
  font-size: var(--global-font-size-sm);
  overflow: hidden;
  position: relative;
  display: inline-block;
  cursor: var(--global-cursor-pointer);
  padding-top: 16px;
}

.mn-tabs__content {
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-sm);
  padding: var(--global-spacing-md);
}

.mn-tabs__list-item:hover {
  background-color: var(--global-colors-sky-light);
}

.mn-tabs__list-item-underline-inactive {
  padding-bottom: 12px;
  border-bottom: 2px solid transparent;
}

.mn-tabs__list-item-underline {
  padding-bottom: 12px;
  border-bottom: 2px solid var(--global-colors-ink-dark);
}

.mn-tabs__label {
  position: relative;
  z-index: 1;
  user-select: none;
}

.mn-tabs__label-icon {
  margin-left: var(--global-spacing-sm);
  float: right;
  line-height: 18px;
}

/* Ripple */
.mn-tabs__list-item .ripple {
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  background-color: var(--global-colors-sky-sky);
  animation: 600ms ease 1 forwards ripple;
}

@keyframes ripple {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(10);
    opacity: 0.375;
  }
  100% {
    transform: scale(35);
    opacity: 0;
  }
}
