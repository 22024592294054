.driver-activity-table .mn-table {
  margin-bottom: 70px;
}

.drivers-activity-pagination {
  position: fixed;
  bottom: 0;
  z-index: 3;
  /* for Chrome, Edge, Opera */
  min-width: -webkit-fill-available !important;
  max-width: -webkit-fill-available !important;
  /* for Mozilla */
  min-width: -moz-available !important;
  max-width: -moz-available !important;
}

.driver-activity-page-withoutTable {
  background-color: var(--global-colors-sky-lighter);
  width: 100%;
  height: 100%;
}

.driver-activity-page-withoutTable-item {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.driver-activity-page-withoutTable-addActivity {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}

.driver-activity-filterbar-row-splitter {
  display: flex;
  align-items: center;
  font-weight: var(--global-font-semi-bold);
  width: 100%;
  border-bottom: 1px solid var(--global-colors-sky-dark);
}

.drivers-activity-filterbar-dropdown {
  margin-left: 12px;
}

.drivers-activity-filterbar-export {
  width: auto;
  margin-left: auto;
  margin-right: 23px;
}

.drivers-activity-filterbar-export .dropdown-list {
  background-color: var(--global-colors-ui-white);
  position: absolute;
  right: 0px;
  width: max-content;
}

.drivers-activity-filterbar-export
  .dropdown-btn
  .dropdown-list
  .dropdown-elements
  .text {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
}

.drivers-activity-filterbar-export-generate #button {
  width: 85px;
}

.driver-activity-attachments .mn-modal__dialog {
  width: fit-content;
  height: fit-content;
}

.driver-activity-attachments .mn-modal__dialog .mn-modal__dialog-footer {
  padding: 0px 30px 30px 0px;
}

.driver-activity-attachments
  .mn-modal
  .mn-modal__dialog
  .mn-modal__dialog-body {
  max-height: 420px;
  overflow: scroll;
}

.driver-activity-detail-label {
  display: flex;
  align-items: normal;
  justify-content: space-between;
  margin-top: 10px;
}

.driver-activity-detail-label .text-field {
  width: 420px;
}

.driver-activity-detail-attachments {
  display: flex;
  flex-direction: row;
  align-items: normal;
}

.driver-activity-detail-attachments-label {
  height: fit-content;
  padding-left: 5%;
}

.driver-activity-detail-attachments .row {
  display: flex;
  flex-direction: row;
  margin-top: 9px;
  align-items: center;
}

.driver-activity-detail-attachments .attach {
  width: fit-content;
  height: fit-content;
  padding: 0px 16px 0px 10px;
}
.driver-activity-detail-attachments .tag {
  width: fit-content;
  height: fit-content;
}
