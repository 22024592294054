.mn-data-label {
  display: flex;
  align-items: center;
}

.mn-data-label .mn-data-label__icon {
  margin-right: 16px;
}

.mn-data-label .mn-data-label__text .mn-data-label__text-label,
.mn-data-label .mn-data-label__text .mn-data-label__text-data {
  font-family: var(--global-font-regular);
  margin-bottom: 2px;
  display: block;
}

.mn-data-label .mn-data-label__text .mn-data-label__text-label {
  font-size: var(--global-font-size-xxs);
  color: var(--global-colors-ink-light);
}

.mn-data-label .mn-data-label__text .mn-data-label__text-data {
  color: var(--global-colors-ink-dark);
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-sm);
}

.mn-data-label.mn-data-label--small
  .mn-data-label__text
  .mn-data-label__text-data {
  font-size: var(--global-spacing-sm);
}

.mn-data-label__text-label--placeholder,
.mn-data-label__text-data--placeholder {
  background-color: var(--global-colors-sky-dark);
  display: block;
  animation: tada 1.5s infinite;
}

.mn-data-label__text-label--placeholder {
  width: 100px;
  height: 10px;
  margin-bottom: var(--global-spacing-xs);
}

.mn-data-label__text-data--placeholder {
  width: 250px;
  height: 14px;
}

.mn-data-label.mn-data-label--has-graph .mn-data-label__text {
  width: 100%;
}

.mn-data-label.mn-data-label--has-graph
  .mn-data-label__text
  .mn-data-label__text-data {
  display: flex;
  align-items: flex-end;
  width: 100%;
}

.mn-data-label.mn-data-label--has-graph
  .mn-data-label__text
  .mn-data-label__text-data
  progress {
  vertical-align: middle;
  margin-right: var(--global-spacing-lg);
}

.mn-data-label__graph {
  margin-right: var(--global-spacing-xs);
}

@keyframes tada {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.mn-data-label__icon {
  position: relative;
}

.mn-data-label__icon .check {
  position: absolute;
  bottom: -1px;
  left: 7px;
}

.mn-data-label-error:hover,
.mn-data-label__icon .check:hover {
  cursor: var(--global-cursor-pointer);
}

.mn-data-label-error {
  width: auto;
  height: 20px;
  background-color: var(--global-colors-sky-sky);
  border-radius: 3px;
}

.mn-data-label-error > span {
  font-family: var(--global-font-regular);
  font-size: 9px;
  color: var(--global-colors-ink-ink);
  line-height: 12px;
  padding: 4px 7px;
  display: block;
}
