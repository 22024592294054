.mn-action-status-columns {
  display: block;
  cursor: var(--global-cursor-pointer);
}

.mn-action-status-columns:not(:last-child) {
  border-bottom: 1px solid var(--global-colors-sky-sky);
}

.mn-action-status-columns:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}

.mn-action-status-columns-disabled {
  display: block;
}

.mn-action-status-columns-disabled:not(:last-child) {
  border-bottom: 1px solid var(--global-colors-sky-sky);
}

.mn-action-status-columns__inner {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-sm);
  padding-left: 13px;
}

.mn-action-status-columns__sub-label {
  height: 16px;
  margin-top: 2px;
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-xs);
  color: var(--global-colors-ink-light);
}

.mn-action-status-columns__check {
  height: 60px;
  display: flex;
  align-items: center;
}

.mn-action-status-columns__check-left {
  margin-right: 36px;
}

.mn-action-status-columns__check-right {
  margin-right: 22px;
}
