.add-group-modal .mn-modal .mn-modal__dialog {
  min-height: 322px;
  width: 440px;
  border-radius: 4px;
}

.add-group-modal .group-input {
  margin-top: 16px;
  padding-bottom: 4px;
}
