.mn-periodnav {
  background-color: var(--global-colors-ui-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: var(--global-spacing-lg); */
  font-family: var(--global-font-regular);
  width: auto;
  margin-right: -34px;
}

.mn-periodnav-container {
  display: flex;
  align-items: center;
}

.mn-periodnav .mn-periodnav__nav-index {
  font-size: var(--global-font-size-sm);
  margin-right: var(--global-spacing-md);
  color: var(--global-colors-ink-light);
}
