.button-list {
  display: inline-flex;
  align-items: center;
  font-family: var(--global-font-regular);
  justify-content: center;
  font-size: var(--global-font-size-sm);
  background-color: transparent;
  border: 0;
  outline: 0;
  cursor: var(--global-cursor-pointer);
  user-select: none;
}

.button-list > span {
  display: flex;
  align-items: center;
}

.button-list .text {
  margin-right: 10px;
  display: inline-block;
}

.button-list--enabled {
  margin-left: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 150px;
  background-color: #00cfa2;
  padding-bottom: 1px;
}

.button-list--disabled {
  color: var(--global-colors-ui-secondary);
}

.button-list--failure span {
  margin-left: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--global-colors-feedback-danger);
  border-radius: 150px;
  padding-bottom: 1px;
}

.button-list--deleted span {
  margin-left: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #d2d5da;
  border-radius: 150px;
  padding-bottom: 1px;
}

.button-list--empty span {
  margin-left: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 150px;
  border: 1px solid #d2d5da;
  background-color: transparent;
}

.button-list--pending span {
  margin-left: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 150px;
  background-color: var(--global-colors-ui-secondary);
  padding-bottom: 1px;
}

.button-list--update span {
  margin-left: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 150px;
  background-color: #0052bd;
  padding-bottom: 1px;
}
