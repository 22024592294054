.geofence-alert-info__align {
  display: flex;
  width: 100%;
  justify-content: center;
}

.geofence-alert-info {
  height: 54px;
  color: var(--global-colors-ui-white);
  padding: 18px 36px;
  border-radius: 4px;
  background-color: var(--global-colors-ink-dark);
  box-shadow: var(--global-elevation-01);
  font-family: var(--global-font-regular);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--global-font-size-sm);
  margin-bottom: 20px;
  z-index: 1;
  position: absolute;
  bottom: 0;
  white-space: nowrap;
}

.geofence-edit-button,
.geofence-button-close {
  margin-top: 16px;
  margin-right: 16px;
}

.geofence-button-close button {
  background-color: var(--global-colors-ink-ink);
}

.geofence-button-close button:not(.nohover):hover {
  background-color: var(--global-colors-ink-ink);
}

.geofence-draw-button {
  margin-left: 16px;
  margin-top: 16px;
}

.cg-map-functionalities .gm-style .maptype-control {
  position: absolute !important;
  right: 0px !important;
  bottom: 86px !important;
}

.cg-map-functionalities .gm-style .gm-style-iw-c,
.cg-map-functionalities .gm-style .gm-style-iw-c:hover {
  position: absolute;
  z-index: 1;
  font-family: var(--global-font-regular);
  font-size: 12px !important;
  margin: 0px !important;
  width: fit-content;
  max-width: fit-content !important;
  min-width: fit-content !important;
  padding: 4px 8px !important;
  border-radius: 4px;
  color: var(--global-colors-ui-white);
  background-color: var(--global-colors-ink-dark) !important;
  opacity: 0.9;
  left: 0px;
  top: 0px;
}

.cg-map-functionalities .gm-style .gm-style-iw-t {
  bottom: 16px !important;
}

.cg-map-functionalities .gm-style .gm-style-iw-tc {
  opacity: 0.9;
}

.cg-map-functionalities .gm-style .gm-style-iw-tc::after {
  margin-top: 0px;
  width: 22px;
  height: 8px;
  background-color: var(--global-colors-ink-dark);
  opacity: 0.9;
}

.cg-map-functionalities .gm-style .gm-style-iw-d {
  overflow: hidden !important;
  margin: 0px;
  display: flex;
  font-size: 12px;
  justify-content: center;
}

div img[src$="undo_poly.png"] {
  display: none;
}

div[style*="touch-action: none;"][style*="width: 11px;"][style*="height: 11px;"]
  > div {
  background-color: var(--global-colors-ui-secondary) !important;
  border-color: var(--global-colors-ui-white) !important;
  border-width: 2px !important;
  width: 12px !important;
  height: 12px !important;
}
