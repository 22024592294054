.mn-timeSeriesChartCard__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mn-timeSeriesChartCard {
  font-size: var(--global-font-size-sm);
}

.mn-timeSeriesChartCard .fuel-chart,
.mn-timeSeriesChartCard .maintenance-chart {
  margin-left: auto;
  margin-right: auto;
}

.mn-timeSeriesChartCard [class$="-col"] {
  width: 50%;
}
.mn-timeSeriesChartCard [class$="center-col"] {
  width: 100%;
}

.mn-timeSeriesChartCard__container {
  width: 100%;
}

.mn-timeSeriesChartCard__center-col:nth-child(2) {
  margin-top: 20px;
}

.mn-timeSeriesChartCard__data-item {
  display: inline-block;
  width: 50%;
}

.mn-timeSeriesChartCard__data-item > div:first-child {
  padding: var(--global-spacing-md) var(--global-spacing-lg) 0
    var(--global-spacing-lg);
  font-family: var(--global-font-semi-bold);
  font-size: var(--global-font-size-xs);
  margin: 0 0 var(--global-spacing-xs);
}

.mn-timeSeriesChartCard__data-item p {
  font-size: var(--global-font-size-sm);
  padding: 0 var(--global-spacing-lg) var(--global-spacing-md)
    var(--global-spacing-lg);
  color: var(--global-colors-ink-light);
  margin: 0;
}

.apexcharts-xaxistooltip {
  background-color: var(--global-colors-ui-white) !important;
  border: white !important;
}

.apexcharts-xaxistooltip:before,
.apexcharts-xaxistooltip-bottom:after {
  border-bottom-color: transparent !important;
}

.mn-timeSeriesChartCard__single-value {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 37px;
}

.spaceAround {
  justify-content: space-around;
}

.spaceBetween {
  justify-content: space-between;
}

.mn-timeSeriesChartCard__single-value.spaceAround > div {
  margin-right: "48px";
}

.mn-timeSeriesChartCard__single-value-title {
  font-size: 14px;
  font-family: var(--global-font-medium);
}

.mn-timeSeriesChartCard__single-value-data {
  height: 99px;
  width: 238px;
  border-radius: 8px;
  background-color: #f7f7f8;
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mn-timeSeriesChartCard__inner .mn-graph > div {
  display: block !important;
}

.icongasstation {
  margin-left: -35px;
}

.iconperiodicalinspection {
  margin-left: -35px;
}

.pricelabel-gas-maintenance {
  font-size: var(--global-font-size-xl);
  font-family: var(--global-font-semi-bold);
}

.datelabel-gas-maintenance {
  font-size: var(--global-font-size-xs);
}
