.tachograph-file-tabs .title {
  font-size: var(--global-font-size-xxl);
  font-weight: normal;
  padding: 27px 1.9rem 11px;
  margin-left: var(--global-spacing-md);
  margin-bottom: 0;
}

.tachograph-file-tabs .mn-tabs__content {
  padding: 0px;
}

.tachograph-file-tabs .mn-tabs__list {
  padding-left: 1.9rem;
  border-bottom: 1px solid var(--global-colors-sky-dark);
  height: 52px;
}

.tachograph-file-tabs .details-tachograph-file-wrapper {
  padding: 4px 0px 16px 32px;
}

.tachograph-file-table-container .tachograph-file-container .mn-table thead th {
  cursor: var(--global-cursor-default);
}

.tachograph-file-table-container
  .tachograph-file-container
  .mn-table
  thead
  th:nth-child(8)
  > span {
  display: block;
  text-align: center;
}

.tachograph-file-table-container
  .tachograph-file-container
  .mn-table
  thead
  th:last-child
  > span {
  display: block;
  text-align: center;
}

.tachograph-file-table-container
  .tachograph-file-container
  .mn-table
  tbody
  tr
  td:last-child {
  display: table-cell;
  padding: 14px var(--global-spacing-lg);
  float: unset;
}

.tachograph-file-table-container
  .tachograph-file-container
  .mn-table
  tbody
  tr
  td
  .svg {
  padding: 0;
  float: unset;
}

.tachograph-file-table-container
  .tachograph-file-container
  .mn-table
  tbody
  tr
  td
  .svg
  span {
  display: block;
}

.tachograph-file-table-container .page-count {
  position: fixed;
  bottom: 0;
  z-index: 3;
  /* for Chrome, Edge, Opera */
  min-width: -webkit-fill-available !important;
  max-width: -webkit-fill-available !important;
  /* for Mozilla */
  min-width: -moz-available !important;
  max-width: -moz-available !important;
}

.tachograph-file-rows-container {
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: calc(100% - (365px));
  position: absolute;
  width: 100%;
}

.details-tachograph-file-wrapper {
  padding: 16px 16px 16px 32px;
  font-size: var(--global-font-size-sm);
  line-height: 18px;
  text-align: left;
  height: 100px;
  border-radius: 4px;
  border-bottom: var(--global-colors-sky-sky-outline);
}

.details-tachograph-file-wrapper .details-tachograph-file-title {
  margin: 16px 0;
  font-family: var(--global-font-medium);
  font-size: var(--global-font-size-sm);
}

.details-tachograph-file-wrapper .details-tachograph-file-container {
  height: 34px;
  align-items: center;
  width: 100%;
  display: flex;
}

.details-tachograph-file-wrapper .details-tachograph-file-icons {
  display: flex;
  padding-left: 8px;
  float: left;
}

.details-tachograph-file-wrapper .details-tachograph-file-item {
  margin-right: 20px;
  max-width: 16%;
  min-width: 120px;
  width: 10vw;
}

.details-tachograph-file-wrapper .details-tachograph-file-loader {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid var(--global-colors-ink-light);
  border-top-color: transparent;
  animation: spinner 0.6s linear infinite;
  margin-right: 15px;
  margin-left: 70px;
  margin-top: -17px;
}

.tachograph-file-table-container
  .tachograph-file-container
  .mn-table
  tbody
  td
  button {
  border: unset;
  background-color: unset;
  width: 100%;
  height: 100%;
}

.tachograph-file-table-container
  .tachograph-file-container
  .mn-table
  tbody
  td
  .mn-data-label
  .mn-data-label__icon {
  margin-right: 12px;
}

.tachograph-file-tabs .file-not-found {
  background-color: var(--global-colors-sky-lighter);
  width: 100%;
  height: calc(100vh - 360px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
