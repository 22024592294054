.add-maintenance-container
  .mn-modal
  .mn-modal__dialog
  .add-maintenance
  .mn-modal__dialog-body {
  padding: 32px 2px 0px 0px;
  width: 600px;
  min-height: 303px;
}

.add-maintenance-container
  .mn-modal
  .mn-modal__dialog
  .set-maintenance
  .mn-modal__dialog-body {
  margin-top: 32px;
  padding: 0;
  min-height: 424px;
  max-height: 400px;
  overflow-y: scroll;
  width: 600px;
}

.add-maintenance .mn-label-text__label {
  margin-bottom: 0;
}

.add-maintenance .mn-label-text__text {
  color: var(--global-colors-ink-ink);
}

.add-maintenance .mn-modal__dialog-body .mn-label-text__text {
  margin-left: 8px;
  font-family: var(--global-font-medium);
  font-size: var(--global-font-size-xs);
}

.add-maintenance .mn-modal__dialog-body .central-forms {
  display: flex;
  margin: 24px 32px;
}

.add-maintenance .mn-modal__dialog-body .central-forms .dropdown {
  margin-left: 16px;
  min-width: 172px;
}

.add-maintenance .mn-modal__dialog-body .central-forms .search-field {
  width: 158px;
}

.add-maintenance .mn-modal__dialog-body .checkboxes-list {
  overflow-y: scroll;
  height: 216px;
  max-height: 216px;
  display: flex;
  flex-wrap: wrap;
  padding-left: 32px;
}

.add-maintenance .mn-modal__dialog-body .checkboxes-list div {
  width: 220px;
  height: 32px;
  display: flex;
  align-items: center;
}

.add-maintenance .mn-modal__dialog-body .checkboxes-list div:nth-child(even) {
  margin-left: 46px;
}

.add-maintenance .mn-modal__dialog-body .checkboxes-list label p {
  margin-left: 4px !important;
  width: auto;
  color: var(--global-colors-ink-light);
  font-size: var(--global-font-size-sm);
}

.add-maintenance .mn-modal__dialog-body .checkboxes-list .checkbox-label {
  width: auto;
}

.add-maintenance .splitter,
.set-maintenance .splitter {
  height: 4px;
  width: 100%;
  background-color: var(--global-colors-ink-lighter);
  position: absolute;
  left: 0;
}

.add-maintenance .splitter > div,
.set-maintenance .splitter > div {
  height: 4px;
  width: 50%;
  background-color: var(--global-colors-ui-primary);
  position: absolute;
  left: 0;
  z-index: 999999;
}

.add-maintenance .splitter-full,
.set-maintenance .splitter-full {
  height: 4px;
  width: 100%;
  background-color: var(--global-colors-ui-primary);
  position: absolute;
  left: 0;
  z-index: 999999;
}

.split-line-add-maintenance {
  height: 1px;
  width: auto;
  background-color: var(--global-colors-sky-sky);
}

.add-maintenance-container .mn-modal__dialog .mn-modal__dialog-footer-back {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
}
