.mn-mw-card-vehicle {
  background-color: var(--global-colors-ui-white);
  cursor: var(--global-cursor-grab);
  float: left;
  max-width: 222px;
  margin: 0 auto;
  height: 150px;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}

.mn-mw-card-vehicle .status-badge {
  position: absolute;
  left: var(--global-spacing-xs);
  bottom: var(--global-spacing-xs);
  background-color: var(--global-colors-ui-white);
  height: 26px;
}

.mn-mw-card-vehicle--dragging {
  max-width: 222px;
  max-height: 150px;
  width: 222px;
  height: 150px;
  margin-right: var(--global-spacing-md);
  background: none;
  border: 1px dashed var(--global-colors-sky-dark);
  cursor: var(--global-cursor-grabbing);
}

.mn-mw-card-vehicle--dragging > * {
  display: none;
}

.marker-locator {
  position: absolute;
  left: 100px;
  bottom: 65px;
  height: 25px;
  width: 25px;
}
