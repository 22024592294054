.edit-categories .mn-modal .mn-modal__dialog {
  max-width: 600px;
  max-height: 530px;
}

.edit-categories .mn-modal .mn-modal__dialog .mn-modal__dialog-body {
  padding: 31px 32px 0px 32px;
  width: 100%;
  height: 340px;
}

/* Table */
.edit-categories-table {
  width: 100%;
  height: 97%;
}

.edit-categories-table .mn-table {
  width: 100%;
}

.edit-categories-table .mn-table thead {
  display: table;
  width: 100%;
  table-layout: fixed;
  background-color: var(--global-colors-ui-white);
}

.edit-categories-table .mn-table tbody {
  display: block;
  max-height: 240px;
  overflow: auto;
  width: 100%;
}

.edit-categories-table .mn-table tbody td {
  padding: 14px var(--global-spacing-lg);
}

.edit-categories-table .mn-table tbody tr td:last-child {
  padding: 14px 17px 14px 32px;
}

.edit-categories .mn-table thead th:last-child {
  width: 133px;
}

.edit-categories-table .mn-table .mn-label-text .mn-label-text__label {
  font-family: var(--global-font-regular);
}

.edit-categories-table button + button {
  margin-left: 16px;
}

/* Icon customization */
.edit-categories .mn-table thead th#id > span {
  justify-content: stretch;
}

.edit-categories .mn-table thead th#id .ascending > span > span {
  margin-left: 10px;
  transform: rotate(180deg);
}

.edit-categories .mn-table thead th#id > span > span {
  margin-left: 10px;
}

/* Columns table body resize */
.edit-categories-table .mn-table tbody td:nth-child(1) {
  width: 205px;
}

.edit-categories-table .mn-table tbody td:nth-child(2) {
  width: 197px;
}

.edit-categories-table .mn-table tbody td:nth-child(3) {
  width: 129px;
}

/* Rows colors */
.edit-categories-table .mn-table tbody tr:nth-child(odd) {
  background-color: var(--global-colors-sky-lighter);
}

.edit-categories-table .mn-table tbody tr:nth-child(even) {
  background-color: var(--global-colors-ui-white);
}

/* Custom */
.edit-categories-table .mn-data-label .mn-data-label__icon {
  margin: 0;
}

.edit-categories-table .mn-table .mn-label-text .mn-label-text__text {
  display: none;
}

.deletion-false {
  margin-left: 16px;
  padding: 0;
  position: static;
  display: inline;
}

.mn-table tbody tr td:last-child .deletion-false .svg {
  cursor: var(--global-cursor-not-allowed) !important;
}
