.mn-mini-card {
  font-family: var(--global-font-regular);
  width: 150px;
  border-radius: 4px;
  padding: 12px;
  height: 138px;
  display: inline-flex;
  flex-direction: column;
  background-color: var(--global-colors-sky-lighter);
  margin-bottom: 8px;
}

.mn-mini-card:not(:last-child) {
  margin-right: 16px;
}

.mn-mini-card:last-child {
  margin-top: 7px;
}

.mn-mini-card .mn-mini-card__icon {
  margin-bottom: var(--global-spacing-xl);
}

.mn-mini-card .mn-mini-card__pretitle {
  margin: 0;
  color: var(--global-colors-ink-light);
  margin-bottom: 4px;
  font-size: var(--global-font-size-xs);
}

.mn-mini-card .mn-mini-card__title {
  margin: 0;
  font-family: var(--global-font-medium);
  font-size: var(--global-font-size-sm);
}

.mn-mini-card .mn-mini-card__pretitle,
.mn-mini-card .mn-mini-card__icon + .mn-mini-card__title {
  margin-top: auto;
}

.mn-mini-card__icon {
  position: relative;
}

.mn-mini-card__icon .check {
  position: absolute;
  bottom: 0px;
  left: 16px;
}
