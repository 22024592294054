.notification-menu {
  width: 240px;
  border: 1px solid var(--global-colors-sky-sky);
  background-color: var(--global-colors-ui-white);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  position: relative;
  font-family: var(--global-font-regular);
  z-index: 99;
}

.notification-menu::before,
.notification-menu::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid var(--global-colors-sky-sky);
  display: block;
  position: absolute;
  top: -5.3px;
  right: 10px;
}

.notification-menu::after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid var(--global-colors-ui-white);
  top: -4.2px;
  right: 10.9px;
}

.notification-menu .notification-list {
  padding: 0;
  overflow: hidden;
  overflow-y: auto;
  max-height: 322px;
}

.notification-link {
  margin: 8px auto;
  display: block;
  text-align: center;
  color: var(--global-colors-ui-secondary);
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-sm);
}

.mn-notification-category {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: var(--global-font-semi-bold);
  font-size: var(--global-font-size-xs);
  padding: var(--global-spacing-xs) var(--global-spacing-sm);
  border-bottom: 1px solid var(--global-colors-sky-sky);
  width: 100%;
  cursor: var(--global-cursor-pointer);
}

.mn-notification-category-items {
  padding: 16px 4px 4px 4px;
  height: 100%;
  font-family: var(--global-font-semi-bold);
  font-size: var(--global-font-size-xs);
  transition: all 0.2s ease-in-out;
  background-color: var(--global-colors-ui-white);
  display: flex;
  align-items: center;
}

.mn-notification-category-items .svg {
  margin: auto var(--global-spacing-sm);
}
