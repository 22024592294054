.mc-icon-title {
  width: 295px;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: var(--global-font-medium);
  color: var(--global-colors-ink-light);
}

.mc-icon-title__icon {
  width: 138px;
  height: 138px;
  background-color: var(--global-colors-sky-sky);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 150px;
  margin: 0 auto var(--global-spacing-lg);
  color: var(--global-colors-ink-light);
}

.mc-icon-title__title,
.mc-icon-title__text {
  font-size: var(--global-font-size-sm);
}

.mc-icon-title__title {
  font-family: var(--global-font-medium);
  margin: 0 0 var(--global-spacing-md);
  color: var(--global-colors-ink-dark);
}

.mc-icon-title__text {
  margin: 0;
  line-height: 1.5;
  font-family: var(--global-font-regular);
}

.mc-icon-title--absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
