.admin-user-subtopbar {
  height: 82px;
  width: 100%;
  background-color: var(--global-colors-ui-white);
  border-bottom: 1px solid #d2d5da;
  padding: 16px;
  display: flex;
  align-items: center;
}

.admin-user-subsection-name {
  color: var(--global-colors-ink-dark);
  font-family: var(--global-font-regular);
  font-size: 24px;
}

.admin-user-buttons {
  margin-left: auto;
}

.admin-user-profile-icon {
  display: flex;
  align-items: center;
}

.admin-user-profile-icon .admin-user-profile-text {
  margin-left: 16px;
}

.admin-user-info-form {
  margin: 16px 16px;
  box-sizing: border-box;
  padding-bottom: 60px;
  background-color: var(--global-colors-ui-white);
  border: 1px solid #e4e6e9;
  border-radius: 4px;
}

.admin-user-forms-title {
  font-family: var(--global-font-medium);
  font-size: var(--global-font-size-sm);
  width: 153px;
  height: 18px;
  margin-top: 36px;
  margin-left: 36px;
}

.admin-user-page-container {
  position: relative;
  top: 0;
  background-color: var(--global-colors-sky-lighter);
  width: auto;
  height: 100%;
  overflow-y: scroll;
}

.admin-user-half-form {
  min-width: 50%;
  display: inline-block;
  max-width: 50%;
}

.admin-user-left-forms-label {
  font-family: var(--global-font-semi-bold);
  font-size: var(--global-font-size-xs);
  color: var(--global-colors-ink-light);
  width: auto;
  height: 42px;
  float: left;
  display: flex;
  align-items: center;
}

.admin-user-left-form-item {
  width: 100%;
  height: 42px;
  float: left;
}

.admin-user-right-forms-label {
  font-family: var(--global-font-semi-bold);
  font-size: var(--global-font-size-xs);
  color: var(--global-colors-ink-light);
  height: 42px;
  float: left;
  margin-left: 73px;
  display: flex;
  align-items: center;
}

.admin-user-right-form-item {
  width: 100%;
  height: 42px;
  float: right;
  margin-left: unset;
}

.admin-user-left-form-phone {
  width: 160px;
  height: 42px;
  float: left;
}

.admin-user-drpdwn-small > :last-child {
  width: 100%;
}

.admin-user-right-form-phone {
  width: calc(100% - (292px));
  height: 42px;
  display: inline-block;
  margin-left: 16px;
}

.admin-user-forms-container {
  height: 42px;
  margin-top: 24px;
  margin-left: 36px;
  margin-right: 77px;
  vertical-align: middle;
}

.admin-user-forms {
  width: calc(100% - (85px + 111px));
  height: 42px;
  float: right;
}

.admin-user-forms-function {
  display: flex;
  flex-direction: row;
  height: auto;
}

.admin-user-forms-function .form-function {
  width: 100%;
}

.admin-user-forms-function .admin-user-forms {
  height: auto;
  float: unset;
  margin-right: 0;
  margin-left: 146px;
  width: calc(100% - 85px - 46px);
}

.admin-user-forms-function .admin-user-forms .admin-user-user {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: auto;
}

.admin-user-forms-function
  .admin-user-forms
  .admin-user-user
  .admin-user-onblock {
  display: inline-flex;
  align-items: center;
}

.admin-user-forms-function
  .admin-user-forms
  .admin-user-user
  .admin-user-onblock:nth-child(2) {
  margin-top: 16px;
}

.admin-user-forms-function
  .admin-user-forms
  .admin-user-user
  .admin-user-onblock
  .mn-label-text__text.mn-label-text__text-function {
  white-space: nowrap;
  font-family: var(--global-font-semi-bold);
  font-size: var(--global-font-size-xs);
}

.admin-user-forms-function
  .admin-user-forms
  .admin-user-user
  .admin-user-onblock
  .admin-user-user-fuction.admin-user-user-fuction-first {
  margin-left: 30px;
}

.admin-user-forms-function
  .admin-user-forms
  .admin-user-user
  .admin-user-onblock
  .admin-user-user-fuction.admin-user-user-fuction-second {
  margin-left: 76px;
}

.admin-user-forms-function
  .admin-user-forms
  .admin-user-user
  .admin-user-onblock
  .admin-user-user-fuction.admin-user-user-fuction-first,
.admin-user-forms-function
  .admin-user-forms
  .admin-user-user
  .admin-user-onblock
  .admin-user-user-fuction.admin-user-user-fuction-second {
  display: flex;
  flex-wrap: wrap;
}

.admin-user-forms-function
  .admin-user-forms
  .admin-user-user
  .admin-user-onblock
  .admin-user-user-fuction.admin-user-user-fuction-first
  span,
.admin-user-forms-function
  .admin-user-forms
  .admin-user-user
  .admin-user-onblock
  .admin-user-user-fuction.admin-user-user-fuction-second
  span {
  margin-bottom: 6px;
  margin-left: -1px;
}

.admin-user-onblock .admin-user-user-fuction span:not(:last-of-type) {
  margin-right: 10px;
}

.admin-user-left-form-item > .dropdown {
  width: 100%;
}

.admin-user-left-form-text-field {
  width: calc(100% - (116px));
  height: 42px;
  display: inline-block;
}

.admin-user-only-view {
  pointer-events: none;
}

.admin-user-only-view .selected-value input,
.admin-user-only-view input {
  border: 1px solid var(--global-colors-sky-dark);
  color: var(--global-colors-ink-light);
}

.admin-user-validate-button {
  display: inline-flex;
  float: right;
}

/* #region constraint for edit user page */
.admin-user-info-form .constraint-container {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 223px;
}

.admin-user-info-form .constraint-container .constraint-title {
  color: var(--global-colors-ink-light);
  font-size: var(--global-font-size-xs);
  margin-bottom: 6px;
  margin-left: 25px;
  font-family: var(--global-font-medium);
}

.admin-user-info-form .constraint-container .main-check {
  display: flex;
  align-items: center;
  margin-left: 9px;
}

.admin-user-info-form .constraint-container .main-check-right {
  margin-top: -73px;
  position: absolute;
  margin-left: 515px;
}

.admin-user-info-form .constraint-container .main-check .hide-constraint-check {
  display: none;
}

.admin-user-info-form
  .constraint-container
  .main-check
  .hide-constraint-check
  ~ div {
  margin-left: 15px;
}

.admin-user-info-form
  .constraint-container
  .main-check
  .constraint-check
  ~ div {
  margin-left: 6px;
}

.admin-user-info-form .constraint-container .main-check .error-constraint {
  font-size: var(--global-font-size-xs);
  color: var(--global-colors-feedback-danger);
}

.admin-user-info-form .constraint-container .main-check .default-constraint {
  font-size: var(--global-font-size-xs);
  color: var(--global-colors-ui-primary);
}

.admin-user-info-form .constraint-container .main-check .success-constraint {
  font-size: var(--global-font-size-xs);
  color: var(--global-colors-feedback-success);
}
/* #endregion constraint for edit user page */
