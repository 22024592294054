.mn-vehicle-box {
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-xs);
  padding: var(--global-spacing-md);
  position: relative;
  overflow: hidden;
  max-width: 302px;
  cursor: var(--global-cursor-pointer);
}

.mn-vehicle-box__content {
  display: flex;
  align-items: center;
}

.mn-vehicle-box:hover {
  background-color: var(--global-colors-sky-light);
}

.mn-vehicle-box--active {
  border-right: 4px solid var(--global-colors-ui-primary);
  background-color: var(--global-colors-sky-lighter);
}

.mn-vehicle-box__icon-detail,
.mn-vehicle-box__icon-status {
  position: relative;
  z-index: 2;
}

.mn-vehicle-box__content {
  position: relative;
  z-index: 2;
  width: 100% !important;
}

.mn-vehicle-box__detail {
  width: 100% !important;
}

.mn-vehicle-box__icon-status {
  margin-right: var(--global-spacing-sm);
}

.mn-vehicle-box__icon {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 150px;
  overflow: hidden;
  background-color: var(--global-colors-ui-white);
  border: 1px solid var(--global-colors-sky-sky);
}

.mn-vehicle-box__icon > svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--global-colors-ui-white);
}

.mn-vehicle-box__icon-status .signal-status {
  position: absolute;
  bottom: 1px;
  right: 1px;
}

.mn-vehicle-box__id {
  font-family: var(--global-font-semi-bold);
}

.mn-vehicle-box__alarm {
  display: inline-flex;
  padding-left: 12px;
}

.mn-vehicle-box__license-plate {
  font-family: var(--global-font-semi-bold);
  display: inline-flex;
  align-items: center;
}

.icon-snow {
  margin-left: var(--global-spacing-xxs);
  bottom: 2px;
  display: inline-flex;
  align-items: center;
  background: none;
  padding: 0;
  border: none;
  position: relative;
}

.mn-vehicle-box__upper-row {
  font-family: var(--global-font-semi-bold);
  color: var(--global-colors-ink-dark);
  margin-bottom: var(--global-spacing-xxs);
  white-space: nowrap;
  width: 100%;
}

.mn-vehicle-box__upper-row > span:first-child::after {
  content: "\2022";
  display: inline-block;
  margin: 0 var(--global-spacing-xxs);
  color: var(--global-colors-ink-lighter);
}

.mn-vehicle-box__center-row {
  margin: auto 0;
}

.mn-vehicle-box__center-row > span:first-child::after {
  content: "\2022";
  display: inline-block;
  margin: 0 var(--global-spacing-xxs);
}

.mn-vehicle-box__lower-row {
  color: var(--global-colors-ink-light);
  display: flex;
  align-items: baseline;
}

.mn-vehicle-box__lower-row .mn-vehicle-box__city,
.mn-vehicle-box__lower-row span {
  max-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mn-vehicle-box__lower-row .svg {
  margin-top: 2px;
  margin-right: 4px;
}

.mn-vehicle-box__lower-row .divider {
  margin-left: 10px;
  margin-right: 10px;
}

.mn-vehicle-box__driver-name {
  display: inline-flex;
  align-items: center;
  width: 91px;
}

.mn-vehicle-box__driver-name-label {
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.mn-vehicle-box__distance,
.mn-vehicle-box__time {
  display: inline-flex;
  white-space: pre;
  float: left;
  margin-left: 12px;
  color: var(--global-colors-ink-light);
  margin-bottom: var(--global-spacing-xxs);
}

/* Ripple */
.mn-vehicle-box .ripple {
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  background-color: #e3e5e8;
  animation: 600ms ease 1 forwards ripple;
}

.mn-vehicle-box__lastUpdate {
  font-size: var(--global-font-size-xxs) !important;
  font-family: var(--global-font-regular) !important;
  color: var(--global-colors-ink-light) !important;
  float: right;
}

@keyframes ripple {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(10);
    opacity: 0.375;
  }
  100% {
    transform: scale(35);
    opacity: 0;
  }
}
