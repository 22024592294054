.chats-list-container {
  overflow-y: auto;
}

.chat-list-no-value {
  margin-left: 18px;
  margin-top: 14px;
  display: flex;
  font-size: var(--global-font-size-xs);
  font-family: var(--global-font-semi-bold);
}
