.gps-report-tabs .mn-tabs__content {
  padding-right: 0px;
  padding-left: 0px;
}

.gps-report-tabs .mn-tabs__list {
  padding-left: 2rem;
  border-bottom: 1px solid var(--global-colors-sky-dark);
  height: 52px;
}

.gps-report-tabs .mn-tabs__list-item {
  overflow: unset;
  padding-top: 16px;
}

.gps-report-tabs .details-report-wrapper {
  padding: 4px 0px 16px 32px;
}

.gps-report-tabs .mn-table thead th {
  cursor: var(--global-cursor-default);
}

.gpsr-table-container .page-count {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.gpsr-rows-container {
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: calc(100% - (365px));
  position: absolute;
  width: 100%;
}

.details-gpsDataReport-wrapper {
  padding: 16px 16px 16px 32px;
  font-size: var(--global-font-size-sm);
  line-height: 18px;
  text-align: left;
  height: 100px;
  border-radius: 4px;
  border-bottom: var(--global-colors-sky-sky-outline);
}

.details-gpsDataReport-wrapper .details-report-title {
  margin-bottom: 16px;
  font-family: var(--global-font-medium);
  font-size: var(--global-font-size-sm);
}

.details-gpsDataReport-wrapper .details-report-container {
  height: 34px;
  align-items: center;
  width: 100%;
  display: flex;
}

.details-gpsDataReport-wrapper .details-report-icons {
  display: flex;
  padding-left: 8px;
  float: left;
}

.details-gpsDataReport-wrapper .details-report-item {
  margin-right: 20px;
  max-width: 16%;
  min-width: 120px;
  width: 10vw;
}

.details-gpsDataReport-wrapper .details-report-item:last-child {
  margin: 0;
}

.details-gpsDataReport-wrapper .details-report-button {
  display: flex;
  height: 32px;
  float: right;
  margin-left: auto;
}

.details-gpsDataReport-wrapper .details-report-button button {
  min-width: 146px;
}

.details-gpsDataReport-wrapper .details-report-loader {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid var(--global-colors-ink-light);
  border-top-color: transparent;
  animation: spinner 0.6s linear infinite;
  margin-right: 15px;
  margin-left: 70px;
  margin-top: -17px;
}
