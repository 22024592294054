.textfield-container-tags {
  min-height: 42px;
  display: flex;
  width: 100%;
  border: 1px solid var(--global-colors-sky-dark);
  border-radius: 4px;
  padding: 8px 14px 2px 14px;
  transition: all 0.3s ease;
  font-size: var(--global-font-size-sm);
}

.textfield-tags-time-slot {
  display: flex;
  align-items: center;
  margin-right: 6px;
  height: 24px;
  margin-bottom: 6px;
  cursor: var(--global-cursor-pointer);
}
