.count-notification {
  position: absolute;
  padding: 2px;
  top: -2px;
  display: flex;
  align-items: center;
  height: 12px;
  min-width: 12px;
  border-radius: 2px;
  background-color: var(--global-colors-feedback-danger);
  font-family: var(--global-font-semi-bold);
  color: var(--global-colors-ui-white);
  font-size: 10px;
  cursor: var(--global-cursor-default);
  text-align: center;
  left: 20px;
  justify-content: center;
  z-index: 99;
}
