.analogic-report-sub-filterbar {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.analogic-report-summary-legend-row {
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  padding-bottom: 10px;
  width: 100%;
  align-items: center;
}

.analogic-report-day-selector-section {
  display: flex;
  flex-direction: row;
  width: fit-content;
  align-items: center;
  gap: 10px;
}

.analogic-report-legend-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
}

.analogic-report-legend-element {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  justify-content: left;
  gap: 10px;
}

.analogic-report-legend-element-firstsect {
}

.analogic-report-legend-element-secondsect {
  color: #687484;
  font-family: var(--global-font-semi-bold);
  font-size: 12px;
}

.analogic-report-details-summary-element {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
}

.analogic-report-details-summary-element-body {
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: left;
}

.analogic-report-sensor-data-modal .mn-modal .mn-modal__dialog {
  width: fit-content;
  max-width: none;
}

.analogic-report-details .mn-table th:nth-child(10) {
  width: 28vh;
  white-space: nowrap;
}

.analogic-report-details {
  height: 100%;
  margin-bottom: 100px;
}
