.schedule-note {
  font-family: var(--global-font-medium);
  max-width: 653px;
  height: 76px;
  padding: 17px 32px;
  user-select: none;
  display: flex;
  align-items: center;
  cursor: var(--global-cursor-pointer);
}

.schedule-note:hover {
  background-color: var(--global-colors-sky-lighter);
}

.schedule-note-simple {
  font-family: var(--global-font-medium);
  max-width: 653px;
  height: 76px;
  padding: 17px 32px;
  user-select: none;
  display: flex;
  align-items: center;
  cursor: var(--global-cursor-pointer);
}

.schedule-note-icon {
  margin: 0;
  float: right;
  display: flex;
  align-items: center;
  cursor: var(--global-cursor-pointer);
}

.schedule-note-button {
  background: transparent;
  padding: 0;
  border: 0;
  width: 100%;
  display: flex;
  align-items: center;
}

.schedule-note-circle span {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  border: 1px solid var(--global-colors-sky-sky);
  background-color: var(--global-colors-ui-white);
  float: left;
  display: flex;
  justify-content: center;
}

.schedule-note-label {
  margin-left: 26px;
  margin-right: auto;
}

.schedule-note-pretitle {
  color: var(--global-colors-ink-light);
  font-size: var(--global-font-size-xs);
  font-family: var(--global-font-regular);
  margin-bottom: 6px;
  float: left;
}

.schedule-note-title {
  color: var(--global-colors-ink-dark);
  font-size: var(--global-font-size-md);
  font-family: var(--global-font-regular);
  float: left;
  text-overflow: ellipsis;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  max-width: 400px;
}

.schedule-note-reset {
  height: 16px;
  width: 34px;
  font-size: var(--global-font-size-sm);
  font-family: var(--global-font-regular);
  margin-right: 16px;
  float: left;
  text-decoration: none;
  -webkit-text-decoration-color: var(--global-colors-ui-secondary);
  text-decoration-color: var(--global-colors-ui-secondary);
  color: var(--global-colors-ui-secondary);
}

.schedule-note-save-button {
  margin-right: 16px;
  height: 32px;
  width: 57px;
  float: left;
}

.schedule-note-body {
  max-width: 653px;
  height: 116px;
  padding: 0 32px 32px 100px;
}

.schedule-note-selection {
  color: var(--global-colors-ink-light);
  font-size: var(--global-font-size-xs);
  font-family: var(--global-font-semi-bold);
  float: left;
  height: 36px;
  align-items: center;
  display: flex;
}

.schedule-note-form {
  float: right;
  width: 310px;
  margin-right: 14px;
}

.schedule-note-form .text-field.text-field--has-icon input {
  width: 160px;
}

/* Chrome, Safari, Edge, Opera */
.schedule-note-form input::-webkit-outer-spin-button,
.schedule-note-form input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.schedule-note-form input[type="number"] {
  -moz-appearance: textfield;
}
