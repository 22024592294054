.geofence-dropdown {
  position: relative;
  cursor: var(--global-cursor-pointer);
  width: auto;
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-sm);
  user-select: none;
}

.geofence-dropdown .dropdown-button {
  border: 1px solid var(--global-colors-sky-dark);
  border-radius: 4px;
  cursor: var(--global-cursor-pointer);
  outline: none;
  transition: all 200ms ease;
  width: 100%;
  height: 32px;
  background-color: transparent;
  font-family: var(--global-font-regular);
  color: var(--global-colors-ink-light);
  margin: 0;
}

.geofence-dropdown .dropdown-button.disabled .btn-label {
  background-color: var(--global-colors-sky-dark);
  opacity: 0.5;
  cursor: var(--global-cursor-not-allowed);
}

.geofence-dropdown .dropdown-button .btn-label {
  padding-left: 16px;
  padding-right: 32px;
  font-family: var(--global-font-regular);
  font-size: 14px;
  text-overflow: ellipsis;
  outline: none;
  border: none;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  cursor: var(--global-cursor-pointer);
}

.geofence-dropdown .dropdown-button.active {
  border: 1px solid var(--global-colors-ui-primary--focus);
  cursor: var(--global-cursor-default);
  color: var(--global-colors-ui-black);
  text-overflow: ellipsis;
}

.geofence-dropdown .dropdown-button.selected {
  border: 1px solid var(--global-colors-ink-dark);
  cursor: var(--global-cursor-default);
  color: var(--global-colors-ui-black);
}

.geofence-dropdown .dropdown-button:focus,
.geofence-dropdown .drop-search input:focus {
  border: 1px solid var(--global-colors-ui-primary--focus);
}

.geofence-dropdown .dropdown-content {
  border: 1px solid var(--global-colors-sky-dark);
  border-radius: 4px;
  position: absolute;
  margin-top: 4px;
  width: 100%;
  padding: 16px 0px 0px 0px;
  background-color: var(--global-colors-ui-white);
  cursor: var(--global-cursor-pointer);
  outline: none;
  color: var(--global-colors-ink-dark);
  font-family: var(--global-font-regular);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
  z-index: 100;
}

.geofence-dropdown .drop-search {
  margin-bottom: 8px;
  padding-right: 16px;
  padding-left: 16px;
  position: relative;
}

.geofence-dropdown .drop-search input {
  height: 32px;
  width: 100%;
  padding: 16px 34px 16px 16px;
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-sm);
  border: 1px solid var(--global-colors-sky-dark);
  border-radius: 4px;
  outline: 0;
}

.geofence-dropdown .drop-search input::placeholder {
  color: var(--global-colors-ink-light);
}

.geofence-dropdown .drop-search .svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
}

.geofence-dropdown .dropdown-content .drop-item {
  width: 100%;
  padding: 12px 16px 12px 16px;
  align-items: center;
  display: flex;
}

.geofence-dropdown .dropdown-content .drop-item:hover,
.drop-item.active {
  background: var(--global-colors-sky-lighter);
}

.geofence-dropdown .dropdown-content .drop-item .color {
  width: 14px;
  height: 14px;
  border-radius: 150px;
  margin-right: var(--global-spacing-md);
  display: inline-block;
}

.geofence-dropdown .dropdown-content .drop-item .option-label {
  display: inline-block;
}

.geofence-dropdown .arrow {
  display: block;
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--global-colors-ink-light);
  transition: all 0.2s ease-in-out;
}

.geofence-dropdown .arrow.open {
  transform: translateY(-50%) rotate(180deg);
}

.geofence-dropdown .dropdown-content .drop-item .sub-arrow {
  display: block;
  position: absolute;
  right: 16px;
  transition: all 0.2s ease-in-out;
}

.geofence-dropdown .dropdown-content .drop-item .sub-arrow.open {
  transform: rotate(180deg);
}

.geofence-dropdown .option-checklist {
  background-color: var(--global-colors-sky-lighter);
}

.geofence-dropdown .option-checkbox {
  width: 100%;
  padding: 12px 16px 12px 14px;
  align-items: center;
  display: flex;
}
