.customize-columns .mn-modal .mn-modal__dialog {
  max-width: none;
  max-height: 640px;
  width: auto;
}

.mn-modal .mn-modal__dialog .customize-columns-body .mn-modal__dialog-body {
  display: flex;
  padding: 32px 32px 0px 24px;
  height: 478px;
}

/* Right Column */
.columns-available {
  height: 446px;
  float: left;
  border-right: 1px solid var(--global-colors-sky-sky);
}

.available-title {
  display: inline-block;
  font-family: var(--global-font-semi-bold);
  font-size: var(--global-font-size-xs);
  color: var(--global-colors-ink-light);
  width: auto;
  height: 18px;
  margin-bottom: 8px;
  margin-left: 10px;
}

.available-container {
  height: 412px;
  overflow-y: scroll;
}

.checkbox-list {
  height: 37px;
  width: auto;
  margin-left: 10px;
  margin-right: 24px;
}

.checkbox-list:first-child {
  margin-top: 8px;
}

/* Left Column */
.columns-selected {
  float: right;
  margin: 0 auto;
  width: 447px;
  height: 446px;
}

.selected-title {
  display: inline-block;
  font-family: var(--global-font-semi-bold);
  font-size: var(--global-font-size-xs);
  color: var(--global-colors-ink-light);
  margin-left: 16px;
  margin-bottom: 16px;
  height: 18px;
}

.selected-container {
  overflow-y: auto;
  width: 446px;
  border-bottom: 1px solid var(--global-colors-sky-sky);
}

.save-preset {
  margin-left: 19px;
  margin-top: 12px;
}

.preset-checkbox {
  height: 32px;
  float: left;
  margin-top: 4px;
}

.preset-input {
  width: 273px;
  float: right;
}
/* Checkbox */
.checkbox-custom-columns > span {
  white-space: nowrap;
}
