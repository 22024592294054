.pending {
	width: 20px;
	height: 20px;
	background-color: var(--global-colors-ui-secondary);
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 150px;
}

.pending .screen-reader {
	display: none;
}