.adminNotification-filterbar-row {
  display: flex;
  align-items: center;
  font-weight: var(--global-font-semi-bold);
  width: 100%;
  border-bottom: 1px solid var(--global-colors-sky-dark);
}

.notification-filterbar-dropdown-columns .dropdown {
  width: 207px;
}

.notification-filterbar-search {
  margin-left: 16px;
}

.notification-filterbar-search,
.notification-filterbar-statuses {
  margin-top: 24px;
  margin-bottom: 24px;
  margin-right: 16px;
}

.notification-filterbar-addressBook {
  width: auto;
  margin-left: auto;
  margin-right: 16px;
  margin-top: 24px;
  margin-bottom: 24px;
}

.notification-filterbar-addressBook .dropdown-btn.dropdown--secondary {
  margin-top: 0;
  height: 32px;
}

.notification-filterbar-addressBook .dropdown-list {
  background-color: var(--global-colors-ui-white);
  position: absolute;
  right: 0px;
  width: max-content;
}

.notification-filterbar-addressBook
  .dropdown-btn
  .dropdown-list
  .dropdown-elements
  .text {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  color: var(--global-colors-ink-dark);
}

.notification-filterbar-addRule {
  width: auto;
  margin-right: 16px;
  margin-top: 24px;
  margin-bottom: 24px;
}
