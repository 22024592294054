.tooltip-style__small {
  font-size: 10px !important;
  font-family: var(--global-font-semi-bold);
  background: var(--global-colors-cyan-blue) !important;
  display: flex !important;
  align-items: center !important;
  text-transform: uppercase;
  border-radius: 4px !important;
  padding: 2px 8px !important;
  margin-top: 0 !important;
  max-width: 442px;
  max-height: fit-content;
  line-height: 16px;
  opacity: 1 !important;
}

.tooltip-style__regular {
  font-size: 12px !important;
  font-family: var(--global-font-semi-bold);
  background: var(--global-colors-cyan-blue) !important;
  display: flex !important;
  align-items: center !important;
  text-transform: uppercase;
  border-radius: 4px !important;
  padding: 4px 8px !important;
  margin-top: 0 !important;
  max-width: 442px;
  max-height: fit-content;
  line-height: 16px;
  opacity: 1 !important;
}

.__react_component_tooltip.place-left::after {
  border-left: 8px solid var(--global-colors-cyan-blue) !important;
}

.__react_component_tooltip.place-right::after {
  border-right: 8px solid var(--global-colors-cyan-blue) !important;
}

.__react_component_tooltip.place-top::after {
  border-top: 8px solid var(--global-colors-cyan-blue) !important;
}

.__react_component_tooltip.place-bottom::after {
  border-bottom: 8px solid var(--global-colors-cyan-blue) !important;
}
