.dropdown-btn {
  display: block;
  position: relative;
  width: fit-content;
}

.dropdown-btn .label {
  padding: 9px 16px;
}

.dropdown-btn > .dropdown__button--ghost .label,
.dropdown-btn > .dropdown__button--outline .label {
  padding: 9px;
}

.dropdown-btn > .dropdown__button > button.button--is-loading {
  border-radius: 4px;
}

/*#region dropdown menu*/
.dropdown-btn .dropdown-list {
  z-index: 12;
  box-shadow: var(--global-elevation-02);
  border: 1px solid var(--global-colors-sky-sky);
  outline: 0;
  top: calc(100% + 6px);
  border-radius: 4px;
  background-color: var(--global-colors-ui-white);
}

.dropdown-btn .dropdown-list-only-icon {
  background-color: var(--global-colors-ui-white);
  box-shadow: var(--global-elevation-02);
  border-radius: 4px;
  border: 1px solid var(--global-colors-sky-sky);
  outline: 0;
  position: absolute;
  top: 36px;
  margin-top: 4px;
  width: max-content;
  height: auto;
  right: 0px;
  z-index: 3;
  float: right;
  clear: right;
}

.dropdown-btn .dropdown-list .dropdown-list__item {
  display: block;
  border: 0;
  background-color: var(--global-colors-ui-white);
  font-family: var(--global-font-regular);
  margin: 0;
  padding: 16px;
  width: 100%;
  text-align: left;
  font-size: var(--global-font-size-sm);
  outline: 0;
  cursor: var(--global-cursor-pointer);
}

.dropdown-elements .button,
.dropdown-elements .button .label {
  width: 100%;
}

.dropdown-elements .button:hover {
  border-radius: 0px;
}

.dropdown-elements:first-child .button:hover {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.dropdown-elements:last-child .button:hover {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.dropdown-btn .dropdown-list .dropdown-icon-btn {
  margin-right: 16px;
}

.dropdown-list-only-icon .dropdown-elements .button--ghost .label .text {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
}

.dropdown-list-only-icon .dropdown-elements .button--ghost:hover {
  overflow: hidden;
}
/*#endregion dropdown menu*/

/*#region dropdown button ripple*/
.dropdown__button {
  --ripple-background: var(--global-colors-ui-white);
  --ripple-opacity: 0.3;
  --ripple-duration: 300ms;
  cursor: var(--global-cursor-pointer);
}

/*#endregion dropdown button ripple*/

/*#region dropdown only icon*/
.dropdown-btn > .dropdown__button--ghost > .dropdown-only-icon {
  cursor: var(--global-cursor-pointer);
  height: 32px;
  width: 32px;
  display: inline-flex;
  align-items: center;
  padding: 11px 9px 11px 9px;
  border-radius: 0.4rem;
  margin-left: 200px;
  float: right;
}

.dropdown-btn > .dropdown__button--outline > .dropdown-only-icon {
  cursor: var(--global-cursor-pointer);
  height: 32px;
  width: 32px;
  display: inline-flex;
  align-items: center;
  padding: 11px 9px 11px 9px;
  border-radius: 0.4rem;
  float: right;
  margin-left: 200px;
  border: 1px solid var(--global-colors-ink-lighter);
}

.dropdown-btn > .dropdown__button--outline > .dropdown-only-icon:hover:enabled {
  background-color: var(--global-colors-sky-light);
}

.dropdown-btn > .dropdown__button--ghost > .dropdown-only-icon:hover:enabled {
  background-color: var(--global-colors-sky-light);
}
/*#endregion dropdown only icon*/
