.schedule-date {
  font-family: var(--global-font-medium);
  max-width: 653px;
  height: 76px;
  padding: 17px 32px;
  user-select: none;
  cursor: var(--global-cursor-pointer);
}

.schedule-date:hover {
  background-color: var(--global-colors-sky-lighter);
}

.schedule-date-simple {
  font-family: var(--global-font-medium);
  max-width: 653px;
  height: 76px;
  padding: 17px 32px;
  user-select: none;
  cursor: var(--global-cursor-pointer);
}

.schedule-date-circle span {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  border: 1px solid var(--global-colors-sky-sky);
  background-color: var(--global-colors-ui-white);
  float: left;
  display: flex;
  justify-content: center;
}

.schedule-date-icon {
  margin: 0;
  float: right;
  display: flex;
  align-items: center;
  cursor: var(--global-cursor-pointer);
}

.schedule-date-button {
  background: transparent;
  padding: 0;
  border: 0;
  width: 100%;
  display: flex;
  align-items: center;
}

.schedule-date-label {
  margin-left: 26px;
  margin-right: auto;
}

.schedule-date-pretitle {
  color: var(--global-colors-ink-light);
  font-size: var(--global-font-size-xs);
  font-family: var(--global-font-regular);
  margin-bottom: 6px;
  float: left;
}

.schedule-date-title {
  color: var(--global-colors-ink-dark);
  font-size: var(--global-font-size-md);
  font-family: var(--global-font-regular);
  float: left;
  text-overflow: ellipsis;
  text-align: left;
  overflow: hidden;
  max-width: 400px;
}

.schedule-date-reset {
  height: 16px;
  width: 34px;
  font-size: var(--global-font-size-sm);
  font-family: var(--global-font-regular);
  margin-right: 16px;
  float: left;
  text-decoration: none;
  -webkit-text-decoration-color: var(--global-colors-ui-secondary);
  text-decoration-color: var(--global-colors-ui-secondary);
  color: var(--global-colors-ui-secondary);
}

.schedule-date-save-button {
  margin-right: 16px;
  height: 32px;
  width: 86px;
  float: left;
}

.schedule-date-body {
  max-width: 653px;
  height: 116px;
  padding: 0 32px 32px 100px;
}

.schedule-date-selection {
  color: var(--global-colors-ink-light);
  font-size: var(--global-font-size-xs);
  font-family: var(--global-font-semi-bold);
  float: left;
  height: 36px;
  align-items: center;
  display: flex;
}

.schedule-date-months {
  margin-left: 12px;
}

.schedule-date-picker {
  float: right;
  margin-right: 156px;
}

.schedule-date-picker .datepicker-menu {
  left: -204px;
  margin-top: 36px;
}

.schedule-date-picker .text-field.text-field--has-icon input {
  width: 180px;
}

.schedule-date-labels {
  color: var(--global-colors-ink-light);
  font-size: var(--global-font-size-xs);
  font-family: var(--global-font-semi-bold);
  float: left;
  height: 36px;
  width: 140px;
  margin-bottom: 14px;
  align-items: center;
  display: flex;
}

.schedule-date-value {
  color: var(--global-colors-ink-dark);
  font-size: var(--global-font-size-sm);
  height: 36px;
  align-items: center;
  display: flex;
  margin-bottom: 14px;
}

.schedule-date-left-column {
  display: flex;
  height: 36px;
  float: left;
}

.schedule-date-right-column {
  display: flex;
  height: 36px;
  float: right;
  margin-right: 54px;
}

.schedule-date-right-column .schedule-date-labels {
  width: 90px;
}

.schedule-date-value form {
  max-width: 54px;
}

/* Chrome, Safari, Edge, Opera */
.schedule-date-value input::-webkit-outer-spin-button,
.schedule-date-value input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.schedule-date-value input[type="number"] {
  -moz-appearance: textfield;
}
