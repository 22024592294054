.reportMarked {
  margin-bottom: 0;
  font-family: var(--global-font-semi-bold);
  white-space: nowrap;
}

.reportNormal {
  color: var(--global-colors-ink-light);
  margin: 0;
  white-space: nowrap;
}

.reportIO {
  margin: 0;
  white-space: nowrap;
}
