.ea-subtopbar {
  height: 82px;
  width: 100%;
  margin: 0;
  background-color: var(--global-colors-ui-white);
  border-bottom: 1px solid var(--global-colors-sky-sky);
  padding: 25px 24px;
  align-items: center;
}

.ea-only-view {
  pointer-events: none;
}

.ea-only-view input {
  border: 1px solid var(--global-colors-sky-dark);
  color: var(--global-colors-ink-light);
}

.ea-only-view .dropdown input {
  border: 1px solid var(--global-colors-sky-dark);
  color: var(--global-colors-ink-light);
}

.ea-subsection-name {
  max-height: 28px;
  max-width: 200px;
  color: #031832;
  font-family: var(--global-font-regular);
  font-size: 24px;
  letter-spacing: 0;
  line-height: 28px;
  margin: 0;
  float: left;
}

.ea-button-save-group {
  float: right;
}

.ea-button-save-group .button {
  min-width: 57px;
  min-height: 32px;
  height: 32px;
  width: auto;
}

/*#region Forms */

.ea-form-container {
  position: relative;
  top: 0;
  background-color: var(--global-colors-sky-lighter);
  width: auto;
  height: 100%;
  overflow-y: scroll;
}

.ea-forms-title {
  font-family: var(--global-font-medium);
  font-size: var(--global-font-size-sm);
  width: 165px;
  height: 18px;
  margin-top: 36px;
  margin-left: 36px;
}

.ea-forms-container {
  width: auto;
  height: 42px;
  margin-top: 24px;
  margin-left: 36px;
  margin-right: 77px;
  vertical-align: middle;
}

.ea-forms-label {
  font-family: var(--global-font-semi-bold);
  font-size: var(--global-font-size-xs);
  color: var(--global-colors-ink-light);
  width: 85px;
  height: 42px;
  float: left;
  display: flex;
  align-items: center;
}

.ea-right-forms-label {
  font-family: var(--global-font-semi-bold);
  font-size: var(--global-font-size-xs);
  color: var(--global-colors-ink-light);
  width: 97px;
  height: 42px;
  float: left;
  margin-left: 58px;
  display: flex;
  align-items: center;
}

.ea-left-forms-label {
  font-family: var(--global-font-semi-bold);
  font-size: var(--global-font-size-xs);
  color: var(--global-colors-ink-light);
  width: 95px;
  height: 18px;
  margin-top: 12px;
  line-height: 18px;
  float: left;
  margin-left: 80px;
  margin-right: 30px;
}

.ea-forms {
  width: calc(100% - (85px + 110px));
  height: 42px;
  float: right;
  margin-left: 110px;
}

.ea-half-form {
  min-width: 50%;
  display: inline-block;
}

.ea-half-form-splitted-left {
  min-width: 50%;
  max-width: 528.5px;
  float: left;
  display: flex;
}

.ea-half-form-splitted-right {
  min-width: 50%;
  float: right;
}

.ea-half-form-company-info-tel {
  min-width: 50%;
  max-width: 528.5px;
  float: right;
}
/*#endregion Forms */

/*#region Personal Info Form */

.ea-personal-info-form {
  margin: 16px 16px;
  box-sizing: border-box;
  height: 562px;
  width: auto;
  background-color: var(--global-colors-ui-white);
  border: 1px solid #e4e6e9;
  border-radius: 4px;
}

.ea-left-form-name {
  width: 100%;
  height: 42px;
  float: left;
}

.ea-right-form-name {
  width: calc(100% - 185px);
  height: 42px;
  float: right;
}

.ea-right-form-last-element {
  width: auto;
  height: 42px;
  float: right;
  margin-left: 16px;
}

.ea-left-form-city {
  width: 45%;
  height: 42px;
  float: left;
}

.ea-right-form-zip {
  width: 37%;
  height: 42px;
  float: right;
  margin-left: 16px;
}

.ea-left-form-phone {
  width: 24%;
  height: 42px;
  float: left;
}

.ea-right-form-phone {
  width: 73%;
  height: 42px;
  float: right;
  margin-left: -13px;
}
/*#endregion Personal Info Form */

/*#region Security Form */

.ea-security-form {
  margin-left: 16px;
  margin-top: 16px;
  margin-right: 16px;
  box-sizing: border-box;
  height: 144px;
  width: auto;
  background-color: var(--global-colors-ui-white);
  border: 1px solid #e4e6e9;
  border-radius: 4px;
}

.ea-left-form-text {
  margin-top: 10px;
  margin-bottom: 21px;
}

.ea-left-form-text a {
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-sm);
  vertical-align: middle;
}

.ea-left-form-text-credit {
  font-family: var(--global-font-semi-bold);
  font-size: var(--global-font-size-xs);
  vertical-align: middle;
}
/*#endregion Security Form */

/*#region Company Info Form */

.ea-company-forms {
  width: calc(100% - (75px + 110px));
  height: 42px;
  float: right;
}

.ea-company-info-form {
  margin-left: 16px;
  margin-top: 16px;
  margin-right: 16px;
  box-sizing: border-box;
  height: 420px;
  width: auto;
  background-color: var(--global-colors-ui-white);
  border: 1px solid #e4e6e9;
  border-radius: 4px;
}

.ea-company-info-label {
  font-family: var(--global-font-semi-bold);
  font-size: var(--global-font-size-xs);
  color: var(--global-colors-ink-light);
  width: 93px;
  height: 18px;
  margin-top: 12px;
  line-height: 18px;
  float: left;
}

.ea-company-info-forms {
  width: calc(100% - (93px + 102px));
  height: 42px;
  float: right;
  margin-left: 102px;
}

.ea-company-right-form-phone {
  width: 73%;
  height: 42px;
  float: right;
}

.ea-company-left-form-phone {
  width: 24%;
  height: 42px;
  float: left;
}
/*#endregion Company Info Form */

/*#region Preferences Form */

.ea-preferences-form {
  margin-left: 16px;
  margin-top: 16px;
  margin-right: 16px;
  box-sizing: border-box;
  width: auto;
  background-color: var(--global-colors-ui-white);
  border: 1px solid #e4e6e9;
  border-radius: 4px;
  padding-bottom: 36px;
}

.ea-preferences-label {
  font-family: var(--global-font-semi-bold);
  font-size: var(--global-font-size-xs);
  color: var(--global-colors-ink-light);
  width: 106px;
  height: 18px;
  margin-top: 12px;
  line-height: 18px;
  float: left;
}

.ea-preferences-label-two {
  font-family: var(--global-font-semi-bold);
  font-size: var(--global-font-size-xs);
  color: var(--global-colors-ink-light);
  width: 106px;
  height: 18px;
  margin-top: 4px;
  line-height: 18px;
  float: left;
}

.ea-preferences-forms {
  width: calc(100% - (106px + 89px));
  height: 42px;
  float: right;
  margin-left: 89px;
}
/*#endregion Preferences Form */

/* Custom Logo Form */

.ea-custom-logo-form {
  margin: 16px;
  box-sizing: border-box;
  height: 162px;
  width: auto;
  background-color: var(--global-colors-ui-white);
  border: 1px solid #e4e6e9;
  border-radius: 4px;
}

/* Splitter Bar */

.ea-splitter {
  height: 1px;
  background-color: var(--global-colors-sky-sky);
  margin-top: 26px;
  margin-bottom: 26px;
  margin-left: 36px;
  margin-right: 77px;
}

/* Dropdown Small */

.ea-drpdwn-small > :last-child {
  width: 100%;
}

/* Dropdown Large */

.ea-drpdwn-large > :last-child {
  width: 100%;
}

/* Button Group */

.ea-bg .btn-group {
  width: 100%;
}

.ea-bg .btn-group .button {
  width: 50%;
}

/* Change Password Style */

.ea-change-password {
  height: 18px;
  width: 120px;
  color: var(--global-colors-ui-secondary);
  font-family: var(--global-font-regular);
  font-size: 14px;
  cursor: var(--global-cursor-pointer);
  line-height: 18px;
}

.ea-change-password:hover {
  color: var(--global-colors-ui-primary);
}

.ea-credit-container {
  border: 1px solid var(--global-colors-sky-dark);
  border-radius: 4px;
  padding: 13px 34px 13px 16px;
  display: flex;
  align-items: center;
  width: calc(100% - 210px);
  height: 42px;
}
