.colors td span {
  margin: 8px;
}

.colors label {
  cursor: var(--global-cursor-pointer);
}

.colors input {
  display: none;
}

.colors input[type="radio"]:checked + .circle {
  box-shadow: inset 0 0 0 2px white;
}

.circle {
  display: inline-block;
  vertical-align: middle;
  height: 24px;
  width: 24px;
  border-radius: 50%;
}
