.mn-tag {
  padding: 4px 6px;
  color: var(--global-colors-ink-ink);
  font-family: var(--global-font-semi-bold);
  text-transform: uppercase;
  font-size: var(--global-font-size-xs);
  display: inline-flex;
  border-radius: 4px;
  max-height: 34px;
  margin: 0px auto;
  width: max-content;
  text-align: center;
}

.mn-tag .svg {
  align-items: center;
  display: flex;
  fill: var(--global-colors-ink-ink);
  width: 16px;
  justify-content: center;
}

.mn-tag--small {
  padding: 1px 6px;
  min-height: 12px;
  width: max-content;
  font-size: var(--global-font-size-xxs);
}

.mn-tag--basic {
  background-color: transparent;
  border-color: transparent;
}

.mn-tag--basic.mn-tag--outline {
  border: 1px solid var(--global-colors-sky-dark);
  color: var(--global-colors-ink-ink);
}

.mn-tag--primary {
  background-color: var(--global-colors-ui-primary);
  border-color: var(--global-colors-ui-primary);
  color: var(--global-colors-ui-white);
}

.mn-tag--primary.mn-tag--outline {
  border: 1px solid var(--global-colors-ui-primary);
  color: var(--global-colors-ui-primary);
}

.mn-tag--secondary {
  background-color: var(--global-colors-ui-secondary);
  border-color: var(--global-colors-ui-secondary);
  color: var(--global-colors-ui-white);
}

.mn-tag--secondary.mn-tag--outline {
  border: 1px solid var(--global-colors-ui-secondary);
  color: var(--global-colors-ui-secondary);
}

.mn-tag--success {
  background-color: var(--global-colors-feedback-success);
  border-color: var(--global-colors-feedback-success);
  color: var(--global-colors-ui-white);
}

.mn-tag--success.mn-tag--outline {
  border: 1px solid var(--global-colors-feedback-success);
  color: var(--global-colors-feedback-success);
}

.mn-tag--danger {
  background-color: var(--global-colors-feedback-danger);
  border-color: var(--global-colors-feedback-danger);
  color: var(--global-colors-ui-white);
}

.mn-tag--danger.mn-tag--outline {
  border: 1px solid var(--global-colors-feedback-danger);
  color: var(--global-colors-feedback-danger);
}

.mn-tag--neutro {
  background-color: var(--global-colors-ink-light);
  border-color: var(--global-colors-ink-light);
  color: var(--global-colors-ui-white);
}

.mn-tag--neutro2 {
  background-color: var(--global-colors-ink-lighter);
  border-color: var(--global-colors-ink-lighter);
  color: var(--global-colors-ui-ink);
}

.mn-tag--neutro2.mn-tag--outline {
  border: 1px solid var(--global-colors-ui-lighter);
  color: var(--global-colors-ui-lighter);
}

.mn-tag--neutro.mn-tag--outline {
  border: 1px solid var(--global-colors-ink-light);
  color: var(--global-colors-ink-light);
}

.mn-tag--outline {
  background-color: transparent;
}
