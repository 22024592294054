.edit-geofence-button {
  padding: 9px;
  border-radius: 4px;
  background-color: var(--global-colors-ui-white);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
}

.draw-geofence-button {
  padding: 9px;
  background-color: var(--global-colors-ui-white);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
}

.draw-geofence-button:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.draw-geofence-button:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.draw-geofence-button .svg span {
  color: var(--global-colors-ui-primary);
}

.draw-geofence-button:hover {
  cursor: var(--global-cursor-pointer);
}

.draw-geofence-focused {
  padding: 9px;
  background-color: var(--global-colors-ui-primary);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
}

.draw-geofence-focused:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.draw-geofence-focused:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.draw-geofence-focused .svg span {
  color: var(--global-colors-ui-white);
}

.draw-geofence-focused:hover {
  cursor: var(--global-cursor-pointer);
}

.draw-geofence-container {
  border-radius: 4px;
  display: inline-grid;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 8%);
}

.edit-geofence-container {
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  cursor: var(--global-cursor-pointer);
  background-color: var(--global-colors-ui-white);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
}

.edit-geofence-container .edit-geofence-button {
  box-shadow: 0 0 0 transparent;
}

.edit-geofence-container .edit-geofence-button.disable {
  box-shadow: 0 0 0 transparent;
  opacity: 0.2;
  pointer-events: none;
}

.draw-geofence-container .draw-geofence-button {
  box-shadow: 0 0 0 transparent;
}
