.mn-box-container {
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-xs);
  position: relative;
  border-bottom: 1px solid var(--global-colors-sky-dark);
  overflow: hidden;
  width: 100%;
  height: 72px;
}

.mn-box-container:hover {
  background-color: var(--global-colors-sky-light);
  cursor: var(--global-cursor-pointer);
}

.mn-box-container-disabled {
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-xs);
  position: relative;
  border-bottom: 1px solid var(--global-colors-sky-dark);
  overflow: hidden;
  width: 100%;
  height: 72px;
}

.mn-box-container-disabled:hover {
  cursor: var(--global-cursor-not-allowed);
}

.mn-box-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.mn-box-icon {
  width: 40px;
  height: 40px;
  margin-left: 24px;
  margin-right: 11px;
}

.mn-box-icon > div {
  align-items: center;
  justify-content: center;
  display: flex;
  text-align: center;
  background-color: var(--global-colors-ui-white);
  border: 1px solid var(--global-colors-sky-sky);
}

.mn-box-detail {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.mn-box-icon-img {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 150px;
  overflow: hidden;
  background-color: var(--global-colors-sky-sky);
}

.mn-box-icon-img > .svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mn-box-title {
  font-family: var(--global-font-semi-bold);
  color: var(--global-colors-ink-dark);
  width: 100%;
  display: flex;
  align-items: center;
}

.mn-box-icon-status {
  margin-left: 52px;
  margin-top: 27px;
  position: absolute;
  z-index: 999;
}

.mn-box-subtitle {
  font-family: var(--global-font-regular);
  width: 100%;
  color: var(--global-colors-ink-light);
  white-space: nowrap;
  margin-top: 4px;
}

.mn-box-alias-plate-divider {
  content: "\2022";
  display: inline-block;
  margin-left: 6px;
  margin-right: 6px;
  margin-bottom: 2px;
  height: 4px;
  width: 4px;
  background-color: #cdd1d6;
  border-radius: 50px;
  align-content: center;
}

.mn-box-button {
  background-color: transparent;
  border-color: transparent;
  padding-right: 40px !important;
  padding-top: 16px !important;
  padding-bottom: 20px !important;
  padding-left: 0px !important;
  align-items: center;
}

.mn-box-category-color {
  min-width: 14px;
  height: 14px;
  border-radius: 50%;
  display: flex;
  margin: 30px;
}

.mn-box-container .ripple {
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  background-color: var(--global-colors-sky-sky);
  animation: 600ms ease 1 forwards ripple;
}

@keyframes ripple {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(10);
    opacity: 0.375;
  }
  100% {
    transform: scale(35);
    opacity: 0;
  }
}
