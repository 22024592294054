.drvr-subtopbar {
  height: 82px;
  width: 100%;
  background-color: var(--global-colors-ui-white);
  border-bottom: 1px solid #d2d5da;
  padding: 16px;
  display: flex;
  align-items: center;
}

.drvr-left-form-item > .dropdown {
  width: 100%;
}

.drvr-only-view {
  pointer-events: none;
}

.drvr-only-view .selected-value input,
.drvr-only-view input {
  border: 1px solid var(--global-colors-sky-dark);
  color: var(--global-colors-ink-light);
}

.drvr-buttons {
  margin-left: auto;
}

.drvr-margin-top {
  margin-top: 50px !important;
}

.drvr-input-error {
  color: red;
}

.drvr-create-accont-desc {
  font-family: var(--global-font-medium);
  font-size: var(--global-font-size-sm);
  color: var(--global-colors-ink-ink);
  margin-bottom: 16px;
}

.drvr-profile-icon {
  display: flex;
  align-items: center;
}

.drvr-profile-icon .drvr-profile-text {
  margin-left: 16px;
}

.drvr-tacograph-claim {
  padding-top: 3px;
  font-size: var(--global-font-size-xs);
  line-height: 26px;
  color: var(--global-colors-ink-light);
}

.drvr-separator {
  margin: 48px 36px 24px 36px;
  border: 1px solid var(--global-colors-sky-sky);
}

.drvr-subsection-name {
  color: var(--global-colors-ink-dark);
  font-family: var(--global-font-regular);
  font-size: 24px;
}

.drvr-forms-title {
  font-family: var(--global-font-medium);
  font-size: var(--global-font-size-sm);
  width: 153px;
  height: 18px;
  margin-top: 36px;
  margin-left: 36px;
}

.drvr-page-container {
  position: relative;
  top: 0;
  background-color: var(--global-colors-sky-lighter);
  width: auto;
  height: 100%;
  overflow-y: scroll;
}

.drvr-forms-container {
  width: auto;
  height: 42px;
  margin-top: 24px;
  margin-left: 36px;
  margin-right: 77px;
  vertical-align: middle;
}

.drvr-right-forms-label {
  font-family: var(--global-font-semi-bold);
  font-size: var(--global-font-size-xs);
  color: var(--global-colors-ink-light);
  width: 100px;
  height: 42px;
  float: left;
  margin-left: 73px;
  display: flex;
  align-items: center;
}

.drvr-left-forms-label {
  font-family: var(--global-font-semi-bold);
  font-size: var(--global-font-size-xs);
  color: var(--global-colors-ink-light);
  width: auto;
  height: 42px;
  float: left;
  display: flex;
  align-items: center;
}

.drvr-forms {
  width: calc(100% - (85px + 111px));
  height: 42px;
  float: right;
}

.drvr-half-form {
  min-width: 50%;
  display: inline-block;
  max-width: 50%;
}

.drvr-info-label {
  font-family: var(--global-font-semi-bold);
  font-size: var(--global-font-size-xs);
  color: var(--global-colors-ink-light);
  width: 93px;
  height: 18px;
  margin-top: 12px;
  line-height: 18px;
  float: left;
}

.drvr-info-forms {
  width: calc(100% - (93px + 102px));
  height: 42px;
  float: right;
  margin-left: 102px;
}

/* Info Form */

.drvr-info-form {
  margin: 16px 16px;
  box-sizing: border-box;
  padding-bottom: 60px;
  width: auto;
  background-color: var(--global-colors-ui-white);
  border: 1px solid #e4e6e9;
  border-radius: 4px;
}

.drvr-left-form-item {
  width: 100%;
  height: 42px;
  float: left;
}

.drvr-right-form-item {
  width: 100%;
  height: 42px;
  float: right;
  margin-left: unset;
}

.drvr-left-form-phone {
  width: 160px;
  height: 42px;
  float: left;
}

.drvr-right-form-phone {
  width: calc(100% - (292px));
  height: 42px;
  display: inline-block;
  margin-left: 16px;
}

/* Dropdown Small */

.drvr-drpdwn-small > :last-child {
  width: 100%;
}

.dvr-half-permission {
  display: inline-block;
}

.drvr-half-form-permission {
  width: calc(100% - 197px);
  margin-left: 111px;
}

.form-permission {
  display: inline-block;
  width: calc(50% - 8px);
}
.form-permission:first-child {
  margin-right: 16px;
}
.drvr-forms-permission {
  width: 100%;
}

.drvr-left-form-text-field {
  width: calc(100% - (116px));
  height: 42px;
  display: inline-block;
}

.drvr-validate-button {
  display: inline-flex;
  float: right;
}
