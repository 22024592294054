.public-route-stop-switch-element-container {
  display: flex;
  flex-direction: row;
  height: 40px;
  border-bottom: 1px solid var(--global-colors-sky-sky);
  padding-left: 30px;
}

.public-route-stop-switch-element-selected {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 15px;
  height: 100%;
  border-bottom: 2px solid black;
  font-family: var(--global-font-regular);
  font-size: 14px;
  margin-right: 20px;
}

.public-route-stop-switch-element {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 15px;
  height: 100%;
  cursor: pointer;
  font-family: var(--global-font-regular);
  font-size: 14px;
  margin-right: 20px;
}

.public-route-stop-summary-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--global-colors-sky-sky);
}

.public-route-stop-summary-title {
  font-size: 14px;
  font-weight: 500;
  font-family: var(--global-font-regular);
  padding-bottom: 15px;
}

.public-route-stop-summary-elements-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 20px;
  gap: 50px;
}

.public-route-stop-summary-element {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
}

.public-route-stop-summary-element-formatted {
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding-left: 10px;
}

.public-route-stop-table-header {
  display: flex;
  flex-direction: row;
}

.public-route-stop-row-stops {
  display: flex;
  flex-direction: row;
}

.public-route-stop-table-body {
  height: 100%;
  width: 100%;
  overflow: scroll;
  padding-bottom: 300px;
}

.public-stop-row {
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.public-route-stop-header-element {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 24px;
  padding-right: 24px;
  height: 60px;
  align-items: center;
  border-bottom: 1px solid #e4e6e9;
  border-right: 1px solid #e4e6e9;
  align-self: center;
  justify-content: center;
  background-color: #e4e6e985;
}

.public-route-stop-header-element-cell {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 24px;
  padding-right: 24px;
  height: 60px;
  align-items: center;
  border-bottom: 1px solid #e4e6e9;
  border-right: 1px solid #e4e6e9;
  align-self: center;
  justify-content: center;
  background-color: #e4e6e985;
  width: 100%;
}
