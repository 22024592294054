.map-actions-container {
  float: right;
  display: flex;
  border-radius: 7px;
}

.map-actions.open {
  background-color: var(--global-colors-ui-white);
  border-radius: 0;
}

.map-actions.open:active {
  background-color: var(--global-colors-ui-white);
}

button[class^="map-actions__"]:not(.disabled) {
  cursor: var(--global-cursor-pointer);
}

button[class^="map-actions__"]:not(.fullScreen).disabled {
  background-color: var(--global-colors-ui-sky-sky--focus);
  pointer-events: none;
}

button[class^="map-actions__"]:not(.fullScreen).disabled div span,
button[class^="map-actions__"][name="disabled"] div span {
  color: var(--global-colors-ui-sky-sky--focus);
}

button[class^="map-actions__"][name="disabled"] {
  background-color: var(--global-colors-ui-sky-sky--focus);
  cursor: var(--global-cursor-not-allowed) !important;
}
