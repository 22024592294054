.data-issues-modal .mn-modal .mn-modal__dialog {
  min-height: 322px;
  width: 440px;
  border-radius: 4px;
}

.data-issues-modal .mn-modal .mn-modal__dialog .mn-modal__dialog-header {
  padding-left: 22px;
}

.data-issues-modal .data-issues-title {
  font-family: var(--global-font-medium);
  margin-top: 6px;
}

.data-issues-modal .data-issues-title .check {
  float: left;
  margin-right: 15px;
}

.data-issues-modal
  .mn-modal
  .mn-modal__dialog
  .data-issues-body
  .mn-modal__dialog-body {
  height: 128px;
  margin-top: 26px;
  background-color: var(--global-colors-sky-sky);
  padding: 46px 82px;
  box-shadow: inset 0 2px 10px 3px rgb(0 0 0 / 8%);
}

.data-issues-modal
  .mn-modal
  .mn-modal__dialog
  .data-issues-body
  .mn-modal__dialog-body
  .data-issues-text {
  display: block;
  color: var(--global-colors-ink-light);
}

.data-issues-modal .group-input {
  margin-top: 16px;
  padding-bottom: 4px;
}

.data-issues-modal .mn-modal .mn-modal__dialog .data-issues-footer {
  height: 118px;
}

