.info-modal-container .mn-modal .mn-modal__dialog {
  max-width: 660px;
  max-height: 750px;
}

.info-modal .mn-modal .mn-modal__dialog .mn-modal__dialog-body {
  padding: 32px;
  font-size: var(--global-font-size-sm);
  line-height: 18px;
  text-align: left;
}

/* Privacy-policy */

.info-modal-privacy-policy {
  margin-top: 16px;
  width: 598px;
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-sm);
  color: var(--global-colors-ink-dark);
  line-height: 22px;
  text-align: left;
  max-height: 469px;
  overflow-y: auto;
  overflow-x: hidden;
}

.info-modal-privacy-policy p {
  margin-bottom: 24px;
}

.info-modal-privacy-policy .subtitle {
  margin-bottom: 0px;
}

/* Terms-conditions */

.info-modal-terms-conditions {
  margin-top: 16px;
  width: 598px;
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-sm);
  color: var(--global-colors-ink-dark);
  line-height: 22px;
  text-align: left;
  max-height: 469px;
  overflow-y: auto;
  overflow-x: hidden;
}

.info-modal-terms-conditions p {
  margin-bottom: 24px;
}

.info-modal-terms-conditions .subtitle {
  margin-bottom: 0px;
}

/* Credits */

.mn-modal__dialog-body .logo-macnil {
  margin-top: 10px;
}

.info-modal-credits {
  margin-top: 23px;
  width: 581px;
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-sm);
  color: var(--global-colors-ink-dark);
  line-height: 22px;
  text-align: left;
}

.info-modal-credits p {
  margin-bottom: 24px;
}

.e-mail span {
  margin-right: 1px;
}

.link {
  margin-top: 20px;
}

.link span {
  margin-right: 5px;
}
