.vehicles-dropdown-btn {
  display: flex;
  border: 1px solid var(--global-colors-sky-dark);
  border-radius: 4px;
  cursor: var(--global-cursor-pointer);
  outline: none;
  transition: all 200ms ease;
  height: 32px;
  background-color: transparent;
  font-family: var(--global-font-regular);
  color: var(--global-colors-ink-light);
}

.vehicles-dropdown-btn.selected {
  border: 1px solid var(--global-colors-ink-light);
  cursor: var(--global-cursor-default);
  color: var(--global-colors-ui-black);
}

.vehicles-dropdown-btn.active:has(input:disabled) {
  background-color: var(--global-colors-sky-dark);
  opacity: 0.5;
  border: 1px solid var(--global-colors-ui-sky-sky--hover);
  cursor: var(--global-cursor-not-allowed);
}

.vehicles-dropdown-btn.active {
  border: 1px solid var(--global-colors-ui-primary--focus);
  cursor: var(--global-cursor-default);
  color: var(--global-colors-ui-black);
}

.vehicles-dropdown-span {
  width: 100%;
  display: flex;
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-sm);
  color: var(--global-colors-ink-light);
  cursor: var(--global-cursor-pointer);
}

.vehicles-dropdown-btn input:disabled {
  opacity: 0.5;
  background-color: var(--global-colors-sky-dark);
  color: var(--global-colors-ink-dark);
  cursor: var(--global-cursor-not-allowed);
}

.vehicles-dropdown-btn .vehicles-dropdown-arrow.iconDisabled {
  color: var(--global-colors-ink-light);
  opacity: 0.5;
  cursor: var(--global-cursor-not-allowed);
  background-color: var(--global-colors-sky-dark);
}

.vehicles-dropdown-btn input {
  display: flex;
  padding-left: 16px;
  padding-right: 12px;
  font-family: var(--global-font-regular);
  font-size: 14px;
  outline: none;
  border: none;
  width: 100%;
  height: 100%;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  cursor: var(--global-cursor-pointer);
}

.vehicles-dropdown-arrow {
  display: flex;
  padding-right: 12px;
  align-items: center;
  transition: all 0.2s ease-in-out;
  cursor: var(--global-cursor-pointer);
}

.vehicles-dropdown-arrow .svg {
  transition: all 0.2s ease-in-out;
}

.vehicles-dropdown-arrow.open .svg {
  transform: rotate(180deg);
}
