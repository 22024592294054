.mn-single-track-row {
  width: auto;
  height: 110px;
  padding: 24px;
  border-right: 4px solid transparent;
}

.mn-single-track-row__start-point,
.mn-single-track-row__end-point {
  font-size: var(--global-font-size-xs);
  font-family: var(--global-font-regular);
  display: flex;
  align-items: center;
  height: 20px;
}

.mn-single-track-row__start-point span:first-child,
.mn-single-track-row__end-point span:first-child {
  margin-right: var(--global-spacing-xs);
}

.mn-single-track-row__start-point > div,
.mn-single-track-row__end-point > div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mn-single-track-row__start-point {
  margin-bottom: 3px;
}

.mn-single-track-row__mid-point {
  position: relative;
  height: 22px;
  margin-left: 9.5px;
  border-left: 1px solid var(--global-colors-sky-sky);
  margin-bottom: 3px;
}

.mn-single-track-row__mid-point::before {
  content: "";
  width: 10px;
  height: 10px;
  display: block;
  border: 1px solid var(--global-colors-sky-sky);
  background-color: var(--global-colors-ui-white);
  border-radius: 150px;
  position: absolute;
  top: 6px;
  left: -6px;
}

.mn-single-track-row:hover {
  cursor: var(--global-cursor-pointer);
  background-color: var(--global-colors-sky-lighter);
}

.mn-single-track-row--focused {
  background-color: #f1f2f4;
  border-right: 4px solid var(--global-colors-ui-primary);
}

.mn-single-track-row__pending span:first-child {
  margin-right: 0;
}

.mn-single-track-row__end-time {
  justify-content: center;
  display: flex;
}
