.container-wrapper
  .grid-container.container-fluid
  .page-container
  .row.page-content.withFilterBar
  .col.fleetControl,
.container-wrapper
  .grid-container.container-fluid
  .page-container
  .row.page-content.withFilterBar
  .col.fleetControl
  > div {
  position: unset !important;
}

.mn-vehicle-details {
  height: 100%;
  width: 292px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;
  font-family: var(--global-font-regular);
  background-color: var(--global-colors-ui-white);
}

/* Header */
.mn-vehicle-details__header {
  background: linear-gradient(
    280deg,
    #007adb 0% /* Not in design system color palette. */,
    var(--global-colors-ui-primary) 30%
  );
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 76px;
  padding: var(--global-spacing-md) var(--global-spacing-lg);
  color: var(--global-colors-ui-white);
}

.mn-vehicle-details__header-left .mn-vehicle-details__signal-id {
  display: flex;
  align-items: center;
  font-size: var(--global-font-size-md);
}

.mn-vehicle-details__id {
  margin-left: var(--global-spacing-xs);
}
.mn-vehicle-details__id_icon {
  margin-left: var(--global-spacing-xxs);
}

.mn-vehicle-details__license-plate {
  width: fit-content;
  height: 20px;
  font-size: var(--global-font-size-xs);
  text-align: center;
  line-height: 13px;
  display: block;
  margin-top: var(--global-spacing-xs);
  background-color: var(--global-colors-sky-lighter);
  color: var(--global-colors-ink-ink);
  border-radius: 4px;
  padding: var(--global-spacing-xxs);
  padding-left: 9px;
  padding-right: 9px;
  font-family: var(--global-font-semi-bold);
}

.mn-vehicle-details__header-right {
  background-color: var(--global-colors-sky-lighter);
  border-radius: 150px;
  overflow: hidden;
  width: 48px;
  height: 48px;
  position: relative;
}

.mn-vehicle-details__header-right > svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mn-vehicle-details__status {
  padding: var(--global-spacing-md) var(--global-spacing-xs)
    var(--global-spacing-md) var(--global-spacing-lg);
}

.mn-vehicle-details__actions {
  margin-top: 12px;
}

.mn-vehicle-details__actions button:not(:first-child) {
  margin-left: 12px;
}

/* Live Data */
.mn-vehicle-details__live-data {
  padding: var(--global-spacing-sm) 0;
  border: 1px solid var(--global-colors-sky-sky);
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--global-spacing-md);
  width: 244px;
  height: 64px;
}

.mn-vehicle-details__live-data > div {
  padding: var(--global-spacing-xs) var(--global-spacing-md);
}

.mn-vehicle-details__live-data > div:first-child {
  border-right: 1px solid var(--global-colors-sky-sky);
}

.mn-vehicle-details__live-data-left,
.mn-vehicle-details__live-data-right {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  color: var(--global-colors-ink-dark);
  width: 121px;
}

.mn-vehicle-details__live-data-left .live-data__icon,
.mn-vehicle-details__live-data-right .live-data__icon {
  margin: 5px var(--global-spacing-xs) 0 0;
}

.mn-vehicle-details__live-data-left .live-data__icon .svg,
.mn-vehicle-details__live-data-right .live-data__icon .svg {
  color: inherit;
}

.mn-vehicle-details__live-data-left .live-data__data,
.mn-vehicle-details__live-data-right .live-data__data {
  font-size: var(--global-font-size-xl);
  font-family: var(--global-font-bold);
  display: flex;
  flex-direction: column;
}

.mn-vehicle-details__live-data-left .live-data__data p,
.mn-vehicle-details__live-data-right .live-data__data p {
  margin: 0 0 var(--global-spacing-xs) 0;
  font-family: var(--global-font-bold);
  font-size: var(--global-font-size-xm);
}

.mn-vehicle-details__live-data-left .live-data__data .live-data-unit,
.mn-vehicle-details__live-data-right .live-data__data .live-data-unit {
  font-size: var(--global-font-size-xxs);
  font-family: var(--global-font-regular);
  color: var(--global-colors-ink-light);
  display: inline-block;
  margin-left: var(--global-spacing-xxs);
}

.mn-vehicle-details__live-data-left .live-data__data .progress-bar,
.mn-vehicle-details__live-data-right .live-data__data .progress-bar {
  width: 60px;
  height: 4px;
}

/* Live Data - onlyspeed */
.mn-vehicle-details__live-data-onlyspeed_container {
  padding: 15px 77px 16px 77px;
  border: 1px solid var(--global-colors-sky-sky);
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  margin-bottom: var(--global-spacing-md);
  width: 244px;
  height: 64px;
}

.mn-vehicle-details__live-data-onlyspeed {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--global-colors-ink-dark);
  width: 121px;
}

.mn-vehicle-details__live-data-onlyspeed .live-data__icon {
  margin: 5px var(--global-spacing-xs) 16px 0;
}

.mn-vehicle-details__live-data-onlyspeed .live-data__icon .svg {
  color: inherit;
}

.mn-vehicle-details__live-data-onlyspeed .live-data__data {
  font-size: var(--global-font-size-xl);
  font-family: var(--global-font-bold);
  display: flex;
  flex-direction: column;
}

.mn-vehicle-details__live-data-onlyspeed .live-data__data p {
  margin: 0 0 var(--global-spacing-xs) 0;
  font-family: var(--global-font-bold);
  font-size: var(--global-font-size-xm);
}

.mn-vehicle-details__live-data-onlyspeed .live-data__data .live-data-unit {
  font-size: var(--global-font-size-xxs);
  font-family: var(--global-font-regular);
  color: var(--global-colors-ink-light);
  display: inline-block;
  margin-left: var(--global-spacing-xxs);
}

.mn-vehicle-details__live-data-onlyspeed .live-data__data .progress-bar {
  width: 60px;
  height: 4px;
}

/* Status */
.mn-vehicle-details__status > div:first-of-type,
.mn-vehicle-details__driver > div:first-of-type,
.mn-vehicle-details__vehicle > div:first-of-type,
.mn-public-transport__info > div:first-of-type {
  font-size: var(--global-font-size-xs);
  color: var(--global-colors-ink-light);
  margin-bottom: var(--global-spacing-md);
  margin-top: 0;
}

.mn-vehicle-details__status,
.mn-vehicle-details__driver,
.mn-vehicle-details__vehicle,
.mn-public-transport__info {
  margin-bottom: 0;
}

.mn-vehicle-details__location-history-start,
.mn-vehicle-details__location-history-end,
.mn-vehicle-details__location-history-current,
.mn-public-transport__next-stop {
  display: flex;
  justify-content: space-between;
}

.mn-public-transport__next-stop {
  align-items: center;
}

.mn-vehicle-details_current_padding {
  padding: var(--global-spacing-md) 0;
}

.mn-vehicle-details__location-history-start
  .mn-data-label
  .mn-data-label__text
  .mn-data-label__text-data {
  font-size: var(--global-font-size-xs);
}

.mn-vehicle-details__without-midlePoints {
  padding-top: 16px;
}

.mn-vehicle-details__location-history-end
  .mn-data-label
  .mn-data-label__text
  .mn-data-label__text-data,
.mn-vehicle-details__assets
  .mn-data-label
  .mn-data-label__text
  .mn-data-label__text-data,
.mn-public-transport__next-stop
  .mn-data-label
  .mn-data-label__text
  .mn-data-label__text-data {
  font-size: var(--global-font-size-xs);
}
.mn-vehicle-details__assets .mn-data-label {
  padding-bottom: 10px;
}

.mn-vehicle-details__location-history-end,
.mn-public-transport__next-stop {
  margin-bottom: 0;
}

.mn-vehicle-details__location-history .button {
  width: 100%;
  justify-content: center;
  margin-top: var(--global-spacing-lg);
}

.mn-vehicle-details__location-history-start time,
.mn-vehicle-details__location-history-end time {
  align-self: flex-end;
  font-size: var(--global-font-size-xs);
  color: var(--global-colors-ink-light);
  font-family: var(--global-font-regular);
}

.mn-vehicle-details__location-history-path li > span {
  margin-left: var(--global-spacing-lg);
  display: block;
}

.mn-vehicle-details__location-history-path li > span.name-stop {
  margin-left: var(--global-spacing-ld);
  display: block;
}

.mn-vehicle-details__location-history-path .points-list {
  color: var(--global-colors-ink-dark);
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-xs);
}

.mn-vehicle-details__location-history-path {
  margin: 0;
  padding: var(--global-spacing-md) 0;
  margin-left: 7px;
  list-style: none;
  border-left: 1px solid var(--global-colors-sky-dark);
}

.mn-vehicle-details__location-history-path li {
  margin-bottom: var(--global-spacing-sm);
  font-size: var(--global-font-size-xs);
  color: var(--global-colors-ink-light);
  margin-left: -5px;
  display: flex;
  align-items: center;
  cursor: var(--global-cursor-pointer);
}

.mn-vehicle-details__location-history-path li:last-child {
  margin-bottom: 0;
}

.mn-vehicle-details__location-history-path li::before {
  content: "";
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
  border: 1px solid var(--global-colors-sky-dark);
  background-color: var(--global-colors-ui-white);
  display: block;
  border-radius: 150px;
}

.mn-vehicle-details__location-history-path li.prev-stop::before {
  content: "";
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
  border: 1px solid var(--global-colors-feedback-success);
  background-color: var(--global-colors-feedback-success);
  display: block;
  border-radius: 150px;
}

.mn-vehicle-details__location-history-path li.curr-stop::before {
  content: "";
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
  border: 1px solid var(--global-colors-feedback-success);
  background-color: var(--global-colors-ui-white);
  display: block;
  border-radius: 150px;
}

.next-stop {
  color: var(--global-colors-ui-secondary);
  font-family: var(--global-font-bold);
  align-self: flex-end;
  font-size: var(--global-font-size-xs);
}

.stop-late {
  color: var(--global-colors-feedback-danger);
  font-family: var(--global-font-bold);
}

.stop-ontime {
  color: var(--global-colors-feedback-success);
  font-family: var(--global-font-bold);
}

.name-stop {
  color: var(--global-colors-ink-dark);
  width: 72%;
}

.time-stop {
  width: 40%;
}

.public-stop-list {
  height: 210px;
  overflow-y: scroll;
}

.mn-vehicle-details__location-history-path li > span {
  margin-left: var(--global-spacing-lg);
  display: block;
}

.mn-vehicle-details__location-history-path
  .mn-vehicle-details__go-to-location-history {
  cursor: var(--global-cursor-pointer);
}

.mn-vehicle-details__location-history-path
  .mn-vehicle-details__go-to-location-history
  span {
  margin-left: 34px;
  color: var(--global-colors-ink-light);
}

.mn-vehicle-details__location-history-path
  .mn-vehicle-details__go-to-location-history::before {
  content: none;
}

/* Driver */
.mn-vehicle-details__driver {
  display: block;
  padding: 0 var(--global-spacing-xs) var(--global-spacing-md)
    var(--global-spacing-lg);
}

.mn-vehicle-details__driver-detail
  .mn-data-label
  .mn-data-label__text
  .mn-data-label__text-data {
  font-size: var(--global-font-size-xs);
}

.mn-vehicle-details__driver > div:first-of-type {
  padding-top: var(--global-spacing-md);
  border-top: 1px solid var(--global-colors-sky-sky);
}

.mn-vehicle-details__driver-detail-upper-row {
  display: flex;
  justify-content: space-between;
}

.mn-vehicle-details__driver-detail-lower-row {
  display: flex;
  width: 100%;
  padding: var(--global-spacing-sm) 0;
  border-bottom: 1px solid var(--global-colors-sky-sky);
}

.mn-vehicle-details__driver-detail-lower-row .mn-chart__data {
  font-family: var(--global-font-semi-bold);
}

.mn-vehicle-details__driver-detail-lower-row > *:not(:last-child) {
  margin-right: var(--global-spacing-md);
}

.mn-vehicle-details__driver-detail-lower-row-second-type {
  display: flex;
  width: 100%;
  padding: var(--global-spacing-sm) 0 21px 0;
  margin-top: 9px;
}

.mn-vehicle-details__driver-detail-lower-row-second-type .mn-chart__data {
  font-family: var(--global-font-semi-bold);
}

.mn-vehicle-details-block {
  width: 33%;
  display: flex;
}

.mn-vehicle-details__bottom-upper-row {
  display: flex;
  justify-content: space-between;
  padding: var(--global-spacing-md) 0;
}

.mn-vehicle-details__bottom-lower-row {
  font-size: 8px;
  color: var(--global-colors-ink-light);
  margin-bottom: var(--global-spacing-md);
}

.mn-vehicle-details__bottom-lower-row-second-type {
  font-size: var(--global-font-size-xxs);
  color: var(--global-colors-ink-light);
  margin-bottom: 19px;
}

/* Vehicle */
.mn-vehicle-details__vehicle,
.mn-public-transport__info {
  padding: 0 var(--global-spacing-xs) var(--global-spacing-md)
    var(--global-spacing-lg);
}

.mn-vehicle-details__vehicle_sensor {
  padding: 0 var(--global-spacing-xs) var(--global-spacing-md)
    var(--global-spacing-lg);
  margin-bottom: 4px;
}

.mn-vehicle-details__vehicle > div:first-of-type,
.mn-public-transport__info > div:first-of-type {
  padding-top: var(--global-spacing-md);
  border-top: 1px solid var(--global-colors-sky-sky);
}

.mn-vehicle-details__stats,
.mn-public-transport__stats {
  display: grid;
  width: 100%;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr;
  word-break: break-all;
  gap: 8px 20px;
  margin-bottom: 14px;
}

.mn-vehicle-details__stats
  > .mn-data-label
  .mn-data-label__text
  .mn-data-label__text-data,
.mn-public-transport__stats
  > .mn-data-label
  .mn-data-label__text
  .mn-data-label__text-data {
  font-size: var(--global-font-size-xs);
}

.mn-vehicle-details__stats-button {
  margin-top: var(--global-spacing-lg);
}

.mn-vehicle-details__sensor {
  display: grid;
  width: 100%;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr;
  word-break: break-all;
  gap: 8px 20px;
  /* margin-bottom: 13px; */
}

.mn-vehicle-details__sensor
  > .mn-data-label
  .mn-data-label__text
  .mn-data-label__text-data,
.mn-public-transport__sensor
  > .mn-data-label
  .mn-data-label__text
  .mn-data-label__text-data {
  font-size: var(--global-font-size-xs);
}

.mn-vehicle-details__sensor-name {
  font-size: var(--global-font-size-xs);
  color: var(--global-colors-ink-light);
  margin-bottom: 3px;
}
.mn-vehicle-details__vehicle__container {
  max-height: 253px;
  position: relative;
  overflow: auto;
}

.mn-vehicle-details__dot {
  content: "•";
  padding: 0 4px;
  font-size: 1.2em;
  color: var(--global-colors-ink-light);
}

.mn-vehicle-details__lh-button {
  display: inline-flex;
  margin-left: 12px;
}

.mn-vehicle-details__lh-button-long {
  margin-top: 12px;
}

/* Chart */
.mn-vehicle-details__driver-detail .mn-chart__label {
  font-size: 8px;
  color: var(--global-colors-ink-light);
  display: block;
  margin-right: 8px;
}

.mn-chart__data {
  color: var(--global-colors-ink-dark);
  display: block;
  font-family: var(--global-font-semi-bold);
  font-size: var(--global-font-size-sm);
}

.mn-vehicle-details__driver-green-row {
  height: 27px;
  width: 6px;
  border-radius: 3.5px;
  background-color: var(--global-colors-status-online);
  margin-right: 8px !important;
}

.mn-vehicle-details__driver-orange-row {
  height: 27px;
  width: 6px;
  border-radius: 3.5px;
  background-color: var(--global-colors-radialbar-break);
  margin-right: 8px !important;
}

.mn-vehicle-details__driver-sky-blue-row {
  height: 27px;
  width: 6px;
  border-radius: 3.5px;
  background-color: var(--global-colors-ui-secondary);
  margin-right: 8px !important;
}

.internal-battery-icon {
  word-break: break-word;
}

.mn-vehicle-details__driver-detail-second-row-identification {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 10px;
}
