.reset-password-container .mn-modal .mn-modal__dialog {
  max-width: 600px;
  max-height: 642px;
}

.reset-password-container .mn-modal .mn-modal__dialog .reset-password .mn-modal__dialog-body {
  padding: 48px 48px 0px 45px;
  height: 443px;
  width: 600px;
}

.reset-password .mn-label-text__label {
  margin-bottom: 0;
}

.reset-password .mn-label-text__text {
  color: var(--global-colors-ink-ink);
}

.reset-password .mn-modal__dialog-body .mn-label-text__text {
  margin-left: 8px;
  font-family: var(--global-font-medium);
  font-size: var(--global-font-size-xs);
}

.reset-password .mn-modal__dialog-body .central-forms {
  height: 60px;
  width: 504px;
  float: left;
  margin-bottom: 140px;
}

.reset-password .captcha {
  position: absolute;
  height: 77px;
  width: 504px;
  margin-top: 100px;
}
