.mn-vehicle-activity {
  height: 254px;
  width: 600px;
}

.mn-vehicle-activity .mn-vehicle-activity__inner {
  width: 160px;
  display: flex;
  float: left;
  margin: 45px 0px 27px 82px;
}

.marker-list {
  margin: 46px 18px 40px 0px;
  min-width: 207px;
  display: flex;
  float: right;
}
git .marker-list-title {
  color: var(--global-colors-ink-dark);
  font-family: var(--global-font-semi-bold);
  font-size: 14px;
  min-width: 115px;
  width: 115px;
  margin-bottom: 4px;
}

.marker-list-title-overview {
  color: var(--global-colors-ink-dark);
  font-family: var(--global-font-medium);
  font-size: 13px;
  min-width: 115px;
  width: 115px;
  margin-bottom: 4px;
}

.marker-list ul {
  margin: 0px;
  padding: 0px;
}

.marker-list ul:first-child {
  margin-right: 19px;
}

.marker-list ul li {
  height: 32px;
  width: fit-content;
  border-radius: 4px;
  list-style-type: none;
  font-size: 14px;
  margin: 0;
  cursor: var(--global-cursor-pointer);
  padding: 8px 12px 12px 8px;
  display: flex;
  align-items: center;
}

.marker-list ul li:hover {
  background-color: var(--global-colors-sky-light);
}

.marker-offline-circle {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  float: left;
  margin-right: 10px;
  background-color: var(--bs-gray-500);
}

.marker-maintenance-circle {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  float: left;
  margin-right: 10px;
  background-color: var(--global-colors-status-maintenance);
}

.marker-online-circle {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  float: left;
  margin-right: 10px;
  background-color: var(--global-colors-status-online);
}

.marker-stopped-circle {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  float: left;
  margin-right: 10px;
  background-color: var(--global-colors-status-stopped);
}
