.mn-only-stop-row {
  width: auto;
  height: 70px;
  padding: 24px;
  border-right: 4px solid transparent;
}

.mn-only-stop-row-point {
  font-size: var(--global-font-size-xs);
  font-family: var(--global-font-regular);
  display: flex;
  align-items: center;
  height: 20px;
}

.mn-only-stop-row-point span:first-child {
  margin-right: var(--global-spacing-xs);
}

.mn-only-stop-row-point > div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mn-only-stop-row:hover {
  cursor: var(--global-cursor-pointer);
  background-color: var(--global-colors-sky-lighter);
}

.mn-only-stop-row--focused {
  background-color: #f1f2f4;
  border-right: 4px solid var(--global-colors-ui-primary);
}
