.datepicker-dropdown-btn {
  display: flex;
  border: 1px solid var(--global-colors-sky-dark);
  border-radius: 4px;
  cursor: var(--global-cursor-pointer);
  outline: none;
  transition: all 200ms ease;
  height: 32px;
  background-color: transparent;
  font-family: var(--global-font-regular);
  color: var(--global-colors-ink-light);
}

.datepicker-dropdown-btn.active {
  border: 1px solid var(--global-colors-ink-light);
  cursor: var(--global-cursor-default);
  color: var(--global-colors-ui-black);
}

.datepicker-dropdown-btn span {
  width: 100%;
  font-size: var(--global-font-size-sm);
  color: var(--global-colors-ink-light);
  cursor: var(--global-cursor-pointer);
}

.datepicker-dropdown-btn input {
  display: flex;
  padding-left: 16px;
  padding-right: 12px;
  font-family: var(--global-font-regular);
  font-size: 14px;
  outline: none;
  border: none;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  cursor: var(--global-cursor-pointer);
}

.datepicker-dropdown-btn span .first-compare-date {
  font-size: 11px;
  height: auto;
  line-height: 0px;
  margin-top: 2px;
}

.datepicker-dropdown-btn span .second-compare-date {
  font-size: 10px;
  height: auto;
  color: var(--global-colors-ink-light);
  line-height: 0px;
  margin-top: -2px;
}

.datepicker-dropdown-btn.active input::placeholder {
  color: black;
}
.datepicker-dropdown-btn .dp-dropdown-arrow {
  display: flex;
  margin-top: 1px;
  margin-right: 12px;
  align-items: center;
  transition: all 0.2s ease-in-out;
  cursor: var(--global-cursor-pointer);
}

.datepicker-dropdown-btn .dp-dropdown-arrow.open {
  transform: translateY(-4%) rotate(180deg);
}

.datepicker-dropdown-container {
  position: absolute;
  z-index: 1000;
  margin-top: 4px;
}
