.borderRadiusCanvas {
  border-radius: 50%;
}

.borderRadiusCanvas.imegeCanvas {
  width: 199.14px;
  height: 199.14px;
  cursor: var(--global-cursor-grab);
  touch-action: none;
}
