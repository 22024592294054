.validation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.validation-logo {
  width: 200px;
  margin-bottom: 132px;
}

.validation-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.validation-success,
.validation-fail {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 48px;
}

.validation-success .check--small,
.validation-fail .check--small {
  width: 100px;
  height: 100px;
  margin-bottom: 48px;
}

.validation-success .check--small .svg span,
.validation-fail .check--small .svg span {
  font-size: 52px !important;
}

.validation-success .mn-label-text,
.validation-fail .mn-label-text {
  text-align: center;
}

.validation-success .mn-label-text .mn-label-text__label,
.validation-fail .mn-label-text .mn-label-text__label {
  font-size: 42px;
}

.validation-success .mn-label-text .mn-label-text__text,
.validation-fail .mn-label-text .mn-label-text__text {
  font-size: 24px;
}

.validation-loader {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  border: 2px solid var(--global-colors-ink-light);
  border-top-color: transparent;
  animation: spinner 0.6s linear infinite;
}
