.refuel-report-arrow-box {
  font-family: var(--global-font-semi-bold) !important;
  font-size: 10px;
  text-align: center;
  align-items: center;
  color: var(--global-colors-ui-white);
  display: flex;
  background-color: var(--global-colors-cyan-blue);
  border-color: var(--global-colors-cyan-blue);
  height: 21px;
  padding: 0 8px;
  text-transform: uppercase;
  opacity: 1;
  border-radius: 4px;
}

.refuel-report-graph {
  padding: 20px 24px 0 20px !important;
}

.refuel-report-graph .apexcharts-legend .apexcharts-legend-series:first-child {
  margin-right: 20px !important;
}

.refuel-report-graph .apexcharts-legend .apexcharts-legend-series {
  display: flex !important;
  align-items: center !important;
}

.refuel-report-graph
  .apexcharts-legend
  .apexcharts-legend-series
  .apexcharts-legend-text {
  margin-left: 4px !important;
}

.refuel-report-graph .apexcharts-reset-icon,
.refuel-report-graph .apexcharts-zoomin-icon,
.refuel-report-graph .apexcharts-zoomout-icon {
  transform: none;
  margin-left: 6px;
  margin-right: 0px;
  width: auto;
  height: auto;
}

.refuel-report-graph .apexcharts-menu,
.refuel-report-graph .apexcharts-zoom-icon {
  display: none;
}

.refuel-report-graph .refuel-report-graph-loader {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  border: 2px solid var(--global-colors-ink-light);
  border-top-color: transparent;
  animation: spinner 0.6s linear infinite;
  margin-left: auto;
  margin-right: 32px;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
