.upload-image .mn-modal__dialog-body {
  display: flex;
  flex-direction: column;
  width: 100% !important;
}

.upload-image .mn-modal__dialog {
  max-width: 620px;
  min-width: 620px;
  max-height: 795px;
  min-height: 795px;
}

.marginTop .mn-modal__dialog {
  margin-top: 160px;
}

.columns-available.image-import:first-child > div:nth-child(2) {
  margin-left: 77px;
}

.columns-available.image-import:first-child > div:first-child {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.columns-available.image-import canvas {
  margin-top: 27px;
}

.columns-available.image-import:first-child > div:first-child > div {
  margin-bottom: -5px;
  margin-top: 42px;
}

.columns-available.image-import.dropfileerror {
  display: block;
  margin-top: 35px;
  min-width: 100%;
  max-width: 100%;
}

.columns-available.image-import.dropfilecontainer {
  margin-top: 97px;
  width: 100%;
  padding-bottom: 20px;
}

.columns-available.image-import.dropfilecontainer.showError {
  margin-top: 14px;
}

.columns-available.image-import.dropfilecontainer > div {
  max-width: 100%;
  min-width: 100%;
  min-height: 212px;
  max-height: 212px;
  background-color: #e4e6e9;
  border-radius: 4px;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.12), 0 1px 10px 0 rgba(0, 0, 0, 0.06),
    0 2px 4px -1px rgba(0, 0, 0, 0.1);
}

.upload-image .mn-modal .mn-modal__dialog .mn-modal__dialog-body {
  padding: 27px 36px 0 36px;
  min-height: 638px;
  max-height: 638px;
}

.upload-image .mn-modal .mn-modal__dialog .mn-modal__dialog-footer {
  padding: 24px;
}

.disableCloseButton
  .mn-modal
  .mn-modal__dialog
  .mn-modal__dialog-header
  .mn-modal__dialog-close
  .svg {
  pointer-events: none;
}
