.mn-chart-rel {
  position: relative;
}

.mn-chart-icon {
  position: absolute;
  top: 20%;
  left: 40%;
}

.mn-chart-var-val {
  position: absolute;
  top: 45%; 
  font-size: var(--global-font-size-xl);
  text-align: center;
  width: inherit;
  font-family: var(--global-font-medium);
}

.mn-chart-var-sub-val {
  position: absolute;
  bottom: 24px;
  font-size: var(--global-font-size-xs);
  letter-spacing: 0;
  line-height: 16px;
  font-family: var(--global-font-regular);
  text-align: center;
  width: inherit;
  justify-content: center;
  display: inline-flex;
}

.mn-chart-var-sub-val .svg {
  margin-right: 2px;
  margin-top: 0px;
}

.mn-chart-radial-bar svg {
  display: block;
}

.mn-chart-radial-bar svg path {
  stroke-linecap: round;
  stroke-width: 2.2;
  animation: progress 1s ease-out forwards;
}

.mn-chart-radial-bar svg text {
  fill: var(--global-colors-ink-dark);
  text-anchor: middle;
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-xxxxs);
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}
