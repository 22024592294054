.mn-lh-track-details {
  padding: 0;
  width: 261px;
}

.mn-lh-track-details .mn-card__header {
  height: 34px;
  font-size: var(--global-font-size-sm);
  padding: var(--global-spacing-xs) 0 var(--global-spacing-xs)
    var(--global-spacing-md);
  border-bottom: 1px solid var(--global-colors-sky-sky);
  margin-bottom: 0;
}

.mn-lh-track-details .mn-card__header > div {
  margin-top: 8px;
  margin-bottom: 8px;
}

.mn-lh-track-details .mn-card__body {
  margin-top: 0;
}

.mn-lh-track-details .mn-lh-track-details__details {
  padding: var(--global-spacing-xs) var(--global-spacing-md) 0;
}

.mn-lh-track-details__details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.mn-lh-track-details__details > div {
  flex-basis: 47%;
  margin-bottom: 16px;
}

.mn-lh-track-details__details .mn-data-label__text-data > span {
  margin-bottom: 4px;
}

.mn-lh-track-details__details > div:not(:last-child) {
  margin-bottom: var(--global-spacing-md);
}

.mn-lh-track-details__details > .fuel-consumption {
  flex-basis: 100%;
}
