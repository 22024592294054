.ior-filterbar-row {
  display: flex;
  align-items: center;
  font-weight: var(--global-font-semi-bold);
  width: 100%;
  border-bottom: 1px solid var(--global-colors-sky-dark);
}

.ior-filterbar-row .dropdown-list .dropdown-elements .button .label {
  font-family: var(--global-font-regular);
  font-size: 14px;
  text-align: left;
  color: var(--global-colors-ink-dark);
}

.ior-filterbar-search,
.ior-dropdown-groups,
.ior-date-picker,
.ior-dropdown-events,
.ior-dropdown-statuses,
.ior-dropdown-customize {
  margin-left: 12px;
}

.ior-filterbar-btn-generate {
  margin-left: 12px;
  margin-right: 12px;
}

.ior-filterbar-btn-generate #button {
  width: 85px;
}

.ior-filterbar-search {
  margin-left: 24px;
  width: 220px;
}

.ior-date-picker {
  margin-left: 12px;
  height: 32px;
}

.ior-filterbar-btn {
  width: auto;
  margin: 24px 16px 24px auto;
  display: flex;
}

.ior-filterbar-btn-schedule {
  margin-right: 12px;
}

.ior-filterbar-row .dropdown-list {
  background-color: var(--global-colors-ui-white);
  position: absolute;
  right: 0px;
  width: max-content;
}

.ior-filterbar-btn .dropdown-elements {
  font-family: var(--global-font-regular);
  font-size: 14px;
  text-align: left;
  color: var(--global-colors-ink-dark);
}

.ior-filterbar-search .ior-search-results {
  box-sizing: border-box;
  height: auto;
  width: 234px;
  border: 1px solid var(--global-colors-sky-sky);
  border-radius: 4px;
  margin-top: 4px;
  position: absolute;
  background-color: var(--global-colors-ui-white);
  z-index: 999;
  max-height: 300px;
  overflow-y: auto;
  cursor: var(--global-cursor-pointer);
}

.ior-filterbar-search .ior-search-results .ior-fleet-label {
  font-weight: var(--global-font-semi-bold);
  color: var(--global-colors-ink-light);
  font-size: 12px;
  padding-left: 16px;
  padding-top: 12px;
  padding-bottom: 4px;
}

.ior-search-results .ior-driverbox {
  height: 72px;
  font-weight: var(--global-font-semi-bold);
  font-size: 14px;
}

.ior-filterbar-search
  .ior-search-results
  .ior-driverbox-selected
  .mn-user-box__upper-row--regular,
.ior-filterbar-search
  .ior-search-results
  .ior-driverbox-selected
  .mn-user-box__lower-row {
  color: var(--global-colors-ui-white);
}

.ior-filterbar-search .ior-search-results .ior-driverbox-selected {
  background-color: var(--global-colors-ui-primary);
  color: var(--global-colors-ui-white);
  height: 38px;
  font-weight: var(--global-font-semi-bold);
  font-size: 14px;
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 11px;
}

.ior-filterbar-search .ior-search-results .ior-alias-selected {
  background-color: var(--global-colors-ui-primary);
  color: var(--global-colors-ui-white);
  height: 38px;
  display: flex;
  font-weight: var(--global-font-semi-bold);
  font-size: 14px;
  padding: 8px 16px 11px 16px;
}

.ior-filterbar-search .ior-search-results .ior-alias {
  height: 38px;
  display: flex;
  font-weight: var(--global-font-semi-bold);
  font-size: 14px;
  padding: 8px 16px 11px 16px;
}

.ior-filterbar-search .ior-search-results .ior-alias:hover {
  background: var(--global-colors-sky-lighter);
}

.ior-filterbar-search .ior-search-results .ior-dot {
  display: flex;
  justify-content: center;
  color: var(--global-colors-ink-lighter);
  width: 24px;
  font-size: 14px;
}

.ior-filterbar-search .ior-search-results .ior-dot::after {
  content: "\2022";
}

.ior-filterbar-search .ior-search-results .ior-driverbox:hover {
  background: var(--global-colors-sky-lighter);
}
