.archive-file-detail-modal .mn-modal .mn-modal__dialog {
  height: auto;
  min-height: 626px;
  max-width: 1240px;
  border-radius: 4px;
}

.archive-file-detail-modal .mn-modal .mn-modal__dialog .mn-modal__dialog-body {
  padding: 0;
  min-height: 486px;
  border-top: var(--global-colors-sky-sky-outline);
}

.archive-file-detail-modal
  .mn-modal
  .mn-modal__dialog
  .mn-modal__dialog-header
  .mn-modal__dialog-title {
  margin-bottom: 12px;
}

.archive-file-detail-modal .mn-table {
  border-top: var(--global-colors-sky-sky-outline);
  background-color: var(--global-colors-ui-white);
  text-align: left;
}

.archive-file-detail-modal table {
  display: block;
}

.archive-file-detail-modal tbody {
  display: block;
  max-height: 400px;
  overflow: auto;
}

.archive-file-detail-modal .mn-table thead th {
  height: 60px;
  padding: 21px 24px 21px 24px;
}

.archive-file-detail-modal .mn-table tbody td {
  white-space: nowrap;
  height: 60px;
  padding: 12px 24px 12px 24px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.archive-file-detail-modal thead {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.archive-file-detail-modal tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

/* Columns table body resize */
.archive-file-detail-modal .mn-table tbody tr td:last-child {
  display: table-cell;
  padding: 14px var(--global-spacing-lg);
  float: unset;
  cursor: var(--global-cursor-default);
}

.archive-file-detail-modal .mn-table tbody tr td .svg {
  padding: 0 0 0 20px;
}

.archive-file-detail-modal .mn-table tbody tr td .svg span {
  display: block;
}

.archive-file-detail-modal .mn-table tbody td button {
  border: unset;
  background-color: unset;
  width: 20%;
  height: 100%;
}
.archive-file-detail-modal .mn-table tbody tr td:last-child .svg {
  float: left;
}

/* Footer */
.archive-file-detail-modal-footer {
  display: flow-root !important;
  box-shadow: 0px 2px 10px 0px rgb(0 0 0 / 8%);
}

.archive-file-table-container .page-count {
  /* for Chrome, Edge, Opera */
  min-width: -webkit-fill-available !important;
  max-width: -webkit-fill-available !important;
  /* for Mozilla */
  min-width: -moz-available !important;
  max-width: -moz-available !important;
}
.archive-file-detail-modal .file-not-found {
  background-color: var(--global-colors-sky-lighter);
  width: 100%;
  height: 406px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
