#batchPolyLineMarker {
  margin-top: -5px;
  z-index: 999;
}

#eventsNumber {
  margin-top: -136px !important;
}

#event {
  margin-top: -76px !important;
}
