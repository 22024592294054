.vehicle-lh-sidebar {
  height: 100%;
}

.vehicle-lh-sidebar .mn-sidebar .mn-sidebar__content {
  width: 302px;
  margin: 0;
  overflow-y: auto;
}

.vehicle-lh-trackSummary .mn-card {
  border-radius: none;
  border: none;
  box-shadow: none;
}

.vehicle-lh-static-map {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.vehicle-lh-trackSummary-header .mn-vehicle-box,
.vehicle-lh-all-tracks-header .mn-vehicle-box--active {
  pointer-events: none;
}

.vehicle-lh-trackSummary-wrapper {
  height: 100%;
}

.vehicle-lh-trackSummary-header {
  overflow-y: hidden;
  overflow-x: hidden;
}

.vehicle-lh-trackSummary {
  overflow-x: hidden;
}

.vehicle-lh-all-tracks-wrapper {
  height: 100%;
}

.vehicle-lh-all-tracks-header {
  overflow-y: hidden;
  overflow-x: hidden;
}

.vehicle-lh-all-tracks {
  overflow-x: hidden;
}

.vehicle-lh-splitter-line {
  height: 1px;
  width: 90%;
  margin: 0 auto;
  background-color: var(--global-colors-sky-sky);
}
