#stops {
  width: 30%;
}

#arrow {
  margin-left: 10px;
}

.route-rows-grouped {
  width: 100%;
}

.routes-container .mn-table thead th {
  padding: 21px var(--global-spacing-lg);
  font-size: var(--global-font-size-xs);
  color: var(--global-colors-ink-light);
  text-align: left;
  cursor: var(--global-cursor-pointer);
  -webkit-user-select: none;
  user-select: none;
  font-weight: normal;
}
.routes-container td.show {
  height: 60px;
  font-family: var(--global-font-regular);
  padding: 8px 24px !important;
  background-color: transparent !important;
  box-shadow: inset 0 -1px 0 0 transparent !important;
}

.routes-container-collapsed .mn-table thead th {
  padding: 21px var(--global-spacing-lg);
  font-size: var(--global-font-size-xs);
  color: var(--global-colors-ink-light);
  text-align: left;
  cursor: var(--global-cursor-pointer);
  -webkit-user-select: none;
  user-select: none;
  font-weight: normal;
}
.routes-container-collapsed td.show {
  height: 60px;
  font-family: var(--global-font-regular);
  padding: 8px 24px;
  background-color: transparent !important;
  box-shadow: inset 0 -1px 0 0 transparent !important;
}

.routes-container-collapsed {
  max-height: 100%;
  width: 64% !important;
  overflow-y: auto;
}

.routes-container-pagination {
  position: fixed;
  bottom: 0;
  z-index: 3;
  /* for Chrome, Edge, Opera */
  min-width: -webkit-fill-available !important;
  max-width: -webkit-fill-available !important;
  /* for Mozilla */
}

.routes-container-pagination-collapsed {
  position: fixed;
  bottom: 0;
  z-index: 3;
  /* for Chrome, Edge, Opera */
  min-width: -webkit-fill-available !important;
  max-width: -webkit-fill-available !important;
  /* for Mozilla */
}

.routes-map-container {
  width: 36% !important;
  height: 100%;
}

.route-tabs .mn-tabs__list {
  padding-left: 24px;
  margin-bottom: -5px;
}

.route-tabs .mn-tabs__content {
  padding: 0px;
}

.routes-container {
  height: calc(100% - (100px));
}

.routes-table-container .withFilterBar {
  height: calc(
    94vh - (var(--header-menu-height) + var(--page-container-filters-height))
  );
  overflow-y: auto;
}

data title style .show-child [data-title] {
  position: relative;
  cursor: var(--global-cursor-pointer);
}

.show-child [data-title]:hover::before {
  content: attr(data-title);
  position: absolute;
  height: 16px;
  padding: 6px;
  text-transform: uppercase;
  border-radius: 4px;
  background: var(--global-colors-ink-dark);
  color: var(--global-colors-ui-white);
  display: flex;
  align-items: center;
  font-size: 10px;
  font-family: var(--global-font-semi-bold);
  white-space: nowrap;
  left: 96px;
  top: 11px;
}

.publicRoutes-filterbar-row {
  display: flex;
  align-items: center;
  font-weight: var(--global-font-semi-bold);
  width: 100%;
  border-bottom: 1px solid var(--global-colors-sky-dark);
}

.publicRoutes-filterbar-search {
  margin-left: 16px;
}

.publicRoutes-filterbar-search,
.publicRoutes-filterbar-vehicles {
  margin-top: 24px;
  margin-bottom: 24px;
  margin-right: 16px;
}

.publicRoutes-filterbar-viewMap {
  width: auto;
  margin-left: auto;
  margin-right: 16px;
  margin-top: 24px;
  margin-bottom: 24px;
}

.public-route-name {
  height: 26px;
  width: auto;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  margin: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.public-route-name-color {
  width: 8px;
  height: 8px;
  border-radius: 7px;
  margin-left: 8px;
  margin-right: 8px;
}

.public-route-name-text {
  height: 18px;
  width: auto;
  color: black;
  font-family: var(--global-font-regular);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  margin-right: 8px;
}

.mn-table tbody tr#publicRoute {
  background-color: var(--global-colors-ui-white);
  border-bottom: solid 1px var(--global-colors-sky-light);
}

.mn-table tbody tr#publicRoute-clicked {
  background-color: var(--global-colors-ui-primary);
  color: var(--global-colors-ui-white);
  border-bottom: solid 1px var(--global-colors-sky-light);
  cursor: var(--global-cursor-pointer);
}

.mn-table tbody tr#publicRoute:hover {
  background-color: var(--global-colors-sky-light);
  cursor: var(--global-cursor-pointer);
}

.mn-table tbody tr:nth-child(odd) {
  background-color: var(--global-colors-sky-lighter);
}

.mn-table tbody tr:nth-child(even) {
  background-color: var(--global-colors-ui-white);
}

#publicRoute-clicked {
  background-color: var(--global-colors-ui-primary);
  color: var(--global-colors-ui-white);
}

.routes-container-collapsed .mn-table tbody tr td:last-child .svg {
  padding: 0px;
  cursor: var(--global-cursor-pointer);
  float: right;
}
