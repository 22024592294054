.radius-label {
  padding-top: 16px;
  padding-bottom: 10px;
  height: 44px;
  color: var(--global-colors-ink-light);
  font-family: var(--global-font-regular);
  font-size: 12px;
}

.slider-zoom-container {
  height: 20px;
  width: 274px;
  display: inline-flex;
  align-items: center;
}

.slider-zoom-container .svg {
  margin-top: 3px;
}

.slider-zoom {
  width: 100%;
  display: contents;
  text-align: right;
}

.slider-zoom-container label {
  font-size: 29px;
  margin-top: -3px;
  cursor: var(--global-cursor-pointer);
}

.slider-zoom-container.visibled {
  display: none;
}

.slider-zoom input[type="range"] {
  -webkit-appearance: none;
  width: 90%;
  height: 3px;
  background: var(--global-colors-sky-sky);
  border-radius: 3px;
  background-size: 0% 100%;
  background-repeat: no-repeat;
  margin-left: 23px;
  margin-right: 23px;
}

/* Input Thumb Edge Browser */
.slider-zoom input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 25px;
  cursor: var(--global-cursor-pointer);
  background: white;
  background: url("../Map/button_ham_1.svg");
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.12), 0 1px 10px 0 rgba(0, 0, 0, 0.06),
    0 2px 4px -1px rgba(0, 0, 0, 0.1);
}

/* Input Track Edge Browser */
.slider-zoom input[type="range"]::-webkit-slider-runnable-track {
  box-shadow: none;
  border: none;
  cursor: var(--global-cursor-pointer);
  background: transparent;
  border-radius: 3px;
  -webkit-appearance: none;
}

/* Input Thumb Mozzilla Browser */
.slider-zoom input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 25px;
  cursor: var(--global-cursor-pointer);
  background: white;
  background: url("../Map/button_ham_1.svg");
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.12), 0 1px 10px 0 rgba(0, 0, 0, 0.06),
    0 2px 4px -1px rgba(0, 0, 0, 0.1);
}

/* Input Track Mozzilla Browser */
.slider-zoom input[type="range"]::-moz-range-track {
  box-shadow: none;
  border: none;
  cursor: var(--global-cursor-pointer);
  background: transparent;
  border-radius: 3px;
  -webkit-appearance: none;
}

/* Input Thumb Chrome Browser */
.slider-zoom input[type="range"]::-ms-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 25px;
  cursor: var(--global-cursor-pointer);
  background: white;
  background: url("../Map/button_ham_1.svg");
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.12), 0 1px 10px 0 rgba(0, 0, 0, 0.06),
    0 2px 4px -1px rgba(0, 0, 0, 0.1);
}
