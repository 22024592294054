.filter-row {
  border-bottom: 1px solid var(--global-colors-sky-dark);
  display: flex;
  align-items: center;
  font-weight: var(--global-font-semi-bold);
  width: 100%;
  height: 100%;
}

.fleet-control-search {
  margin-left: 16px;
}

.dropdown-groups,
.dropdown-statuses,
.dropdown-orderby {
  margin-left: 12px;
}

.multiview-btn {
  min-width: 117px;
  margin: 24px 16px 24px auto;
}
