.mn-chart__label {
  margin-right: 9px;
}

.mn-chart__data {
  color: var(--global-colors-ink-light);
  font-size: var(--global-font-size-xxs);
  font-family: var(--global-font-regular);
}

.polling-container {
  height: 32px;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  background-color: var(--global-colors-ui-white);
  display: flex;
  white-space: nowrap;
  align-items: center;
  padding: 13px;
}

.progress-bar {
  background-color: var(--global-colors-ui-primary);
}

.progress-bar--blue {
  background-color: var(--global-colors-ui-secondary);
}
