.switch {
  z-index: 0;
  position: relative;
  display: flex;
  width: fit-content;
  height: 40px;
  font-family: var(--global-font-semi-bold);
  font-size: var(--global-font-size-md);
  line-height: 1.5;
  align-items: center;
}

/* Input */
.switch > input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  z-index: -1;
  position: absolute;
  right: 6px;
  display: block;
  margin: 0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: var(--global-colors-sky-dark);
  outline: none;
  opacity: 0;
  transform: scale(1);
  pointer-events: none;
  transition: opacity 0.3s 0.1s, transform 0.2s 0.1s;
}

/* Span */
.switch > span {
  display: flex;
  width: 100%;
  cursor: var(--global-cursor-pointer);
  align-items: center;
}

/* Track */
.switch > span::before {
  content: "";
  display: inline-block;
  border-radius: 7px;
  width: 36px;
  height: 14px;
  background-color: var(--global-colors-sky-sky);
  transition: background-color 0.2s, opacity 0.2s;
}

/* Thumb */
.switch > span::after {
  content: "";
  position: absolute;
  top: 10px;
  right: 16px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: var(--global-colors-ui-white);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition: background-color 0.2s, transform 0.2s;
}

/* Checked */
.switch > input:checked {
  right: -10px;
  background-color: var(--global-colors-ui-primary);
}

.switch > input:checked:not(input:disabled) + span::before {
  background-color: var(--global-colors-ui-primary);
  opacity: 0.4;
}

.switch > input:checked + span::after {
  background-color: var(--global-colors-ui-primary);
  transform: translateX(16px);
}

/* Hover */
.switch:hover > input {
  opacity: 0.2;
}

.switch:hover > input:checked {
  opacity: 0.1;
}

/* Focus */
/* .switch > input:focus {
  opacity: 0.12;
}

.switch:hover > input:focus {
  opacity: 0.16;
}  */

/* Active */
.switch > input:active {
  opacity: 1;
  transform: scale(0);
  transition: transform 0s, opacity 0s;
}

.switch > input:active + span::before {
  background-color: var(--global-colors-ui-primary);
}

/* Disabled */
.switch > input:disabled {
  opacity: 0;
}

.switch > input:disabled + span {
  cursor: var(--global-cursor-not-allowed);
}

.switch > input:disabled + span::before {
  background-color: var(--global-colors-sky-sky);
}

.switch > input:disabled + span::after {
  background-color: var(--global-colors-ink-light);
}

.switch:hover > input:disabled {
  display: none;
}
