.vtr-reportDate {
  font-weight: 600;
  margin-bottom: 0;
  font-family: var(--global-font-semi-bold);
}

.vtr-reportTime {
  color: var(--global-colors-ink-light);
}

.vtr-rows-container {
  width: 100%;
  overflow-x: auto;
  overflow-y: scroll;
  max-height: calc(100% - (304px));
  position: absolute;
}

.vtr-rows-container-collapsed {
  overflow-x: auto;
  width: 100%;
  max-height: calc(100% - (304px));
}

.vtr-rows-container-collapsed-numbers {
  overflow-x: auto;
  width: 90%;
  max-height: calc(100% - (304px));
}

.vtr-rows-container-collapsed-numbers table,
.vtr-rows-container-collapsed table {
  width: 164%;
  overflow-x: auto;
}

.vtr-rows-container table {
  width: 100%;
  overflow-x: auto;
}

.vtr-rows-container table tbody tr td:last-child {
  padding: 14px var(--global-spacing-lg);
  cursor: var(--global-cursor-default);
}

.vtr-rows-container-collapsed table tbody tr td:last-child,
.vtr-rows-container-collapsed-numbers table tbody tr td:last-child {
  padding: 14px var(--global-spacing-lg);
  cursor: var(--global-cursor-default);
}

.vtr-rows-container-collapsed-numbers
  table
  tbody
  tr:last-child
  .dropdown-btn
  .dropdown-list-only-icon,
.vtr-rows-container-collapsed
  table
  tbody
  tr:last-child
  .dropdown-btn
  .dropdown-list-only-icon,
.vtr-rows-container
  table
  tbody
  tr:last-child
  .dropdown-btn
  .dropdown-list-only-icon {
  top: -86px;
}

.vtr-checkbox-list {
  float: left;
}

.vtr-checkbox {
  width: 60px;
  height: 80px;
  padding: 14px;
  font-size: var(--global-font-size-sm);
  font-family: var(--global-font-regular);
  display: flex;
  align-items: center;
}

.vtr-marker-list {
  float: right;
}

.vtr-marker {
  width: 84px;
  padding: 10px var(--global-spacing-lg);
  font-size: var(--global-font-size-sm);
  font-family: var(--global-font-regular);
  word-wrap: break-word;
  display: flex;
  align-items: center;
}

.vtr-marker:nth-child(odd),
.vtr-checkbox:nth-child(odd) {
  background-color: var(--global-colors-sky-lighter);
}

.vtr-marker:nth-child(even),
.vtr-checkbox:nth-child(even) {
  background-color: var(--global-colors-ui-white);
}

.vtr-table-container .page-count {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.vtr-table-container-collapsed {
  max-height: calc(100% - (162px));
  width: 64%;
  overflow-y: auto;
}

.vtr-table-container-collapsed .page-count {
  width: 64%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
}

.vtr-map-container {
  width: 36%;
  height: calc(100% - (100px));
}

.vtr-marker-column {
  position: fixed;
}

.vtr-container .withFilterBar {
  overflow-y: hidden;
}

.details-report-wrapper {
  padding: 16px 16px 16px 32px;
  font-size: var(--global-font-size-sm);
  line-height: 18px;
  text-align: left;
  height: 100px;
  border-radius: 4px;
  border-bottom: var(--global-colors-sky-sky-outline);
}

.details-report-wrapper .details-report-title {
  margin-bottom: 16px;
  font-family: var(--global-font-medium);
  font-size: var(--global-font-size-sm);
}

.details-report-wrapper .details-report-container {
  height: 34px;
  align-items: center;
  width: 100%;
  display: flex;
}

.details-report-wrapper .details-report-icons {
  display: flex;
  padding-left: 8px;
  float: left;
  min-width: 1022px;
}

.details-report-wrapper .details-report-item {
  margin-right: 20px;
  max-width: 16%;
  min-width: 120px;
  width: 10vw;
}

.details-report-wrapper .details-report-item:last-child {
  margin: 0;
}

.details-report-wrapper .details-report-button {
  display: flex;
  height: 32px;
  float: right;
  margin-left: auto;
}

.details-report-wrapper .details-report-button button {
  min-width: 146px;
}

.details-report-wrapper .details-report-loader {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid var(--global-colors-ink-light);
  border-top-color: transparent;
  animation: spinner 0.6s linear infinite;
  margin-right: 15px;
}
