.subtopbar {
  height: 82px;
  width: 100%;
  margin: 0;
  background-color: var(--global-colors-ui-white);
  border-bottom: 1px solid #d2d5da;
  padding: 25px 24px;
  align-items: center;
}

.subsection-security-name {
  max-height: 28px;
  max-width: 205px;
  color: var(--global-colors-ink-dark);
  font-family: var(--global-font-regular);
  font-size: 24px;
  letter-spacing: 0;
  line-height: 28px;
  margin: 0;
  float: left;
}

.button-save-group {
  float: right;
}

.button-save-group .button {
  min-width: 57px;
  min-height: 32px;
  height: 32px;
  width: auto;
}

/* Security Psw Form */

.form-container {
  position: relative;
  top: 0;
  background-color: var(--global-colors-sky-lighter);
  width: auto;
  height: 100%;
  overflow-y: scroll;
}

.as-forms-title {
  font-family: var(--global-font-medium);
  font-size: var(--global-font-size-sm);
  width: 153px;
  height: 18px;
  margin-top: 36px;
  margin-left: 36px;
}

.as-forms-container {
  width: auto;
  height: 42px;
  margin-top: 24px;
  margin-left: 36px;
  margin-right: 77px;
  vertical-align: middle;
}

.as-forms-container:last-child {
  margin-bottom: 36px;
}

.security-psw-label {
  font-family: var(--global-font-semi-bold);
  font-size: var(--global-font-size-xs);
  color: var(--global-colors-ink-light);
  width: 127px;
  height: 18px;
  margin-top: 12px;
  line-height: 18px;
  float: left;
}

.security-psw-forms {
  width: calc(100% - (127px + 77px));
  height: 42px;
  float: right;
  margin-left: 77px;
}

.security-psw-form {
  margin: 16px 16px;
  box-sizing: border-box;
  height: auto + 20px;
  width: auto;
  background-color: var(--global-colors-ui-white);
  border: 1px solid #e4e6e9;
  border-radius: 4px;
}

.security-psw-form .constraint-container {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 233px;
}

.security-psw-form .constraint-container.invalidPassword {
  margin-top: 35px;
}

.security-psw-form .constraint-title {
  color: var(--global-colors-ink-light);
  font-size: var(--global-font-size-xs);
  margin-bottom: 6px;
  margin-left: 25px;
  font-family: var(--global-font-medium);
}

.security-psw-form .hide-constraint-check {
  display: none;
}

.security-psw-form .hide-constraint-check ~ div {
  margin-left: 15px;
}

.security-psw-form .constraint-check ~ div {
  margin-left: 6px;
}

.security-psw-form .default-constraint {
  font-size: var(--global-font-size-xs);
  color: var(--global-colors-ui-primary);
}

.security-psw-form .success-constraint {
  font-size: var(--global-font-size-xs);
  color: var(--global-colors-feedback-success);
}

.security-psw-form .error-constraint {
  font-size: var(--global-font-size-xs);
  color: var(--global-colors-feedback-danger);
}

.password-not-equals input {
  border-color: var(--global-colors-feedback-danger) !important;
}

.password-not-equals .text-field p {
  margin: 0;
}

.password-not-equals .password-not-equals-label {
  color: var(--global-colors-feedback-danger);
  font-size: 11px;
  display: flex;
  align-items: center;
  font-family: var(--global-font-light);
  margin-top: 4px;
}

.new-password-conflict input {
  border-color: var(--global-colors-feedback-danger) !important;
}

.left-form .new-password-conflict .text-field p {
  margin: 0;
}

.left-form .new-password-conflict-label {
  color: var(--global-colors-feedback-danger);
  font-size: 11px;
  display: flex;
  align-items: center;
  font-family: var(--global-font-light);
  margin-top: 4px;
}

::placeholder {
  color: var(--global-colors-ink-light);
}

.main-check {
  display: flex;
  align-items: center;
  margin-left: 9px;
}
