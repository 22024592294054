.container-wrapper {
  width: 100%;
  min-height: 100%;
  max-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex: 1 1;
  flex-wrap: nowrap;
}
