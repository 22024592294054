.tachograph-file-filterbar-row {
  display: flex;
  align-items: center;
  font-weight: var(--global-font-semi-bold);
  width: 100%;
  height: 80px;
  padding: 24px;
  border-bottom: 1px solid var(--global-colors-sky-dark);
}

.tachograph-file-filter-btn-generate #button {
  width: 85px;
}

.tachograph-file-filterbar-row > div:not(:last-child) {
  margin-right: 16px;
}
