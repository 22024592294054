.gmnoprint.gm-bundled-control {
  bottom: 44px !important;
  padding: 0 16px !important;
}

.gm-svpc {
  background-color: #35465b !important;
  width: 32px !important;
  height: 32px !important;
  border-radius: 4px !important;
  margin-left: 2px !important;
}

.gm-svpc:hover {
  cursor: var(--global-cursor-grab) !important;
}

.gm-svpc:active {
  cursor: var(--global-cursor-grabbing) !important;
}

.gm-svpc > div img:nth-child(1),
.gm-svpc > div img:nth-child(2) {
  height: 20px !important;
  width: 20px !important;
}

.gm-svpc > div img:last-child {
  height: 30px !important;
  width: 30px !important;
}

.gm-svpc.disabled {
  background-color: var(--global-colors-ui-sky-sky--focus) !important;
  pointer-events: none;
  cursor: auto;
}

.gm-svpc.disabled div img {
  filter: grayscale(1);
}

.gm-style-iw-d + button {
  display: none !important;
}

.gm-style > div {
  cursor: var(--global-cursor-default) !important;
}

.set-width {
  width: 100px;
}

.gm-style-iw-chr {
  display: none;
}
