.ticket-modal .mn-modal .mn-modal__dialog {
  max-width: 660px;

  max-height: 942px;
}

.info-modal .mn-modal .mn-modal__dialog {
  max-width: 660px;
  max-height: 750px;
}
