.dropdown {
  position: relative;
  cursor: var(--global-cursor-pointer);
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-sm);
}

.dropdown .clear {
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
  line-height: 0;
  color: var(--global-colors-ui-white);
  width: 22px;
  height: 22px;
  background-color: var(--global-colors-ink-light);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 150px;
  cursor: var(--global-cursor-pointer);
}

.dropdown.dropdown--small .clear {
  right: 37px;
  width: 18px;
  height: 18px;
  cursor: var(--global-cursor-pointer);
}

/*#region clear single item*/
.dropdown .clear-item {
  color: var(--global-colors-ui-white);
  background-color: var(--global-colors-ink-light);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 150px;
  margin-left: auto;
  cursor: var(--global-cursor-pointer);
}

.dropdown.dropdown--small .clear-item {
  width: 18px;
  height: 18px;
  cursor: var(--global-cursor-pointer);
}
/*#endregion clear single item*/

.dropdown .arrow {
  display: block;
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--global-colors-ink-light);
  line-height: 0;
  transition: all 0.2s ease-in-out;
}

.dropdown .arrow.open {
  transform: translateY(-50%) rotate(180deg);
}

.dropdown .arrow.iconDisabled {
  color: var(--global-colors-ink-light);
  opacity: 0.5;
  cursor: var(--global-cursor-not-allowed);
}

.dropdown .selected-value input {
  border: 1px solid var(--global-colors-ink-light);
  border-radius: 4px;
  cursor: var(--global-cursor-pointer);
  outline: none;
  transition: all 200ms ease;
  width: 100%;
  height: 42px;
  padding: var(--global-spacing-sm) var(--global-spacing-xl)
    var(--global-spacing-sm) var(--global-spacing-md);
  background-color: transparent;
  font-family: var(--global-font-regular);
  color: var(--global-colors-ink-dark);
}

.dropdown .selected-value input:placeholder-shown {
  border: 1px solid var(--global-colors-sky-dark);
}

.dropdown .selected-value input:placeholder-shown:hover {
  border: 1px solid var(--global-colors-sky-dark);
  background-color: transparent;
}

.dropdown .selected-value.permanentBorder input:placeholder-shown {
  border: 1px solid var(--global-colors-ui-primary--focus);
}

.dropdown .selected-value input:hover {
  background-color: var(--global-colors-sky-light);
}

.dropdown.dropdown--small .selected-value input {
  padding: var(--global-spacing-xs) var(--global-spacing-md);
  height: 32px;
  padding-right: 32px;
}

.dropdown .selected-value input:focus {
  border: 1px solid var(--global-colors-ui-primary--focus);
}

.dropdown .selected-value input:disabled {
  background-color: var(--global-colors-sky-dark);
  opacity: 0.5;
  border: 1px solid var(--global-colors-ui-sky-sky--hover);
  cursor: var(--global-cursor-not-allowed);
}

.dropdown .selected-value input:disabled:hover {
  background-color: var(--global-colors-sky-dark);
  opacity: 0.5;
}

.dropdown .options {
  display: none;
  user-select: none;
  background-color: var(--global-colors-ui-white);
  font-family: var(--global-font-regular);
  border: 1px solid var(--global-colors-sky-sky);
  border-radius: 4px;
  margin-top: 4px;
  max-height: 300px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
}

.dropdown .options.open {
  display: block;
}

.dropdown .option {
  box-sizing: border-box;
  min-height: 38px;
  color: var(--global-colors-ink-dark);
  cursor: var(--global-cursor-pointer);
  padding: var(--global-font-size-xxxxs) var(--global-font-size-xs);
  font-size: var(--global-font-size-sm);
  display: flex;
  align-items: center;
}

.dropdown .option:hover {
  background-color: var(--global-colors-ui-secondary-light);
  color: var(--global-colors-ink-dark);
}

.dropdown .option.selected {
  background-color: var(--global-colors-ui-primary);
  color: var(--global-colors-ui-white);
}

.dropdown--has-radio .option.selected,
.dropdown--has-radio .option:hover {
  background-color: transparent;
  color: var(--global-colors-ink-dark);
}

.dropdown--has-checkbox .option.selected,
.dropdown--has-checkbox .option:hover {
  background-color: transparent;
  color: var(--global-colors-ink-dark);
}

.dropdown .option .color {
  width: 14px;
  height: 14px;
  border-radius: 150px;
  display: inline-block;
  margin-right: var(--global-spacing-md);
}

.dropdown .option.selected .svg,
.dropdown .option:hover .svg {
  color: var(--global-colors-ui-white);
}

.dropdown .search {
  padding: var(--global-spacing-md);
  position: relative;
}

/* Button Style */
.dropdown-button button {
  width: 100%;
  height: 38px;
}

.dropdown-button .button .label {
  width: 100%;
  padding: 0;
  margin-left: 16px;
}

.dropdown-button .button .label .text {
  font-size: var(--global-font-size-sm);
}

/* Split-line Style */
.split-line {
  height: 1px;
  width: 100%;
  background-color: var(--global-colors-sky-sky);
}
