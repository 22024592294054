/*#region FilterBar */
.ag-subtopbar {
  height: 82px;
  width: 100%;
  margin: 0;
  background-color: var(--global-colors-ui-white);
  padding: 25px 24px;
  align-items: center;
}

.ag-subsection-name {
  max-height: 28px;
  color: var(--global-colors-ink-dark);
  font-family: var(--global-font-regular);
  font-size: 24px;
  letter-spacing: 0;
  line-height: 28px;
  margin: 0;
  float: left;
}

.ag-button-save-group {
  float: right;
}

.ag-button-save-group .button {
  min-width: 57px;
  min-height: 32px;
  height: 32px;
  width: auto;
}
/*#endregion FilterBar */

/*#region groups */
.group-tabs-container .mn-tabs__content {
  display: flex;
  background-color: var(--global-colors-sky-lighter);
  width: 100%;
}

.group-tabs-container .mn-tabs__content .widget:first-child {
  margin-right: 16px;
}

.group-tabs-container .mn-tabs .mn-tabs__list {
  border-bottom: 1px solid var(--global-colors-sky-dark);
}

/*#endregion groups */
