.track-bar-marker {
  position: absolute;
  cursor: var(--global-cursor-pointer);
  width: 36px;
  align-items: center;
  line-height: 32px;
  border: 2px solid var(--global-colors-ui-white);
  height: 36px;
  border-radius: 50%;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  padding: 0px;
}

.track-bar-marker-small {
  position: absolute;
  cursor: var(--global-cursor-pointer);
  width: 27px;
  align-items: center;
  line-height: 24px;
  border: 2px solid var(--global-colors-ui-white);
  height: 27px;
  border-radius: 50%;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  padding: 0px;
}

.track-bar-marker-inner {
  width: 32px;
  height: 32px;
  font-family: var(--global-font-semi-bold);
  justify-content: center;
  display: flex;
  border-radius: 50%;
  box-shadow: inset 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
}

.track-bar-marker-inner-small {
  width: 23px;
  height: 23px;
  font-size: var(--global-font-size-xs);
  font-family: var(--global-font-semi-bold);
  justify-content: center;
  display: flex;
  border-radius: 50%;
  box-shadow: inset 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
}

.track-bar-marker-triangle {
  border-top: 6px solid var(--global-colors-ui-white);
  border-left: 4px dashed transparent;
  border-right: 4px dashed transparent;
  border-bottom: 0;
  position: absolute;
  z-index: 1;
  display: block;
  top: calc(100% + 1px);
  left: calc(100% - 20px);
  overflow: hidden;
  width: 0;
  height: 0;
  filter: drop-shadow(0px 2px 1px rgb(0 0 0 / 0.2));
}

.track-bar-marker-triangle-small {
  border-top: 5px solid var(--global-colors-ui-white);
  border-left: 3px dashed transparent;
  border-right: 3px dashed transparent;
  border-bottom: 0;
  position: absolute;
  z-index: 1;
  display: block;
  top: calc(100% + 1px);
  left: calc(100% - 15px);
  overflow: hidden;
  width: 0;
  height: 0;
  filter: drop-shadow(0px 2px 1px rgb(0 0 0 / 0.2));
}

.cluster-pie {
  position: absolute;
  top: -5px;
  left: -18px;
}

.cluster-pie .apexcharts-legend-marker {
  position: absolute;
}

.cluster-pie .apexcharts-legend-series {
  position: relative;
  padding-left: 35px;
}
