.mn-select {
  position: relative;
  width: auto;
}

.mn-select:hover {
  cursor: var(--global-cursor-pointer);
}

/* Style for default value selected in input */
.mn-select .mn-select__inner {
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-sm);
  color: var(--global-colors-ink-light);
  padding: var(--global-spacing-sm) var(--global-spacing-md);
  height: 42px;
  width: 100%;
  border: 1px solid var(--global-colors-ink-light);
  border-radius: 4px;
  outline: none;
  background-color: transparent;
  transition: all 0.3s ease;
  cursor: var(--global-cursor-pointer);
}

.mn-select.mn-select--has-value .mn-select__inner {
  border: 1px solid var(--global-colors-ink-light);
  color: var(--global-colors-ink-dark);
}

.mn-select:focus .mn-select__inner,
.mn-select:active .mn-select__inner {
  border: 1px solid var(--global-colors-ui-primary);
}

.mn-select.mn-select--small .mn-select__inner {
  height: 32px;
  padding: var(--global-spacing-xs) var(--global-spacing-md);
}

.mn-select .mn-select__inner .mn-select__inner-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mn-select .mn-select__inner .mn-select__label {
  user-select: none;
  margin-right: 14px;
  margin-top: -2px;
}

.mn-select .mn-select__clear-btn {
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
  line-height: 0;
  color: var(--global-colors-ui-white);
  width: 22px;
  height: 22px;
  background-color: var(--global-colors-ink-light);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 150px;
}

.mn-select .mn-select__clear-btn .svg {
  color: var(--global-colors-ui-white);
}

.mn-select .mn-select__drop-btn {
  display: flex;
  align-items: center;
  transition: all 0.2s ease-in-out;
  cursor: var(--global-cursor-pointer);
  margin-top: -3px;
}

.mn-select .mn-select__drop-btn-open {
  transition: all 0.2s ease-in-out;
  transform: translateY(-4%) rotate(180deg);
  margin-top: -3px;
}

.mn-select.mn-select--is-open .mn-select__inner {
  border: 1px solid var(--global-colors-ui-primary--focus);
}

.mn-select .mn-select__options {
  width: max-content;
  box-shadow: var(--global-elevation-02);
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  border: 1px solid var(--global-colors-sky-sky);
  background-color: var(--global-colors-ui-white);
  position: absolute;
  top: 100%;
  left: 0;
  outline: 0;
  margin-top: var(--global-spacing-xs);
  height: 350px;
  overflow-y: auto;
  z-index: 9;
}

/* Style for selected option from list */
.mn-select .mn-select__options .mn-select__option {
  box-sizing: border-box;
  height: 38px;
  color: var(--global-colors-ink-dark);
  padding: var(--global-font-size-xxxxs) var(--global-font-size-md);
  font-size: var(--global-font-size-sm);
  display: flex;
  margin: 0;
  border: none;
  background-color: var(--global-colors-ui-white);
  align-items: center;
  width: 100%;
  text-align: left;
}

.mn-select .mn-select__options .mn-select__option.selected {
  background-color: var(--global-colors-ui-primary);
  color: var(--global-colors-ui-white);
  pointer-events: none;
}

.mn-select .mn-select__options .mn-select__option:hover {
  background-color: var(--global-colors-ui-secondary-light);
  color: var(--global-colors-ink-dark);
}

.mn-select .mn-select__options .mn-select__options-groups {
  border-top: var(--global-colors-sky-sky-outline);
  padding: 12px 16px 4px;
  font-size: var(--global-font-size-xs);
  color: var(--global-colors-ink-light);
  cursor: default;
}

.mn-select .mn-select__options .mn-select__option .mn-select__item {
  display: flex;
  align-items: center;
  /* padding: 12px; */
}

.mn-select .mn-select__options .mn-select__option .mn-select__color-icon {
  margin-right: 5px;
}

.mn-select.mn-select--is-disabled {
  pointer-events: none;
}

.mn-select.mn-select--is-disabled .mn-select__inner {
  background-color: var(--global-colors-sky-sky);
  border-color: var(--global-colors-sky-dark);
}

.mn-select__error {
  padding-left: 8px;
  padding-bottom: 4px;
  display: block;
  font-size: var(--global-font-size-xxs);
  font-family: var(--global-font-regular);
  color: var(--global-colors-feedback-danger);
}

.mn-select.mn-select--has-error .mn-select__inner {
  border-color: var(--global-colors-feedback-danger);
}

.mn-select .mn-select__options .mn-select__options-search {
  padding: var(--global-spacing-md);
  position: relative;
}

.mn-select .mn-select__options .mn-select__options-search input {
  width: 100%;
  padding: var(--global-spacing-xxs) var(--global-spacing-md);
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-sm);
  border: 1px solid var(--global-colors-sky-dark);
  border-radius: 4px;
  outline: 0;
}

.mn-select .mn-select__options .mn-select__options-search input:focus {
  border: 1px solid var(--global-colors-ui-primary--focus);
}

.mn-select .mn-select__options .mn-select__options-search .svg {
  position: absolute;
  top: 50%;
  line-height: 0;
  transform: translateY(-50%);
  right: 28px;
}
