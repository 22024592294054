.radio {
  z-index: 0;
  position: relative;
  display: inline-block;
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-sm);
  line-height: 1.8;
}

/* Input */
.radio > input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  z-index: -1;
  position: absolute;
  left: -10px;
  top: -8px;
  display: block;
  margin: 0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: var(--global-colors-sky-dark);
  outline: none;
  opacity: 0;
  transform: scale(1);
  pointer-events: none;
  transition: opacity 0.3s, transform 0.2s;
}

/* Span */
.radio > span {
  display: inline-block;
  width: 100%;
  cursor: var(--global-cursor-pointer);
}

/* Circle */
.radio > span::before {
  content: "";
  display: inline-block;
  box-sizing: border-box;
  margin: 2px 10px 2px 0;
  border: solid 2px; /* Safari */
  border-color: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  vertical-align: top;
  transition: border-color 0.2s;
}

/* Check */
.radio > span::after {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 0;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background-color: var(--global-colors-ui-primary);
  transform: translate(5px, 5px) scale(0);
  transition: transform 0.2s;
}

/* Checked */
.radio > input:checked {
  background-color: var(--global-colors-ui-primary);
}

.radio > input:checked + span::before {
  border-color: var(--global-colors-ui-primary);
}

.radio > input:checked + span::after {
  transform: translate(5px, 5px) scale(1);
}

/* Hover */
.radio:hover > input {
  opacity: 0.2;
}

.radio:hover > input:checked {
  opacity: 0.1;
}

/* Focus */
/* .radio > input:focus {
  opacity: 0.12;
}

.radio:hover > input:focus {
  opacity: 0.16;
}  */

/* Active */
.radio > input:active {
  opacity: 1;
  transform: scale(0);
  transition: transform 0s, opacity 0s;
}

.radio > input:active + span::before {
  border-color: var(--global-colors-ui-primary);
}

/* Disabled */
.radio > input:disabled {
  opacity: 0;
}

.radio > input:disabled + span {
  color: var(--global-colors-sky-dark);
  cursor: var(--global-cursor-default);
}

.radio > input:disabled + span::before {
  border-color: var(--global-colors-sky-dark);
}

.radio > input:disabled + span::after {
  background-color: var(--global-colors-sky-dark);
}
