.location-information {
  min-width: 300px;
  width: fit-content;
  min-height: 52px;
  border-radius: 4px;
  background-color: var(--global-colors-ui-white);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  display: inline-flex;
  align-items: center;
  font-size: var(--global-font-size-xs);
  font-family: var(--global-font-regular);
  line-height: 14px;
  text-align: left;
  color: var(--global-colors-ink-dark);
}

.location-information .icon {
  padding: 16px;
}

.location-information .location-information__details {
  padding: 8px 16px 8px 0;
  width: max-content;
}

.location-information p {
  margin: 0 0 8px 0;
  font-family: var(--global-font-medium);
}

.location-information time {
  font-family: var(--global-font-regular);
  color: var(--global-colors-ink-light);
  font-size: var(--global-font-size-xs);
  margin-top: 8px;
  padding: 0;
  border-top: 0;
}

img[alt="Google"] {
  display: none;
}
