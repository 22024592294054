.public-route-planner {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 12px;
  min-height: 110px;
  min-width: 120px;
  max-width: 100%;
  max-height: 150px;
  border-bottom: 1px solid var(--global-colors-sky-sky);
  border-right: 1px solid var(--global-colors-sky-sky);
  align-self: center;
  justify-content: flex-start;
  width: 100%;
}

.public-route-planner-statused {
  display: flex;
  flex-direction: column;
  background-color: var(--global-colors-sky-sky);
  border: 1px solid var(--global-colors-sky-sky);
  gap: 10px;
  padding: 12px;
  min-height: 140px; /*TO BE REMOVED - RESTORE TO      min-height: 120px;*/
  min-width: 120px;
  max-width: 100%;
  max-height: 150px;
  align-self: center;
  width: 100%;
}
.public-route-planner-duration {
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--global-colors-sky-sky);
  border-bottom: 1px solid var(--global-colors-sky-sky);
  padding: 12px;
  min-height: 356px; /*TO BE REMOVED - RESTORE TO    min-height: 314px;*/
  min-width: 130px;
  max-width: 100%;
  max-height: 300px;
  align-self: center;
  justify-content: center;
  width: calc(100% - 30px);
}

.public-route-planner-header-left {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 12px;
}

.public-route-planner-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-right: 1px solid var(--global-colors-sky-sky);
}

.public-route-planner-header-statused {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  height: fit-content;
  background-color: var(--global-colors-sky-sky);
  padding-top: 3px;
  padding-right: 4px;
}

.public-route-planner-header-text {
  font-family: var(--global-font-regular);
  font-size: 12px;
  font-weight: 600;
  margin-top: 8px;
}

.public-route-planner-header-text-two {
  font-family: var(--global-font-regular);
  color: var(--global-colors-ink-light);
  white-space: nowrap;
}
