.table-report-archive-row-element {
  width: 100%;
}

.archivereport-rows-container {
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: calc(100% - (200px));
  position: absolute;
}
