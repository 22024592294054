/*#region manage display table or default window*/
.fleet-page-withoutTable {
  background-color: var(--global-colors-sky-lighter);
  width: 100%;
  height: 100%;
}

.fleet-page-withoutTable-item {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.fleet-page-withoutTable-addFleet {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}
/*#endregion manage display table or default window*/

/*#region table or pagination style*/
.fleet-table {
  margin-bottom: 70px;
}

.fleet-table
  table
  tbody
  tr
  .dropdown-btn
  > .dropdown__button--ghost
  > .dropdown-only-icon {
  margin-left: 0px;
}

.fleet-table
  table
  tbody
  tr:nth-last-child(-n + 2):not(:first-child)
  .dropdown-btn.dropdown--ghost
  .dropdown-list-only-icon.list-two:not(.list-one) {
  top: -91px;
}

.fleet-table
  table
  tbody
  tr:last-child:not(:only-child)
  .dropdown-btn
  .dropdown-list-only-icon
  .list-one {
  top: -46px;
}

.fleet-table
  table
  tbody
  tr:last-child:not(:only-child)
  .dropdown-btn
  .dropdown-list-only-icon
  .list-two {
  top: -88px;
}

.fleet-pagination {
  position: fixed;
  bottom: 0;
  z-index: 3;
  /* for Chrome, Edge, Opera */
  min-width: -webkit-fill-available !important;
  max-width: -webkit-fill-available !important;
  /* for Mozilla */
  min-width: -moz-available !important;
  max-width: -moz-available !important;
}
/*#endregion table or pagination style*/
