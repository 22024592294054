.mn-pie .apexcharts-legend-marker {
  position: absolute;
}

.mn-pie .apexcharts-legend-series {
  position: relative;
  padding-left: 35px;
}

.mn-pie .apexcharts-pie {
  cursor: var(--global-cursor-pointer);
}
