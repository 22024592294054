.mn-user-box--regular {
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-xs);
  padding: 14px;
  position: relative;
  overflow: hidden;
  max-width: 302px;
}

.mn-user-box--large {
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-xs);
  padding: var(--global-spacing-md);
  position: relative;
  overflow: hidden;
  width: 868px;
  height: 72px;
}

.mn-user-box--large:hover {
  background-color: var(--global-colors-sky-light);
}

.mn-user-box__content {
  width: 100%;
  display: flex;
  align-items: center;
}

.mn-user-box__detail {
  width: 220px;
}

.mn-user-box:hover {
  background-color: var(--global-colors-sky-light);
}

.mn-user-box--active {
  border-right: 4px solid var(--global-colors-ui-primary);
  background-color: var(--global-colors-sky-light);
}

/* .mn-user-box--disabled {
  opacity: 50%;
} */

.mn-user-box__content,
.mn-user-box__icon-status,
.mn-user-box__icon-detail {
  position: relative;
  z-index: 2;
}

.mn-user-box__icon-status {
  margin-right: var(--global-spacing-sm);
}

.mn-user-box__icon {
  position: relative;
  width: 38px;
  height: 38px;
  border-radius: 150px;
  overflow: hidden;
  background-color: var(--global-colors-sky-sky);
}

.mn-user-box__icon > .svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mn-user-box__icon-status .signal-status {
  position: absolute;
  bottom: 1px;
  right: 1px;
}

.mn-user-box__id {
  font-family: var(--global-font-semi-bold);
}

.mn-user-box__data {
  font-family: var(--global-font-regular);
  font-size: var(--global-font-size-xxs);
  color: var(--global-colors-ink-light);
  float: right;
}

.mn-user-box__upper-row--regular {
  align-items: center;
  display: inline-flex;
  color: var(--global-colors-ink-dark);
  margin-bottom: var(--global-spacing-xxs);
  width: 215px;
}

.mn-user-box__upper-row--large {
  align-items: center;
  display: inline-flex;
  color: var(--global-colors-ink-dark);
  margin-bottom: var(--global-spacing-xxs);
  width: 100%;
}

.mn-user-box__upper-row > span:first-child::after {
  display: inline-block;
  margin: 0 var(--global-spacing-xxs);
}

.mn-user-box__lower-row {
  color: var(--global-colors-ink-light);
  align-items: center;
  display: inline-flex;
}

.mn-user-box__lower-row > span:first-child::after {
  content: " ";
  display: inline-block;
  margin: 0 var(--global-spacing-xxs);
}

.mn-user-box__iconText {
  display: inline-flex;
  margin-right: 7px;
  align-items: center;
  width: 8px;
}

/* Ripple */
.mn-user-box--regular .ripple {
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  background-color: #e3e5e8;
  animation: 600ms ease 1 forwards ripple;
}

@keyframes ripple {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(10);
    opacity: 0.375;
  }
  100% {
    transform: scale(35);
    opacity: 0;
  }
}
