.mn-vehicle-box-group {
  font-family: var(--global-font-medium);
  max-width: 302px;
}

.mn-vehicle-box-group__label {
  font-family: var(--global-font-semi-bold);
}

.mn-vehicle-box-group__button {
  padding: var(--global-spacing-sm) var(--global-spacing-md);
  background: transparent;
  border: 0;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-size: var(--global-font-size-sm);
  font-family: var(--global-font-semi-bold);
  color: var(--global-colors-ink-dark);
}

.mn-vehicle-box-group__button:hover {
  cursor: var(--global-cursor-pointer);
}

.mn-vehicle-box-group__button .mn-vehicle-box-group__icon > .svg {
  color: var(--global-colors-ink-dark);
}

.mn-vehicle-box-group__status-square-container {
  display: inline-flex;
  gap: 4px;
  margin-left: auto;
  margin-right: 12px;
}

.mn-vehicle-box-group__status-square {
  color: var(--global-colors-ui-white);
  border-radius: 4px;
  font-size: 10px;
  height: 18px;
  padding: 6px;
  align-items: center;
  justify-content: center;
  display: flex;
}
