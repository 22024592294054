/* All Fonts */
@font-face {
  font-family: global-font-regular;
  src: url(../src/font/SF-Pro-Text-Regular.otf);
  font-display: block;
}

@font-face {
  font-family: global-font-medium;
  src: url(../src/font/SF-Pro-Text-Medium.otf);
  font-display: block;
}

@font-face {
  font-family: global-font-light;
  src: url(../src/font/SF-Pro-Text-Light.otf);
  font-display: block;
}

@font-face {
  font-family: global-font-bold;
  src: url(../src/font/SF-Pro-Text-Bold.otf);
  font-display: block;
}

@font-face {
  font-family: global-font-semibold;
  src: url(../src/font/SF-Pro-Text-Semibold.otf);
  font-display: block;
}

@font-face {
  font-family: global-font-heavy;
  src: url(../src/font/SF-Pro-Text-Heavy.otf);
  font-display: block;
}
:root {
  /* Colors */
  /* 01 - Fill */
  --global-colors-ui-primary: #0052bd;
  --global-colors-ui-primary--focus: #296dc7;
  --global-colors-ui-primary--hover: #01449b;
  --global-colors-ui-secondary: #00aaff;
  --global-colors-ui-secondary-light: #d6e3f4;
  --global-colors-ui-white: #ffffff;
  --global-colors-ui-black: #0000;
  --global-colors-cyan-blue: #335278;
  --global-colors-ink-dark: #031832;
  --global-colors-ink-ink: #35465b;
  --global-colors-ink-light: #687484;
  --global-colors-ink-lighter: #cdd1d6;
  --global-colors-sky-dark: #d2d5da;
  --global-colors-sky-sky: #e4e6e9;
  --global-colors-ui-sky-sky--hover: #aeb4bd;
  --global-colors-ui-sky-sky--focus: #b0b2bf;
  --global-colors-sky-light: #f1f2f3;
  --global-colors-sky-lighter: #f7f7f8;
  --global-colors-feedback-success: #00cfa2;
  --global-colors-feedback-danger: #ff4f48;
  --global-colors-feedback-danger--hover: #c34242;
  --global-colors-feedback-danger--focus: #ff726c;
  --global-colors-feedback-warning: #fbcd03;
  --global-colors-status-online: #00cfa2;
  --global-colors-status-offline: #ff4f48;
  --global-colors-status-maintenance: #ffbe48;
  --global-colors-status-stopped: #687484;
  --global-colors-radialbar-break: #f7ab82;
  --global-colors-icon-disabled: #d8d8d8;
  --global-colors-status-warning: #fed005;

  /* 02 - Basic Palette */
  --global-colors-palette-black: #000000;
  --global-colors-palette-dark-grey: #333333;
  --global-colors-palette-grey: #656565;
  --global-colors-palette-light-grey: #989898;
  --global-colors-palette-lighter-grey: #cbcbcb;
  --global-colors-palette-violet: #7a3ca1;
  --global-colors-palette-light-violet: #b089c6;
  --global-colors-palette-lighter-violet: #e4d8ec;
  --global-colors-palette-purple: #020088;
  --global-colors-palette-light-purple: #6667b9;
  --global-colors-palette-lighter-purple: #cccce8;
  --global-colors-palette-blue: #0265b3;
  --global-colors-palette-light-blue: #66a3d2;
  --global-colors-palette-lighter-blue: #cde0ef;
  --global-colors-palette-green: #007f4d;
  --global-colors-palette-light-green: #67b293;
  --global-colors-palette-lighter-green: #cbe5da;
  --global-colors-palette-dark-yellow: #fed005;
  --global-colors-palette-yellow: #fef305;
  --global-colors-palette-light-yellow: #fff967;
  --global-colors-palette-lighter-yellow: #fffdcc;
  --global-colors-palette-orange: #f57631;
  --global-colors-palette-light-orange: #f7ab82;
  --global-colors-palette-lighter-orange: #fde3d6;
  --global-colors-palette-red: #fd0201;
  --global-colors-palette-light-red: #ff6766;
  --global-colors-palette-lighter-red: #ffcccb;
  --global-colors-palette-bordeaux: #a8272b;
  --global-colors-palette-light-bordeaux: #c97d7f;
  --global-colors-palette-lighter-bordeaux: #edd3d4;

  /* 03 - Gradient */
  --global-gradient-primary: linear-gradient(
    45deg,
    var(--global-colors-ui-secondary),
    var(--global-colors-ui-primary)
  );
  --global-gradient-day: linear-gradient(45deg, #29c3ff, #c3f5ff);
  --global-gradient-night: linear-gradient(45deg, #38397a, #674e91);
  --global-gradient-dark: linear-gradient(
    45deg,
    var(--global-colors-ink-dark),
    var(--global-colors-ink-ink)
  );

  /* 04 - Outline */
  --global-colors-ui-primary-outline: 1px solid var(--global-colors-ui-primary);
  --global-colors-ui-secondary-outline: 1px solid
    var(--global-colors-ui-secondary);
  --global-colors-ui-white-outline: 1px solid var(--global-colors-ui-white);
  --global-colors-ink-dark-outline: 1px solid var(--global-colors-ink-dark);
  --global-colors-ink-ink-outline: 1px solid var(--global-colors-ink-ink);
  --global-colors-ink-light-outline: 1px solid var(--global-colors-ink-light);
  --global-colors-ink-lighter-outline: 1px solid
    var(--global-colors-ink-lighter);
  --global-colors-sky-dark-outline: 1px solid var(--global-colors-sky-dark);
  --global-colors-sky-sky-outline: 1px solid var(--global-colors-sky-sky);
  --global-colors-sky-light-outline: 1px solid var(--global-colors-sky-light);
  --global-colors-sky-lighter-outline: 1px solid
    var(--global-colors-sky-lighter);
  --global-colors-feedback-success-outline: 1px solid
    var(--global-colors-feedback-success);
  --global-colors-feedback-danger-outline: 1px solid
    var(--global-colors-feedback-danger);

  /* 05 - Overlays */
  --global-colors-overlay-background: #02285a;

  /* 06 - Shadows */
  --global-colors-shadow-ui-primary: 0 1px 3px 0 rgba(0, 170, 255, 0.259); /* Same as --global-colors-ui-secondary */
  --global-colors-shadow-feedback-danger: 0 1px 3px 0 rgba(255, 79, 72, 0.28); /* Same as --global-colors-feedback-danger */

  /* 07 - Transparent */
  --global-colors-transparent-ui-4: rgba(
    255,
    255,
    255,
    0.04
  ); /* Same as --global-colors-ui-white */
  --global-colors-transparent-ui-8: rgba(255, 255, 255, 0.08);
  --global-colors-transparent-ui-16: rgba(255, 255, 255, 0.16);
  --global-colors-transparent-ui-24: rgba(255, 255, 255, 0.24);
  --global-colors-transparent-ui-48: rgba(255, 255, 255, 0.48);
  --global-colors-transparent-ink-4: rgba(
    3,
    24,
    50,
    0.04
  ); /* Same as --global-colors-ink-dark */
  --global-colors-transparent-ink-8: rgba(3, 24, 50, 0.08);
  --global-colors-transparent-ink-16: rgba(3, 24, 50, 0.16);
  --global-colors-transparent-ink-24: rgba(3, 24, 50, 0.24);
  --global-colors-transparent-ink-48: rgba(3, 24, 50, 0.48);

  /* Elevations */
  --global-elevation-00: 0 0 0 0 transparent;
  --global-elevation-01: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  --global-elevation-02: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
  --global-elevation-04: 0 4px 5px 0 rgba(0, 0, 0, 0.12),
    0 1px 10px 0 rgba(0, 0, 0, 0.06), 0 2px 4px -1px rgba(0, 0, 0, 0.01);
  --global-elevation-08: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2 rgba(0, 0, 0, 0.06), 0 5px 5px -3px rgba(0, 0, 0, 0.01);

  /* Typography */
  --global-font-light: global-font-light; /* 200 */
  --global-font-regular: global-font-regular; /* 400 */
  --global-font-medium: global-font-medium;
  --global-font-semi-bold: global-font-semibold; /* 600 */
  --global-font-bold: global-font-bold; /* 700 */
  --global-font-heavy: global-font-heavy; /* 900 */
  --global-font-size-xxxxs: 0.6rem;
  --global-font-size-xxxs: 0.8rem;
  --global-font-size-xxs: 1rem;
  --global-font-size-xs: 1.2rem;
  --global-font-size-s: 1.3rem;
  --global-font-size-sm: 1.4rem;
  --global-font-size-md: 1.6rem;
  --global-font-size-lg: 1.8rem;
  --global-font-size-xl: 2rem;
  --global-font-size-xxl: 2.4rem;

  /* Spacing */
  --global-spacing-xxs: 0.4rem;
  --global-spacing-xs: 0.8rem;
  --global-spacing-sm: 1.2rem;
  --global-spacing-md: 1.6rem;
  --global-spacing-ld: 1.8rem;
  --global-spacing-lg: 2.4rem;
  --global-spacing-xl: 3.2rem;
  --global-spacing-xxl: 4.8rem;
  --global-spacing-xxxl: 6.4rem;

  /* Grid */
  --layout-grid-margin-phone: 16px;
  --layout-grid-gutter-phone: 16px;
  --layout-grid-margin-tablet: 16px;
  --layout-grid-gutter-tablet: 16px;
  --layout-grid-margin-desktop: 24px;
  --layout-grid-gutter-desktop: 24px;

  /* Cursor */
  --global-cursor-pointer: url(../src/assets/Cursors/Hand/Pointing.svg) 8 4,
    pointer;
  --global-cursor-not-allowed: url(../src/assets/Cursors/Circle/Not-allowed.svg)
      8 4,
    not-allowed;
  --global-cursor-move: url(../src/assets/Cursors/Move.svg) 8 4, move;
  --global-cursor-default: url(../src/assets/Cursors/Arrow/Default.svg), default;
  --global-cursor-help: url(../src/assets/Cursors/Help.svg) 8 4, help;
  --global-cursor-progress: url(../src/assets/Cursors/Circle/Loading.svg) 8 4,
    progress;
  --global-cursor-crosshair: url(../src/assets/Cursors/Cross.svg) 8 4, crosshair;
  --global-cursor-cell: url(../src/assets/Cursors/Cell.svg) 8 4, cell;
  --global-cursor-text: url(../src/assets/Cursors/text/ibeam-horizontal.svg) 12
      6,
    text;
  --global-cursor-vertical-text: url(../src/assets/Cursors/text/ibeam-vertical.svg)
      8 4,
    vertical-text;
  --global-cursor-alias: url(../src/assets/Cursors/make-alias.svg) 8 4, alias;
  --global-cursor-copy: url(../src/assets/Cursors/Circle/Copy.svg) 8 4, copy;

  --global-cursor-e-resize: url(../src/assets/Cursors/ResizeW/East.svg) 8 4,
    e-resize;
  --global-cursor-n-resize: url(../src/assets/Cursors/ResizeW/North.svg) 8 4,
    n-resize;
  --global-cursor-ne-resize: url(../src/assets/Cursors/ResizeW/North-East.svg) 8
      4,
    ne-resize;
  --global-cursor-nw-resize: url(../src/assets/Cursors/ResizeW/North-West.svg) 8
      4,
    nw-resize;
  --global-cursor-s-resize: url(../src/assets/Cursors/ResizeW/South.svg) 8 4,
    s-resize;
  --global-cursor-se-resize: url(../src/assets/Cursors/ResizeW/South-East.svg) 8
      4,
    se-resize;
  --global-cursor-sw-resize: url(../src/assets/Cursors/ResizeW/South-West.svg) 8
      4,
    sw-resize;
  --global-cursor-w-resize: url(../src/assets/Cursors/ResizeW/West.svg) 8 4,
    w-resize;
  --global-cursor-ew-resize: url(../src/assets/Cursors/ResizeW/East-West.svg) 12
      6,
    ew-resize;
  --global-cursor-ns-resize: url(../src/assets/Cursors/ResizeW/North-South.svg)
      8 4,
    ns-resize;
  --global-cursor-nesw-resize: url(../src/assets/Cursors/ResizeW/North-East-South-West.svg)
      8 4,
    nesw-resize;
  --global-cursor-nwse-resize: url(../src/assets/Cursors/ResizeW/North-West-South-East.svg)
      8 4,
    nwse-resize;
  --global-cursor-col-resize: url(../src/assets/Cursors/Resize/LeftRIght.svg) 12
      6,
    col-resize;
  --global-cursor-row-resize: url(../src/assets/Cursors/Resize/UpDown.svg) 8 4,
    row-resize;

  --global-cursor-grab: url(../src/assets/Cursors/Hand/Open.svg) 8 4, grab;
  --global-cursor-grabbing: url(../src/assets/Cursors/Hand/Closed.svg) 8 4,
    grabbing;

  --global-cursor-zoom-in: url(../src/assets/Cursors/Zoom/in.svg) 8 4, zoom-in;
  --global-cursor-zoom-out: url(../src/assets/Cursors/Zoom/out.svg) 8 4,
    zoom-out;
}

/* RESET */
html {
  font-size: 10px;
  box-sizing: border-box;
  cursor: var(--global-cursor-default);
  overflow: hidden;
}

/* Scrollbar */

/* Chrome, Edge, and Safari */

/* Firefox */
.firefoxStyle {
  scrollbar-color: var(--global-colors-sky-dark) transparent;
  scrollbar-width: thin;
}

/* width */
*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

/* Track */
*::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: var(--global-colors-sky-dark);
  border-radius: 100px;
  border: 4px solid transparent;
  background-clip: content-box;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: var(--global-colors-ink-lighter);
  border: 2px solid transparent;
  border-radius: 100px;
  background-clip: content-box;
  cursor: var(--global-cursor-pointer);
}

body {
  margin: 0;
  user-select: none;
  font-family: var(--global-font-regular);
  color: var(--global-colors-ink-dark);
}

a {
  text-decoration: none !important;
  color: var(--global-colors-ui-secondary) !important;
}

button {
  cursor: var(--global-cursor-pointer) !important;
}

input,
textarea {
  cursor: var(--global-cursor-text);
}

/* END RESET */

.h1 {
  font-weight: var(--global-font-regular);
  font-size: var(--global-font-size-xxl);
  line-height: 3.2rem;
}

.h2 {
  font-weight: var(--global-font-regular);
  font-size: var(--global-font-size-xl);
  line-height: 2.8rem;
}

.h3 {
  font-weight: var(--global-font-regular);
  font-size: var(--global-font-size-lg);
  line-height: 2.6rem;
}

.h4 {
  font-weight: var(--global-font-regular);
  font-size: var(--global-font-size-md);
  line-height: 2.4rem;
}

.h5 {
  font-weight: var(--global-font-regular);
  font-size: var(--global-font-size-sm);
  line-height: 2.2rem;
}

.subtitle-1 {
  font-weight: var(--global-font-light);
  font-size: var(--global-font-size-lg);
  line-height: 2.6rem;
}

.subtitle-2 {
  font-weight: var(--global-font-light);
  font-size: var(--global-font-size-md);
  line-height: 2.4rem;
}

.body-1 {
  font-weight: var(--global-font-regular);
  font-size: var(--global-font-size-sm);
  line-height: 1.8rem;
}

.body-2 {
  font-weight: var(--global-font-regular);
  font-size: var(--global-font-size-xs);
  line-height: 1.6rem;
}

.label-1 {
  font-weight: var(--global-font-semi-bold);
  font-size: var(--global-font-size-xs);
  line-height: 1.6rem;
}

.label-2 {
  font-weight: var(--global-font-regular);
  font-size: var(--global-font-size-xxs);
  line-height: 1.4rem;
}

.h1--dark,
.h2--dark,
.h3--dark,
.h4--dark,
.h5--dark,
.subtitle-1--dark,
.subtitle-2--dark,
.body-1--dark,
.body-2--dark,
.label-1--dark,
.label-2--dark {
  color: var(--global-colors-ink-dark);
}

.h1--light,
.h2--light,
.h3--light,
.h4--light,
.h5--light,
.subtitle-2--light,
.body-1--light,
.body-2--light,
.label-1--light,
.label-2--light {
  color: var(--global-colors-ink-light);
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.text-align-center {
  text-align: center;
}

/* Progress Bar */

.progress-bar {
  display: inline;
  border: none;
  background-color: var(--global-colors-sky-sky);
  border-radius: 150px;
  overflow: hidden;
  height: 6px;
  width: 70px;
}

.progress-bar::-webkit-progress-value {
  background-color: var(--global-colors-ui-primary);
}

.progress-bar--orange::-webkit-progress-value {
  background-color: var(--global-colors-radialbar-break);
}

.progress-bar--blue::-webkit-progress-value {
  background-color: var(--global-colors-ui-secondary);
}

.progress-bar--green::-webkit-progress-value {
  background-color: var(--global-colors-feedback-success);
}

.progress-bar::-webkit-progress-bar {
  background-color: var(--global-colors-sky-sky);
}

.progress-bar::-moz-progress-bar {
  background-color: var(--global-colors-sky-sky);
}
