.ior-reportDate {
  font-weight: 600;
  margin-bottom: 0;
  font-family: var(--global-font-semi-bold);
}

.ior-reportTime {
  color: var(--global-colors-ink-light);
}

.ior-rows-container {
  width: 100%;
  overflow-x: auto;
  overflow-y: scroll;
  max-height: calc(100% - (312px));
  position: absolute;
}

.ior-rows-container .mn-table tbody tr td:last-child .svg {
  padding: 0px;
}

.ior-rows-container-collapsed {
  width: 100%;
  overflow-x: scroll;
  max-height: calc(100% - (312px));
}

.ior-rows-container-collapsed table {
  width: 100%;
  overflow-x: auto;
}

.ior-rows-container table {
  width: 100%;
  overflow-x: auto;
}

.ior-rows-container table tbody tr td:last-child {
  padding: 9px var(--global-spacing-lg);
  cursor: var(--global-cursor-default);
  float: none;
}

.ior-rows-container-collapsed table tbody tr td:last-child {
  padding: 9px var(--global-spacing-lg);
  cursor: var(--global-cursor-default);
}

.ior-rows-container table tbody tr td {
  padding: 9px var(--global-spacing-lg);
  cursor: var(--global-cursor-default);
}

.ior-rows-container-collapsed table tbody tr td {
  padding: 9px var(--global-spacing-lg);
  cursor: var(--global-cursor-default);
}

.ior-checkbox-list {
  float: left;
  margin-top: auto;
  margin-bottom: 12px;
}

.ior-checkbox {
  width: 40px;
  height: 80px;
  padding: 14px var(--global-spacing-lg);
  font-size: var(--global-font-size-sm);
  font-family: var(--global-font-regular);
  word-wrap: break-word;
  display: flex;
  align-items: center;
}

.ior-marker-list {
  float: right;
  margin-top: auto;
  margin-bottom: 12px;
}

.ior-marker {
  width: 84px;
  padding: 10px var(--global-spacing-lg);
  font-size: var(--global-font-size-sm);
  font-family: var(--global-font-regular);
  word-wrap: break-word;
  display: flex;
  align-items: center;
}

.ior-marker:nth-child(odd),
.ior-checkbox:nth-child(odd) {
  background-color: var(--global-colors-sky-lighter);
}

.ior-marker:nth-child(even),
.ior-checkbox:nth-child(even) {
  background-color: var(--global-colors-ui-white);
}

.ior-table-container .page-count {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.ior-table-container-collapsed {
  max-height: 79%;
  overflow-y: auto;
  width: 64%;
}

.ior-table-container-collapsed .page-count {
  width: 64%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.ior-map-container {
  width: 36%;
  height: calc(100% - (100px));
}

.ior-marker-column {
  position: fixed;
}

.ior-container .withFilterBar {
  overflow-y: hidden;
}

.details-reportUtilization-wrapper {
  padding: 16px 16px 16px 32px;
  font-size: var(--global-font-size-sm);
  line-height: 18px;
  text-align: left;
  height: 100px;
  border-radius: 4px;
  border-bottom: var(--global-colors-sky-sky-outline);
}

.details-reportUtilization-wrapper .details-report-title {
  margin-bottom: 16px;
  font-family: var(--global-font-medium);
  font-size: var(--global-font-size-sm);
}

.details-reportUtilization-wrapper .details-report-container {
  height: 34px;
  align-items: center;
  width: 100%;
  display: flex;
}

.details-reportUtilization-wrapper .details-report-icons {
  display: flex;
  padding-left: 8px;
  float: left;
  min-width: 1022px;
}

.details-reportUtilization-wrapper .details-report-item {
  margin-right: 20px;
  max-width: 16%;
  min-width: 120px;
  width: 22vw;
}

.details-reportUtilization-wrapper
  .details-report-item
  .mn-data-label
  .mn-data-label__text
  .mn-data-label__text-label {
  width: 120px;
}

.details-reportUtilization-wrapper .details-report-item:last-child {
  margin: 0;
}

.details-reportUtilization-wrapper .details-report-button {
  display: flex;
  height: 32px;
  float: right;
  margin-left: auto;
}

.details-reportUtilization-wrapper .details-report-button button {
  min-width: 146px;
}

.details-reportUtilization-wrapper .details-report-loader {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid var(--global-colors-ink-light);
  border-top-color: transparent;
  animation: spinner 0.6s linear infinite;
  margin-right: 15px;
  margin-left: 70px;
  margin-top: -17px;
}
